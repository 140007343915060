import { urls } from "config/url";
import Asset from "pages/user-asset";
import Calendar from "pages/user-calendar";
import DashBoard from "pages/user-dashboard";
import LeaveForm from "pages/user-leaveform";
import OverTime from "pages/user-overtime";
import UserInformation from "pages/user-setting";
import TimeSheet from "pages/user-timesheet";
import TimeSheetDetailPage from "pages/user-timesheet/detail/TimeSheetDetail";
import WorkFromHome from "pages/user-work-from-home";
import { Navigate } from "react-router-dom";

const userRoutes = [
  { index: true, element: <Navigate to={urls.web.user.dashboard} replace /> },
  {
    path: urls.web.user.dashboard,
    element: <DashBoard />
  },
  {
    path: urls.web.user.timeSheet,
    element: <TimeSheet />
  },
  {
    path: urls.web.user.timeSheetDetail,
    element: <TimeSheetDetailPage />
  },
  {
    path: urls.web.user.overtime,
    element: <OverTime />
  },
  {
    path: urls.web.user.leaveForm,
    element: <LeaveForm />
  },
  {
    path: urls.web.user.calendar,
    element: <Calendar />
  },
  {
    path: urls.web.user.wfh,
    element: <WorkFromHome />
  },
  {
    path: urls.web.user.asset,
    element: <Asset />
  },
  {
    path: urls.web.user.userSetting,
    element: <UserInformation />
  }
];

export default userRoutes;
