import axiosClient from "api/api-config/axiosClient";

const SwapDayAPI = {
  async getSwapDayByYear(year) {
    const url = `/Timesheet/SwapDay/GetListView/${year}`;
    return axiosClient.get(url);
  },

  async createSwapDay(data) {
    const url = `/Timesheet/SwapDay/Create`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: data
    });
  },
  async updateSwapDay(data) {
    const url = `/Timesheet/SwapDay/Update`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: data
    });
  },
  async deleteSwapDay(id) {
    const url = `/Timesheet/SwapDay/Delete/${id}`;
    return axiosClient.delete(url);
  }
};
export default SwapDayAPI;
