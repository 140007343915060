import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Dialog, DialogContent } from "@mui/material";
import DatePickerTTD from "components/DateTimePicker/DatePickerTTD";
import SelectTTD from "components/PhaseIII/SeclectTTD";
import TextInputTTD from "components/PhaseIII/TextInputTTD";
import Editor from "components/TextEditor";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { formatDateYYYYMMDD } from "utils/DateTimeUtils";
import * as yup from "yup";
import SelectObject from "./SelectObject";

function NoteForm({ onClose, open, noteInfo, onCreateNote, isUpdate }) {
  const listObjects = useSelector((state) => state.calendar.objects);
  const timeRegExp = /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;

  const valuesRepeat = [
    {
      id: 1,
      label: "None",
      value: "None"
    },
    {
      id: 2,
      label: "Every Day",
      value: "EveryDay"
    },
    {
      id: 3,
      label: "Every Week",
      value: "EveryWeek"
    },
    {
      id: 4,
      label: "Every Month",
      value: "EveryMonth"
    }
  ];

  const schema = yup.object().shape({
    title: yup.string().required("Title không được để trống"),
    date: yup.string().required("Yêu cầu nhập"),
    start: yup.string().required("Yêu cầu nhập").matches(timeRegExp, "Thời gian không hợp lệ"),
    end: yup.string().required("Yêu cầu nhập").matches(timeRegExp, "Thời gian không hợp lệ"),
    content: yup.string().required("Work Description không được bỏ trống")
  });

  const form = useForm({
    defaultValues: {
      title: noteInfo?.title,
      date: dayjs(noteInfo?.date) || dayjs(new Date()),
      start: noteInfo?.start || "08:00",
      end: noteInfo?.end || "17:00",
      objectId: noteInfo?.objectId || (listObjects && listObjects[0]?.id),
      repeatType: noteInfo?.repeatType || valuesRepeat[0].value,
      content: noteInfo?.content || ""
    },
    resolver: yupResolver(schema)
  });

  const handleClose = () => {
    onClose(false);
  };

  const handleSubmit = async () => {
    var valueForm = form.getValues();

    let dataSample = {
      id: noteInfo?.id,
      title: valueForm?.title,
      date: formatDateYYYYMMDD(valueForm?.date),
      objectId: valueForm?.objectId,
      start: valueForm?.start,
      end: valueForm?.end,
      repeatType: valueForm?.repeatType,
      content: valueForm?.content
    };

    if (onCreateNote) {
      onCreateNote(dataSample);
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="lg" PaperProps={{ sx: { borderRadius: "5px" } }}>
      <DialogContent style={{ padding: "20px" }}>
        <div className="w-[350px] flex flex-col justify-center gap-3 overflow-x-hidden">
          <div className="flex flex-col items-center">
            <h3>
              <strong>{isUpdate ? "Update" : "New"}</strong>
            </h3>
          </div>

          <div className="flex flex-row items-start">
            <div className="w-full">
              <TextInputTTD
                form={form}
                defaultValue={noteInfo?.title}
                name="title"
                label="Title"
                requiredInput={true}
              />
            </div>
          </div>

          <div className="flex flex-row items-start gap-2">
            <div className="w-[50%]">
              <DatePickerTTD name="date" label="Date" requiredInput={true} disablePreviousDate={true} form={form} />
            </div>
            <div className="w-[50%]">
              <SelectObject label="Object" listObjects={listObjects} name="color" form={form} requiredInput />
            </div>
          </div>

          <div className="flex flex-row items-start gap-2">
            <TextInputTTD form={form} defaultValue={noteInfo?.start} name="start" label="Start" requiredInput={true} />

            <TextInputTTD form={form} defaultValue={noteInfo?.end} name="end" label="End" requiredInput={true} />
          </div>

          <div className="flex flex-row items-start">
            <SelectTTD
              listValues={valuesRepeat}
              defaultValue={valuesRepeat[0]?.value || "None"}
              form={form}
              name="repeatType"
              label="Repeat"
            />
          </div>

          <div className="w-full flex flex-col items-start">
            <div className="flex flex-row items-start ml-2">
              <span>Description</span>
              <p style={{ color: "red", marginLeft: "5px" }}>*</p>
            </div>
            <div className="w-full flex flex-col items-start">
              <Editor name="content" form={form} />
            </div>
          </div>

          <div className="w-full flex flex-row justify-between items-start">
            <Button
              variant="contained"
              size="small"
              onClick={form.handleSubmit(handleSubmit)}
              sx={{
                textTransform: "capitalize",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "#BCCDEA"
                },
                background: "#BCCDEA",
                color: "#001B48",
                fontWeight: 600,
                borderRadius: "10px",
                width: "90px",
                padding: "5px 0"
              }}
            >
              {isUpdate ? "Update" : "Create"}
            </Button>

            <Button
              variant="contained"
              onClick={() => {
                handleClose();
              }}
              size="small"
              sx={{
                textTransform: "capitalize",
                boxShadow: "none",
                "&:hover": { boxShadow: "none", background: "#F0F0F0" },
                background: "#F0F0F0",
                color: "#555555",
                fontWeight: 600,
                borderRadius: "10px",
                width: "90px",
                padding: "5px 0",
                marginLeft: "20px"
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default NoteForm;
