import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Dialog, DialogContent } from "@mui/material";
import ProjectAPI from "api/ProjectAPI";
import CustomInputField from "components/CustomInputField";
import DatePickerFormInput from "components/DateTimePicker/DatePickerFormInput";
import DropdownListSelection from "components/DropdownListSelection";
import { useForm } from "react-hook-form";
import { formatDateYYYYMMDD } from "utils/DateTimeUtils";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import * as yup from "yup";
import ViewGroup from "../Groups/ViewGroups";

const ViewProject = (props) => {
  const {
    projectDetail,
    setProjectDetail,
    dataListTeam,
    projectManagers,
    dataTypeProject,
    dataStatusProject,
    dataOptionClient,
    open,
    close
  } = props;

  const schema = yup.object().shape({
    type: yup.string().required(" Yêu cầu nhập "),
    code: yup.string().required(" Yêu cầu nhập "),
    teamId: yup.string().required(" Yêu cầu nhập "),
    clientId: yup.string().required(" Yêu cầu nhập "),
    quotationHour: yup.string().required(" Yêu cầu nhập "),
    name: yup.string().required(" Yêu cầu nhập "),
    manager: yup.string().required(" Yêu cầu nhập ")
  });

  const form = useForm({
    defaultValues: {
      fiscalYear: projectDetail?.fiscalYear,
      type: projectDetail?.type,
      projectNumber: projectDetail?.projectNumber,
      code: projectDetail?.code,
      teamId: projectDetail?.teamId,
      clientId: projectDetail?.clientId,
      status: projectDetail?.status,
      quotationHour: projectDetail?.quotationHour,
      name: projectDetail?.name,
      fullName: projectDetail?.fullName,
      manager: projectDetail?.manager,
      startedDate: new Date(projectDetail?.startedDate),
      finishedDate: new Date(projectDetail?.finishedDate)
    },
    resolver: yupResolver(schema)
  });

  const handleSubmitFunc = async () => {
    let valueSubmit = form.getValues();

    valueSubmit = {
      id: projectDetail?.id,
      fiscalYear: valueSubmit?.fiscalYear,
      type: valueSubmit?.type,
      projectNumber: valueSubmit?.projectNumber,
      code: valueSubmit?.code,
      teamId: valueSubmit?.teamId,
      clientId: valueSubmit?.clientId,
      status: valueSubmit?.status,
      quotationHour: valueSubmit?.quotationHour,
      name: valueSubmit?.name,
      fullName: valueSubmit?.fullName,
      manager: valueSubmit?.manager,
      startedDate: formatDateYYYYMMDD(valueSubmit?.startedDate),
      finishedDate: formatDateYYYYMMDD(valueSubmit?.finishedDate),
      groupIds: projectDetail?.groups?.map((item) => item.id)
    };

    try {
      const response = await ProjectAPI.update(valueSubmit);
      if (response?.status === 200) {
        ToastShowSuccess("success");
        close();
      }
    } catch (error) {
      ToastShowError(error.message);
    }
  };

  return (
    <Dialog onClose={close} open={open} maxWidth="lg" PaperProps={{ sx: { borderRadius: "5px" } }}>
      <DialogContent>
        <div>
          <h1 className="text-center text-2xl pb-4 font-bold">View</h1>
          <div className="flex gap-2">
            <div className="w-40">
              <CustomInputField
                name="fiscalYear"
                label={
                  <div>
                    <span>Fiscal</span>
                  </div>
                }
                form={form}
                disable={true}
              />
            </div>
            <div className="w-40">
              <DropdownListSelection
                name="type"
                label={
                  <div>
                    <span className="ml-[2px]">Project Type</span>
                    <span className="text-red-500 text-base ml-1">*</span>
                  </div>
                }
                disable={true}
                form={form}
                listSelection={dataTypeProject.map((item) => {
                  return {
                    value: item,
                    label: item
                  };
                })}
                width="200px"
              />
            </div>
            <div className="w-40">
              <CustomInputField
                name="projectNumber"
                label={
                  <div>
                    <span>Project Number</span>
                  </div>
                }
                form={form}
                disable={true}
              />
            </div>
            <div className="w-40">
              <CustomInputField
                name="code"
                label={
                  <div>
                    <span>Project Code</span>
                    <span className="text-red-500 text-base ml-1">*</span>
                  </div>
                }
                form={form}
                disable={true}
              />
            </div>
          </div>

          <div className="flex gap-2">
            <div className="w-40">
              <DropdownListSelection
                name="teamId"
                label={
                  <div>
                    <span className="ml-[2px]">Team</span>
                    <span className="text-red-500 text-base ml-1">*</span>
                  </div>
                }
                disable={true}
                form={form}
                listSelection={dataListTeam.map((item) => {
                  return {
                    value: item.id,
                    label: item.code
                  };
                })}
                width="200px"
              />
            </div>
            <div className="w-40">
              <DropdownListSelection
                name="clientId"
                label={
                  <div>
                    <span className="ml-[2px]">Client</span>
                    <span className="text-red-500 text-base ml-1">*</span>
                  </div>
                }
                disable={true}
                form={form}
                listSelection={dataOptionClient.map((item) => ({
                  value: item.id,
                  label: item.name
                }))}
                width="200px"
              />
            </div>
            <div className="w-40">
              <DropdownListSelection
                name="status"
                label={
                  <div>
                    <span className="ml-[2px]">Status</span>
                  </div>
                }
                disable={true}
                form={form}
                listSelection={dataStatusProject.map((item) => {
                  return {
                    value: item,
                    label: item
                  };
                })}
                width="200px"
              />
            </div>
            <div className="w-40">
              <CustomInputField
                name="quotationHour"
                label={
                  <div>
                    <span>Quotation Hours</span>
                    <span className="text-red-500 text-base ml-1">*</span>
                  </div>
                }
                form={form}
                disable={true}
              />
            </div>
          </div>

          <div style={{ width: "665px" }}>
            <CustomInputField
              name="name"
              label={
                <div>
                  <span>Project Name</span>
                  <span className="text-red-500 text-base ml-1">*</span>
                </div>
              }
              form={form}
              disable={true}
            />
          </div>

          <div style={{ width: "665px" }}>
            <CustomInputField
              name="fullName"
              disable
              label={
                <div>
                  <span>Full Project Name</span>
                </div>
              }
              form={form}
            />
          </div>

          <div className="flex gap-2">
            <div className="w-1/2">
              <DropdownListSelection
                name="manager"
                label={
                  <div>
                    <span className="ml-[2px]">Project Manager</span>
                    <span className="text-red-500 text-base ml-1">*</span>
                  </div>
                }
                disable={true}
                form={form}
                listSelection={projectManagers.map((item) => {
                  return {
                    value: item.id,
                    label: item.name
                  };
                })}
                width="200px"
              />
            </div>
            <div className="w-52">
              <DatePickerFormInput
                name="startedDate"
                label={
                  <div>
                    <span>Start Date</span>
                    <span className="text-red-500 text-base ml-1">*</span>
                  </div>
                }
                form={form}
                disable={true}
              />
            </div>
            <div className="w-56">
              <DatePickerFormInput
                name="finishedDate"
                label={
                  <div>
                    <span>Finish Date</span>
                  </div>
                }
                form={form}
                disable={true}
              />
            </div>
          </div>
          <div>
            <ViewGroup projectDetail={projectDetail} setProjectDetail={setProjectDetail} />
          </div>
          <div className="flex justify-end gap-5 pt-4 ">
            <Button
              variant="contained"
              size="small"
              onClick={form.handleSubmit(handleSubmitFunc)}
              sx={{
                textTransform: "capitalize",
                boxShadow: "none",
                "&:hover": { boxShadow: "none", background: "#bccdea" },
                background: "#bccdea",
                color: "#001b48",
                fontWeight: "600",
                borderRadius: "10px",
                width: "90px",
                padding: "5px 0",
                visibility: "hidden"
              }}
            >
              update
            </Button>

            <Button
              variant="contained"
              onClick={() => {
                close();
              }}
              size="small"
              sx={{
                textTransform: "capitalize",
                boxShadow: "none",
                "&:hover": { boxShadow: "none", background: "#f0f0f0" },
                background: "#f0f0f0",
                color: "#555555",
                fontWeight: "600",
                borderRadius: "10px",
                width: "90px",
                padding: "5px 0"
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ViewProject;
