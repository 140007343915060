import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import AdminTimeSheetAPI from "api/AdminTimesheetAPI";
import DatePickerTTD from "components/DateTimePicker/DatePickerTTD";
import TextInputTTD from "components/PhaseIII/TextInputTTD";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { formatDateYYYYMMDD } from "utils/DateTimeUtils";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_CREATE_SUCCESS_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import { showAllValueErrors } from "utils/formErrorUtil";
import * as yup from "yup";
import SelectUser from "./SelectUser";

function EditFingerPrinterPopup(props) {
  const { isOpen, handleClose, fingerPrinterInfo, refetch } = props;

  const schema = yup.object().shape({
    date: yup.string().required("Yêu cầu nhập"),
    timeIn: yup.string().required("Yêu cầu nhập"),
    timeOut: yup.string().required("Yêu cầu nhập"),
    note: yup.string().required("Note không được bỏ trống")
  });

  const form = useForm({
    defaultValues: {
      date: dayjs(fingerPrinterInfo?.date) || dayjs(new Date()),
      userId: fingerPrinterInfo?.userId,
      timeIn: fingerPrinterInfo?.timeIn || "08:00",
      timeOut: fingerPrinterInfo?.timeIn || "17:00",
      note: fingerPrinterInfo?.note
    },
    resolver: yupResolver(schema)
  });

  const handleUpdateFingerPrinter = async () => {
    try {
      const values = form.getValues();
      const params = {
        id: fingerPrinterInfo?.id,
        userId: values.userId,
        dateSelect: formatDateYYYYMMDD(values.date),
        timeIn: values.timeIn,
        timeOut: values.timeOut,
        note: values.note
      };
      let response;
      let successMessage = fingerPrinterInfo?.id ? "Update success" : "Create success";
      if (fingerPrinterInfo?.id) {
        response = await AdminTimeSheetAPI.updateFingerPrinter(params);
        successMessage = TOAST_CREATE_SUCCESS_STRING;
      } else {
        response = await AdminTimeSheetAPI.createFingerPrinter(params);
        successMessage = TOAST_UPDATE_SUCCESS_STRING;
      }

      if (response.status === 200) {
        ToastShowSuccess(successMessage);
        refetch();
        handleClose();
      }
    } catch (error) {
      const errorData = error.response.data;
      if (errorData?.StatusCode === 500) {
        ToastShowError(errorData?.Message);
      } else {
        ToastShowError(showAllValueErrors(errorData?.errors));
      }
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">
        <div className="font-medium text-2xl text-center">{fingerPrinterInfo?.id ? "Update" : "New"}</div>
      </DialogTitle>

      <DialogContent>
        <div className="flex w-full flex-col gap-4">
          <div className="flex flex-row items-start gap-3">
            <div className="w-[50%]">
              <DatePickerTTD
                name="date"
                label="Date"
                requiredInput={true}
                form={form}
                // disable={isUpdate}
                disablePreviousDate={false}
              />
            </div>
            <div className="w-[50%]">
              <SelectUser form={form} label="User" defaultValue={fingerPrinterInfo?.userId} />
            </div>
          </div>

          <div className="flex flex-row items-start gap-3">
            <TextInputTTD
              form={form}
              defaultValue={fingerPrinterInfo?.timeIn}
              name="timeIn"
              label="Time In"
              requiredInput={true}
            />

            <TextInputTTD
              form={form}
              defaultValue={fingerPrinterInfo?.timeOut}
              name="timeOut"
              label="Time Out"
              requiredInput={true}
            />
          </div>

          <div className="flex flex-row items-start gap-2">
            <TextInputTTD
              form={form}
              defaultValue={fingerPrinterInfo?.note}
              name="note"
              label="Note"
              requiredInput={true}
            />
          </div>
        </div>
      </DialogContent>

      <DialogActions sx={{ padding: "0px 24px 20px 0px" }}>
        <Button
          variant="contained"
          size="small"
          sx={{
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "#BCCDEA"
            },
            background: "#BCCDEA",
            color: "#001B48",
            fontWeight: 600,
            borderRadius: "10px",
            width: "90px",
            padding: "5px 0",
            marginRight: "10px",
            textTransform: "none"
          }}
          onClick={handleUpdateFingerPrinter}
        >
          {fingerPrinterInfo?.id ? "Update" : "Create"}
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            handleClose();
          }}
          size="small"
          sx={{
            boxShadow: "none",
            "&:hover": { boxShadow: "none", background: "#F0F0F0" },
            background: "#F0F0F0",
            color: "#555555",
            fontWeight: 600,
            borderRadius: "10px",
            width: "90px",
            padding: "5px 0",
            marginLeft: "20px",
            textTransform: "none"
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditFingerPrinterPopup;
