import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useQueries } from "@tanstack/react-query";
import AdminLeaveFormAPI from "api/AdminLeaveFormAPI";
import AdminTeamAPI from "api/AdminTeamAPI";
import MonthPickerInput from "components/DateTimePicker/MonthPickerInput";
import DropdownListSelectStatus from "components/DropdownListSelectStatus";
import DropdownListSelectionFilter from "components/DropdownListSelectionFilter";
import LoadingOverLay from "components/LoadingOverlay";
import { listStatus, listStatusModify } from "components/SharedData";
import parse from "html-react-parser";
import CustomNoRowsOverlay from "pages/admin-timesheet/finger-printer/CustomNoRowsOverlay";
import * as React from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { formatDateTime } from "utils/DateTimeUtils";
import { ToastShowError, ToastShowSuccess, ToastShowWarning } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import { showToastErrorCustom } from "utils/constants/ShowToastErrorCustom";
import { renderChipStatus } from "utils/renderChipStatus";
import useRefreshQuery from "utils/useRefreshQuery";

export default function Requests() {
  const menuAccess = useSelector((state) => state.common.menuAccess);
  const adminMenu = menuAccess?.admin || [];

  const leaveformPagePermission = adminMenu?.leave;

  const canApprove = leaveformPagePermission.tabRequest?.isApprove || false;

  const [selectedMonthYears, setSelectedMonthYears] = React.useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1
  });
  const [selectedStatus, setSelectedStatus] = React.useState("All");
  const [selectedTeam, setSelectedTeam] = React.useState(0);
  const { refreshQuery: refreshQueryObject } = useRefreshQuery(["listViewAdminLeaveQuery"]);

  const [listViewAdminLeaveQuery, listOptionTeamQuery] = useQueries({
    queries: [
      {
        queryKey: ["listViewAdminLeaveQuery", selectedMonthYears.year, selectedMonthYears.month],
        queryFn: async () => {
          const response = await AdminLeaveFormAPI.getListView(selectedMonthYears.year, selectedMonthYears.month);
          return response.data;
        }
      },
      {
        queryKey: ["listTeamQuery"],
        queryFn: async () => {
          const response = await AdminTeamAPI.getOptionTeam();
          return response.data;
        }
      }
    ]
  });
  const { data: listOptionTeam } = listOptionTeamQuery;
  const { data: listViewAdminLeave } = listViewAdminLeaveQuery;

  const handleChangeStatusLeave = async (id, value, setSelected, previousState) => {
    try {
      if (value === "Approve") {
        const response = await AdminLeaveFormAPI.approveOrRejectLeave(id, true);
        if (response.status === 200) {
          ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
          refreshQueryObject();
        }
      } else if (value === "Reject") {
        const response = await AdminLeaveFormAPI.approveOrRejectLeave(id, false);
        if (response.status === 200) {
          ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
          refreshQueryObject();
        }
      } else {
        ToastShowWarning("Status không hợp lệ");
        setTimeout(() => {
          setSelected(previousState.current);
        }, 1500);
      }
    } catch (error) {
      const errorData = error.response.data.errors;
      if (error.response.status === 400) {
        if (errorData) {
          showToastErrorCustom(errorData);
        } else ToastShowError(error.response.data);
      } else ToastShowError(TOAST_ERROR_STRING);
      setTimeout(() => {
        setSelected(previousState.current);
      }, 1500);
    }
  };

  const columns = [
    {
      field: "status",
      headerName: "Status",
      width: 130,
      align: "center",
      editable: true,
      sortable: true,
      headerClassName: "header-click",

      headerAlign: "center",

      renderCell: (params) => {
        return (
          <>
            {canApprove ? (
              <DropdownListSelectStatus
                listSelection={listStatusModify}
                initValue={params.row.status}
                id={params.row.id}
                handleChangeStatus={handleChangeStatusLeave}
              />
            ) : (
              renderChipStatus(params.row.status)
            )}
          </>
        );
      }
    },
    {
      field: "creatorName",
      headerName: "User",
      width: 100,
      align: "left",
      sortable: true,
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "teamCode",
      headerName: "Team",
      width: 100,
      align: "center",
      sortable: true,
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 100,
      align: "center",
      sortable: true,
      headerClassName: "header-click",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return formatDateTime(value);
      }
    },
    {
      field: "startTime",
      headerName: "Time",
      width: 70,
      align: "center",
      sortable: true,
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 100,
      align: "center",
      sortable: true,
      headerClassName: "header-click",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return formatDateTime(value);
      }
    },
    {
      field: "endTime",
      headerName: "Time",
      width: 70,
      align: "center",
      sortable: true,
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "paidBy",
      headerName: "Paid By",
      width: 140,
      align: "center",
      sortable: true,
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "timeLeave",
      headerName: "Hours",
      width: 80,
      align: "center",
      sortable: true,
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 460,
      align: "left",
      sortable: true,
      headerClassName: "header-click",
      headerAlign: "center",
      renderCell: (params) => {
        return <>{parse(params.value)}</>;
      }
    },
    {
      field: "reviewerName",
      headerName: "Reviewer",
      width: 100,
      align: "left",
      sortable: true,
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "type",
      headerName: "Type",
      width: 120,
      align: "center",
      sortable: true,
      headerClassName: "header-click",

      headerAlign: "center"
    }
  ];

  const teams = useMemo(() => {
    if (listOptionTeam && !listOptionTeam.some((teamItem) => teamItem.name === "All"))
      listOptionTeam.unshift({
        name: "All",
        id: 0
      });
    return listOptionTeam;
  }, [listOptionTeam]);

  const filteredLeaveAdmin =
    listViewAdminLeave?.filter((leave) => {
      const teamFilter = (selectedTeam === 0 && listViewAdminLeave) || leave.teamId === selectedTeam;
      const statusFilter =
        (selectedStatus === "All" && listViewAdminLeave) ||
        (selectedStatus === "Pending" && leave.status === selectedStatus) ||
        (selectedStatus === "Approve" && leave.status === selectedStatus) ||
        (selectedStatus === "Reject" && leave.status === selectedStatus);
      return statusFilter && teamFilter;
    }) || [];

  return (
    <div className="flex flex-col bg-white rounded-lg p-5 gap-1.5 h-full">
      <div className="flex items-center gap-[10px]">
        <div className="w-fit flex items-center gap-[10px]">
          <span>Team</span>
          <DropdownListSelectionFilter
            listSelection={teams?.map((team) => ({
              label: team.name,
              value: team.id
            }))}
            selected={teams ? selectedTeam : ""}
            setSelected={setSelectedTeam}
          />
        </div>
        <div className="flex items-center gap-[10px]">
          <span>Month</span>
          <MonthPickerInput selectedMonthYears={selectedMonthYears} setSelectedMonthYears={setSelectedMonthYears} />
          <div className="flex items-center gap-[10px]">
            <div style={{ width: "max-content" }}>
              <span>Status</span>
            </div>
            <DropdownListSelectionFilter
              listSelection={listStatus?.map((item) => ({
                label: item.status,
                value: item.status
              }))}
              selected={selectedStatus ?? listStatus[0].value}
              setSelected={setSelectedStatus}
            />
          </div>
        </div>
      </div>

      <Box
        className="flex-grow"
        sx={{
          height: "100%",
          width: "100%",
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "15px",
            fontWeight: 600
          }
        }}
      >
        <DataGrid
          rowHeight={40}
          sx={{
            border: "none",
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
              fontSize: "16px"
            },
            minHeight: "100px",
            ".MuiDataGrid-footerContainer": { borderTop: "none" },
            "--DataGrid-overlayHeight": "calc(100vh - 200px)"
          }}
          rows={filteredLeaveAdmin ?? []}
          columns={columns}
          getRowId={(row) => row?.id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15
              }
            }
          }}
          pageSizeOptions={[15]}
          slots={{
            loadingOverlay: LoadingOverLay,
            noRowsOverlay: CustomNoRowsOverlay
          }}
          hideFooterSelectedRowCount
          disableColumnMenu={true}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableColumnSort={true}
          disableRowSelectionOnClick={true}
        />
      </Box>
    </div>
  );
}
