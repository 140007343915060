import { css } from "@emotion/css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, FormControl, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import AdminTimeSheetAPI from "api/AdminTimesheetAPI";
import DatePickerComponent from "components/DateTimePicker/DatePickerComponent";
import LoadingOverLay from "components/LoadingOverlay";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { formatDateYYYYMMDD } from "utils/DateTimeUtils";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { showAllValueErrors } from "utils/formErrorUtil";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import EditFingerPrinterPopup from "./EditFingerPrinterPopup";

function FingerPrinter() {
  const menuAccess = useSelector((state) => state.common.menuAccess);
  const adminMenu = menuAccess?.admin || [];
  const timesheetPermission = adminMenu?.timesheet;
  const tabFingerPrinterPermission = timesheetPermission?.tabFingerPrinter;

  const listAllUsers = useSelector((state) => state.common.listAllUsers);
  const [userId, setUserId] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [fingerPrinterInfo, setFingerPrinterInfo] = useState({
    date: dayjs(new Date()),
    userId: listAllUsers[0]?.id,
    timeIn: "08:00",
    timeOut: "17:00",
    note: ""
  });

  const handleClose = () => {
    setOpenEdit(!openEdit);
  };
  const fingerPrintQuery = useQuery({
    queryKey: ["fingerPrintQuery", userId, dateFrom, dateTo],
    queryFn: async () => {
      const params = {
        userId: userId,
        dateFrom: formatDateYYYYMMDD(dateFrom),
        dateTo: formatDateYYYYMMDD(dateTo)
      };
      const response = await AdminTimeSheetAPI.getListViewFingerPrinter(params);
      return response?.data;
    }
  });
  const { data: fingerPrintQueryData, refetch } = fingerPrintQuery;

  const handleEditFingerPrinter = (param) => {
    const fingerPrinterSelected = fingerPrintQueryData.find((item) => item.id === param.id);
    setFingerPrinterInfo(fingerPrinterSelected);
    setOpenEdit(true);
  };

  const handleDeleteFingerPrinter = async (param) => {
    try {
      const response = await AdminTimeSheetAPI.deleteFingerPrinter(param?.id);
      if (response?.status === 200) {
        fingerPrintQuery.refetch();
        ToastShowSuccess("Delete success");
      }
    } catch (error) {
      const errorData = error.response.data.errors;
      ToastShowError(showAllValueErrors(errorData));
    }
  };

  const handleCreateFingerPrinter = async () => {
    setFingerPrinterInfo({
      date: dayjs(new Date()),
      userId: listAllUsers[0]?.id,
      timeIn: "08:00",
      timeOut: "17:00",
      note: ""
    });
    setOpenEdit(true);
  };

  const columns = [
    {
      field: "dateText",
      headerName: "Date",
      headerClassName: "header-click",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false
    },
    {
      field: "userName",
      headerName: "User",
      headerClassName: "header-click",
      flex: 1,
      minWidth: 250,
      maxWidth: 450,
      headerAlign: "center",
      align: "left",
      sortable: false
    },
    {
      field: "timeIn",
      headerClassName: "header-click",
      headerName: "Time In",
      headerAlign: "center",
      align: "center",
      width: 130,
      sortable: false
    },
    {
      field: "timeOut",
      headerClassName: "header-click",
      headerName: "Time Out",
      headerAlign: "center",
      align: "center",
      width: 130,
      sortable: false
    },
    {
      field: "modifierName",
      headerClassName: "header-click",
      headerName: "Modified By",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 150
    },
    {
      field: "modifiedDateText",
      headerClassName: "header-click",
      headerName: "Modified Date",
      headerAlign: "center",
      sortable: false,
      align: "center",
      width: 150
    },
    {
      field: "note",
      headerName: "Note",
      headerClassName: "header-click",
      headerAlign: "center",
      sortable: false,
      align: "left",
      width: 350
    },
    {
      field: "action",
      headerClassName: "header-click",
      headerName: "Action",
      width: 125,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {tabFingerPrinterPermission?.isUpdate && (
              <IconButton onClick={() => handleEditFingerPrinter(params.row)}>
                <EditIcon />
              </IconButton>
            )}
            {tabFingerPrinterPermission?.isDelete && (
              <IconButton onClick={() => handleDeleteFingerPrinter(params.row)}>
                <DeleteIcon />
              </IconButton>
            )}
          </>
        );
      }
    }
  ];

  let fingerPrinterPopup = null;
  if (openEdit) {
    fingerPrinterPopup = (
      <EditFingerPrinterPopup
        isOpen={openEdit}
        handleClose={handleClose}
        fingerPrinterInfo={fingerPrinterInfo}
        refetch={refetch}
      />
    );
  }

  return (
    <div className="flex flex-col bg-white rounded-lg p-5 gap-1.5 h-full">
      <div className="flex flex-row items-center justify-between gap-2">
        <div className="flex flex-row w-[80%] items-center gap-2">
          <div className="flex flex-row w-[25%] items-center gap-2">
            <Typography>User</Typography>
            <FormControl>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                style={{
                  borderRadius: "8px",
                  backgroundColor: "#dcdcdc",
                  height: "40px",
                  ".MuiOutlinedInputNotchedOutline": {
                    border: "none",
                    outline: "flexnone"
                  },
                  width: "100%",
                  minWidth: "200px"
                }}
                className={css`
                  .MuiOutlinedInput-notchedOutline {
                    border: none;
                    outline: none;
                  }
                `}
              >
                <MenuItem value={0}>All</MenuItem>
                {listAllUsers.map((user, index) => {
                  return (
                    <MenuItem key={index} value={user?.id}>
                      {user?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className="flex flex-row w-full items-center gap-2">
            <div className="flex flex-row w-[25%] items-center gap-2">
              <p>Start</p>
              <DatePickerComponent
                value={dateFrom}
                style={{
                  width: "40%",
                  borderRadius: "8px",
                  backgroundColor: "#dcdcdc",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    outline: "flexnone"
                  }
                }}
                onChange={(newValue) => {
                  setDateFrom(newValue);
                }}
              ></DatePickerComponent>
            </div>

            <div className="flex flex-row items-center gap-2">
              <p>End</p>
              <DatePickerComponent
                value={dateTo}
                style={{
                  width: "40%",
                  borderRadius: "8px",
                  backgroundColor: "#dcdcdc",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    outline: "flexnone"
                  }
                }}
                onChange={(newValue) => {
                  setDateTo(newValue);
                }}
              ></DatePickerComponent>
            </div>
          </div>
        </div>
        <div className="flex flex-row h-full items-center gap-2">
          {tabFingerPrinterPermission?.isCreate && (
            <AddCircleIcon
              sx={{ cursor: "pointer" }}
              style={{ color: "#757575" }}
              onClick={handleCreateFingerPrinter}
            />
          )}
        </div>
      </div>

      <Box
        className="flex-grow"
        sx={{
          height: "100%",
          width: "100%",
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "15px",
            fontWeight: 600
          }
        }}
      >
        <DataGrid
          rowHeight={40}
          sx={{
            border: "none",
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
              fontSize: "16px"
            },
            minHeight: "100px",
            ".MuiDataGrid-footerContainer": { borderTop: "none" },
            "--DataGrid-overlayHeight": "calc(100vh - 200px)"
          }}
          rows={fingerPrintQueryData ?? []}
          columns={columns}
          getRowId={(row) => row?.id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 14
              }
            }
          }}
          pageSizeOptions={[14]}
          slots={{
            loadingOverlay: LoadingOverLay,
            noRowsOverlay: CustomNoRowsOverlay
          }}
          hideFooterSelectedRowCount
          disableColumnMenu={true}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableColumnSort={true}
          disableRowSelectionOnClick={true}
        />
      </Box>

      {fingerPrinterPopup}
    </div>
  );
}

export default FingerPrinter;
