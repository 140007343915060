import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import AdminOverTimeAPI from "api/AdminOverTimeAPI";
import LoadingOverLay from "components/LoadingOverlay";
import CustomNoRowsOverlay from "pages/admin-timesheet/finger-printer/CustomNoRowsOverlay";
import React from "react";
import useRefreshQuery from "utils/useRefreshQuery";
import ModalOverTimeInformation from "./ModalOverTimeInformation";

const Information = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [dataRow, setDataRow] = React.useState([]);

  const { refreshQuery: refreshQueryObject } = useRefreshQuery(["overTimeInfomationQuery"]);

  const overTimeInfomationQuery = useQuery({
    queryKey: ["overTimeInfomationQuery"],
    queryFn: async () => {
      const response = await AdminOverTimeAPI.getOverTimeInformation();
      const formatData = response?.data?.map((item, index) => ({
        ...item,
        stt: index + 1
      }));
      return formatData;
    }
  });

  const { data: overTimeInfomation, isLoading } = overTimeInfomationQuery;

  const handleEdit = (params) => {
    setIsModalOpen(true);
    setDataRow(params);
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      field: "stt",
      headerName: "STT",
      width: 60,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "type",
      headerName: "Type",
      width: 120,
      align: "left",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "weekday",
      headerName: "Weekday",
      width: 400,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "hour",
      headerName: "Hour From - To",
      width: 200,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "multiplier",
      headerName: "Multiplier",
      width: 100,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "description",
      headerName: "Note",
      flex: 1,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "action",
      headerName: "",
      width: 100,
      align: "center",
      sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <div className="table__action-btn">
            <IconButton onClick={() => handleEdit(params.row)}>
              <EditIcon />
            </IconButton>
          </div>
        );
      }
    }
  ];

  return (
    <div className="flex flex-col bg-white rounded-lg p-5 gap-1.5 h-full">
      <Box
        className="flex-grow"
        sx={{
          height: "100%",
          width: "100%",
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "15px",
            fontWeight: 600
          }
        }}
      >
        <DataGrid
          rowHeight={40}
          sx={{
            border: "none",
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
              fontSize: "16px"
            },
            minHeight: "100px",
            ".MuiDataGrid-footerContainer": { borderTop: "none" },
            "--DataGrid-overlayHeight": "calc(100vh - 200px)"
          }}
          rows={overTimeInfomation ?? []}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15
              }
            }
          }}
          pageSizeOptions={[15]}
          slots={{
            loadingOverlay: LoadingOverLay,
            noRowsOverlay: CustomNoRowsOverlay
          }}
          isLoading={isLoading}
          getRowId={(row) => row?.stt}
          hideFooterSelectedRowCount
          disableColumnMenu={true}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableColumnSort={true}
          disableRowSelectionOnClick={true}
        />
      </Box>
      {dataRow && isModalOpen && (
        <ModalOverTimeInformation
          open={isModalOpen}
          onClose={onClose}
          refreshQueryObject={refreshQueryObject}
          dataRow={dataRow}
        />
      )}
    </div>
  );
};

export default Information;
