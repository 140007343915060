import { List } from "@mui/material";
import MemberItemStatus from "components/MemberItemStatus";
import SearchField from "components/SearchField";
import { useState } from "react";
import { useSelector } from "react-redux";

MemberListStatus.propTypes = {};

function MemberListStatus() {
  const listAllUsers = useSelector((state) => state.common.listAllUsers) || [];

  const userStatus = useSelector((state) => state.status);

  const [textSearch, setTextSearch] = useState("");

  const textSearchChange = (text) => {
    setTextSearch(text);
  };

  let renderUser = [...listAllUsers];

  (() => {
    function removeVietnameseTones(str) {
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
      str = str.replace(/Đ/g, "D");
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "");
      str = str.replace(/\u02C6|\u0306|\u031B/g, "");
      str = str.replace(/ + /g, " ");
      str = str.trim();
      // eslint-disable-next-line no-useless-escape
      str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
      return str;
    }

    if (textSearch !== "") {
      renderUser = renderUser.filter((user) =>
        removeVietnameseTones(user?.name.toLowerCase()).includes(removeVietnameseTones(textSearch.toLowerCase()))
      );
    }
  })();

  if (renderUser.length !== 0) {
    var newList = renderUser.map((item) => {
      let updatePerson;
      let findUser = userStatus?.find((user) => user.id == item.id);
      if (findUser !== undefined) {
        updatePerson = { ...item, state: findUser?.state };
      } else {
        updatePerson = item;
      }
      return updatePerson;
    });

    renderUser = newList;
  }

  return (
    <>
      <div className="w-full h-full flex flex-col border-solid rounded-[10px] pt-2">
        <SearchField textSearchChange={textSearchChange} textSearch={textSearch} />
        <List
          sx={{
            width: "100%",
            position: "relative",
            overflow: "auto",
            height: "100%"
          }}
        >
          {renderUser?.map((user, index) => (
            <MemberItemStatus key={index} user={user} />
          ))}
        </List>
      </div>
    </>
  );
}

export default MemberListStatus;
