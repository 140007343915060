import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";

import DateCalendarDetail from "./DateCalendarDetail";
import DateCalendarInfo from "./DateCalendarInfo";
import TagCalendar from "./TagCalendar";

function DateInCalendar(props) {
  const { date, status, isCurrentMonth } = props;

  const calendarsInMonth = useSelector((state) => state.calendar.calendarsInMonth);

  const dayData =
    (Array.isArray(calendarsInMonth) &&
      calendarsInMonth?.find((dateData) => dayjs(dateData?.date).isSame(dayjs(date), "day"))) ||
    [];

  const { tasks } = dayData || [];

  const [openDetail, setOpenDetail] = useState(false);

  const handleDoubleClick = async () => {
    if (tasks !== null && tasks !== undefined) {
      setOpenDetail(!openDetail);
    }
  };
  const handleCloseDetail = async () => {
    setOpenDetail(!openDetail);
  };

  let dateCalendarPopup = null;
  if (openDetail === true) {
    dateCalendarPopup = <DateCalendarDetail open={openDetail} onClose={handleCloseDetail} date={dayData?.date} />;
  }

  return (
    <div
      className={`flex w-[calc(100%/7)] h-[155px] hover:cursor-pointer border-b border-r border-solid border-[#adb5bd] ${
        status === "inactive" && "bg-[#e3e3e3]"
      }`}
    >
      <div className="flex flex-col w-full h-full overflow-hidden p-[3px]" onDoubleClick={() => handleDoubleClick()}>
        <DateCalendarInfo date={date} isCurrentMonth={isCurrentMonth} />

        <div className="flex w-full h-[75%] max-h-[75%] overflow-x-auto flex-col gap-0.5">
          {tasks && tasks.map((task, index) => <TagCalendar key={index} taskInfo={task} />)}
        </div>
      </div>

      {dateCalendarPopup}
    </div>
  );
}

export default DateInCalendar;
