import { css } from "@emotion/css";
import { FormControl, MenuItem, Select } from "@mui/material";
import * as React from "react";

const useStyle = {
  input__all: {
    margin: "5px 0",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    whiteSpace: "pre-line",
    wordBreak: "break-all"
  },

  input_label: {
    marginBottom: "3px",
    fontWeight: 600,
    fontSize: "16px",
    textTransform: "capitalize"
  },
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      outline: "none"
    }
  }
};

const style = [
  { id: 1, name: "Pending", background: "EDE5C5", color: "1E1D19" },
  { id: 2, name: "Reject", background: "E3CDCD", color: "660000" },
  { id: 4, name: "Approve", background: "C6DCC6", color: "004D00" },
  { id: 3, name: "Calculate", background: "C6DCC6", color: "004D00" }
];

function DropdownListSelectStatus(props) {
  const { listSelection, id, initValue, handleChangeStatus } = props;
  const [selected, setSelected] = React.useState(initValue);

  const previousState = React.useRef(selected);

  const handleChange = async (event) => {
    const value = event.target.value;
    setSelected(value);
    handleChangeStatus(id, value, setSelected, previousState);
  };

  const selectedStyle = style.find((item) => item.name === selected);
  const background = selectedStyle ? selectedStyle.background : "gray";
  const color = selectedStyle ? selectedStyle.color : "#fff";

  return (
    <div style={useStyle.input__all}>
      <FormControl sx={{ width: 110 }}>
        <Select
          value={selected}
          onChange={handleChange}
          inputProps={{
            "aria-label": "Without label"
          }}
          size="small"
          className={css`
            background-color: #dcdcdc;
            height: 30px;
            .MuiOutlinedInput-notchedOutline {
              border: none;
              outline: none;
            }
          `}
          style={{
            borderRadius: "8px",
            background: `#${background}`,
            color: `#${color}`,
            fontWeight: 500
          }}
        >
          {listSelection?.map((item, index) => (
            <MenuItem value={item.status} key={index}>
              {item.status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default DropdownListSelectStatus;
