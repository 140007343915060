import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ToastShowSuccess = (message) =>
  toast.success(message, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light"
  });

export const ToastShowError = (message, time) =>
  toast.error(message, {
    position: "bottom-center",
    autoClose: time || 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false
  });

export const ToastShowWarning = (message, time) =>
  toast.warning(message, {
    position: "bottom-center",
    autoClose: time || 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false
  });

export const ToastShowNotification = (message, time) =>
  toast.warning(message, {
    position: "bottom-right",
    autoClose: time || 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false
  });
