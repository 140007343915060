import MenuItem from "../MenuItem/MenuItem";

SideBarUser.propTypes = {};

function SideBarUser(props) {
  const { navigation, navigationClick } = props;

  const handleOnClick = (value) => {
    if (navigationClick) navigationClick(value);
  };
  return (
    <div className="w-full flex flex-col">
      {navigation.map((item, index) => (
        <MenuItem key={index} navigation={item} onClick={handleOnClick} />
      ))}
    </div>
  );
}

export default SideBarUser;
