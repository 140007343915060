import * as echarts from "echarts";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

HoursByProjectChart.propTypes = {};

let optionChart = {
  legendWidth: 350,
  // height: "100%",
  emFontSize: "16",
  labelFontSize: "14",
  // centerLeft: "20%",
  // centerTop: "48%",
  showLegend: true
};

function HoursByProjectChart({ projectInfo }) {
  const idRender = uuidv4();
  useEffect(() => {
    var option;
    option = {
      color: [
        "#1f78b4",
        "#a6cee3",
        "#b2df8a",
        "#33a02c",
        "#fb9a99",
        "#e31a1c",
        "#fdc06f",
        "#ff7f00",
        "#cab2d6",
        "#6a3d9a",
        "#ffff99",
        "#b15928",
        "#a6cee3",
        "#1f78b4"
      ],
      tooltip: {
        trigger: "item",
        icon: "circle",
        textStyle: {
          fontSize: "14",
          width: optionChart.legendWidth
        },
        formatter: function (object) {
          const { name, value, percent } = object;
          return `${name} <br/> ${value}(h) ${percent} %`;
        }
      },
      legend: {
        show: true,
        icon: "circle",
        textStyle: {
          fontSize: "14",
          width: optionChart.legendWidth
        },
        tooltip: {
          show: true,
          formatter: function (object) {
            const { name } = object;
            const objectData = projectInfo.find((series) => series.name === name);
            return `${name} ${objectData.workingHours}(h)`;
          }
        },
        type: "scroll",
        width: "100%",
        orient: "vertical",
        // height: '40%'
        top: "50%",
        left: "2%",
        formatter: function (name) {
          const objectData = projectInfo.find((series) => series.name === name);
          return `${name} ${objectData.workingHours || 0}(h)`;
        }
      },
      series: [
        {
          name: "",
          type: "pie",
          // center: [optionChart.centerLeft, optionChart.centerTop],
          center: ["50%", "25%"],

          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
            width: "100%"
          },
          label: {
            show: true,
            position: "inner",
            formatter: "{d}%",
            fontSize: optionChart.labelFontSize.toString(),
            fontWeight: "600"
          },
          emphasis: {
            label: {
              show: true,
              fontSize: optionChart.labelFontSize.toString(),
              fontWeight: "bold"
            }
          },
          labelLine: {
            show: false
          },
          data:
            projectInfo &&
            projectInfo.map((item) => ({
              name: item?.name,
              value: item?.workingHours
            }))
        }
      ]
    };

    var chartDom = document.getElementById(idRender);
    var myChart = echarts.init(chartDom);
    option && myChart.setOption(option);

    myChart.on("legendselectchanged", function (params) {
      suppressSelection(myChart, params);
      // console.log(e);
      // Add custom functionality here
    });

    function suppressSelection(chart, params) {
      chart.setOption({ animation: false });

      // Re-select what the user unselected
      chart.dispatchAction({
        type: "legendSelect",
        name: params.name
      });
      chart.setOption({ animation: true });
    }
  }, [projectInfo]);

  return (
    <div
      id={idRender}
      style={{
        width: "100%",
        height: "100%",
        padding: "10px"
      }}
    ></div>
  );
}

export default HoursByProjectChart;
