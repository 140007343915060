import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Dialog } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import AdminOverTimeAPI from "api/AdminOverTimeAPI";
import CustomInputField from "components/CustomInputField";
import TextAreaInputField from "components/TextAreaInputField";
import { useForm } from "react-hook-form";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import { setReactHookFormErrors } from "utils/formErrorUtil";
import * as yup from "yup";
import styles from "./modal-overtime-information.module.scss";

const ModalOverTimeInformation = ({ open, onClose, dataRow, refreshQueryObject }) => {
  const convertDataRow = (dataRow) => {
    const [hourFrom, hourTo] = dataRow.hour.split(" - ");
    return {
      description: dataRow.description,
      hourFrom: hourFrom,
      hourTo: hourTo,
      id: dataRow.id,
      multiplier: dataRow.multiplier,
      type: dataRow.type,
      weekday: dataRow.weekday
    };
  };

  const newData = convertDataRow(dataRow);

  const schema = yup.object().shape({
    name: yup.string().required("Yêu cầu nhập"),
    multiplier: yup.string().required("Yêu cầu nhập"),
    hourFrom: yup.string().required("Yêu cầu nhập"),
    hourTo: yup.string().required("Yêu cầu nhập"),
    description: yup.string().required("Yêu cầu nhập")
  });

  const form = useForm({
    defaultValues: {
      name: newData.type,
      multiplier: newData.multiplier,
      hourFrom: newData.hourFrom,
      hourTo: newData.hourTo,
      description: newData.description
    },
    resolver: yupResolver(schema)
  });

  const handleClose = () => {
    onClose(false);
  };

  const mutation = useMutation({
    mutationFn: (value) => {
      return AdminOverTimeAPI.updateRule(value);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        const errorData = error.response.data.errors;
        setReactHookFormErrors(errorData, form.setError);
      } else ToastShowError(TOAST_ERROR_STRING);
      setTimeout(() => {
        refreshQueryObject();
      }, "1500");
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      handleClose();
      refreshQueryObject();
    }
  });

  const handleSubmit = async () => {
    var valueForm = form.getValues();

    let valueSubmit = {
      id: newData.id,
      type: valueForm?.name,
      multiplier: valueForm?.multiplier,
      hourFrom: valueForm?.hourFrom,
      hourTo: valueForm?.hourTo,
      description: valueForm?.description
    };

    mutation.mutate(valueSubmit);
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth="lg" PaperProps={{ sx: { borderRadius: "5px" } }}>
      <div className={styles.container}>
        <div className={styles.title}>
          <span>Update</span>
        </div>
        <div className={styles.name}>
          <CustomInputField
            name="name"
            label={
              <div className={styles.form_label}>
                <span className={styles.label}>Name</span>
                <span className="text-[red] text-base ml-1">*</span>
              </div>
            }
            form={form}
          />
        </div>

        <div className={styles.multiplier}>
          <CustomInputField
            name="multiplier"
            label={
              <div className={styles.form_label}>
                <span className={styles.label}>Multiplier</span>
                <span className="text-[red] text-base ml-1">*</span>
              </div>
            }
            form={form}
          />
        </div>

        <div className={styles.from_to}>
          <CustomInputField
            name="hourFrom"
            label={
              <div className={styles.form_label}>
                <span className={styles.label}>From</span>
                <span className="text-[red] text-base ml-1">*</span>
              </div>
            }
            form={form}
          />

          <CustomInputField
            name="hourTo"
            label={
              <div className={styles.form_label}>
                <span className={styles.label}>To</span>
                <span className="text-[red] text-base ml-1">*</span>
              </div>
            }
            form={form}
          />
        </div>

        <div className={styles.note}>
          <TextAreaInputField
            name="description"
            label={
              <div className="flex flex-row">
                <span className="flex flex-row items-start ml-2">Work Description</span>
                <span className="text-[red] text-base ml-1">*</span>
              </div>
            }
            minRows={2.5}
            maxRows={2.5}
            form={form}
          />
        </div>

        <div className={styles.btn_container}>
          <div className={styles.btn_ok}>
            <Button
              variant="contained"
              size="small"
              onClick={form.handleSubmit(handleSubmit)}
              sx={{
                textTransform: "uppercase",
                boxShadow: "none",
                "&:hover": { boxShadow: "none", backgroundColor: "#BCCDEA" },
                background: "#BCCDEA",
                color: "#001B48",
                fontWeight: 600,
                borderRadius: "10px",
                width: "90px",
                padding: "5px 0"
              }}
            >
              OK
            </Button>
          </div>
          <div className={styles.btn_cancel}>
            <Button
              variant="contained"
              onClick={() => {
                handleClose();
              }}
              size="small"
              sx={{
                textTransform: "capitalize",
                boxShadow: "none",
                "&:hover": { boxShadow: "none", background: "#F0F0F0" },
                background: "#F0F0F0",
                color: "#555555",
                fontWeight: 600,
                borderRadius: "10px",
                width: "90px",
                padding: "5px 0",
                marginLeft: "20px"
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalOverTimeInformation;
