import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import AdminTimeSheetAPI from "api/AdminTimesheetAPI";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_CREATE_SUCCESS_STRING, TOAST_ERROR_STRING } from "utils/ToastString";

function DocumentPopup(props) {
  const { isOpen, handleClose, projectId, refetch } = props;
  const [uploading, setUploading] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleUpload = async () => {
    try {
      setUploading(true);
      for (let i = 0; i < acceptedFiles.length; i++) {
        const file = acceptedFiles[i];
        const response = await AdminTimeSheetAPI.uploadDocument(projectId, file);
        if (response?.status !== 200) {
          ToastShowError(TOAST_ERROR_STRING);
          return;
        }
      }
      ToastShowSuccess(TOAST_CREATE_SUCCESS_STRING);
      refetch();
    } catch (error) {
      ToastShowError(TOAST_ERROR_STRING);
    } finally {
      setUploading(false);
      handleClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title" sx={{ width: "600px" }}>
        <div className="font-medium text-2xl text-center">Document</div>
      </DialogTitle>

      <DialogContent>
        <div className="flex w-full flex-col ">
          <div className="flex flex-col w-full justify-end mb-4 pl-[50%]">
            <p>* Số file tối đa : 5</p>
            <p>* Chấp nhận định dạng pdf, xlsx, doc</p>
            <p>* Dung lượng file tối đa: 10MB</p>
          </div>

          <div className="flex flex-col items-center p-2 border-2 border-dashed border-gray-300 rounded bg-gray-50 text-gray-400 outline-none transition duration-300 ease-in-out cursor-pointer">
            <div {...getRootProps({ className: "dropzone" })} className="flex flex-col items-center justify-center">
              <CloudUploadOutlined style={{ fontSize: "40px" }} />
              <input {...getInputProps()} />
              <p>Drag and drop some files here, or click to select files</p>
            </div>
            {files.length > 0 && (
              <aside>
                <h4>Files</h4>
                <ul>{files}</ul>
              </aside>
            )}
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          size="small"
          sx={{
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "#BCCDEA"
            },
            background: "#BCCDEA",
            color: "#001B48",
            fontWeight: 600,
            borderRadius: "10px",
            width: "90px",
            padding: "5px 0",
            marginRight: "10px",
            textTransform: "none"
          }}
          onClick={handleUpload} // Removed parentheses
          disabled={uploading} // Disable button while uploading
        >
          {uploading ? "Uploading..." : "Upload"}
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            handleClose();
          }}
          size="small"
          sx={{
            textTransform: "capitalize",
            boxShadow: "none",
            "&:hover": { boxShadow: "none", background: "#F0F0F0" },
            background: "#F0F0F0",
            color: "#555555",
            fontWeight: 600,
            borderRadius: "10px",
            width: "90px",
            padding: "5px 0",
            marginLeft: "20px"
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DocumentPopup;
