import * as echarts from "echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/grid";
import "echarts/lib/component/legend";
import "echarts/lib/component/tooltip";
import { useEffect } from "react";

const HoursPerUser = ({ data }) => {
  useEffect(() => {
    const dom = document.getElementById("chart-container");
    const myChart = echarts.init(dom, null, {
      renderer: "canvas",
      useDirtyRect: false
    });

    const xAxisData = data?.map((item) => item.name);
    const workingHoursData = data?.map((item) => item.workingHours);
    const overtimeHoursData = data?.map((item) => item.overtimeHours);

    const option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        },
        fontWeight: "bold",
        formatter: function (params) {
          var total = 0;
          for (var i = 0; i < params.length; i++) {
            total += params[i].value;
          }
          var tooltipContent = "</b>" + params[0].name + "</b>" + "<br/>" + "<b>Total</b>: " + total + "<br/>";

          tooltipContent += "<b>Normal:</b> " + params[0].value + "<br/>";
          tooltipContent += "<b>Overtime:</b> " + params[1].value + "<br/>";
          return tooltipContent;
        }
      },
      legend: {},
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true
      },
      xAxis: [
        {
          type: "category",
          data: xAxisData
        }
      ],
      yAxis: [
        {
          type: "value"
        }
      ],
      series: [
        {
          name: "Normal",
          type: "bar",
          barWidth: "30px",
          stack: "hours",
          emphasis: {
            focus: "series"
          },
          label: {
            show: true,
            position: "inside",
            fontWeight: "bold"
          },
          color: "#60a051",
          data: workingHoursData
        },
        {
          name: "Overtime",
          type: "bar",
          stack: "hours",
          emphasis: {
            focus: "series"
          },
          label: {
            show: true,
            position: "inside",
            fontWeight: "bold"
          },
          color: "#ea9237",
          data: overtimeHoursData
        }
      ]
    };

    if (option && typeof option === "object") {
      myChart.setOption(option);
    }

    window.addEventListener("resize", () => {
      myChart.resize();
    });

    return () => {
      window.removeEventListener("resize", () => {
        myChart.resize();
      });
    };
  }, [data]);

  return (
    <div
      id="chart-container"
      style={{
        width: "100%",
        height: "100%"
      }}
    ></div>
  );
};

export default HoursPerUser;
