import { Delete } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Chip, IconButton, Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import AdminProjectAPI from "api/AdminProjectAPI";
import AdminTeamAPI from "api/AdminTeamAPI";
import AdminUserAPI from "api/AdminUserAPI";
import OtherAPI from "api/OtherAPI";
import DeletePopup from "components/DeletePopUp";
import DropdownListSelectionFilter from "components/DropdownListSelectionFilter";
import LoadingOverLay from "components/LoadingOverlay";
import SearchInputField from "components/SearchInputFeild";
import { listProjectStatus } from "components/SharedData";
import { useState } from "react";
import { useSelector } from "react-redux";
import { formatDateTime } from "utils/DateTimeUtils";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import useRefreshQuery from "utils/useRefreshQuery";
import CustomNoRowsOverlay from "../finger-printer/CustomNoRowsOverlay";
import EditProject from "./Modal/EditProject";
import NewProject from "./Modal/NewProject";
import ViewProject from "./Modal/ViewProject";
import { useProjectFilter } from "./services/useProjectFilter";

function Project() {
  const menuAccess = useSelector((state) => state.common.menuAccess);
  const adminMenu = menuAccess?.admin || [];
  const timesheetPermission = adminMenu?.timesheet;
  const tabProjectAccess = timesheetPermission?.tabProject;

  //const [dataProject, setDataProject] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [projectDetail, setProjectDetail] = useState(null);
  const [dataTypeProject, setDataTypeProject] = useState([]);
  const [dataStatusProject, setDataStatusProject] = useState([]);
  const [dataNewProjectNumber, setDataNewProjectNumber] = useState();
  const [dataFiscalNumber, setDataFiscalNumber] = useState();
  const [dataListTeam, setDataListTeam] = useState([]);
  const [dataOptionClient, setDataOptionClient] = useState({});
  const [projectManagers, setProjectManagers] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [projectNumber, setProjectNumber] = useState(null);
  const [fiscal, setFiscal] = useState(null);

  const { refreshQuery: refreshQueryObject } = useRefreshQuery(["listProject"]);
  const { data: dataProject, isLoading: isProjectLoading } = useQuery({
    queryKey: ["listProject"],
    queryFn: async () => {
      const response = await AdminProjectAPI.GetListView();
      return response?.data;
    },
    refetchOnWindowFocus: true // Refetch the query when window regains focus
  });

  const {
    teams,
    filterProjects,
    searchGroupValue,
    setSearchGroupValue,
    selectedStatus,
    setSelectedStatus,
    selectedTeam,
    setSelectedTeam
  } = useProjectFilter(dataProject);

  const handleSearchGroup = (e) => {
    const newValue = e.target.value;
    setSearchGroupValue(newValue);
  };

  const handleCreateNewProject = async () => {
    try {
      //setIsLoading(true);
      const [
        getListTeamResponse,
        getPMResponse,
        typeProjectResponse,
        statusProjectResponse,
        newProjectNumberResponse,
        fiscalNumberResponse,
        optionClientResponse,
        fiscalRes,
        projectNumberRes
      ] = await Promise.all([
        AdminTeamAPI.getOptionTeam(),
        AdminUserAPI.getProjectManagers(),
        OtherAPI.getOptionTypeProject(),
        OtherAPI.getOptionStatusProject(),
        OtherAPI.getNewProjectNumber(),
        OtherAPI.getFiscalNumber(),
        OtherAPI.getOptionClient(),
        AdminUserAPI.getFiscalNumber(),
        AdminUserAPI.getNewProjectNumber()
      ]);

      setDataListTeam(getListTeamResponse.data);
      setProjectManagers(getPMResponse.data);
      setDataListTeam(getListTeamResponse.data);
      setDataTypeProject(typeProjectResponse.data);
      setDataStatusProject(statusProjectResponse.data);
      setDataNewProjectNumber(newProjectNumberResponse.data);
      setDataFiscalNumber(fiscalNumberResponse.data);
      setDataOptionClient(optionClientResponse.data);
      setFiscal(fiscalRes.data);
      setProjectNumber(projectNumberRes.data);

      setIsLoading(false);
      setIsNew(true);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const handleRequestEdit = async (params) => {
    setProjectDetail(null);
    try {
      const [
        getDetailResponse,
        getListTeamResponse,
        getPMResponse,
        typeProjectResponse,
        statusProjectResponse,
        newProjectNumberResponse,
        fiscalNumberResponse,
        optionClientResponse
      ] = await Promise.all([
        await AdminProjectAPI.GetDetail(params.id, false),
        AdminTeamAPI.getOptionTeam(),
        AdminUserAPI.getProjectManagers(),
        OtherAPI.getOptionTypeProject(),
        OtherAPI.getOptionStatusProject(),
        OtherAPI.getNewProjectNumber(),
        OtherAPI.getFiscalNumber(),
        OtherAPI.getOptionClient()
      ]);

      setProjectDetail(getDetailResponse.data);
      setDataListTeam(getListTeamResponse.data);
      setProjectManagers(getPMResponse.data);
      setDataListTeam(getListTeamResponse.data);
      setDataTypeProject(typeProjectResponse.data);
      setDataStatusProject(statusProjectResponse.data);
      setDataNewProjectNumber(newProjectNumberResponse.data);
      setDataFiscalNumber(fiscalNumberResponse.data);
      setDataOptionClient(optionClientResponse.data);
      setIsEdit(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const handleRequestDelete = async (params) => {
    setShowDeletePopup(true);
    setProjectId(params.id);
  };

  const handleRequestView = async (params) => {
    try {
      const [
        getDetailResponse,
        getListTeamResponse,
        getPMResponse,
        typeProjectResponse,
        statusProjectResponse,
        newProjectNumberResponse,
        fiscalNumberResponse,
        optionClientResponse
      ] = await Promise.all([
        await AdminProjectAPI.GetDetail(params.id, true),
        AdminTeamAPI.getOptionTeam(),
        AdminUserAPI.getProjectManagers(),
        OtherAPI.getOptionTypeProject(),
        OtherAPI.getOptionStatusProject(),
        OtherAPI.getNewProjectNumber(),
        OtherAPI.getFiscalNumber(),
        OtherAPI.getOptionClient()
      ]);

      setProjectDetail(getDetailResponse.data);
      setDataListTeam(getListTeamResponse.data);
      setProjectManagers(getPMResponse.data);
      setDataListTeam(getListTeamResponse.data);
      setDataTypeProject(typeProjectResponse.data);
      setDataStatusProject(statusProjectResponse.data);
      setDataNewProjectNumber(newProjectNumberResponse.data);
      setDataFiscalNumber(fiscalNumberResponse.data);
      setDataOptionClient(optionClientResponse.data);
      setIsLoading(false);
      setIsView(true);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const handleDeleteProject = async () => {
    try {
      const response = await AdminProjectAPI.Delete(projectId);
      if (response?.status === 200) {
        ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      }
    } catch (error) {
      ToastShowError(error.message);
    }
  };

  const columns = [
    {
      field: "code",
      headerName: "Code",
      width: 100,
      align: "left",
      headerClassName: "header-click",
      headerAlign: "center"
    },
    {
      field: "fullName",
      headerName: "Project Name",
      width: 510,
      align: "left",
      headerClassName: "header-click",
      headerAlign: "center"
    },
    {
      field: "teamCode",
      headerName: "Team",
      width: 100,
      align: "center",
      headerClassName: "header-click",
      headerAlign: "center"
    },
    {
      field: "quotationHour",
      renderHeader: () => {
        return (
          <div className="flex justify-center items-center flex-col h-[54px] font-semibold text-base">
            <span className="h-[20px]">Quotation</span>
            <span>Hours</span>
          </div>
        );
      },
      headerName: "Quotation Hours",
      width: 120,
      align: "center",
      headerClassName: "header-click",
      headerAlign: "center"
    },
    {
      field: "totalHour",
      headerName: "Total Hours",
      width: 120,
      align: "center",
      headerClassName: "header-click",
      headerAlign: "center"
    },
    {
      field: "usagePercentage",
      renderHeader: () => {
        return (
          <div className="flex justify-center items-center flex-col h-[54px] font-semibold text-base">
            <span className="h-[20px]">Usage</span>
            <span>Percentage</span>
          </div>
        );
      },
      width: 120,
      align: "center",
      headerClassName: "header-click",
      headerAlign: "center"
    },
    {
      field: "users",
      renderHeader: () => {
        return (
          <div className="flex justify-center items-center flex-col h-[54px] font-semibold text-base">
            <span className="h-[20px]">Number Of</span>
            <span>Members</span>
          </div>
        );
      },
      width: 120,
      align: "center",
      headerClassName: "header",
      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return (
          <AvatarGroup
            max={3}
            sx={{
              "& .MuiAvatar-root": { width: 26, height: 26, fontSize: "14px", color: "#000" }
            }}
          >
            {value.map((item, index) => (
              <Avatar
                key={item.userId}
                alt={item.userName}
                src={item.avatar}
                sx={{
                  backgroundColor: index === 0 ? "#76b7b2" : "#edc948"
                }}
              />
            ))}
          </AvatarGroup>
        );
      }
    },
    {
      field: "otHour",
      headerName: "OT Hours",
      width: 100,
      align: "center",
      headerClassName: "header-click",
      headerAlign: "center"
    },
    {
      field: "createdDate",
      renderHeader: () => {
        return (
          <div className="flex justify-center items-center flex-col h-[54px] font-semibold text-base">
            <span className="h-[20px]">Created</span>
            <span>Date</span>
          </div>
        );
      },
      width: 100,
      align: "center",
      headerClassName: "header-click",
      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return formatDateTime(value);
      }
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      align: "center",
      headerClassName: "header-click",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div>
            {params.row.status === "Active" ? (
              <Chip
                style={{
                  width: "90px",
                  background: "#C6DCC6",
                  color: "#004D00",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="Active"
              />
            ) : params.row.status === "Pending" ? (
              <Chip
                style={{
                  width: "90px",
                  background: "#EDE5C5",
                  color: "#1E1D19",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="Pending"
              />
            ) : (
              <Chip
                style={{
                  width: "90px",
                  background: "#E3CDCD",
                  color: "#660000",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="End"
              />
            )}
          </div>
        );
      }
    },
    {
      field: "action",
      headerName: "",
      width: 100,
      align: "center",
      sortable: false,
      headerClassName: "header",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="flex items-start justify-start">
            {tabProjectAccess?.isUpdate && (
              <IconButton onClick={() => handleRequestEdit(params)}>
                <EditIcon />
              </IconButton>
            )}
            {tabProjectAccess?.isDelete && (
              <IconButton onClick={() => handleRequestDelete(params)}>
                <Delete />
              </IconButton>
            )}
            {tabProjectAccess?.isView && !tabProjectAccess?.isUpdate && (
              <IconButton onClick={() => handleRequestView(params)}>
                <DescriptionIcon />
              </IconButton>
            )}
          </div>
        );
      }
    }
  ];

  return (
    <div className="flex flex-col h-full w-full bg-white rounded-lg p-5">
      <div className="flex justify-between h-10 mb-[10px]">
        <div className="flex items-center gap-[10px]">
          <span>Name</span>
          <SearchInputField
            onChange={handleSearchGroup}
            name="FindProject"
            placeholder="Search..."
            value={searchGroupValue}
          />
          <span>Team</span>
          <DropdownListSelectionFilter
            listSelection={teams?.map((team) => ({
              label: team.name,
              value: team.name
            }))}
            selected={teams ? selectedTeam : ""}
            setSelected={setSelectedTeam}
          />
          <span>Status</span>
          <DropdownListSelectionFilter
            listSelection={listProjectStatus?.map((item) => ({
              label: item.status,
              value: item.status
            }))}
            selected={selectedStatus}
            setSelected={setSelectedStatus}
          />
        </div>
        {tabProjectAccess?.isCreate && (
          <Tooltip title="Add Project">
            <IconButton variant="contained" onClick={handleCreateNewProject}>
              <AddCircleIcon className="team-tab--right__icons" />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <Box
        className="flex-grow"
        sx={{
          height: "100%",
          width: "100%",
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "15px",
            fontWeight: 600
          }
        }}
      >
        <DataGrid
          rowHeight={40}
          sx={{
            border: "none",
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
              fontSize: "16px"
            },
            minHeight: "100px",
            ".MuiDataGrid-footerContainer": { borderTop: "none" },
            "--DataGrid-overlayHeight": "300px"
          }}
          rows={filterProjects ?? []}
          columns={columns}
          getRowId={(row) => row?.id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 14
              }
            }
          }}
          pageSizeOptions={[14]}
          slots={{
            loadingOverlay: LoadingOverLay,
            noRowsOverlay: CustomNoRowsOverlay
          }}
          loading={isProjectLoading}
          hideFooterSelectedRowCount
          disableColumnMenu={true}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableColumnSort={true}
          disableRowSelectionOnClick={true}
        />
      </Box>

      {isView && (
        <ViewProject
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          projectDetail={projectDetail}
          setProjectDetail={setProjectDetail}
          dataListTeam={dataListTeam}
          projectManagers={projectManagers}
          dataTypeProject={dataTypeProject}
          dataStatusProject={dataStatusProject}
          dataNewProjectNumber={dataNewProjectNumber}
          dataFiscalNumber={dataFiscalNumber}
          dataOptionClient={dataOptionClient}
          open={isView}
          close={() => {
            setIsView(false);
            refreshQueryObject();
          }}
        />
      )}
      {isNew && (
        <NewProject
          projectDetail={projectDetail}
          dataListTeam={dataListTeam}
          projectManagers={projectManagers}
          dataTypeProject={dataTypeProject}
          dataStatusProject={dataStatusProject}
          dataNewProjectNumber={dataNewProjectNumber}
          dataFiscalNumber={dataFiscalNumber}
          dataOptionClient={dataOptionClient}
          projectNumber={projectNumber}
          fiscal={fiscal}
          open={isNew}
          close={() => {
            setIsNew(false);
            refreshQueryObject();
          }}
          setProjectDetail={setProjectDetail}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {projectDetail && (
        <EditProject
          projectDetail={projectDetail}
          setProjectDetail={setProjectDetail}
          dataListTeam={dataListTeam}
          projectManagers={projectManagers}
          dataTypeProject={dataTypeProject}
          dataStatusProject={dataStatusProject}
          dataNewProjectNumber={dataNewProjectNumber}
          dataFiscalNumber={dataFiscalNumber}
          dataOptionClient={dataOptionClient}
          open={isEdit}
          close={() => {
            setIsEdit(false);
            refreshQueryObject();
          }}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      <DeletePopup
        open={showDeletePopup}
        onClose={() => {
          setShowDeletePopup(false);
        }}
        handleDelete={handleDeleteProject}
      />
    </div>
  );
}

export default Project;
