import { addDays, eachDayOfInterval, endOfMonth, getDay, startOfMonth, subDays } from "date-fns";
import _ from "lodash";

import dayjs from "dayjs";
import DateInCalendar from "./DateCalendar";

// Define a function to check if a day is a weekend
const isWeekend = (day) => {
  const dayOfWeek = dayjs(day).day();
  return [0, 6].includes(dayOfWeek);
};

function CalendarTable(props) {
  const { daySelected } = props;

  const weekDayShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const startDayOfMonth = startOfMonth(new Date(daySelected));
  const endDayOfMonth = endOfMonth(new Date(daySelected));
  const daysOfMonth = eachDayOfInterval({
    start: startDayOfMonth,
    end: endDayOfMonth
  });

  return (
    <div className="flex flex-col w-full max-h-[88vh] overflow-x-auto border border-solid border-[#adb5bd]">
      {/* Day names */}
      <div className="flex flex-row w-full sticky top-0 bg-white border-b border-solid border-[#adb5bd] z-10">
        {weekDayShort.map((w) => (
          <div className="flex w-[calc(100%/7)] border-r border-solid border-[#adb5bd]" key={w}>
            <div className="flex items-center justify-center w-full text-[#02457a] font-[700]">{w}</div>
          </div>
        ))}
      </div>

      <div className="flex flex-row w-full flex-wrap pb-6">
        {_.range(getDay(startDayOfMonth)).map((i) => (
          <DateInCalendar
            key={i}
            date={subDays(startDayOfMonth, getDay(startDayOfMonth) - i)}
            status="inactive"
            isCurrentMonth={false}
          />
        ))}

        {_.range(daysOfMonth.length).map((item) => (
          <DateInCalendar
            key={item}
            status={isWeekend(addDays(startDayOfMonth, item)) ? "inactive" : "active"}
            date={addDays(startDayOfMonth, item)}
            isCurrentMonth
          />
        ))}

        {_.range(6 - getDay(endDayOfMonth)).map((i) => (
          <DateInCalendar key={i} date={addDays(endDayOfMonth, i + 1)} status="inactive" isCurrentMonth={false} />
        ))}
      </div>
    </div>
  );
}

export default CalendarTable;
