import axiosClient from "./api-config/axiosClient";

const AdminTeamAPI = {
  // async getTeamOrGroup(id) {
  //   const url = `/Team/GetVwTeamByFilter/${id}`;
  //   return axiosClient.request({
  //     method: "GET",
  //     url: url
  //   });
  // },

  // async createTeamOrGroup(params) {
  //   const url = `/Team/`;
  //   return axiosClient.request({
  //     method: "POST",
  //     url: url,
  //     data: params
  //   });
  // },

  // async changeTeamOrGroupInfo(params) {
  //   const url = `/Team/${params.teamId}`;
  //   return axiosClient.request({
  //     method: "PUT",
  //     url: url,
  //     data: params
  //   });
  // },

  // NEW API

  async getListViewTeam() {
    const url = `/Team/GetListView`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getOptionTeam() {
    const url = `/Team/GetOption`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async updateTeam(params) {
    const url = `/Team/Update`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: params
    });
  },

  async createTeam(params) {
    const url = `/Team/Create`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: params
    });
  },

  async deleteTeam(id) {
    const url = `/Team/Delete/${id}`;
    return axiosClient.request({
      method: "DELETE",
      url: url
    });
  }
};

export default AdminTeamAPI;
