import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationAPI from "api/NotificationAPI";
import { useNavigate } from "react-router-dom";
import { useTabStore } from "store/bearStore";

export function NotificationToast({ notification }) {
  const navigate = useNavigate();
  const setTabIndex = useTabStore((state) => state.channgeTabIndex);
  const { id, title, content, path } = notification;

  const handleClickNotification = async (id) => {
    await NotificationAPI.markRead(id);

    if (path?.pathUrl) navigate(path.pathUrl);
    if (path?.tabIndex) setTabIndex(path.tabIndex);
  };
  return (
    <div
      className={`flex flex-col items-start`}
      onClick={() => handleClickNotification(id)}
      style={{ cursor: "pointer" }}
    >
      <div className="w-full flex flex-row items-start border-b border-black mb-3 pb-1">
        <NotificationsActiveIcon />
        <p className="font-bold pl-2">New Notification</p>
      </div>
      <div className="w-full flex flex-row items-start justify-between mb-1">
        <div className="w-[60%] flex flex-row items-center">
          <p className="font-bold">{title}</p>
        </div>
      </div>
      <div className="w-full h-full flex flex-row items-start justify-end">
        <p className="italic h-full text-left">{content}</p>
      </div>
    </div>
  );
}
