export const urls = {
  web: {
    authentication: {
      login: "/login",
      forgotPassword: "/forgot-password"
    },
    user: {
      home: "/",
      dashboard: "/dashboard",
      timeSheet: "/timesheet",
      timeSheetDetail: "/timesheet/detail",
      overtime: "/overtime",
      leaveForm: "/leaveform",
      calendar: "/calendar/:userId",
      wfh: "/wfh",
      asset: "/asset",
      userSetting: "/setting"
    },
    admin: {
      user: "/admin/user",
      timeSheet: "/admin/timesheet",
      overtime: "/admin/overtime",
      leaveForm: "/admin/leaveform",
      wfh: "/admin/wfh"
    },
    email: {
      mailSuccess: "/email/success",
      mailExpired: "/email/expired",
      mailRequest: "/mail-request/:notification/:baseUrl/:params"
    },
    errors: {
      notFound: "/404",
      errorPage: "/error"
    }
  }
};
