import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";

function TaskTitle(props) {
  const { taskInfo, onDeleteProjectTask, disableKeyTimeSheet } = props;

  const userIdSelected = useSelector((state) => state.timesheet.userIdSelected);
  const currentUser = useSelector((state) => state.user.current);
  const isCurrentUser = currentUser?.id === userIdSelected;

  const handleDeleteProjectTask = () => {
    const projectId = taskInfo?.projectId;

    if (onDeleteProjectTask) {
      onDeleteProjectTask(projectId);
    }
  };

  return (
    <>
      <tr className="relative h-[40px] bg-white border-b border-t border-gray-900 hover:bg-gray-200 !z-50">
        <td className="px-2 py-2 font-semibold">{taskInfo?.projectName}</td>
        <td className="px-2 py-2"></td>
        <td className="px-2 py-2"></td>
        <td className="px-2 py-2"></td>
        <td className="px-2 py-2"></td>
        <td className="px-2 py-2">
          <strong>Total: {taskInfo?.hours}</strong>
        </td>
        <td className="px-2 py-2 flex items-center justify-center">
          <IconButton
            aria-label="delete"
            disabled={!isCurrentUser || disableKeyTimeSheet}
            onClick={handleDeleteProjectTask}
            sx={{ padding: "0" }}
            color={isCurrentUser ? "error" : "disable"}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </td>
      </tr>
    </>
  );
}

export default TaskTitle;
