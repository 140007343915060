import { Paper } from "@mui/material";
import "./../shared_component.scss";
TagContent.propTypes = {};

function TagContent({
  title = "TITLE",
  width = "400px",
  height = "130px",
  marginTop = "0px",
  marginBtn = "0px",
  marginLeft = "0px",
  marginRight = "0px",
  borderRadius = "10px",
  children,
  dataFlow = "DownToTop",
  id
}) {
  const styleObject = {
    width: width,
    height: height,
    marginTop: marginTop,
    marginBtn: marginBtn,
    marginLeft: marginLeft,
    marginRight: marginRight,
    borderRadius: borderRadius
  };

  return (
    <Paper style={styleObject} elevation={3} square className="tag__content" id={id}>
      <div
        className="tag__content-right"
        style={{
          justifyContent: dataFlow === "TopToDown" ? "flex-start" : "space-between"
        }}
      >
        <div className="tag__content-title">{title}</div>
        <div className="tag__content-data">{children}</div>
      </div>
    </Paper>
  );
}

export default TagContent;
