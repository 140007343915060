import axiosClient from "./api-config/axiosClient";

const AdminWfhAPI = {
  async getListView(year, month) {
    const url = `/Wfh/GetListView/${year}/${month}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async approveOrRejectWfh(id, isApprove) {
    const url = `/Wfh/Approve/${id}/${isApprove}`;
    return axiosClient.request({
      method: "POST",
      url: url
    });
  }
};

export default AdminWfhAPI;
