import { Button } from "@mui/material";
import accessDenied from "./403.jpg";

function CanNotAccessPage() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
      className="h-screen"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyItems: "center",
          alignItems: "center"
        }}
      >
        <img src={accessDenied} alt="access-denied" style={{ width: "auto", height: "auto" }}></img>

        <Button
          component="a"
          href="https://app.ttdesignco.com/"
          disableElevation
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          color="primary"
          sx={{ width: 200, marginTop: "36px" }}
        >
          Go To Home Page
        </Button>
      </div>
    </div>
  );
}

export default CanNotAccessPage;
