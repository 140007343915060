function SearchField(props) {
  const { searchId, textSearch, textSearchChange } = props;

  const handleChangeText = (e) => {
    e.preventDefault();
    const text = e.target.value;
    if (textSearchChange) textSearchChange(text);
  };

  return (
    <input
      className="bg-[#dcdcdc] mb-2 border border-gray-300 rounded-lg block w-full p-1.5 pl-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
      type="text"
      onChange={handleChangeText}
      value={textSearch}
      id={`shared__searchfield-${searchId || "id"}`}
      placeholder="Search…"
    />
  );
}

export default SearchField;
