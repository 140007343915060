import logo from "assets/images/shared/TTDLogo-full.jpg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MenuProfile from "../MenuProfile";
import Notification from "../Notification";

MenuTopBar.propTypes = {};

function MenuTopBar() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.current);
  const clickToHomePage = () => {
    navigate("/dashboard");
  };
  return (
    <div className="h-[54px] border-b-[1px solid rgb(184, 184, 184)] flex items-center justify-between pr-5 lg:pl-0 pl-5">
      <div
        onClick={clickToHomePage}
        className="flex items-center lg:justify-left lg:ml-1 justify-start w-[250px] cursor-pointer"
      >
        <img className="h-[45px] w-[200px] mr-2 border-none " src={logo} alt="ttd-logo" />
      </div>
      <div className="flex items-center justify-center">
        <Notification />
        <span className="font-medium text-[20px]">Xin chào, {user?.fullName}</span>
        <MenuProfile />
      </div>
    </div>
  );
}

export default MenuTopBar;
