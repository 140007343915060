import axiosClient from "./api-config/axiosClient";

const ObjectAPI = {
  async GetOption() {
    const url = "/Object/GetOption";
    return axiosClient.request({
      method: "GET",
      url: url
    });
  }
};

export default ObjectAPI;
