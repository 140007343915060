import EditTimeSheetPopup from "components/EditTimesheetPopup/EditTimesheetPopup";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeDaySelected } from "store/timesheetSlice";
import DateInfo from "./DateInfo";
import DateTasks from "./DateTasks";
import DateWorkInfo from "./DateWorkInfo";

import dayjs from "dayjs";
import HolidayTag from "./HolidayTag";

function DateTimeSheet(props) {
  const { date, status, isDayInCurrentMonth } = props;
  const isAfterDay = dayjs(date).isAfter(dayjs(), "day");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const timeSheetsInMonth = useSelector((state) => state.timesheet.timesheets);

  const dayData =
    Array.isArray(timeSheetsInMonth) &&
    timeSheetsInMonth?.find((dateData) => dayjs(dateData?.date).isSame(dayjs(date), "day"));

  const {
    lock,
    holidayName,
    timeIn,
    timeOut,
    hourOvertimeStr,
    hourTotalStr,
    hourOvertime,
    hourTotal,
    hourWorkingStr,
    failedTime,
    swapDayDetail,
    enough8h
  } = dayData || {};

  const isKeyError = dayData?.hourWorking > dayData?.hourTotal;

  const isHoliday = holidayName !== null && holidayName !== undefined && holidayName !== "";
  const isSwapDay = swapDayDetail !== null && swapDayDetail !== undefined && swapDayDetail !== "";

  const lockedOpenDetail = hourTotal === 0 && hourOvertime === 0 && timeIn === "--:--" && timeOut === "--:--";
  const showTotal = !(hourTotal === 0 && hourOvertime === 0) && !isAfterDay;

  const lockedOrInactive = lock || status === "inactive";

  const [openEdit, setOpenEdit] = useState(false);

  let editPopup = null;
  if (openEdit === true) {
    editPopup = (
      <EditTimeSheetPopup
        timesheetId={props?.timesheetId}
        isOpen={openEdit}
        date={props?.date}
        timeIn={props?.timeIn}
        timeOut={props?.timeOut}
        setOpenEdit={setOpenEdit}
        showEditSuccess={props?.showEditSuccess}
      ></EditTimeSheetPopup>
    );
  }

  const handleDoubleClick = async () => {
    await dispatch(changeDaySelected(dayjs(date)));
    navigate(`/timesheet/detail`);
  };

  return (
    <div
      className={`flex w-[calc(100%/7)] h-[158px] hover:cursor-pointer border-b border-r border-solid border-[#adb5bd] ${
        isHoliday ? "bg-[#ebe5c6]" : lockedOrInactive ? "bg-[#e3e3e3]" : isKeyError ? "bg-[#e9dada]" : "bg-white"
      } ${lock && "opacity-50"}`}
    >
      <div
        className="flex flex-col w-full h-full overflow-hidden p-[3px]"
        onDoubleClick={() => {
          if (isDayInCurrentMonth && !lockedOpenDetail) {
            handleDoubleClick();
          }
        }}
      >
        <DateInfo date={date} timeIn={timeIn} timeOut={timeOut} lock={lock} showTimeInOut={isDayInCurrentMonth} />

        {isHoliday && <HolidayTag holidayName={holidayName} />}
        {isSwapDay && <HolidayTag holidayName={swapDayDetail} />}

        <DateTasks dayData={dayData} />
        {showTotal && (
          <DateWorkInfo
            otHours={hourOvertimeStr}
            hourWorking={hourWorkingStr}
            failedTime={failedTime}
            enough8h={enough8h}
            hourTotal={hourTotalStr}
          />
        )}
      </div>

      {editPopup}
    </div>
  );
}

export default DateTimeSheet;
