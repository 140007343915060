import StorageKeys from "utils/constants/StorageKeys";
import axiosClient from "./api-config/axiosClient";

const AuthApi = {
  login(data) {
    const url = "/login";
    return axiosClient.request({
      method: "POST",
      url: url,
      data: data
    });
  },
  loginByGoogleAccount(params) {
    const url = `/LoginByGoogleAccount?token=${params.credential}`;
    return axiosClient.request({
      method: "POST",
      url: url
    });
  },

  logout(data) {
    const url = "/token/revoke";
    return axiosClient.request({
      method: "POST",
      url: url,
      data: data
    });
  },
  async getUserCredentialByEmail(params) {
    const accessToken = localStorage.getItem(StorageKeys.access);
    const url = `Users/GetCredential`;
    return axiosClient.request({
      method: "GET",
      url: url,
      headers: {
        Authorization: `Bearer ${params.access ? params.access : accessToken}`
      }
    });
  }
};

export default AuthApi;
