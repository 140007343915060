import axiosClient from "./api-config/axiosClient";

const TimeSheetAPI = {
  //01.01 GET time in, out, note, overtimes[], timesheetTasks[] by date param (y-m-d)
  async getTimesheetDetailPesonal(params) {
    const url = `/Timesheet/GetDetail/${params.year}/${params.month}/${params.day}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  //01.02 GET time in, out, note, overtimes[], timesheetTasks[] by date param (y-m-d)
  async getTimesheetDetailAdmin(params) {
    const url = `/Timesheet/GetDetail/${params.userId}/${params.year}/${params.month}/${params.day}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  // //02. Get time in, out, note, ot, total all day in month
  // async GetListTimesheetByUserIdAndMonthAndYear(params) {
  //   // const userId = JSON.parse(localStorage.getItem("user")).userId;

  //   const url = `/Timesheet/GetListVwTimesheetByUserIdAndMonthAndYear?userId=${params.userId}&month=${params.month}&year=${params.year}`;
  //   return axiosClient.request({
  //     method: "GET",
  //     url: url,
  //   });
  // },

  //03.1 Get all timesheets in months of personal
  async GetListTimesheetByMonthAndYearPersonal(params) {
    const url = `/Timesheet/GetListView/${params.year}/${params.month}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  //03.2 Get all timesheets in months of admin
  async GetListTimesheetByMonthAndYearAdmin(params) {
    const url = `/Timesheet/GetListView/${params.userId}/${params.year}/${params.month}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  //Get all project by userID
  async getListProjectsByUser() {
    const url = `/Project/GetOptionWorking`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  //Get all Cate
  async GetTimesheetCategories() {
    const url = `/Category/GetOption`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  //Get all object
  async GetTimesheetObjects() {
    const url = `/Object/GetOption`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getDashboardTimesheet() {
    const url = `/Timesheet/getDashboardTimesheet`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getDashboardProject(params) {
    const { year, month } = params;
    const url = `/Timesheet/getDashboardProject/${year}/${month}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  //POST task of timesheet
  async saveTimesheet(params) {
    const url = `/Timesheet/Create`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: params
    });
  }
};
export default TimeSheetAPI;
