import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";

function DropdownListSelection(props) {
  const { name, form, label, disable, listSelection } = props;
  const {
    formState: { errors }
  } = form;

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center ml-2">{label}</div>
      <Controller
        control={form.control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <FormControl sx={{ width: "100%" }}>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              disabled={disable}
              onChange={(e) => {
                onChange(e);
              }}
              sx={{
                backgroundColor: "#dcdcdc",
                borderRadius: "8px !important",
                width: "100%",
                ".MuiOutlinedInput-notchedOutline": {
                  border: "none",
                  outline: "none"
                }
              }}
            >
              {listSelection?.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  <p>{item.label}</p>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      <div style={{ height: "14px", width: "100%" }}>
        <span style={{ fontSize: "14px", color: "red", fontWeight: 400 }}>{errors[name] && errors[name].message}</span>
      </div>
    </div>
  );
}

export default DropdownListSelection;
