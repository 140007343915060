import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { disabledRangeTime, formatHHmm } from "./FormatTime";

function TaskNew(props) {
  const { taskInfo, isLastTask, taskIndex, handleAddTask, handleDeleteTask, replaceTask, disableKeyTimeSheet } = props;

  const [taskInfoInput, setTaskInfoInput] = useState(taskInfo);

  const listCategories = useSelector((state) => state.timesheet.timesheetCategories);
  const listObjects = useSelector((state) => state.timesheet.timesheetObjects);

  const userIdSelected = useSelector((state) => state.timesheet.userIdSelected);
  const currentUser = useSelector((state) => state.user.current);
  const isCurrentUser = currentUser?.id === userIdSelected;

  const onDeleteClick = () => {
    handleDeleteTask(taskIndex);
  };

  const handleChangeSelect = (key, event) => {
    const newValue = event.target.value;
    let updatedTaskInfo;

    if (key === "category") {
      updatedTaskInfo = {
        ...taskInfoInput,
        categoryId: newValue,
        category: listCategories.find((cate) => cate.id === newValue)?.name
      };
    } else if (key === "object") {
      updatedTaskInfo = {
        ...taskInfoInput,
        objectId: newValue,
        object: listObjects.find((obj) => obj.id === newValue)?.name
      };
    }

    setTaskInfoInput(updatedTaskInfo);
    replaceTask(updatedTaskInfo, taskIndex);
  };

  const handleChangeTime = (time) => {
    let newValue = dayjs(time).format("HH:mm");

    let updatedTaskInfo = {
      ...taskInfoInput,
      hours: newValue
    };

    setTaskInfoInput(updatedTaskInfo);
    replaceTask(updatedTaskInfo, taskIndex);
  };

  const handleChange = (key, event) => {
    let newValue = event.target.value;

    let newObj = {
      ...taskInfoInput,
      [key]: newValue
    };

    setTaskInfoInput(newObj);
    replaceTask(newObj, taskIndex);
  };

  useEffect(() => {
    setTaskInfoInput(taskInfo);
  }, [taskInfo]);

  return (
    <>
      <tr className="h-[40px] border-t bg-white hover:bg-gray-200">
        <td className="px-2 py-2 w-[25%]"></td>

        <td className={`px-2 py-2 truncate w-[175px]`}>
          <FormControl
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "32px"
              },
              borderRadius: "10px",
              width: "135px"
            }}
            size="small"
          >
            <Select
              id="listCategories"
              value={taskInfoInput?.categoryId ?? ""}
              onChange={(e) => handleChangeSelect("category", e)}
              defaultValue={taskInfoInput?.categoryId ?? ""}
              sx={{ width: "100%" }}
              disabled={!isCurrentUser}
            >
              {listCategories.map((cate, index) => (
                <MenuItem key={index} value={cate?.id}>
                  <Typography noWrap sx={{ paddingRight: 2 }}>
                    {cate?.name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </td>

        <td className={`px-2 py-2 truncate w-[175px]`}>
          <FormControl
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "32px"
              },
              borderRadius: "10px",
              width: "135px"
            }}
            size="small"
          >
            <Select
              id="listObjects"
              value={taskInfoInput?.objectId ?? ""}
              onChange={(e) => handleChangeSelect("object", e)}
              defaultValue={taskInfoInput?.objectId ?? ""}
              sx={{ width: "100%" }}
              disabled={!isCurrentUser}
            >
              {listObjects.map((object, index) => (
                <MenuItem key={index} value={object?.id}>
                  <Typography noWrap sx={{ paddingRight: 2 }}>
                    {object?.name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </td>

        <td className="w-[30%] px-2 py-2">
          <TextField
            disabled={!isCurrentUser}
            value={taskInfoInput?.description}
            onChange={(e) => handleChange("description", e)}
            sx={{
              "& .MuiInputBase-root": {
                height: "32px"
              },
              width: "100%"
            }}
          />
        </td>

        <td className="px-2 py-2 w-[10%]"></td>
        <td className="px-2 py-2 w-[10%]">
          <TimePicker
            defaultValue={dayjs("00:00", formatHHmm)}
            value={dayjs(taskInfoInput?.hours, formatHHmm)}
            format={formatHHmm}
            disabledTime={disabledRangeTime}
            disabled={!isCurrentUser}
            hideDisabledOptions
            minuteStep={15}
            showNow={false}
            allowClear={false}
            needConfirm={false}
            onChange={(e) => handleChangeTime(e)}
          />
        </td>
        <td className="min-w-[75px] px-2 py-2 flex justify-center">
          {isLastTask ? (
            <IconButton
              aria-label="delete"
              disabled={!isCurrentUser || disableKeyTimeSheet}
              onClick={handleAddTask}
              color={isCurrentUser ? "#4b4b4b" : "disable"}
              sx={{ padding: "0", paddingTop: "4px" }}
            >
              <AddCircleIcon />
            </IconButton>
          ) : (
            <IconButton
              aria-label="delete"
              disabled={!isCurrentUser || disableKeyTimeSheet}
              onClick={onDeleteClick}
              sx={{ padding: "0" }}
              color={isCurrentUser ? "#4b4b4b" : "disable"}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </td>
      </tr>
    </>
  );
}

export default TaskNew;
