import { Navigate } from "react-router-dom";
import StorageKeys from "utils/constants/StorageKeys";
import { urls } from "../config/url";

const IsLoggedIn = (props) => {
  const { children } = props;
  const isLogin = localStorage.getItem(StorageKeys.user) ? true : false;
  return <>{isLogin ? <Navigate to={urls.web.user.dashboard} /> : children}</>;
};

export default IsLoggedIn;
