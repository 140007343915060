import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function SelectUser(props) {
  const { form, label, defaultValue } = props;

  const listAllUsers = useSelector((state) => state.common.listAllUsers);
  const [valueSelected, setValueSelected] = useState(defaultValue);

  useEffect(() => {
    setValueSelected(defaultValue);
  }, []);

  const handleSelectChange = (e) => {
    setValueSelected(e.target.value);
    if (form) {
      form.setValue("userId", e.target.value);
    }
  };

  return (
    <div className="flex flex-col items-start">
      <div className="flex flex-row items-start ml-2">{label}</div>

      <div className="flex flex-col items-start w-full">
        <Select
          value={valueSelected}
          defaultValue={valueSelected}
          onChange={handleSelectChange}
          form={form}
          // disabled={!canEdit}

          sx={{
            width: "100%",
            borderRadius: "8px",
            backgroundColor: "#dcdcdc",
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
              outline: "flexnone"
            }
          }}
        >
          {listAllUsers.map((user, index) => (
            <MenuItem key={index} value={user?.id}>
              {user?.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
}
export default SelectUser;
