import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import NotificationAPI from "api/NotificationAPI";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { useTabStore } from "store/bearStore";

export function NotificationItem({ notification, refetch }) {
  const navigate = useNavigate();
  const setTabIndex = useTabStore((state) => state.channgeTabIndex);
  const { id, title, content, type, createdDate, status, path } = notification;
  const handleDeleteNotification = async (id) => {
    await NotificationAPI.deleteNotification(id);
    refetch();
  };

  const handleClickNotification = async (id) => {
    await NotificationAPI.markRead(id);
    refetch();

    if (path?.pathUrl) navigate(path.pathUrl);
    if (path?.tabIndex) setTabIndex(path.tabIndex);
  };
  return (
    <div
      className={`flex flex-col items-start border-t-[1px] border-black p-2 ${!status && "bg-[#e3ecff]"}`}
      onClick={() => handleClickNotification(id)}
      style={{ cursor: "pointer" }}
    >
      <div className="w-full flex flex-row items-start justify-between">
        <div className="w-[60%] flex flex-row">
          {(() => {
            switch (type) {
              case "Notification":
                return <NotificationsActiveIcon />;
              case "LeaveRequest":
                return <WorkOffIcon />;
              case "OvertimeRequest":
                return <AccessTimeIcon />;
              case "WfhRequest":
                return <HomeIcon />;
              case "NoticeInvite":
                return <DesktopWindowsIcon />;
              case "AssetInvite":
                return <DesktopWindowsIcon />;
              case "Information":
                return <WorkOffIcon />;
              case "Approved":
                return <CheckCircleIcon color="success" />;
              case "Rejected":
                return <CloseIcon sx={{ color: "red" }} />;
              default:
                return <NotificationsActiveIcon />;
            }
          })()}
          <p className="font-bold pl-2">{title}</p>
        </div>

        <div>{moment(createdDate).format("YYYY/MM/DD HH:mm")}</div>
      </div>
      <div className="w-full h-full flex flex-row items-start justify-end">
        <p className="italic h-full">{content}</p>
        <DeleteIcon
          sx={{
            cursor: "pointer"
          }}
          onClick={() => handleDeleteNotification(id)}
        />
      </div>
    </div>
  );
}
