import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AdminUserAPI from "api/AdminUserAPI";
import AuthApi from "api/AuthAPI";
import TokenServices from "api/TokenServices";
import StorageKeys from "utils/constants/StorageKeys";
import { setFormErrors } from "utils/formErrorUtil";

// createAsyncThunk cái này sử dụng cho login và register
export const login = createAsyncThunk("Auth/Login", async (payload) => {
  try {
    const response = await AuthApi.login(payload);
    TokenServices.addToken(response.data);

    const email = JSON.parse(response.config.data).username;
    const responseUser = await AuthApi.getUserCredentialByEmail({
      email: email,
      access: localStorage.getItem(StorageKeys.access)
        ? localStorage.getItem(StorageKeys.access)
        : response.data.accessToken
    });
    const user = { ...responseUser };
    const data = {
      ...user.data
    };
    TokenServices.setUser(data);

    return data;
  } catch (error) {
    setFormErrors(error.response.data.errors, payload.setErrors);
    return error.message;
  }
});

export const loginByGoogleAccount = createAsyncThunk("Auth/Login", async (payload) => {
  try {
    const response = await AuthApi.loginByGoogleAccount(payload);
    TokenServices.addToken(response.data);
    const email = payload.email;
    const responseUser = await AuthApi.getUserCredentialByEmail({
      email: email,
      access: localStorage.getItem(StorageKeys.access)
        ? localStorage.getItem(StorageKeys.access)
        : response.data.accessToken
    });
    const user = { ...responseUser };
    const data = {
      ...user.data
    };
    TokenServices.setUser(data);

    return data;
  } catch (error) {
    payload.setErrorLoginGoogle("Email không tồn tại");
    console.log(error);
    return error.message;
  }
});

export const updateUserCredentialByCommonUserAsync = createAsyncThunk(
  "Users/updateUserCredentialByCommonUserAsync",
  async (payload) => {
    try {
      const response = await AdminUserAPI.UpdateUserCredential(payload);
      const email = payload.email;
      const responseUser = await AuthApi.getUserCredentialByEmail({
        email: email,
        access: localStorage.getItem(StorageKeys.access)
          ? localStorage.getItem(StorageKeys.access)
          : response.data.accessToken
      });
      const user = { ...responseUser };
      const data = {
        ...user.data
      };
      TokenServices.setUser(data);

      return data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

export const getUserCredentialByEmail = createAsyncThunk("Users/GetUserCredentialByEmail", async (payload) => {
  try {
    const responseUser = await AuthApi.getUserCredentialByEmail({
      email: payload
    });
    const user = { ...responseUser };
    const data = {
      ...user.data
    };
    TokenServices.setUser(data);
    return data;
  } catch (error) {
    console.log(error);
    return error.message;
  }
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    current: null,
    status: "idle",
    error: null
  },
  reducers: {
    logout(state) {
      state.current = null;
      TokenServices.removeUser();
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.current = action.payload;
        state.status = "succeeded";
      })
      .addCase(login.rejected, (state, action) => {
        TokenServices.removeUser();
        state.current = null;
        state.status = action.error.message;
      })
      .addCase(updateUserCredentialByCommonUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserCredentialByCommonUserAsync.fulfilled, (state, action) => {
        state.current = action.payload;
        state.status = "succeeded";
      })
      .addCase(updateUserCredentialByCommonUserAsync.rejected, (state, action) => {
        state.status = action.error.message;
        TokenServices.removeUser();
        state.current = null;
      })
      .addCase(getUserCredentialByEmail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserCredentialByEmail.fulfilled, (state, action) => {
        state.current = action.payload;
        state.status = "succeeded";
      })
      .addCase(getUserCredentialByEmail.rejected, (state, action) => {
        state.status = action.error.message;
        TokenServices.removeUser();
        state.current = null;
      });
  }
});

const { actions, reducer } = userSlice;
export const { logout } = actions;
export default reducer;
