import AddCircleIcon from "@mui/icons-material/AddCircle";
/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, IconButton, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeletePopup from "components/DeletePopUp";
import DropdownListSelectionFilter from "components/DropdownListSelectionFilter";
import LoadingOverLay from "components/LoadingOverlay";
import { listStatus, listYears } from "components/SharedData";
import CustomNoRowsOverlay from "pages/admin-timesheet/finger-printer/CustomNoRowsOverlay";
import React from "react";
import filterListByStatus from "utils/FilterList";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_CREATE_SUCCESS_STRING, TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import { setReactHookFormErrors, showAllValueErrors } from "utils/formErrorUtil";
import useRefreshQuery from "utils/useRefreshQuery";
import WFHModal from "./components/Modal";
import useUserWFH from "./components/apis/WFHAPI";
import WFHColumn from "./components/data-grid/WFHColumn";

const WorkFromHome = () => {
  const [selectedYear, setSelectedYear] = React.useState(new Date().getFullYear());
  const [selectedStatus, setSelectedStatus] = React.useState("All");

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedWFH, setSelectedWFH] = React.useState({});
  const [showDeletePopup, setShowDeletePopup] = React.useState(false);

  const { refreshQuery } = useRefreshQuery(["wfhQuery", selectedYear]);
  const { listViewWfh, mutateCreateWFH, mutateUpdateWFH, mutateDeleteWFH } = useUserWFH(selectedYear);

  const handleCreateNewWFHRequest = () => {
    setSelectedWFH({});
    setIsModalOpen(true);
  };

  const handleUpdateWFH = (swapDay, setErrors) => {
    mutateUpdateWFH(swapDay, {
      onSuccess: () => {
        refreshQuery();
        ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
        setIsModalOpen(false);
      },
      onError: (error) => {
        if (error.response.status === 400) {
          const errorData = error.response.data.errors;
          ToastShowError(showAllValueErrors(errorData));
          setReactHookFormErrors(error.response.data.errors, setErrors);
          ToastShowError(showAllValueErrors(errorData));
        } else ToastShowError(TOAST_ERROR_STRING);
      }
    });
  };

  const handleCreateWFH = (swapDay, setErrors) => {
    mutateCreateWFH(swapDay, {
      onSuccess: () => {
        ToastShowSuccess(TOAST_CREATE_SUCCESS_STRING);
        refreshQuery();
        setIsModalOpen(false);
      },
      onError: (error) => {
        if (error.response.status === 400) {
          const errorData = error.response.data.errors;

          ToastShowError(showAllValueErrors(errorData));
          setReactHookFormErrors(error.response.data.errors, setErrors);
        } else ToastShowError(TOAST_ERROR_STRING);
      }
    });
  };

  const handleDeleteWFH = () => {
    mutateDeleteWFH(selectedWFH?.id, {
      onSuccess: () => {
        refreshQuery();
        ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      },
      onError: () => {
        ToastShowError(TOAST_ERROR_STRING);
      }
    });
  };

  const handleSubmitWFHForm = (swapDay, setErrors) => {
    if (selectedWFH?.id) {
      handleUpdateWFH(swapDay, setErrors);
    } else {
      handleCreateWFH(swapDay, setErrors);
    }
  };

  const columns = WFHColumn(
    (wfhInfor) => {
      setSelectedWFH(wfhInfor);
      setIsModalOpen(true);
    },
    (wfhInfor) => {
      setSelectedWFH(wfhInfor);
      setShowDeletePopup(true);
    }
  );

  const listFiltered = filterListByStatus(listViewWfh, selectedStatus) || [];

  let wfhModal = null;
  if (isModalOpen === true) {
    wfhModal = (
      <WFHModal
        wfhInfo={selectedWFH}
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        onSubmitWFHForm={(value) => handleSubmitWFHForm(value)}
      />
    );
  }

  return (
    <div className="w-full h-full p-5">
      <div className="flex flex-col w-full h-full overflow-hidden bg-white p-5 rounded-[10px]">
        <div className="flex gap-4 items-center justify-between">
          <div className="flex justify-between gap-2.5">
            <div className="flex items-center">
              <span className="mr-2.5">Year</span>

              <DropdownListSelectionFilter
                listSelection={listYears?.map((item) => ({
                  label: item.year,
                  value: item.year
                }))}
                selected={selectedYear}
                setSelected={setSelectedYear}
              />
            </div>

            <div className="flex items-center">
              <span className="mr-2.5">Status</span>

              <DropdownListSelectionFilter
                listSelection={listStatus?.map((item) => ({
                  label: item.status,
                  value: item.status
                }))}
                selected={selectedStatus}
                setSelected={setSelectedStatus}
              />
            </div>
          </div>

          <Tooltip title="Add request">
            <IconButton variant="contained" onClick={handleCreateNewWFHRequest}>
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </div>

        <Box
          className="flex flex-grow"
          sx={{
            height: "100%",
            width: "100%",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: "15px",
              fontWeight: 600
            }
          }}
        >
          <DataGrid
            rowHeight={40}
            sx={{
              border: "none",
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: "600",
                fontSize: "16px"
              },
              minHeight: "100px",
              ".MuiDataGrid-footerContainer": { borderTop: "none" },
              "--DataGrid-overlayHeight": "calc(100vh - 200px)"
            }}
            rows={
              listFiltered?.map((day, index) => {
                return { ...day, no: index + 1 };
              }) || []
            }
            columns={columns}
            getRowId={(row) => row?.id}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 15
                }
              }
            }}
            pageSizeOptions={[15]}
            slots={{
              loadingOverlay: LoadingOverLay,
              noRowsOverlay: CustomNoRowsOverlay
            }}
            hideFooterSelectedRowCount
            disableColumnMenu={true}
            disableColumnFilter={true}
            disableColumnSelector={true}
            disableColumnSort={true}
            disableRowSelectionOnClick={true}
          />
        </Box>
      </div>

      {wfhModal}

      <DeletePopup
        open={showDeletePopup}
        onClose={() => setShowDeletePopup(false)}
        handleDelete={handleDeleteWFH}
        title="Bạn có chắc chắn xóa ?"
      />
    </div>
  );
};

export default WorkFromHome;
