import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import HolidayAPI from "api/HolidayAPI";
import DropdownListSelectionFilter from "components/DropdownListSelectionFilter";
import LoadingOverLay from "components/LoadingOverlay";
import { listYears } from "components/SharedData";
import CustomNoRowsOverlay from "pages/admin-timesheet/finger-printer/CustomNoRowsOverlay";
import React from "react";
import { formatDateTime } from "utils/DateTimeUtils";

const Holidays = () => {
  const [selectedYear, setSelectedYear] = React.useState(new Date().getFullYear());
  const [holidayData, setHolidayData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchGetHistories = async () => {
      try {
        const response = await HolidayAPI.GetInfoHoliday(selectedYear);
        const newData = response.data;
        const dataResponse = newData.map((item, index) => ({
          ...item,
          id: index + 1
        }));
        setHolidayData(dataResponse);
        setIsLoading(false);
      } catch (error) {
        console.log("Error getStateRemainLeave ", error);
      }
    };
    fetchGetHistories();
  }, [selectedYear]);

  const columns = [
    {
      field: "id",
      headerName: "STT",
      width: 60,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "name",
      headerName: "Event Name",
      width: 1110,
      align: "left",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "type",
      headerName: "Type",
      width: 100,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 100,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return formatDateTime(value);
      }
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 100,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return formatDateTime(value);
      }
    },
    {
      field: "duration",
      headerName: "Duration (days)",
      width: 120,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    }
  ];
  return (
    <div className="flex flex-col bg-white rounded-lg p-5 gap-1.5 h-full">
      <div className="flex flex-row items-center justify-between gap-2">
        <div className="flex flex-row w-[80%] items-center gap-2">
          <span>Year</span>
          <DropdownListSelectionFilter
            listSelection={listYears?.map((item) => ({
              label: item.year,
              value: item.year
            }))}
            selected={selectedYear}
            setSelected={setSelectedYear}
          />
        </div>
      </div>

      <Box
        className="flex-grow"
        sx={{
          height: "100%",
          width: "100%",
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "15px",
            fontWeight: 600
          }
        }}
      >
        <DataGrid
          rowHeight={40}
          sx={{
            border: "none",
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
              fontSize: "16px"
            },
            ".MuiDataGrid-footerContainer": { borderTop: "none" },
            minHeight: "100px",
            "--DataGrid-overlayHeight": "calc(100vh - 200px)"
          }}
          rows={holidayData ?? []}
          columns={columns}
          getRowId={(row) => row?.id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15
              }
            }
          }}
          pageSizeOptions={[15]}
          slots={{
            loadingOverlay: LoadingOverLay,
            noRowsOverlay: CustomNoRowsOverlay
          }}
          isLoading={isLoading}
          hideFooterSelectedRowCount
          disableColumnMenu={true}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableColumnSort={true}
          disableRowSelectionOnClick={true}
        />
      </Box>
    </div>
  );
};

export default Holidays;
