import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import KitchenIcon from "@mui/icons-material/Kitchen";
import { Autocomplete, Chip, TextField, Tooltip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import AdminTimeSheetAPI from "api/AdminTimesheetAPI";
import moment from "moment";
import React, { useEffect } from "react";

import ExportAPI from "api/ExportAPI";
import fileDownload from "js-file-download";
import DocumentLine from "./DocumentLine";
import DocumentPopup from "./DocumentPopup";
import HoursByCategoriesChart from "./HoursByCategoriesChart";
import HoursByProjectChart from "./HoursByProjectChart";
import HoursPerUser from "./HoursPerUser";
import ProjectSummaryLine from "./ProjectSummaryLine";

function Analysis() {
  const ProjectQuery = useQuery({
    queryKey: ["ListProject"],
    queryFn: async () => {
      const response = await AdminTimeSheetAPI.getListProject();
      return response?.data;
    },
    enabled: true
  });

  const { data: listProject } = ProjectQuery;
  const [projectSelected, setProjectSelected] = React.useState(1);

  useEffect(() => {
    if (listProject) setProjectSelected(listProject[0]?.id);
  }, []);

  const ProjectAnalysisViewQuery = useQuery({
    queryKey: ["ProjectAnalysisViewQuery", projectSelected],
    queryFn: async () => {
      const response = await AdminTimeSheetAPI.getProjectAnalysisView(projectSelected || 1);
      return response?.data;
    },
    enabled: true
  });

  const { data: projectAnalysisView, refetch } = ProjectAnalysisViewQuery;
  const { projectName, projectId, objects, documents, categories, summary, users, status } = projectAnalysisView || {};

  const docmentLength = documents?.length || 0;

  const [openDocumentPopup, setOpenDocumentPopup] = React.useState(false);

  const defaultProps = {
    options: listProject || [],
    getOptionLabel: (option) => option.fullName
  };

  const handleExportSummary = async () => {
    const response = await ExportAPI.getProjectAnalysis({ id: projectSelected ?? 0 });

    const outputFilename = `Project_${projectName}.xlsx`;

    fileDownload(response.data, outputFilename);
  };

  return (
    <>
      <div className="flex flex-col h-full max-h-[101vh] gap-2 overflow-y-auto">
        <div className="flex flex-row w-full text-xl font-semibold ">
          <div className="flex flex-col w-full h-full bg-white justify-between rounded-[10px] p-4">
            <Autocomplete
              {...defaultProps}
              id="clear-on-escape"
              disableClearable
              sx={{
                width: "30%",
                backgroundColor: "#dcdcdc",
                borderRadius: "8px",
                padding: 0.5,
                paddingLeft: 1.5,
                ".css-l4u8b9-MuiInputBase-root-MuiInput-root:after": {
                  border: "none"
                },
                ".css-l4u8b9-MuiInputBase-root-MuiInput-root:before": {
                  border: "none !important"
                }
              }}
              onChange={(e, newValue) => {
                setProjectSelected(newValue?.id);
                refetch();
              }}
              renderInput={(params) => <TextField {...params} variant="standard" />}
            />
          </div>
        </div>
        <div className="flex flex-row min-h-[80%] w-full gap-2">
          <div className="flex flex-col items-start w-[50%] bg-white rounded-[10px] p-4">
            <div className="flex flex-col items-start w-full bg-white font-medium text-2xl">
              <div className="flex flex-row justify-between items-start w-full bg-white font-medium text-2xl">
                {projectName}
                <Tooltip title="Download project analysis">
                  <DownloadIcon sx={{ cursor: "pointer" }} onClick={handleExportSummary} />
                </Tooltip>
              </div>
              {status === "Active" ? (
                <Chip
                  style={{
                    width: "75px",
                    background: "#C6DCC6",
                    color: "#004D00",
                    fontWeight: 600,
                    borderRadius: "10px"
                  }}
                  label="Active"
                />
              ) : (
                <Chip
                  style={{
                    width: "75px",
                    background: "#E3CDCD",
                    color: "#660000",
                    fontWeight: 600,
                    borderRadius: "10px"
                  }}
                  label="Inactive"
                />
              )}
            </div>
            <div className="flex flex-col w-full bg-white justify-between gap-2 pb-2">
              <div className="flex flex-col w-full h-full bg-white justify-between border-b-2 font-medium">Summary</div>
              <ProjectSummaryLine dataLeft="Team" dataRight={summary?.teamName} />
              <ProjectSummaryLine dataLeft="Project Manager" dataRight={summary?.manager} />
              <ProjectSummaryLine dataLeft="Member" dataRight={summary?.totalMember} />
              <ProjectSummaryLine dataLeft="Start Date" dataRight={moment(summary?.start).format("YYYY/MM/DD")} />
              <ProjectSummaryLine
                dataLeft="End Date"
                dataRight={(summary?.end && moment(summary?.end).format("YYYY/MM/DD")) || "N/A"}
              />
              <ProjectSummaryLine dataLeft="Work Time" dataRight={summary?.totalHour} hours={true} />
              <ProjectSummaryLine dataLeft="Over Time" dataRight={summary?.totalOvertimeHour} hours={true} />
              <ProjectSummaryLine dataLeft="Total Work Time" dataRight={summary?.totalWorkingHour} hours={true} />
            </div>
            <div className="flex flex-col w-full bg-white justify-between pb-2 gap-2">
              <div className="flex flex-col w-full h-full bg-white justify-between border-b-2 font-medium">Cost</div>
              <ProjectSummaryLine dataLeft="Quotation" dataRight={summary?.quotation} hours={true} />
              <ProjectSummaryLine dataLeft="Working" dataRight={summary?.totalWorking} hours={true} />
              <ProjectSummaryLine dataLeft="Usage Percentage" dataRight={summary?.usagePercentage} />
            </div>

            <div className="flex flex-col w-full bg-white justify-between gap-2">
              <div className="flex flex-row w-full h-full bg-white items-center justify-start border-b-2 font-medium">
                Documents ({`${docmentLength} files`})
                <CloudUploadOutlinedIcon
                  sx={{ cursor: "pointer", marginLeft: 2 }}
                  onClick={() => setOpenDocumentPopup(!openDocumentPopup)}
                />
              </div>
              <div className="flex flex-col w-full h-full bg-white justify-end gap-2">
                {docmentLength > 0 &&
                  documents.map((document, index) => (
                    <DocumentLine key={index} projectId={projectId} documentInfo={document} refetch={refetch} />
                  ))}
              </div>
              <div className="flex flex-row w-full h-full bg-white justify-end font-medium cursor-pointer">
                Export
                <KitchenIcon />
              </div>
            </div>
          </div>

          <div className="flex flex-col w-[25%] h-full bg-white justify-between rounded-[10px]">
            <div className="p-4 font-medium">Hours by objects</div>
            <HoursByProjectChart projectInfo={objects} />
          </div>

          <div className="flex flex-col w-[25%] h-full bg-white justify-between rounded-[10px]">
            <div className="p-4 font-medium">Hours by categories</div>
            <HoursByCategoriesChart projectInfo={categories} />
          </div>
        </div>
        <div className="flex flex-col w-full min-h-[49vh] bg-white justify-between rounded-[10px] p-4 mb-12">
          <div className="font-medium">Hours per user</div>
          <HoursPerUser data={users} />
        </div>
      </div>

      <DocumentPopup
        isOpen={openDocumentPopup}
        projectId={projectId}
        refetch={refetch}
        handleClose={() => setOpenDocumentPopup(!openDocumentPopup)}
      />
    </>
  );
}

export default Analysis;
