function HolidayTag(props) {
  const { holidayName } = props;

  return (
    <div className="flex w-full h-[30%] items-center justify-center p-1 font-semibold text-sm rounded-lg cursor-default">
      <div className="overflow-hidden whitespace-nowrap text-ellipsis text-center ">{holidayName}</div>
    </div>
  );
}

export default HolidayTag;
