import { useAutocomplete } from "@mui/base/useAutocomplete";
import CheckIcon from "@mui/icons-material/Check";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { createPortal } from "react-dom";
import { Controller } from "react-hook-form";

const InputWrapper = styled("div")(
  ({ theme }) => `
  width: 100%;
  min-height: 40px;
  border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#dcdcdc"};
  border-radius: 8px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;


  // &:hover {
  //   border-color: #141414;
  // }

  // &.focused {
  //   border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#141414"};
  //   box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  // }

  & input {
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#dcdcdc"};
    color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"};
    height: 40px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: none;
    border-radius: 8px;
  }
`
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <span onClick={onDelete} style={{ width: "24px", display: "inline-block" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          // eslint-disable-next-line react/no-unknown-property
          stroke-width="1.5"
          stroke="currentColor"
          width={24}
          height={24}
        >
          <path
            // eslint-disable-next-line react/no-unknown-property
            stroke-linecap="round"
            // eslint-disable-next-line react/no-unknown-property
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </span>
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired
};

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#74757a"};
  border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
  border-radius: 8px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;
  color: #fff;
  &:focus {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const ListBox = styled("ul")(
  ({ theme }) => `
  width: 350px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

export default function Autocomplete(props) {
  const { name, form, disable, listSelection } = props;
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
    anchorEl
  } = useAutocomplete({
    id: "autocomplete",
    multiple: true,
    options: listSelection,
    getOptionLabel: (option) => (option?.name ? option.name : option.fullName)
  });

  React.useEffect(() => {
    form.setValue(name, value);
  }, [value]);

  const ListBoxRef = () => {
    let top = 0;
    let left = 0;
    if (anchorEl) {
      const cords = anchorEl?.getBoundingClientRect();
      top = cords.bottom;
      left = cords.left;
    }
    const divRef = React.useRef(null);
    React.useEffect(() => {
      divRef.current.style.top = `${top}px`;
      divRef.current.style.left = `${left}px`;
    }, [top, left]);
    return createPortal(
      <>
        <div style={{ position: "absolute", zIndex: "1400" }} ref={divRef}>
          <ListBox {...getListboxProps()}>
            {groupedOptions.map((option, index) => (
              <li {...getOptionProps({ option, index })} key={index}>
                <span>{option?.name ? option.name : option.fullName}</span>
                <CheckIcon fontSize="small" />
              </li>
            ))}
          </ListBox>
        </div>
      </>,
      document.body
    );
  };

  return (
    <>
      <div {...getRootProps()}>
        <div className="flex flex-row items-start ml-2">
          Apply For
          <p style={{ color: "red", marginLeft: "5px" }}>*</p>
        </div>
        <Controller
          name={name}
          control={form.control}
          render={({ field: { valueForm } }) => (
            <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""} value={valueForm}>
              {value.map((option, index) => (
                <StyledTag
                  label={option?.name ? option.name : option.fullName}
                  {...getTagProps({ index })}
                  key={index}
                />
              ))}
              <input {...getInputProps()} />
            </InputWrapper>
          )}
          size="small"
          variant="outlined"
          fullWidth={true}
          disabled={disable}
        />
      </div>
      <ListBoxRef />
    </>
  );
}
