import axiosClient from "./api-config/axiosClient";

const LeaveFormAPI = {
  async getRequestListView(params) {
    const url = `/Leave/GetRequestListView/${params}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async createLeave(params) {
    const url = `/Leave/Create`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: params
    });
  },

  async updateLeave(params) {
    const url = `/Leave/Update`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: params
    });
  },

  async deleteLeave(id) {
    const url = `/Leave/Delete/${id}`;
    return axiosClient.request({
      method: "DELETE",
      url: url
    });
  },

  async getHistories(params) {
    const url = `/Leave/GetHistories/${params}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getStateRemainLeave() {
    const url = `/Leave/GetStateRemainLeave`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getLeaveInformation() {
    const url = `/Leave/GetLeaveInformation`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  }
};
export default LeaveFormAPI;
