import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { IconButton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import AdminUserAPI from "api/AdminUserAPI";
import SearchInputField from "components/SearchInputFeild";
import * as React from "react";

export default function Members({ dataDetail, setDataDetail, isNewGroup }) {
  const [searchNameValue, setSearchNameValue] = React.useState("");
  const [searchTeamValue, setSearchTeamValue] = React.useState("");

  const { data: listOptionsUser } = useQuery({
    queryKey: ["listOptionsUserQuery"],
    queryFn: async () => {
      const response = await AdminUserAPI.getListOptionUser();
      return response.data;
    }
  });

  const handleRemoveUserFromGroup = (userId) => {
    const updatedUsers = dataDetail?.members.filter((user) => user.id !== userId);

    setDataDetail({
      ...dataDetail,
      members: updatedUsers
    });
  };

  const handleAddUserToGroup = (user) => {
    let updatedUsers = [];
    if (dataDetail?.members) {
      updatedUsers = [...(dataDetail?.members ?? [])];
    }

    updatedUsers.push({
      id: user.id,
      name: user.name,
      fullName: user.fullName,
      teamId: user.teamId,
      teamCode: user.teamCode,
      position: user.position,
      teamName: user.teamName
    });
    setDataDetail({
      ...dataDetail,
      members: updatedUsers
    });
  };

  const handleSearchName = (e) => {
    const newValue = e.target.value;
    setSearchNameValue(newValue);
  };

  const handleSearchTeam = (e) => {
    const newValue = e.target.value;
    setSearchTeamValue(newValue);
  };

  const filteredUsers = React.useMemo(() => {
    if (!listOptionsUser) {
      return;
    }

    function removeVNAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    let filterUsers = [...listOptionsUser];

    if (!isNewGroup && !dataDetail) {
      filterUsers = [];
    }

    if (searchNameValue !== "" && searchTeamValue !== "") {
      filterUsers = listOptionsUser.filter(
        (user) =>
          removeVNAccents(user.fullName.toLowerCase()).includes(removeVNAccents(searchNameValue.toLowerCase())) &&
          removeVNAccents(user.teamName.toLowerCase()).includes(removeVNAccents(searchTeamValue.toLowerCase()))
      );
    } else if (searchNameValue !== "") {
      filterUsers = listOptionsUser.filter((user) =>
        removeVNAccents(user.fullName.toLowerCase()).includes(removeVNAccents(searchNameValue.toLowerCase()))
      );
    } else if (searchTeamValue !== "") {
      filterUsers = listOptionsUser.filter((user) =>
        removeVNAccents(user.teamName.toLowerCase()).includes(removeVNAccents(searchTeamValue.toLowerCase()))
      );
    }

    if (dataDetail?.members) {
      const memberProject = filterUsers?.filter((user) => dataDetail.members.find((u) => u.id === user.id));
      const notMemberProject = filterUsers?.filter((user) => !dataDetail.members.find((u) => u.id === user.id));
      filterUsers = [];
      filterUsers = [...memberProject, ...notMemberProject];
    }

    return filterUsers;
  }, [listOptionsUser, searchNameValue, searchTeamValue, dataDetail?.members]);

  return (
    <div className="rounded-xl bg-[#f0f0f0] p-3 min-h-fit">
      <div className="flex w-full gap-3 pb-2">
        <div className="w-1/2">
          <SearchInputField
            onChange={handleSearchName}
            name="FindUsers"
            placeholder="Find user..."
            value={searchNameValue}
          />
        </div>
        <div className="w-1/2">
          <SearchInputField
            onChange={handleSearchTeam}
            name="FindTeam"
            placeholder="Find team..."
            value={searchTeamValue}
          />
        </div>
      </div>
      <div className="flex mb-1 font-medium w-full">
        <span className="w-[200px] pl-2">Name</span>
        <span className="w-[200px] pl-4">Team</span>
        <span className="w-[150px]">Position</span>
      </div>
      <hr />
      <div>
        <ul className="overflow-auto max-h-[410px] bg-[#f0f0f0]">
          {filteredUsers &&
            filteredUsers.length > 0 &&
            filteredUsers?.map((user) => {
              return (
                <li
                  key={user.id}
                  className="list-none flex items-center h-[35px] cursor-pointer hover:rounded-lg  hover:bg-[#d8d7d7]"
                >
                  <div className="w-[200px] font-medium text-base pl-2">
                    <span>{user.fullName}</span>
                  </div>
                  <div className="w-[200px] leading-9 overflow-hidden text-ellipsis whitespace-nowrap pr-2">
                    <span>{user.teamName || "RD"}</span>
                  </div>
                  <div className="w-[150px] leading-9 flex items-center justify-between pl-2">
                    <span>{user.position || "Probationary Staff"}</span>
                    <div>
                      <span>
                        {dataDetail?.members?.some((userDetail) => userDetail.id === user.id) ? (
                          <IconButton onClick={() => handleRemoveUserFromGroup(user.id)}>
                            <RemoveCircleIcon color="error" />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => handleAddUserToGroup(user)}>
                            <AddCircleIcon />
                          </IconButton>
                        )}
                      </span>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
