import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";

function DataGridColumn(users, handleEditSwapDay, handleDeleteSwapDay) {
  const columns = [
    {
      field: "no",
      headerName: "STT",
      headerAlign: "center",
      align: "center",
      width: 60,
      sortable: false
    },
    {
      field: "fromDateText",
      headerName: "Leave Date",
      headerAlign: "center",
      align: "center",
      width: 130,
      sortable: false
    },
    {
      field: "toDateText",
      headerName: "Compensatory Date",
      headerAlign: "center",
      align: "center",
      width: 130,
      sortable: false
    },
    {
      field: "users",
      headerName: "Users",
      flex: 1,
      minWidth: 250,
      maxWidth: 450,
      headerAlign: "center",
      align: "left",
      sortable: false
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      maxWidth: 470
    },
    {
      field: "createdByName",
      headerName: "Created By",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 100
    },
    {
      field: "createdByText",
      headerName: "Created Date",
      headerAlign: "center",
      sortable: false,
      align: "center",
      width: 150
    },
    {
      field: "action",
      headerName: "",
      width: 100,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton onClick={() => handleEditSwapDay(params.row)}>
              <EditIcon />
            </IconButton>

            <IconButton onClick={() => handleDeleteSwapDay(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          </>
        );
      }
    }
  ];
  return columns;
}

export default DataGridColumn;
