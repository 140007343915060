import { useMutation, useQuery } from "@tanstack/react-query";
import AdminUserAPI from "api/AdminUserAPI";
import SwapDayAPI from "../services/services/SwapDayAPI";

function useSwapDay(year) {
  const { data: swapDays, isLoading } = useQuery({
    queryKey: ["swapDay", year],
    queryFn: () => SwapDayAPI.getSwapDayByYear(year)
  });

  const { data: users } = useQuery({
    queryKey: ["user"],
    queryFn: () => AdminUserAPI.getListOptionUser()
  });

  const { mutate: mutateCreateSwapDay } = useMutation({
    mutationFn: (swapDay) => SwapDayAPI.createSwapDay(swapDay)
  });

  const { mutate: mutateUpdateSwapDay } = useMutation({
    mutationFn: (swapDay) => SwapDayAPI.updateSwapDay(swapDay)
  });

  const { mutate: mutateDeleteSwapDay } = useMutation({
    mutationFn: (id) => SwapDayAPI.deleteSwapDay(id)
  });

  return { swapDays, isLoading, users, mutateCreateSwapDay, mutateUpdateSwapDay, mutateDeleteSwapDay };
}

export default useSwapDay;
