import { ThemeProvider, createTheme } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router";
import store from "store/store";
import { router } from "./router";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0
    }
  }
});

const theme = createTheme({
  typography: {
    fontFamily: ['"Roboto Flex"', "Helvetica", "sans-serif"].join(",")
  }
});
export default function AppRoutes() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <GoogleOAuthProvider clientId="141906522419-2lacudoga3ll963c7baeja5bnhss1rkf.apps.googleusercontent.com">
            <React.StrictMode>
              <RouterProvider router={router}></RouterProvider>
            </React.StrictMode>
          </GoogleOAuthProvider>
        </QueryClientProvider>
      </Provider>
    </ThemeProvider>
  );
}
