import ChevronRight from "@mui/icons-material/ChevronRight";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { cloneDeep, uniq } from "lodash";
import * as React from "react";
import { useMemo } from "react";

export default function PermissionsComponent({
  roleDetail,
  setRoleDetail,
  isNewRole,
  disabled,
  dataNewRole,
  setDataNewRole,
  referenceToRoleValue,
  roleReference,
  setRoleReference
}) {
  const roleNameReference = useMemo(() => {
    //update role
    if (roleDetail && roleDetail?.roles) {
      return Object.keys(roleDetail?.roles);
    }
    //new va reference
    if (roleReference && roleReference?.roles) {
      return Object.keys(roleReference?.roles);
    }
    //new role
    return Object.keys(dataNewRole);
  }, [roleDetail, roleReference, dataNewRole]);

  const [collapse, setCollapse] = React.useState(roleNameReference.map(() => false));
  const toggleCollapse = (index) => {
    const newCollapse = [...collapse];
    newCollapse[index] = !newCollapse[index];
    setCollapse(newCollapse);
  };

  const [collapseNewRole, setCollapseNewRole] = React.useState(roleNameReference.map(() => false));
  const toggleCollapseNewRole = (index) => {
    const newCollapse = [...collapseNewRole];
    newCollapse[index] = !newCollapse[index];
    setCollapseNewRole(newCollapse);
  };

  const handlePermissionChange = (event, roleName, permissionIndex) => {
    const roles = cloneDeep(roleDetail);
    const role = roles.roles[roleName];
    const permission = role[permissionIndex];
    permission.isActive = event.target.checked;
    setRoleDetail(roles);
  };

  const handlePermissionChangeAll = (event, roleName) => {
    const roles = cloneDeep(roleDetail);
    const role = roles.roles[roleName];
    role.forEach((per) => {
      per.isActive = event.target.checked;
    });
    setRoleDetail(roles);
  };

  const handleNewPermissionChangeReference = (event, roleName, permissionIndex) => {
    const roles = cloneDeep(roleReference);
    const role = roles.roles[roleName];
    const permission = role[permissionIndex];
    permission.isActive = event.target.checked;
    setRoleReference(roles);
    setRoleDetail(null);
    setDataNewRole(null);
  };

  const handleNewPermissionChangeReferenceAll = (event, roleName) => {
    const roles = cloneDeep(roleReference);
    const role = roles?.roles[roleName];
    role.forEach((per) => {
      per.isActive = event.target.checked;
    });
    setRoleReference(roles);
    setRoleDetail(null);
    setDataNewRole(null);
  };

  const handleNewRolePermissionChange = (event, roleName, permissionIndex) => {
    const roles = cloneDeep(dataNewRole);
    const role = roles[roleName];
    const permission = role[permissionIndex];
    permission.isActive = event.target.checked;
    setDataNewRole(roles);
  };

  const handleNewRolePermissionChangeAll = (event, roleName) => {
    const roles = cloneDeep(dataNewRole);
    const role = roles[roleName];
    role.forEach((per) => {
      per.isActive = event.target.checked;
    });
    setDataNewRole(roles);
  };

  return (
    <>
      {/* new role */}
      {isNewRole && !referenceToRoleValue ? (
        <List>
          {roleNameReference?.map((roleName, index) => {
            const activeValues = uniq(dataNewRole[roleName]?.map((role) => role?.isActive));
            const isChecked = activeValues.length === 1 && activeValues[0];
            return (
              <div key={roleName}>
                <ListItemButton
                  sx={{
                    backgroundColor: "#d8d7d7",
                    borderRadius: "10px",
                    height: "36px",
                    padding: "10px",
                    margin: "10px",
                    "&:hover": {
                      borderRadius: "10px",
                      backgroundColor: "#d8d7d7",
                      cursor: "context-menu"
                    },
                    marginY: "2px"
                  }}
                >
                  <ListItemText primary={roleName} />
                  <Checkbox
                    checked={isChecked}
                    disabled={!!disabled}
                    indeterminate={activeValues.length === 2}
                    size="small"
                    onChange={(e) => handleNewRolePermissionChangeAll(e, roleName)}
                  />
                  {collapseNewRole[index] ? (
                    <ExpandMore
                      onClick={() => toggleCollapseNewRole(index)}
                      sx={{ "&:hover": { cursor: "pointer" } }}
                    />
                  ) : (
                    <ChevronRight
                      onClick={() => toggleCollapseNewRole(index)}
                      sx={{ "&:hover": { cursor: "pointer" } }}
                    />
                  )}
                </ListItemButton>
                <Collapse in={collapseNewRole[index]} timeout="auto" unmountOnExit>
                  <List sx={{ paddingY: "2px" }}>
                    {dataNewRole[roleName].map((permission, i) => {
                      return (
                        <div className="flex justify-between items-center px-[10px] mr-[18px] ml-8 h-9" key={i}>
                          <div className="flex items-center">
                            <FiberManualRecordIcon sx={{ fontSize: "10px", marginRight: "10px" }} />
                            <ListItemText primary={permission.name} sx={{ textTransform: "capitalize" }} />
                          </div>
                          <Checkbox
                            sx={{ marginRight: "16px" }}
                            checked={permission.isActive}
                            size="small"
                            disabled={!!disabled}
                            onChange={(event) => handleNewRolePermissionChange(event, roleName, i)}
                          />
                        </div>
                      );
                    })}
                  </List>
                </Collapse>
              </div>
            );
          })}
        </List>
      ) : isNewRole && referenceToRoleValue ? (
        // new role
        <List>
          {roleNameReference?.map((roleName, index) => {
            const activeValues = uniq(roleReference?.roles[roleName].map((role) => role?.isActive));
            const isChecked = activeValues.length === 1 && activeValues[0];
            return (
              <div key={roleName}>
                <ListItemButton
                  sx={{
                    backgroundColor: "#d8d7d7",
                    borderRadius: "10px",
                    height: "36px",
                    padding: "10px",
                    margin: "10px",
                    "&:hover": {
                      borderRadius: "10px",
                      backgroundColor: "#d8d7d7",
                      cursor: "context-menu"
                    },
                    marginY: "2px"
                  }}
                >
                  <ListItemText primary={roleName} />
                  <Checkbox
                    checked={isChecked}
                    disabled={!!disabled}
                    indeterminate={activeValues.length === 2}
                    size="small"
                    onChange={(e) => handleNewPermissionChangeReferenceAll(e, roleName)}
                  />
                  {collapse[index] ? (
                    <ExpandMore onClick={() => toggleCollapse(index)} sx={{ "&:hover": { cursor: "pointer" } }} />
                  ) : (
                    <ChevronRight onClick={() => toggleCollapse(index)} sx={{ "&:hover": { cursor: "pointer" } }} />
                  )}
                </ListItemButton>
                <Collapse in={collapse[index]} timeout="auto" unmountOnExit>
                  <List sx={{ paddingY: "2px" }}>
                    {roleReference?.roles[roleName].map((permission, i) => {
                      return (
                        <div className="flex justify-between items-center px-[10px] mr-[18px] ml-8 h-9" key={i}>
                          <div className="flex items-center">
                            <FiberManualRecordIcon sx={{ fontSize: "10px", marginRight: "10px" }} />
                            <ListItemText primary={permission.name} sx={{ textTransform: "capitalize" }} />
                          </div>
                          <Checkbox
                            sx={{ marginRight: "16px" }}
                            checked={permission.isActive}
                            size="small"
                            disabled={!!disabled}
                            onChange={(event) => handleNewPermissionChangeReference(event, roleName, i)}
                          />
                        </div>
                      );
                    })}
                  </List>
                </Collapse>
              </div>
            );
          })}
        </List>
      ) : (
        // edit role
        <List>
          {roleNameReference.map((roleName, index) => {
            const activeValues = uniq(roleDetail?.roles[roleName].map((role) => role.isActive));
            const isChecked = activeValues.length === 1 && activeValues[0];
            return (
              <div key={roleName}>
                <ListItemButton
                  sx={{
                    backgroundColor: "#d8d7d7",
                    borderRadius: "10px",
                    height: "36px",
                    padding: "10px",
                    margin: "10px",
                    "&:hover": {
                      borderRadius: "10px",
                      backgroundColor: "#d8d7d7",
                      cursor: "context-menu"
                    },
                    marginY: "2px"
                  }}
                >
                  <ListItemText primary={roleName} />
                  <Checkbox
                    checked={isChecked}
                    disabled={!!disabled}
                    indeterminate={activeValues.length === 2}
                    size="small"
                    onChange={(e) => handlePermissionChangeAll(e, roleName)}
                  />
                  {collapse[index] ? (
                    <ExpandMore onClick={() => toggleCollapse(index)} sx={{ "&:hover": { cursor: "pointer" } }} />
                  ) : (
                    <ChevronRight onClick={() => toggleCollapse(index)} sx={{ "&:hover": { cursor: "pointer" } }} />
                  )}
                </ListItemButton>
                <Collapse in={collapse[index]} timeout="auto" unmountOnExit>
                  <List sx={{ paddingY: "2px" }}>
                    {roleDetail?.roles[roleName].map((permission, i) => {
                      return (
                        <div className="flex justify-between items-center px-[10px] mr-[18px] ml-8 h-9" key={i}>
                          <div className="flex items-center">
                            <FiberManualRecordIcon sx={{ fontSize: "10px", marginRight: "10px" }} />
                            <ListItemText primary={permission.name} sx={{ textTransform: "capitalize" }} />
                          </div>
                          <Checkbox
                            sx={{ marginRight: "16px" }}
                            checked={permission.isActive}
                            size="small"
                            disabled={!!disabled}
                            onChange={(event) => handlePermissionChange(event, roleName, i)}
                          />
                        </div>
                      );
                    })}
                  </List>
                </Collapse>
              </div>
            );
          })}
        </List>
      )}
    </>
  );
}
