import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { Box, Button, Grid, Typography } from "@mui/material";

function ErrorPage() {
  return (
    <Box>
      <Grid
        item
        spacing={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "600px",
          height: "100px",
          marginTop: "-300px",
          marginLeft: "-300px"
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            height: 150,
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            alignItems: "center"
          }}
        >
          <SentimentVeryDissatisfiedIcon
            align="center"
            style={{ fontSize: 200, color: "gray" }}
          ></SentimentVeryDissatisfiedIcon>

          <Typography
            marginTop={2}
            color="primary"
            variant="body1"
            sx={{ marginBottom: "20px", fontSize: "69px", marginTop: "15px", color: "gray" }}
          >
            404
          </Typography>

          <Typography
            marginTop={2}
            color="primary"
            variant="body1"
            sx={{ marginBottom: "20px", fontSize: "29px", marginTop: "5px", color: "gray" }}
          >
            Page not found
          </Typography>

          <Button
            component="a"
            href="https://dev.ttdesignco.com/"
            disableElevation
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            sx={{ width: 200 }}
          >
            Go To Home Page
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ErrorPage;
