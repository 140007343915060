import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CalendarAPI from "api/CalendarAPI";

// createAsyncThunk cái này sử dụng cho login và register
export const getListCalendars = createAsyncThunk("Calendar/getListView", async (payload) => {
  try {
    const response = await CalendarAPI.getListView(payload);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.message;
  }
});

export const getListObjects = createAsyncThunk("Calendar/getListObjects", async (payload) => {
  try {
    const response = await CalendarAPI.getObjects(payload);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.message;
  }
});

const calendarSlice = createSlice({
  name: "calendar",
  initialState: {
    calendarsInMonth: [],
    objects: [],
    status: "idle",
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListCalendars.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getListCalendars.fulfilled, (state, action) => {
        state.calendarsInMonth = action.payload;
        state.status = "succeeded";
      })
      .addCase(getListCalendars.rejected, (state, action) => {
        state.status = action.error.message;
      })
      .addCase(getListObjects.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getListObjects.fulfilled, (state, action) => {
        state.objects = action.payload;
        state.status = "succeeded";
      })
      .addCase(getListObjects.rejected, (state, action) => {
        state.status = action.error.message;
      });
  }
});

const { actions, reducer } = calendarSlice;
export const { a = {} } = actions;
export default reducer;
