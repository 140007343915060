import { Button, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useQuery } from "@tanstack/react-query";
import UserAPI from "api/UserAPI";
import UrlConstant from "utils/constants/UrlConstant";

function UserInfoPopUp({ onClose, open, userInfo }) {
  const { id } = userInfo;

  const handleClose = () => {
    onClose();
  };

  const { data } = useQuery({
    queryKey: ["userInfoQuery", id],
    queryFn: async () => {
      const response = await UserAPI.getDetailOtherUser(id);
      return response.data;
    },
    staleTime: Infinity
  });

  const { about, avatar, address, dateOfBirthText, email, phone, position, teamCode, userName, fullName } = data ?? {};

  const avatarPath = `${UrlConstant.REACT_APP_DATABASE_URL}${avatar}`;

  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ sx: { borderRadius: "5px", width: "680px" } }}>
      <DialogContent style={{ padding: "20px" }}>
        <div className="w-full flex flex-col justify-center gap-4 overflow-x-hidden">
          <div className="flex flex-col items-center">
            <h4>
              <strong>USER INFORMATION</strong>
            </h4>
          </div>

          <div className="grid grid-rows-3 grid-flow-col gap-4">
            <div className="row-span-3">
              <img src={avatarPath} alt="Avatar" className="rounded-lg" width="100%" height="100%"></img>
            </div>
            <div className="col-span-2">
              <div className="flex flex-row items-start  gap-4">
                <div className="w-[70%] flex-col">
                  <div className="flex flex-row items-start ml-1">Name</div>
                  <TextField
                    value={fullName}
                    size="small"
                    variant="outlined"
                    fullWidth={true}
                    disabled
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "black"
                      },
                      borderRadius: "8px",
                      backgroundColor: "#dcdcdc",
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        outline: "flexnone"
                      }
                    }}
                  />
                </div>
                <div className="w-[30%] flex-col">
                  <div className="flex flex-row items-start ml-1">&nbsp;</div>
                  <TextField
                    value={userName}
                    size="small"
                    variant="outlined"
                    fullWidth={true}
                    disabled
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "black"
                      },
                      borderRadius: "8px",
                      backgroundColor: "#dcdcdc",
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        outline: "flexnone"
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-span-2">
              <div className="flex flex-row items-start gap-4">
                <div className="w-full flex-col">
                  <div className="flex flex-row items-start ml-1">Date Of Birth</div>
                  <TextField
                    value={dateOfBirthText}
                    size="small"
                    variant="outlined"
                    fullWidth={true}
                    disabled
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "black"
                      },
                      borderRadius: "8px",
                      backgroundColor: "#dcdcdc",
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        outline: "flexnone"
                      }
                    }}
                  />
                </div>
                <div className="w-full flex-col">
                  <div className="flex flex-row items-start ml-1">Phone Number</div>
                  <TextField
                    value={phone}
                    size="small"
                    variant="outlined"
                    fullWidth={true}
                    disabled
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "black"
                      },
                      borderRadius: "8px",
                      backgroundColor: "#dcdcdc",
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        outline: "flexnone"
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-span-2">
              <div className="flex flex-row items-start gap-4">
                <div className="w-full flex-col">
                  <div className="flex flex-row items-start ml-1">Team</div>
                  <TextField
                    value={teamCode}
                    size="small"
                    variant="outlined"
                    fullWidth={true}
                    disabled
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "black"
                      },
                      borderRadius: "8px",
                      backgroundColor: "#dcdcdc",
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        outline: "flexnone"
                      }
                    }}
                  />
                </div>
                <div className="w-full flex-col">
                  <div className="flex flex-row items-start ml-1">Position</div>
                  <TextField
                    value={position}
                    size="small"
                    variant="outlined"
                    fullWidth={true}
                    disabled
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "black"
                      },
                      borderRadius: "8px",
                      backgroundColor: "#dcdcdc",
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        outline: "flexnone"
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row items-start gap-4">
            <div className="w-[65%] flex-col ">
              <div className="flex flex-row items-start ml-1">Mail</div>
              <TextField
                value={email}
                size="small"
                variant="outlined"
                fullWidth={true}
                disabled
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "black"
                  },
                  borderRadius: "8px",
                  backgroundColor: "#dcdcdc",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    outline: "flexnone"
                  }
                }}
              />
            </div>
            <div className="w-full flex-col">
              <div className="flex flex-row items-start ml-1">Address</div>
              <TextField
                value={address}
                size="small"
                variant="outlined"
                fullWidth={true}
                disabled
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "black"
                  },
                  borderRadius: "8px",
                  backgroundColor: "#dcdcdc",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    outline: "flexnone"
                  }
                }}
              />
            </div>
          </div>

          <div className="flex flex-row items-start">
            <div className="w-full flex-col gap-4">
              <div className="flex flex-row items-start ml-1">About me</div>
              <TextField
                value={about}
                size="small"
                variant="outlined"
                fullWidth={true}
                disabled
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "black"
                  },
                  borderRadius: "8px",
                  backgroundColor: "#dcdcdc",
                  "& .MuiOutlinedInput-input": {
                    padding: "5px 0 !important"
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    outline: "flexnone"
                  }
                }}
                multiline
              />
            </div>
          </div>

          <div className="w-full flex flex-row justify-end">
            <Button
              variant="contained"
              onClick={() => {
                handleClose();
              }}
              size="small"
              sx={{
                textTransform: "capitalize",
                boxShadow: "none",
                "&:hover": { boxShadow: "none", background: "#F0F0F0" },
                background: "#F0F0F0",
                color: "#555555",
                fontWeight: 600,
                borderRadius: "10px",
                width: "90px",
                padding: "5px 0"
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default UserInfoPopUp;
