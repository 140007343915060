import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TimeSheetAPI from "api/TimeSheetAPI";
import dayjs from "dayjs";

export const getTimesheetDetail = createAsyncThunk("Timesheet/getTimesheetDetail", async (payload) => {
  try {
    if (payload.isAdmin) {
      const response = await TimeSheetAPI.getTimesheetDetailAdmin(payload);
      return response.data;
    } else {
      const response = await TimeSheetAPI.getTimesheetDetailPesonal(payload);
      return response.data;
    }
  } catch (error) {
    console.log(error);
    return error.message;
  }
});

export const getListTimesheetsByMonthAndYear = createAsyncThunk(
  "Timesheet/GetListTimesheetsByMonthAndYear",
  async (payload) => {
    try {
      if (payload.isAdmin) {
        const response = await TimeSheetAPI.GetListTimesheetByMonthAndYearAdmin(payload);
        return response.data;
      } else {
        const response = await TimeSheetAPI.GetListTimesheetByMonthAndYearPersonal(payload);
        return response.data;
      }
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

export const getListProjectsByUser = createAsyncThunk("Timesheet/GetListProjectsByUser", async () => {
  try {
    const response = await TimeSheetAPI.GetListProjectsByUser();
    return response.data;
  } catch (error) {
    console.log(error);
    return error.message;
  }
});

export const getTimesheetObjects = createAsyncThunk("TimesheetObject/getTimesheetObjectsByTeamId", async () => {
  try {
    const response = await TimeSheetAPI.GetTimesheetObjects();
    return response.data;
  } catch (error) {
    console.log(error);
    return error.message;
  }
});

export const getTimesheetCategories = createAsyncThunk("TimesheetCategory/getTimesheetCategories", async () => {
  try {
    const response = await TimeSheetAPI.GetTimesheetCategories();
    return response.data;
  } catch (error) {
    console.log(error);
    return error.message;
  }
});

const timesheetSlice = createSlice({
  name: "timesheet",
  initialState: {
    timesheets: [],
    taskSummaryHours: [],
    tasks: [],
    timesheetCategories: [],
    timesheetObjects: [],
    projectsByUser: [],
    taskDetails: [],
    status: "idle",
    userIdSelected: JSON.parse(localStorage.getItem("user"))?.id,
    daySelected: dayjs(new Date()),
    error: null
  },

  reducers: {
    changeUserIdSelected: (state, action) => {
      state.userIdSelected = action.payload;
    },
    changeDaySelected: (state, action) => {
      state.daySelected = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getTimesheetDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTimesheetDetail.fulfilled, (state, action) => {
        state.taskDetails = action.payload;
        state.status = "succeeded";
      })
      .addCase(getTimesheetDetail.rejected, (state, action) => {
        state.status = action.error.message;
      })
      .addCase(getListTimesheetsByMonthAndYear.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getListTimesheetsByMonthAndYear.fulfilled, (state, action) => {
        state.timesheets = action.payload;
        state.status = "succeeded";
      })
      .addCase(getListTimesheetsByMonthAndYear.rejected, (state, action) => {
        state.status = action.error.message;
      })

      .addCase(getTimesheetObjects.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTimesheetObjects.fulfilled, (state, action) => {
        state.timesheetObjects = action.payload;
        state.status = "succeeded";
      })
      .addCase(getTimesheetObjects.rejected, (state, action) => {
        state.status = action.error.message;
      })
      .addCase(getTimesheetCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTimesheetCategories.fulfilled, (state, action) => {
        state.timesheetCategories = action.payload;
        state.status = "succeeded";
      })
      .addCase(getTimesheetCategories.rejected, (state, action) => {
        state.status = action.error.message;
      })

      .addCase(getListProjectsByUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getListProjectsByUser.fulfilled, (state, action) => {
        state.projectsByUser = action.payload;
        state.status = "succeeded";
      })
      .addCase(getListProjectsByUser.rejected, (state, action) => {
        state.status = action.error.message;
      });
  }
});

const { actions, reducer } = timesheetSlice;
export const { changeUserIdSelected, changeDaySelected } = actions;
export default reducer;
