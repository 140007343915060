import { css } from "@emotion/css";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Controller } from "react-hook-form";

TextAreaInputField.propTypes = {};

function TextAreaInputField(props) {
  const { name, form, label, placeholder, maxRows, minRows, disable } = props;

  const {
    formState: { errors }
  } = form;

  return (
    <div className="flex flex-col w-full my-1.5 mx-0">
      <div className="mb-1">{label}</div>

      <Controller
        name={name}
        control={form.control}
        render={({ field: { onChange, value } }) => (
          <TextareaAutosize
            className={css`
              padding: 10px;
              border-radius: 8px;
              background: #dcdcdc;
              border: none;
              outline: none;
              width: 100%;
              font-size: 16px;
              font: inherit;
            `}
            onChange={onChange}
            value={value}
            minRows={minRows}
            maxRows={maxRows}
            placeholder={placeholder}
            disabled={disable}
          />
        )}
      />
      <div
        style={{
          height: "14px",
          width: "100%",
          border: "none",
          outline: "none"
        }}
      >
        {
          <span style={{ fontSize: "14px", color: "red", fontWeight: 400 }}>
            {errors[name] && errors[name].message}
          </span>
        }
      </div>
    </div>
  );
}

export default TextAreaInputField;
