import { css } from "@emotion/css";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useState } from "react";

function SelectTTD(props) {
  const { form, name, label, requiredInput, defaultValue, listValues } = props;
  const {
    formState: { errors }
  } = form;

  const [selected, setSelected] = useState(defaultValue || 0);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelected(value);

    if (form) form.setValue(name, value, { shouldValidate: true });
  };

  return (
    <div className="w-full flex flex-col items-start">
      <div className="flex flex-row items-start ml-2">
        {label}

        {requiredInput && <p style={{ color: "red", marginLeft: "5px" }}>*</p>}
      </div>

      <div className="w-full flex flex-col items-start">
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Age"
            className={css`
              background-color: #dcdcdc;
              width: 100%;
              .MuiOutlinedInput-notchedOutline {
                border: none;
                outline: none;
              }
            `}
            sx={{ borderRadius: "10px" }}
            defaultValue={defaultValue}
            value={selected}
            onChange={handleChange}
          >
            {listValues.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                <p>{item.label}</p>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <span style={{ fontSize: "14px", color: "red", fontWeight: 400 }}>{errors[name] && errors[name].message}</span>
      </div>
    </div>
  );
}
export default SelectTTD;
