import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge, IconButton, Menu } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import NotificationAPI from "api/NotificationAPI";
import React, { useState } from "react";
import { ToastShowError, ToastShowNotification } from "utils/Notify";
import { TOAST_ERROR_STRING } from "utils/ToastString";
import StorageKeys from "utils/constants/StorageKeys";
import UrlConstant from "utils/constants/UrlConstant";
import { NotificationItem } from "./NotificationItem";
import { NotificationToast } from "./NotificationToast";

Notification.propTypes = {};

function Notification() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [connection, setConnection] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const NotificationQuery = useQuery({
    queryKey: ["Notification"],
    queryFn: async () => {
      const response = await NotificationAPI.getListView();
      return response?.data;
    }
  });
  const { data: notifications, refetch } = NotificationQuery;
  const notiUnRead = notifications?.filter((item) => !item?.status).length ?? 0;

  const queryClient = useQueryClient();
  const handleRefetch = async () => {
    try {
      await queryClient.refetchQueries({ queryKey: ["listViewOverTimeQuery"], type: "active" });
      await queryClient.refetchQueries({ queryKey: ["listViewLeaveQuery"], type: "active" });
      await queryClient.refetchQueries({ queryKey: ["listViewWFHQuery"], type: "active" });
    } catch (error) {
      console.error("Error refetching queries:", error);
    }
  };

  React.useEffect(() => {
    const accessToken = localStorage.getItem(StorageKeys.access);
    const connection = new HubConnectionBuilder()
      .withUrl(`${UrlConstant.REACT_APP_DATABASE_URL}/NotificationUserHubs`, {
        accessTokenFactory: () => accessToken
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();

    setConnection(connection);

    return () => {
      if (connection) {
        connection
          .stop()
          .then(() => console.log("Connection stopped"))
          .catch((err) => console.error("Error while stopping connection: ", err));
      }
    };
  }, []);

  React.useEffect(() => {
    if (connection) {
      connection.start().then(function () {
        connection
          .invoke("getConnectionId")
          .then(function (id) {
            console.log("connectionid:", id);
          })
          .catch(function (err) {
            console.log("ERROR in NotificationHub getConnectionId:", err.toString());
          });

        connection.on("Notification", async (message) => {
          try {
            refetch();

            ToastShowNotification(<NotificationToast notification={message} />);

            handleRefetch();
            // console.log("Notification: ", message);
          } catch (error) {
            // Handle the error
            console.error("Error refetching queries:", error);
            ToastShowNotification("Có lỗi khi làm mới dữ liệu");
          }
        });
      });
    }
  }, [connection]);

  const handleMarkAllRead = async () => {
    try {
      const response = await NotificationAPI.markReadAll();
      if (response?.status === 200) refetch();
    } catch (error) {
      ToastShowError(TOAST_ERROR_STRING);
    }
  };

  const handleDeleteAll = async () => {
    try {
      const response = await NotificationAPI.deleteAll();
      if (response?.status === 200) refetch();
    } catch (error) {
      ToastShowError(TOAST_ERROR_STRING);
    }
  };

  const userMenu = (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 18,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0
          }
        }
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <div className="max-h-[500px] w-[350px] overflow-y-auto overflow-x-hidden">
        <div className="flex flex-row justify-end gap-2 px-2">
          <p onClick={handleMarkAllRead} style={{ cursor: "pointer", color: "blue" }} className="underline">
            <em>Mark Read</em>
          </p>
          <p onClick={handleDeleteAll} style={{ cursor: "pointer", color: "blue" }} className="underline">
            <em>DeleteAll</em>
          </p>
        </div>

        {notifications &&
          notifications.map((notification) => (
            <NotificationItem key={notification?.id} notification={notification} refetch={refetch} />
          ))}

        {notifications?.length === 0 && (
          <div className={`flex flex-col items-start border-t-[1px] border-black p-2 h-[100px]`}>
            <div className="w-full h-full flex flex-row items-center justify-center">
              <p className="italic h-full flex items-center">No notification</p>
            </div>
          </div>
        )}
      </div>
    </Menu>
  );

  return (
    <>
      <IconButton size="large" color="inherit" onClick={handleClick}>
        <Badge badgeContent={notiUnRead} color="error">
          <NotificationsIcon fontSize="inherit" />
        </Badge>
      </IconButton>
      {userMenu}
    </>
  );
}

export default Notification;
