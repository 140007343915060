import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import LeaveFormAPI from "api/LeaveFormAPI";
import LoadingOverLay from "components/LoadingOverlay";
import CustomNoRowsOverlay from "pages/admin-timesheet/finger-printer/CustomNoRowsOverlay";
import React from "react";

const Information = () => {
  const [dataLeaveInfomation, setDataLeaveInfomation] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchGetLeaveInformation = async () => {
      try {
        const response = await LeaveFormAPI.getLeaveInformation();
        setDataLeaveInfomation(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log("Error getStateRemainLeave ", error);
      }
    };
    fetchGetLeaveInformation();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "STT",
      width: 60,
      align: "center",
      sortable: false,
      headerClassName: "header",
      headerAlign: "center"
    },
    {
      field: "type",
      headerName: "Type",
      width: 160,
      align: "left",
      sortable: false,
      headerClassName: "header",
      headerAlign: "center",
      renderCell: (params) => {
        return <>{params.row.typeName}</>;
      }
    },
    {
      field: "detail",
      headerName: "Loại Ngày Nghỉ",
      width: 200,
      align: "center",
      sortable: false,
      headerClassName: "header",
      hederAlign: "center"
    },
    {
      field: "leaveDay",
      headerName: "Số Ngày",
      width: 150,
      align: "center",
      sortable: false,
      headerClassName: "header",
      headerAlign: "center"
    },
    {
      field: "startCondition",
      headerName: "Thời gian bắt đầu",
      width: 175,
      align: "center",
      sortable: false,
      headerClassName: "header",
      headerAlign: "center"
    },
    {
      field: "end",
      headerName: "Thời gian kết thúc",
      width: 175,
      align: "center",
      sortable: false,
      headerClassName: "header",
      headerAlign: "center"
    },
    {
      field: "block",
      headerName: "Block",
      width: 80,
      align: "center",
      sortable: false,
      headerClassName: "header",
      headerAlign: "center"
    },
    {
      field: "using",
      headerName: "Cách sử dụng",
      width: 160,
      align: "center",
      sortable: false,
      headerClassName: "header",
      headerAlign: "center"
    },
    {
      field: "note",
      headerName: "Lưu ý",

      width: 425,
      align: "center",
      sortable: false,
      headerClassName: "header",
      headerAlign: "center"
    }
  ];

  return (
    <div className="flex flex-col h-full w-full bg-white rounded-lg p-5 gap-1.5 ">
      <div className="flex w-full flex-row items-center justify-between gap-2">
        <p className="w-full text-center font-bold text-xl">Definition of Vacation Days</p>
      </div>
      <Box
        className="flex-grow"
        sx={{
          height: "100%",
          width: "100%",
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "15px",
            fontWeight: 600
          }
        }}
      >
        <DataGrid
          getRowHeight={() => "auto"}
          sx={{
            border: "none",
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
              fontSize: "16px"
            },
            "& .MuiDataGrid-cellContent": {
              minHeight: 40,
              display: "flex",
              alignItems: "center"
            },
            ".MuiDataGrid-footerContainer": { borderTop: "none" },
            minHeight: "100px",
            "--DataGrid-overlayHeight": "calc(100vh - 200px)"
          }}
          rows={dataLeaveInfomation ?? []}
          columns={columns}
          getRowId={(row) => row?.id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15
              }
            }
          }}
          pageSizeOptions={[15]}
          slots={{
            loadingOverlay: LoadingOverLay,
            noRowsOverlay: CustomNoRowsOverlay
          }}
          isLoading={isLoading}
          hideFooterSelectedRowCount
          disableColumnMenu={true}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableColumnSort={true}
          disableRowSelectionOnClick={true}
        />
      </Box>
    </div>
  );
};

export default Information;
