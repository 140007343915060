import AddCircleIcon from "@mui/icons-material/AddCircle";
import Delete from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Chip, IconButton, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import CategoryAPI from "api/CategoryAPI";
import ObjectAPI from "api/ObjectAPI";
import UserOverTimeAPI from "api/OverTimeAPI";
import ProjectAPI from "api/ProjectAPI";
import MonthPickerInput from "components/DateTimePicker/MonthPickerInput";
import DeletePopup from "components/DeletePopUp";
import DropdownListSelectionFilter from "components/DropdownListSelectionFilter";
import LoadingOverLay from "components/LoadingOverlay";
import { listOvertimeStatus } from "components/SharedData";
import CustomNoRowsOverlay from "pages/admin-timesheet/finger-printer/CustomNoRowsOverlay";
import React from "react";
import { formatDateTime } from "utils/DateTimeUtils";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import useRefreshQuery from "utils/useRefreshQuery";
import EditOverTimeModal from "./components/OverTimeModal/EditOverTimeModal";
import NewOverTimeModal from "./components/OverTimeModal/NewOverTimeModal";
import RequestDetail from "./components/Shared_Component/RequestDetail";

function OverTime() {
  const [selectedMonthYears, setSelectedMonthYears] = React.useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1
  });

  const [selectedStatus, setSelectedStatus] = React.useState("All");
  const [dataRow, setDataRow] = React.useState();
  const [isNewRequest, setIsNewRequest] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [showDeletePopup, setShowDeletePopup] = React.useState({
    show: false
  });
  const [dataOverTimeDetail, setDataOverTimeDetail] = React.useState(null);

  const [dataListProject, setDataListProject] = React.useState([]);
  const [dataListCategory, setDataListCategory] = React.useState([]);
  const [dataListObject, setDataListObject] = React.useState([]);

  const { refreshQuery } = useRefreshQuery(["user-overtime", selectedMonthYears]);
  const {
    data: dataOverTime,
    isLoading,
    refetch
  } = useQuery({
    queryKey: ["user-overtime", selectedMonthYears],
    queryFn: async () => {
      const response = await UserOverTimeAPI.getRequestListView(selectedMonthYears);
      return response.data;
    }
  });

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const projectResponse = await ProjectAPI.GetOptionWorking();
        if (projectResponse.status === 200) {
          setDataListProject(projectResponse.data);
        }

        const categoryResponse = await CategoryAPI.GetOption();
        if (categoryResponse.status === 200) {
          setDataListCategory(categoryResponse.data);
        }

        const objectResponse = await ObjectAPI.GetOption();
        if (objectResponse.status === 200) {
          setDataListObject(objectResponse.data);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  const handleCreateNewOverTimeRequest = () => {
    setIsNewRequest(true);
    setIsModalOpen(true);
  };

  const handleEditOverTimeRequest = async (params) => {
    try {
      setDataOverTimeDetail(params.row);
      setIsModalOpen(true);
      setIsNewRequest(false);
    } catch (error) {
      console.log("Error getRequestListView ", error);
    }
  };

  const handleDeleteOverTimeRequest = (params) => {
    setShowDeletePopup({ show: true });
    setDataRow(params.row);
  };

  const handleCloseDeletePopup = () => {
    setShowDeletePopup({ show: false });
  };

  const handleRowClick = (params) => {
    setDataRow(params.row);
  };

  const handleDeleteOverTime = async () => {
    try {
      const response = await UserOverTimeAPI.delete(dataRow?.id);
      if (response?.status === 200) {
        refetch();
        ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      }
    } catch (error) {
      ToastShowError(error.message);
    }
  };

  const columns = [
    {
      field: "status",
      headerName: "Status",
      width: 130,
      align: "center",
      headerClassName: "header-click",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div>
            {params.row.status === "Approve" ? (
              <Chip
                style={{
                  width: "110px",
                  background: "#C6DCC6",
                  color: "#004D00",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="Approve"
              />
            ) : params.row.status === "Pending" ? (
              <Chip
                style={{
                  width: "110px",
                  background: "#EDE5C5",
                  color: "#1E1D19",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="Pending"
              />
            ) : params.row.status === "Reject" ? (
              <Chip
                style={{
                  width: "110px",
                  background: "#E3CDCD",
                  color: "#660000",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="Reject"
              />
            ) : (
              <Chip
                style={{
                  width: "110px",
                  background: "#C6DCC6",
                  color: "#004D00",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="Calculate"
              />
            )}
          </div>
        );
      }
    },
    {
      field: "date",
      headerName: "Date",
      width: 100,
      align: "center",
      headerClassName: "header-click",
      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return formatDateTime(value);
      }
    },
    {
      field: "hourStr",
      headerName: "Hours",
      width: 70,
      align: "center",
      headerClassName: "header-click",
      headerAlign: "center"
    },
    {
      field: "actualHourStr",
      headerName: "Actual Hours",
      width: 120,
      align: "center",
      headerClassName: "header-click",
      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== "00:00" ? value : "-";
      }
    },
    {
      field: "projectName",
      headerName: "Project",
      width: 700,
      align: "left",
      headerClassName: "header-click",
      headerAlign: "center"
    },
    {
      field: "action",
      headerName: "",
      align: "center",
      sortable: false,
      headerClassName: "header",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div>
            {params.row.status === "Pending" ? (
              <>
                <IconButton onClick={() => handleEditOverTimeRequest(params)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteOverTimeRequest(params)}>
                  <Delete />
                </IconButton>
              </>
            ) : params.row.status === "Reject" ? (
              <>
                <IconButton onClick={() => handleEditOverTimeRequest(params)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteOverTimeRequest(params)}>
                  <Delete />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton style={{ visibility: "hidden" }}>
                  <EditIcon />
                </IconButton>
                <IconButton style={{ visibility: "hidden" }}>
                  <Delete />
                </IconButton>
              </>
            )}
          </div>
        );
      }
    }
  ];

  const filteredOverTime =
    dataOverTime?.data.filter((overTime) => {
      const statusFilter =
        (selectedStatus === "All" && dataOverTime) ||
        (selectedStatus === "Pending" && overTime.status === selectedStatus) ||
        (selectedStatus === "Approve" && overTime.status === selectedStatus) ||
        (selectedStatus === "Calculate" && overTime.status === selectedStatus) ||
        (selectedStatus === "Reject" && overTime.status === selectedStatus);
      return statusFilter;
    }) || [];

  return (
    <div className="flex flex-col h-full w-full p-5 gap-2.5">
      {/* Infomation */}
      <div className="flex flex-row w-full justify-between font-semibold leading-none gap-2.5">
        <div className="flex flex-row w-full font-semibold leading-none gap-2.5">
          <div className="flex flex-col w-[180px] h-[70px] gap-2.5  bg-white justify-between rounded-[10px] px-[20px] py-[10px]">
            <p>WEEKDAY (hours)</p>
            <p className="text-base">{`${dataOverTime?.hourOfWeekday ?? "00:00"}`}</p>
          </div>

          <div className="flex flex-col w-[180px] h-[70px] gap-2.5   bg-white justify-between rounded-[10px] px-[20px] py-[10px]">
            <p>WEEKEND (hours)</p>
            <p className="text-base">{`${dataOverTime?.hourOfWeekend ?? "00:00"}`}</p>
          </div>

          <div className="flex flex-col w-[200px] h-[70px] gap-2.5  bg-white justify-between rounded-[10px] px-[20px] py-[10px]">
            <p>OVERNIGHT (hours)</p>
            <p className="text-base">{`${dataOverTime?.hourOfOvernight ?? "00:00"}`}</p>
          </div>
          <div className="flex flex-col w-[240px] h-[70px] gap-2.5  bg-white justify-between rounded-[10px] px-[20px] py-[10px]">
            <p>PUBLIC HOLIDAY (hours)</p>
            <p className="text-base">{`${dataOverTime?.hourOfHoliday ?? "00:00"}`}</p>
          </div>
        </div>

        <div className="flex flex-row w-full justify-end font-semibold leading-none gap-2.5">
          <div className="flex flex-col w-[220px] h-[70px] gap-2.5  bg-white justify-between rounded-[10px] px-[20px] py-[10px]">
            <p>TOTAL MONTH (hours)</p>
            <p className="text-base">{`${dataOverTime?.totalHourMonth ?? "00:00"}`}</p>
          </div>

          <div className="flex flex-col w-[220px] h-[70px] gap-2.5   bg-white justify-between rounded-[10px] px-[20px] py-[10px]">
            <p>TOTAL YEAR (hours)</p>
            <p className="text-base">{`${dataOverTime?.totalHourYear ?? "00:00"}`}</p>
          </div>
        </div>
      </div>

      <div className="flex flex-row max-h-[770px] gap-2.5 flex-grow">
        {/* Table */}
        <div className="flex flex-col overflow-hidden bg-white p-5 rounded-[10px]">
          <div className="flex items-center justify-between gap-[10px]">
            <div className="flex items-center gap-[10px]">
              <div className="flex items-center gap-[10px]">
                <span>Month</span>
                <MonthPickerInput
                  selectedMonthYears={selectedMonthYears}
                  setSelectedMonthYears={setSelectedMonthYears}
                />
              </div>
              <div className="flex items-center gap-[10px]">
                <div style={{ width: "max-content" }}>
                  <span>Status</span>
                </div>
                <DropdownListSelectionFilter
                  listSelection={listOvertimeStatus?.map((item) => ({
                    label: item.status,
                    value: item.status
                  }))}
                  selected={selectedStatus ?? listOvertimeStatus[0].value}
                  setSelected={setSelectedStatus}
                />
              </div>
            </div>

            <div className="flex items-center gap-[10px]">
              <Tooltip title="Add request">
                <IconButton variant="contained" onClick={handleCreateNewOverTimeRequest}>
                  <AddCircleIcon className="team-tab--right__icons" />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <Box
            className="flex-grow"
            sx={{
              height: "100%",
              width: "100%",
              "& .MuiDataGrid-columnHeaderTitle": {
                fontSize: "15px",
                fontWeight: 600
              }
            }}
          >
            <DataGrid
              rowHeight={40}
              sx={{
                border: "none",
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "600",
                  fontSize: "16px"
                },
                ".MuiDataGrid-footerContainer": { borderTop: "none" },
                minHeight: "100px",
                "--DataGrid-overlayHeight": "calc(100vh - 200px)"
              }}
              rows={filteredOverTime ?? []}
              columns={columns}
              getRowId={(row) => row?.id}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15
                  }
                }
              }}
              pageSizeOptions={[15]}
              slots={{
                loadingOverlay: LoadingOverLay,
                noRowsOverlay: CustomNoRowsOverlay
              }}
              onRowClick={handleRowClick}
              isLoading={isLoading}
              hideFooterSelectedRowCount
              disableColumnMenu={true}
              disableColumnFilter={true}
              disableColumnSelector={true}
              disableColumnSort={true}
              disableRowSelectionOnClick={true}
            />
          </Box>
        </div>

        {/* RequestDetail */}
        <div className="w-[360px] h-full overflow-y-auto bg-white rounded-[10px]">
          <RequestDetail dataRow={dataRow} />
        </div>
      </div>

      {/* Popup */}
      <DeletePopup
        open={showDeletePopup.show}
        onClose={handleCloseDeletePopup}
        onSuccess={() => {
          refreshQuery();
        }}
        handleDelete={handleDeleteOverTime}
        dataRow={dataRow}
      />
      {dataOverTimeDetail && (
        <EditOverTimeModal
          open={!!dataOverTimeDetail}
          onClose={() => {
            setDataOverTimeDetail(null);
          }}
          onSuccess={() => {
            refreshQuery();
          }}
          dataRow={dataRow}
          dataListProject={dataListProject}
          dataListCategory={dataListCategory}
          dataListObject={dataListObject}
          dataOverTimeDetail={dataOverTimeDetail}
        />
      )}
      {isNewRequest && (
        <NewOverTimeModal
          isNewRequest={isNewRequest}
          open={isModalOpen}
          onClose={setIsModalOpen}
          onSuccess={() => {
            refreshQuery();
          }}
        />
      )}
    </div>
  );
}

export default OverTime;
