import { Delete } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useMutation, useQueries } from "@tanstack/react-query";
import AdminObjectAPI from "api/AdminObjectAPI";
import AdminTeamAPI from "api/AdminTeamAPI";
import DeletePopup from "components/DeletePopUp";
import DropdownListSelectionFilter from "components/DropdownListSelectionFilter";
import LoadingOverLay from "components/LoadingOverlay";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import { showToastErrorCustom } from "utils/constants/ShowToastErrorCustom";
import useRefreshQuery from "utils/useRefreshQuery";
import ObjectModal from "./Modal/ObjectModal";

function Object() {
  const menuAccess = useSelector((state) => state.common.menuAccess);
  const adminMenu = menuAccess?.admin || [];
  const timesheetPermission = adminMenu?.timesheet;
  const tabObjectPermission = timesheetPermission?.tabObject;

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isNew, setIsNew] = React.useState(false);
  const [dataRow, setDataRow] = React.useState();
  const [showDeletePopup, setShowDeletePopup] = React.useState(false);
  const [selectedTeam, setSelectedTeam] = React.useState(0);

  const { refreshQuery: refreshQueryListViewObject } = useRefreshQuery(["listViewObjectQuery"]);

  const [listViewObjectQuery, listOptionTeamQuery] = useQueries({
    queries: [
      {
        queryKey: ["listViewObjectQuery"],
        queryFn: async () => {
          const response = await AdminObjectAPI.GetListView();
          const formatData = response?.data?.map((item, index) => ({
            ...item,
            stt: index + 1
          }));
          return formatData;
        }
      },
      {
        queryKey: ["listOptionTeamQuery"],
        queryFn: async () => {
          const response = await AdminTeamAPI.getOptionTeam();
          return response.data;
        }
      }
    ]
  });
  const { data: listViewObjectData } = listViewObjectQuery;
  const { data: listOptionTeamData } = listOptionTeamQuery;

  const mutationObject = useMutation({
    mutationFn: (value) => {
      return AdminObjectAPI.delete(value);
    },
    onError: (error) => {
      const errorData = error.response.data.errors;
      if (error.response.status === 400) {
        if (errorData) {
          showToastErrorCustom(errorData);
        } else ToastShowError(error.response.data);
      } else ToastShowError(TOAST_ERROR_STRING);
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      handleClose();
      refreshQueryListViewObject();
    }
  });

  const handleCreate = () => {
    setIsNew(true);
    setIsModalOpen(true);
    setDataRow({
      name: ""
    });
  };
  const handleRequestEdit = (params) => {
    setIsNew(false);
    setIsModalOpen(true);
    setDataRow(params);
  };
  const handleRequestDelete = (params) => {
    setShowDeletePopup(true);
    setDataRow(params);
  };

  const handleDeleteObject = () => {
    mutationObject.mutate(dataRow.id);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setDataRow(null);
  };

  const teams = useMemo(() => {
    if (listOptionTeamData && !listOptionTeamData.some((x) => x.name === "All")) {
      const newListOptionTeam = [...listOptionTeamData];
      newListOptionTeam.unshift({
        name: "All",
        id: 0
      });
      return newListOptionTeam;
    }
    return listOptionTeamData;
  }, [listOptionTeamData]);

  const filteredTeamObject = listViewObjectData?.filter((item) => {
    const filterTeam = (selectedTeam === 0 && listViewObjectData) || item.teamId === selectedTeam;
    return filterTeam;
  });

  const columns = [
    {
      field: "stt",
      headerName: "STT",
      width: 60,
      align: "center",
      editable: false,
      sortable: false,
      headerClassName: "header",
      headerAlign: "center"
    },
    {
      field: "name",
      headerName: "Object Name",
      width: 400,
      align: "left",

      headerClassName: "header-click",
      headerAlign: "center"
    },
    {
      field: "teamCode",
      headerName: "Team",
      width: 100,
      align: "center",
      headerClassName: "header-click",
      headerAlign: "center"
    },
    {
      field: "action",
      headerName: "",
      width: 100,
      align: "center",
      editable: false,
      sortable: false,
      headerClassName: "header-click",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            {tabObjectPermission?.isUpdate && (
              <IconButton onClick={() => handleRequestEdit(params.row)}>
                <EditIcon />
              </IconButton>
            )}
            {tabObjectPermission?.isDelete && (
              <IconButton onClick={() => handleRequestDelete(params.row)}>
                <Delete />
              </IconButton>
            )}
          </>
        );
      }
    }
  ];

  return (
    <>
      <div className="flex flex-col bg-white rounded-lg p-5 gap-1.5 h-full w-full">
        <div className="flex flex-col h-full justify-between items-start gap-1.5">
          <div className="flex w-full flex-row justify-between items-center">
            <div className="flex gap-3 items-center">
              <span>Team</span>
              <DropdownListSelectionFilter
                listSelection={teams?.map((team) => ({
                  label: team.name,
                  value: team.id
                }))}
                selected={teams ? selectedTeam : ""}
                setSelected={setSelectedTeam}
              />
            </div>

            {tabObjectPermission?.isCreate && (
              <Tooltip title="Add Category">
                <IconButton onClick={() => handleCreate()}>
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <Box
            className="flex-grow"
            sx={{
              height: "100%",
              width: "42%",
              "& .MuiDataGrid-columnHeaderTitle": {
                fontSize: "15px",
                fontWeight: 600
              }
            }}
          >
            <DataGrid
              rowHeight={40}
              sx={{
                border: "none",
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "600",
                  fontSize: "16px"
                },
                minHeight: "100px",
                ".MuiDataGrid-footerContainer": { borderTop: "none" },
                "--DataGrid-overlayHeight": "calc(100vh - 200px)"
              }}
              rows={filteredTeamObject ?? []}
              columns={columns}
              getRowId={(row) => row?.id}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15
                  }
                }
              }}
              pageSizeOptions={[15]}
              slots={{
                loadingOverlay: LoadingOverLay
              }}
              hideFooterSelectedRowCount
              disableColumnMenu={true}
              disableColumnFilter={true}
              disableColumnSelector={true}
              disableColumnSort={true}
              disableRowSelectionOnClick={true}
            />
          </Box>
        </div>
      </div>

      {isModalOpen && (
        <ObjectModal
          isNew={isNew}
          open={isModalOpen}
          onClose={handleClose}
          ObjectModalDetails={dataRow}
          refreshQueryListViewObject={refreshQueryListViewObject}
        />
      )}

      <DeletePopup
        open={showDeletePopup}
        onClose={() => setShowDeletePopup(false)}
        handleDelete={() => handleDeleteObject()}
      />
    </>
  );
}

export default Object;
