import axiosClient from "./api-config/axiosClient";

const UserOverTimeAPI = {
  async getRequestListView({ year, month }) {
    const url = `/Overtime/GetRequestListView/${year}/${month}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getDetail(params) {
    const url = `Overtime/getDetail/${params}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async create(params) {
    const url = "/Overtime/Create";
    return axiosClient.request({
      method: "POST",
      url: url,
      data: params
    });
  },

  async update(params) {
    const url = `/Overtime/Update`;
    return axiosClient.request({
      method: "PUT",
      url: url,

      data: params
    });
  },

  async delete(params) {
    const url = `/Overtime/Delete/${params}`;
    return axiosClient.request({
      method: "DELETE",
      url: url
    });
  }
};
export default UserOverTimeAPI;
