import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AdminLeaveFormAPI from "api/AdminLeaveFormAPI";
import AdminOverTimeAPI from "api/AdminOverTimeAPI";

// createAsyncThunk cái này sử dụng cho login và register
export const getOTandLeavePending = createAsyncThunk("OT/getOTandLeavePending", async () => {
  try {
    const teamId = JSON.parse(localStorage.getItem("user")).teamId;
    const response1 = await AdminOverTimeAPI.getListOverTimeAdminByTeamIdAndStatus(teamId, 1);
    const response2 = await AdminLeaveFormAPI.getListLeaveByTeamIdAndStatus(teamId, 1);
    return {
      OTPending: response1.data?.length || 0,
      LeavePending: response2.data?.length || 0
    };
  } catch (error) {
    console.log(error);
    return error.message;
  }
});

const countOTandLeavePending = createSlice({
  name: "OtLeavePending",
  initialState: {
    current: {
      OTPending: 0,
      LeavePending: 0
    },
    status: "idle",
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOTandLeavePending.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getOTandLeavePending.fulfilled, (state, action) => {
        if (
          state.current.OTPending !== action.payload.OTPending ||
          state.current.LeavePending !== action.payload.LeavePending
        ) {
          // const hasOT = state.current.OTPending < action.payload.OTPending;
          // const hasLeave = state.current.LeavePending < action.payload.LeavePending;

          // if (hasOT && hasLeave) {
          //   showNotification("You have new Overtime & Leave Form Request");
          // } else if (hasOT) {
          //   showNotification("You have new Overtime Request");
          // } else if (hasLeave) {
          //   showNotification("You have new Leave Form Request");
          // }
          // NotificationServices.setPending(action.payload);
          state.current = action.payload;
        }
        state.status = "succeeded";
      })
      .addCase(getOTandLeavePending.rejected, (state, action) => {
        state.status = action.error.message;
      });
  }
});

const { actions, reducer } = countOTandLeavePending;
export const { a = {} } = actions;
export default reducer;
