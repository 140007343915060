import axiosClient from "./api-config/axiosClient";

const WfhAPI = {
  async getRequestListView(year) {
    const url = `/Wfh/GetRequestListView/${year}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async createWfh(params) {
    const url = `/Wfh/Create`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: params
    });
  },

  async updateWfh(params) {
    const url = `/Wfh/Update`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: params
    });
  },

  async deleteWfh(id) {
    const url = `/Wfh/Delete/${id}`;
    return axiosClient.request({
      method: "DELETE",
      url: url
    });
  }
};

export default WfhAPI;
