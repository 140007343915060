import { Tooltip, styled } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";

function DateNoticeTask(props) {
  const { task } = props;
  const { color, contentFormat, start, end, title } = task;

  const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    () => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        border: "1px solid #dadde9"
      }
    })
  );

  const tooltipContent = <div dangerouslySetInnerHTML={{ __html: contentFormat }} />; // Render contentFormat as HTML

  return (
    <div className="flex w-full h-full pl-20 flex-row items-center justify-betweet font-semibold text-sm rounded-lg cursor-default gap-1">
      <div className="flex w-[15%]">
        {start} ~ {end}
      </div>
      <HtmlTooltip title={tooltipContent} placement="top-end" followCursor>
        <div className="flex flex-col rounded-lg p-2" style={{ backgroundColor: color }}>
          {title}
        </div>
      </HtmlTooltip>
    </div>
  );
}

export default DateNoticeTask;
