import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@mui/x-data-grid";
import { useMutation, useQuery } from "@tanstack/react-query";
import AdminTeamAPI from "api/AdminTeamAPI";
import DeletePopup from "components/DeletePopUp";
import LoadingOverLay from "components/LoadingOverlay";
import CustomNoRowsOverlay from "pages/admin-timesheet/finger-printer/CustomNoRowsOverlay";
import * as React from "react";
import { useSelector } from "react-redux";
import { formatDateTime } from "utils/DateTimeUtils";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import { showToastErrorCustom } from "utils/constants/ShowToastErrorCustom";
import useRefreshQuery from "utils/useRefreshQuery";
import TeamModal from "./component/TeamModal";

export default function Team() {
  const menuAccess = useSelector((state) => state.common.menuAccess);
  const adminMenu = menuAccess?.admin || [];
  const userPagePermission = adminMenu?.user;
  const tabTeamPermission = userPagePermission?.tabTeam;

  const [dataRow, setDataRow] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isNewTeam, setIsNewTeam] = React.useState(false);
  const [showDeletePopup, setShowDeletePopup] = React.useState(false);

  const { refreshQuery: refreshQueryObject } = useRefreshQuery(["listViewTeamQuery"]);

  const listViewTeamQuery = useQuery({
    queryKey: ["listViewTeamQuery"],
    queryFn: async () => {
      const response = await AdminTeamAPI.getListViewTeam();
      return response.data;
    }
  });

  const { data: listViewTeam, isLoading } = listViewTeamQuery;

  const mutation = useMutation({
    mutationFn: (id) => {
      return AdminTeamAPI.deleteTeam(id);
    },
    onError: (error) => {
      const errorData = error.response.data.errors;
      if (error.response.status === 400) {
        if (errorData) {
          showToastErrorCustom(errorData);
        } else ToastShowError(error.response.data);
      } else ToastShowError(TOAST_ERROR_STRING);
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      setIsModalOpen(false);
      refreshQueryObject();
    }
  });

  const handleCreateNewTeam = () => {
    setIsNewTeam(true);
    setIsModalOpen(true);
  };

  const handleEditTeam = (params) => {
    setIsModalOpen(true);
    setDataRow(params);
    setIsNewTeam(false);
  };

  const handleRequestDeleteTeam = (params) => {
    setShowDeletePopup(true);
    setDataRow(params);
  };

  const handleDeleteTeam = async () => {
    mutation.mutate(dataRow?.id);
  };

  const columns = [
    {
      field: "code",
      headerName: "Code",
      headerAlign: "center",
      width: 100,
      align: "center",
      headerClassName: "header-click"
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      width: 700,
      align: "left",
      headerClassName: "header-click"
    },
    {
      field: "leader",
      headerName: "Leader",
      headerAlign: "center",
      width: 300,
      align: "left",
      headerClassName: "header-click"
    },
    {
      field: "amount",
      headerName: "Member",
      headerAlign: "center",
      width: 165,
      align: "center",
      headerClassName: "header-click"
    },
    {
      field: "createdDate",
      headerName: "Create Date",
      headerAlign: "center",
      width: 150,
      align: "center",
      headerClassName: "header-click",

      renderCell: (params) => {
        const value = params.value;
        return formatDateTime(value);
      }
    },
    {
      field: "action",
      headerName: "",
      width: 100,
      align: "center",
      sortable: "false",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <div className="table__action-btn">
            {tabTeamPermission?.isUpdate && (
              <IconButton onClick={() => handleEditTeam(params.row)}>
                <EditIcon />
              </IconButton>
            )}

            {tabTeamPermission?.isDelete && (
              <IconButton onClick={() => handleRequestDeleteTeam(params.row)}>
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        );
      }
    }
  ];

  return (
    <div className="flex flex-col bg-white rounded-lg p-5 gap-1.5 h-full">
      <div className="flex justify-end items-center">
        {tabTeamPermission?.isCreate && (
          <Tooltip title="Add User">
            <IconButton variant="contained" onClick={handleCreateNewTeam}>
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <Box
        className="flex-grow"
        sx={{
          height: "100%",
          width: "100%",
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "15px",
            fontWeight: 600
          }
        }}
      >
        <DataGrid
          rowHeight={40}
          sx={{
            border: "none",
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
              fontSize: "16px"
            },
            minHeight: "100px",
            ".MuiDataGrid-footerContainer": { borderTop: "none" },
            "--DataGrid-overlayHeight": "calc(100vh - 200px)"
          }}
          rows={listViewTeam ?? []}
          columns={columns}
          getRowId={(row) => row?.id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 14
              }
            }
          }}
          pageSizeOptions={[14]}
          slots={{
            loadingOverlay: LoadingOverLay,
            noRowsOverlay: CustomNoRowsOverlay
          }}
          isLoading={isLoading}
          hideFooterSelectedRowCount
          disableColumnMenu={true}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableColumnSort={true}
          disableRowSelectionOnClick={true}
        />
      </Box>

      {isNewTeam && !dataRow && (
        <TeamModal
          isNewTeam={isNewTeam}
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          teamInfo={dataRow}
          refreshQueryObject={refreshQueryObject}
        />
      )}

      {!isNewTeam && dataRow && (
        <TeamModal
          isNewTeam={isNewTeam}
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setDataRow(null);
          }}
          teamInfo={dataRow}
          refreshQueryObject={refreshQueryObject}
        />
      )}

      <DeletePopup
        open={showDeletePopup}
        onClose={() => {
          setShowDeletePopup(false);
          setDataRow(null);
        }}
        handleDelete={handleDeleteTeam}
        title="Bạn có chắc chắn xóa ?"
      />
    </div>
  );
}
