import { FileExcelOutlined, FilePdfOutlined, FileWordOutlined } from "@ant-design/icons";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import AdminTimeSheetAPI from "api/AdminTimesheetAPI";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import UrlConstant from "utils/constants/UrlConstant";

function DocumentLine(props) {
  const { projectId, documentInfo, refetch } = props;
  const { name, createdDateText, comment, id, link } = documentInfo || {};

  const BASE_DOCUMENT_URL = `${UrlConstant.REACT_APP_DATABASE_URL}`;
  const linkDocument = `${BASE_DOCUMENT_URL}${link}`;

  const handleDeleteDocument = async () => {
    const resopnse = await AdminTimeSheetAPI.deleteDocument(projectId, id);
    if (resopnse?.status === 200) {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      refetch();
    } else {
      ToastShowError(TOAST_ERROR_STRING);
    }
  };

  // Function to check file type from name
  const renderIconFileType = (name) => {
    const fileExtension = name.split(".").pop().toLowerCase();
    if (fileExtension === "xlsx") {
      return <FileExcelOutlined />;
    } else if (fileExtension === "docx") {
      return <FileWordOutlined />;
    } else if (fileExtension === "pdf") {
      return <FilePdfOutlined />;
    } else {
      return null;
    }
  };

  const handleDownloadDocument = () => {
    fetch(linkDocument)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = name || "downloaded-file";
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-end items-center">
        <p className="w-[55%] truncate mr-10 flex items-center">
          {renderIconFileType(name)}
          {name || "File"}
        </p>
        <p className="w-[15%] flex items-center">{createdDateText || "2024/04/16"}</p>
        <div className="flex flex-row w-[30%] justify-end gap-3 items-center">
          <ModeCommentIcon sx={{ cursor: "pointer" }} />
          <DownloadIcon sx={{ cursor: "pointer" }} onClick={handleDownloadDocument} />
          <DeleteIcon sx={{ cursor: "pointer" }} onClick={handleDeleteDocument} />
        </div>
      </div>
      <div className="flex flex-col justify-end items-center">
        <p className="italic">{comment || ""}</p>
      </div>
    </div>
  );
}

export default DocumentLine;
