import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import { useQueries } from "@tanstack/react-query";
import CategoryAPI from "api/CategoryAPI";
import ObjectAPI from "api/ObjectAPI";
import UserOverTimeAPI from "api/OverTimeAPI";
import ProjectAPI from "api/ProjectAPI";
import CustomInputField from "components/CustomInputField";
import DatePickerFormInput from "components/DateTimePicker/DatePickerFormInput";
import DropdownListSelection from "components/DropdownListSelection";
import Editor from "components/TextEditor";
import { useForm } from "react-hook-form";
import { formatDateYYYYMMDD } from "utils/DateTimeUtils";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_CREATE_SUCCESS_STRING, TOAST_ERROR_STRING } from "utils/ToastString";
import * as yup from "yup";

function NewOverTimeModal(props) {
  const { disable, onClose, open, onSuccess } = props;

  const [projectsQuery, categoriesQuery, objectsQuery] = useQueries({
    queries: [
      {
        queryKey: ["projectsQuery"],
        queryFn: async () => {
          const response = await ProjectAPI.GetOptionWorking();
          return response.data;
        }
      },
      {
        queryKey: ["categoriesQuery"],
        queryFn: async () => {
          const response = await CategoryAPI.GetOption();
          return response.data;
        }
      },
      {
        queryKey: ["objectsQuery"],
        queryFn: async () => {
          const response = await ObjectAPI.GetOption();
          return response.data;
        }
      }
    ]
  });
  const { data: dataListProject } = projectsQuery;
  const { data: dataListCategory } = categoriesQuery;
  const { data: dataListObject } = objectsQuery;

  const timeRegExp = /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;

  const schema = yup.object().shape({
    date: yup.string().required("Yêu cầu nhập"),
    startTime: yup.string().required("Yêu cầu nhập").matches(timeRegExp, "thời gian không hợp lệ"),
    endTime: yup.string().required("Yêu cầu nhập").matches(timeRegExp, "thời gian không hợp lệ"),
    projectId: yup.string().required("Yêu cầu nhập"),
    categoryId: yup.string().required("Yêu cầu nhập"),
    objectId: yup.string().required("Yêu cầu nhập"),
    workDecription: yup.string().required("Yêu cầu nhập")
  });

  const form = useForm({
    defaultValues: {
      date: new Date(),
      startTime: "17:00",
      endTime: "18:00",
      projectId: dataListProject && dataListProject[0]?.id,
      categoryId: dataListCategory && dataListCategory[0]?.id,
      objectId: dataListObject && dataListObject[0]?.id,
      workDecription: ""
    },
    resolver: yupResolver(schema)
  });

  const handleClose = () => {
    onClose(false);
  };

  const handleErrorServer = (errorServerData) => {
    for (const field in errorServerData.errors) {
      const fieldName = field[0].toLowerCase() + field.slice(1);
      form.setError(fieldName, {
        type: "manual",
        message: errorServerData.errors[field][0]
      });
    }
  };

  const handleSubmit = async () => {
    var valueForm = form.getValues();

    let valueSubmit = {
      date: formatDateYYYYMMDD(valueForm?.date),
      start: valueForm?.startTime,
      end: valueForm?.endTime,
      projectId: valueForm?.projectId,
      categoryId: valueForm?.categoryId,
      objectId: valueForm?.objectId,
      reason: valueForm?.workDecription
    };

    try {
      const response = await UserOverTimeAPI.create(valueSubmit);
      if (response?.status === 200) {
        ToastShowSuccess(TOAST_CREATE_SUCCESS_STRING);
        onClose();
        onSuccess();
      }
    } catch (error) {
      const errorServerData = error.response.data;
      if (errorServerData.status === 400) {
        if (errorServerData) {
          handleErrorServer(errorServerData);
        } else {
          ToastShowError(errorServerData || TOAST_ERROR_STRING);
        }
      } else {
        ToastShowError(errorServerData || TOAST_ERROR_STRING);
      }
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="lg" PaperProps={{ sx: { borderRadius: "5px" } }}>
      <DialogContent style={{ padding: "20px" }}>
        <div className="w-[350px]">
          <div className="mb-2.5 text-center text-lg font-semibold">
            <Typography variant="h5" sx={{ fontWeight: "600", paddingTop: "10px" }}>
              New
            </Typography>
          </div>
          <div className="w-[170px]">
            <DatePickerFormInput
              name="date"
              label={
                <>
                  <span>Date</span>
                  <p style={{ color: "red", marginLeft: "5px" }}>*</p>
                </>
              }
              form={form}
              disable={disable}
            />
          </div>
          <div className="flex gap-[10px]">
            <CustomInputField
              name="startTime"
              label={
                <>
                  <span>Start Time</span>
                  <p style={{ color: "red", marginLeft: "5px" }}>*</p>
                </>
              }
              disable={disable}
              form={form}
            />
            <CustomInputField
              name="endTime"
              label={
                <>
                  <span>End Time</span>
                  <p style={{ color: "red", marginLeft: "5px" }}>*</p>
                </>
              }
              disable={disable}
              form={form}
            />
          </div>

          <DropdownListSelection
            name="projectId"
            label={
              <>
                <span>Project Name</span>
                <p style={{ color: "red", marginLeft: "5px" }}>*</p>
              </>
            }
            disable={disable}
            form={form}
            listSelection={dataListProject?.map((project) => {
              return {
                value: project.id,
                label: project.fullName
              };
            })}
          />

          <div className="flex flex-row gap-[10px]">
            <div className="w-full">
              <DropdownListSelection
                name="categoryId"
                label={
                  <>
                    <span>Category</span>
                    <p style={{ color: "red", marginLeft: "5px" }}>*</p>
                  </>
                }
                disable={disable}
                form={form}
                listSelection={dataListCategory?.map((category) => {
                  return {
                    value: category.id,
                    label: category.name
                  };
                })}
              />
            </div>
            <div className="w-full">
              <DropdownListSelection
                name="objectId"
                label={
                  <>
                    <span>Object</span>
                    <p style={{ color: "red", marginLeft: "5px" }}>*</p>
                  </>
                }
                disable={disable}
                form={form}
                listSelection={dataListObject?.map((object) => {
                  return {
                    value: object.id,
                    label: object.name
                  };
                })}
              />
            </div>
          </div>

          <div className="flex flex-row items-start ml-2">
            Work Description
            <p style={{ color: "red", marginLeft: "5px" }}>*</p>
          </div>
          <Editor name="workDecription" form={form} />

          <div className="flex flex-row w-full justify-end">
            <Button
              variant="contained"
              size="small"
              onClick={form.handleSubmit(handleSubmit)}
              sx={{
                textTransform: "uppercase",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "#BCCDEA"
                },
                background: "#BCCDEA",
                color: "#001B48",
                fontWeight: 600,
                borderRadius: "10px",
                width: "90px",
                padding: "5px 0"
              }}
            >
              OK
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleClose();
              }}
              size="small"
              sx={{
                textTransform: "capitalize",
                boxShadow: "none",
                "&:hover": { boxShadow: "none", background: "#F0F0F0" },
                background: "#F0F0F0",
                color: "#555555",
                fontWeight: 600,
                borderRadius: "10px",
                width: "90px",
                padding: "5px 0",
                marginLeft: "20px"
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default NewOverTimeModal;
