import TimeSheetItem from "../components/TimeSheetItem";

function DateTasks(props) {
  const { holidayInfo, listLeaveForm, listOvertimes, dayData } = props;

  const { tasks } = dayData || {};

  return (
    <div className="flex w-full h-[75%] max-h-[75%] overflow-x-auto flex-col gap-0.5">
      {holidayInfo?.isHoliday ? (
        <TimeSheetItem projectName={holidayInfo?.holidayInfo?.name || "Holiday"} hours="08:00" />
      ) : (
        <>
          {listLeaveForm?.length > 0
            ? listLeaveForm.map((d, index) => <TimeSheetItem key={index} projectName={d.type} hours={d.hours} />)
            : null}
          {tasks?.length > 0
            ? tasks?.map((d, index) => (
                <TimeSheetItem
                  key={index}
                  projectName={d.projectName}
                  hours={d.hours}
                  color={d?.type === "PaidLeave" ? "leaveTask" : "task"}
                />
              ))
            : null}

          {listOvertimes?.length > 0
            ? listOvertimes.map((d, index) => (
                <TimeSheetItem key={index} projectName={`OT :  ${d.projectName}`} hours={d.hours} color="ot-task" />
              ))
            : null}
        </>
      )}
    </div>
  );
}

export default DateTasks;
