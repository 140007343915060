import axiosClient from "./api-config/axiosClient";

const AdminUserAPI = {
  async getListViewUser() {
    const url = `/Users/GetListView`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getListOptionUser() {
    const url = `/Users/GetOption`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },
  async getProjectManagers() {
    const url = `/Users/GetProjectManager`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },
  async getNewProjectNumber() {
    const url = `/getNewProjectNumber`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },
  async getFiscalNumber() {
    const url = `/getFiscalNumber`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },
  async getDashboardUser() {
    const url = "/Users/getDashboardUser";
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getUserDetail(id) {
    const url = `/Users/GetDetail/${id}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async UpdateUser(data) {
    const url = "/Users/Update";
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: data
    });
  },

  async resetPassword(id) {
    const url = `/Users/ResetPassword/${id}`;
    return axiosClient.request({
      method: "PUT",
      url: url
    });
  },

  async uploadAvatar(data) {
    const url = "/Users/UploadAvatar";
    return axiosClient.request({
      method: "POST",
      url: url,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },

  async selfUpdate(data) {
    const url = "/Users/SelfUpdate";
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: data
    });
  },

  async getStatus(data) {
    const url = "/GetOptionStatus";
    return axiosClient.request({
      method: "GET",
      url: url,
      data: data
    });
  },

  async createUser(params) {
    const url = `/Users/Create`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: params
    });
  },

  async changeStatus(id) {
    const url = `/Users/ChangeStatus/${id}`;
    return axiosClient.request({
      method: "PUT",
      url: url
    });
  },

  //use for group member on group tab
  async getMemberOfGroup(id) {
    const url = `/Users/GetMemberOfGroup/${id}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getDynamicOption() {
    const url = `/Users/GetDynamicOption`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },
  // ====================/////=========================================

  // NEW API

  //getMenuAccess
  async getMenuAccess() {
    const url = `/Auth/GetMenuAccess`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  }
};

export default AdminUserAPI;
