import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import countOTandLeavePendingReducer from "./OTandLeaveCount";
import calendarReducer from "./calendarSlice";
import commonReducer from "./commonSlice";
import reportReducer from "./reportSlice";
import teamReducer from "./teamSlice";
import timesheetReducer from "./timesheetSlice";
import userReducer from "./userSlice";

const rootReducer = combineReducers({
  user: userReducer,
  timesheet: timesheetReducer,
  report: reportReducer,
  team: teamReducer,
  calendar: calendarReducer,
  common: commonReducer,
  OtLeavePending: countOTandLeavePendingReducer
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["common"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
export const persistor = persistStore(store);

export default store;
