import StorageKeys from "utils/constants/StorageKeys";

class TokenService {
  addToken(data) {
    localStorage.setItem(StorageKeys.access, data.accessToken);
    localStorage.setItem(StorageKeys.refresh, data.refreshToken);
    localStorage.setItem(StorageKeys.expiration, data.expiration);
  }
  getLocalRefreshToken() {
    return localStorage.getItem(StorageKeys.refresh);
  }
  getLocalAccessToken() {
    return localStorage.getItem(StorageKeys.access);
  }
  updateLocalAccessToken(token) {
    localStorage.setItem(StorageKeys.access, token);
  }
  getUser() {
    return JSON.parse(localStorage.getItem(StorageKeys.user));
  }
  setUser(user) {
    localStorage.setItem(StorageKeys.user, JSON.stringify(user));
  }
  removeUser() {
    localStorage.removeItem(StorageKeys.user);
    localStorage.removeItem(StorageKeys.access);
    localStorage.removeItem(StorageKeys.refresh);
    localStorage.removeItem(StorageKeys.expiration);
    localStorage.removeItem("RememberAccTTDWebsite");
    localStorage.removeItem(StorageKeys.objectPending);
  }

  getLocalStorageRemember() {
    return localStorage.getItem("RememberAccTTDWebsite");
  }
  setLocalStorageRemember(state) {
    return localStorage.setItem("RememberAccTTDWebsite", state);
  }
}
export default new TokenService();
