import axiosClient from "./api-config/axiosClient";
import axiosClientDownloadFile from "./api-config/axiosClientDownLoadFile";

const ExportAPI = {
  async getKeyGoogleSheet() {
    const url = `/Reports/GetGoogleTimesheetKeys`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getSummaryOfTimesheet(params) {
    const fromDate = params.dateFrom;
    const toDate = params.dateTo;
    const url = `/Reports/GetSummaryOfTimesheetByDateRange?fromDate=${fromDate}&toDate=${toDate}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async exportLeaveDaysManagament(year) {
    const url = `/Reports/GetReportLeaveDaysOfStaff/${year}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async summaryAsPersonByMonth(userId, year) {
    const url = `/Reports/SummaryAsPersonByMonth/${userId}/${year}`;
    return axiosClientDownloadFile.request({
      method: "GET",
      url: url,
      responseType: "blob"
    });
  },

  async getReportWorkingHoursSummary(params) {
    const url = `/Reports/GetReportWorkingHoursSummaryToExcel/?fromDate=${params?.dateFrom}&toDate=${params?.dateTo}`;
    return axiosClientDownloadFile.request({
      method: "GET",
      url: url,
      responseType: "blob"
    });
  },

  // =============================NEW API===============================

  async getReportSummary(params) {
    const { year, month, type } = params;

    const url = `/Timesheet/ExportReport/${year}/${month}/${type}`;

    return axiosClientDownloadFile.request({
      method: "GET",
      url: url,
      responseType: "blob"
    });
  },

  async getReportByTeam(params) {
    const { year, month, type } = params;

    const url = `/Timesheet/ExportReportByTeam/${year}/${month}/${type}`;

    return axiosClientDownloadFile.request({
      method: "GET",
      url: url,
      responseType: "blob"
    });
  },

  async getProjectAnalysis({ id }) {
    const url = `/Project/ExportAnalysis/${id}`;

    return axiosClientDownloadFile.request({
      method: "GET",
      url: url,
      responseType: "blob"
    });
  }
};
export default ExportAPI;
