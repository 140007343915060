import { Button, TextField } from "@mui/material";
import UserAPI from "api/UserAPI";
import { Formik } from "formik";
import InputPasswordComponent from "pages/authentication/components/InputPasswordComponent";

import { useSelector } from "react-redux";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import * as Yup from "yup";

const loginSchema = Yup.object().shape({
  newPassword: Yup.string().required("Password không được để trống"),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Confirm password và password phải giống nhau")
    .required("Confirm password không được để trống")
});

export default function SercuritySetting() {
  const initialValues = { confirmNewPassword: "", newPassword: "" };
  const user = useSelector((state) => state.user.current);
  const { email } = user;

  const handleUpdatePassword = async (values) => {
    try {
      const response = await UserAPI.changePassword({ newPassword: values?.newPassword });
      if (response?.status === 200) {
        ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      }
    } catch (error) {
      ToastShowError(TOAST_ERROR_STRING);
    }
  };

  return (
    <div className="w-[22%] p-5 rounded-lg bg-white flex flex-col">
      <Formik initialValues={initialValues} validationSchema={loginSchema} onSubmit={handleUpdatePassword}>
        {({ values, handleChange, errors, handleSubmit, setErrors }) => (
          <div className="space-y-[10px] flex flex-col">
            <div className="flex flex-row items-start ml-2">Email</div>
            <TextField
              value={email}
              size="small"
              variant="outlined"
              disabled
              sx={{
                borderRadius: "8px",
                backgroundColor: "#dcdcdc",
                ".MuiOutlinedInput-notchedOutline": {
                  border: "none",
                  outline: "flexnone"
                }
              }}
            />

            <div className="flex flex-col gap-[2px] items-start justify-start">
              <div className="flex flex-row items-start ml-2">
                New password
                <p style={{ color: "red", marginLeft: "5px" }}>*</p>
              </div>
              <InputPasswordComponent
                name="newPassword"
                id="newPassword"
                className="w-full bg-[#DCDCDC]  rounded-lg block p-2.5 text-sm px-2 py-[10px] outline-none border-none"
                value={values.newPassword}
                onChange={handleChange}
              ></InputPasswordComponent>

              {errors.newPassword && (
                <span className="text-sm text-red-400 line-clamp-1 pl-[10px]">{errors.newPassword}</span>
              )}
            </div>

            <div className="flex flex-col gap-[2px] items-start justify-start">
              <div className="flex flex-row items-start ml-2">
                Confirm new password
                <p style={{ color: "red", marginLeft: "5px" }}>*</p>
              </div>
              <InputPasswordComponent
                name="confirmNewPassword"
                id="confirmNewPassword"
                className="w-full bg-[#DCDCDC]  rounded-lg block p-2.5 text-sm px-2 py-[10px] outline-none border-none"
                value={values.confirmPassword}
                onChange={handleChange}
              ></InputPasswordComponent>

              {errors.confirmNewPassword && (
                <span className="text-sm text-red-400 line-clamp-1 pl-[10px]">{errors.confirmNewPassword}</span>
              )}
            </div>

            <div className="w-full flex flex-row justify-end items-end">
              <Button
                variant="contained"
                size="small"
                onClick={() => handleSubmit(values, setErrors)}
                sx={{
                  textTransform: "capitalize",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "#BCCDEA"
                  },
                  background: "#BCCDEA",
                  color: "#001B48",
                  fontWeight: 600,
                  borderRadius: "10px",
                  width: "90px",
                  padding: "5px 0"
                }}
              >
                Update
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}
