import axiosClient from "./api-config/axiosClient";

const TeamAPI = {
  async getTeams() {
    const url = `/Team/GetOption`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  }
};

export default TeamAPI;
