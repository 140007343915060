export const setFormErrors = (errors, setErrors) => {
  Object.keys(errors).forEach(function (key) {
    const propertyKey = capitalizeFirstLetter(key);
    setErrors({ [propertyKey]: errors[key][0] });
  });
};
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export const setReactHookFormErrors = (errors, setError) => {
  Object.keys(errors).forEach(function (key) {
    const propertyKey = capitalizeFirstLetter(key);
    setError(propertyKey, {
      type: "manual",
      message: errors[key][0]
    });
  });
};

//Function to concatenate error values into a string
//Function này sẽ concat tất cả các error tạo thành 1 string duy nhất để hiển thị lên Toast
export function showAllValueErrors(obj) {
  let values = [];
  let result = "";

  // Collect all values
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (Array.isArray(obj[key])) {
        values.push(...obj[key]);
      }
    }
  }

  // Build the result string based on the number of values
  if (values.length === 1) {
    result = values[0];
  } else {
    values.forEach((item, index) => {
      result += `${index + 1}. ${item}\n`;
    });
  }

  return result.trim(); // Remove the trailing newline character
}
