import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined";
import { Box, Chip, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@mui/x-data-grid";
import { useMutation, useQueries } from "@tanstack/react-query";
import AdminTeamAPI from "api/AdminTeamAPI";
import AdminUserAPI from "api/AdminUserAPI";
import OtherAPI from "api/OtherAPI";
import RoleAPI from "api/RolesAPI";
import DeletePopup from "components/DeletePopUp";
import DropdownListSelectionFilter from "components/DropdownListSelectionFilter";
import LoadingOverLay from "components/LoadingOverlay";
import CustomNoRowsOverlay from "pages/admin-timesheet/finger-printer/CustomNoRowsOverlay";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { formatDateTime } from "utils/DateTimeUtils";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import { positions } from "utils/constants/positions";
import useRefreshQuery from "utils/useRefreshQuery";
import EditUser from "./component/UserModal/EditUser";
import NewUser from "./component/UserModal/NewUser";

export default function User() {
  const menuAccess = useSelector((state) => state.common.menuAccess);
  const adminMenu = menuAccess?.admin || [];
  const userPagePermission = adminMenu?.user;
  const tabUserPermission = userPagePermission?.tabUser;

  const [selectedTeam, setSelectedTeam] = React.useState(0);
  const [selectedPosition, setSelectedPosition] = React.useState("All");
  const [selectedStatus, setSelectedStatus] = React.useState("All");
  const [dataRow, setDataRow] = React.useState();
  const [disableEdit, setDisableEdit] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [showDeletePopup, setShowDeletePopup] = React.useState({
    show: false
  });

  const [messageChangeStatusUser, setMessageChangeStatusUser] = React.useState("");

  const { refreshQuery: refreshQueryObject } = useRefreshQuery(["listUserQuery"]);
  const { refreshQuery: refreshQueryUserDetail } = useRefreshQuery(["userDetailQuery"]);

  const [teamQuery, statusQuery, listUserQuery, roleQuery, userDetailQuery] = useQueries({
    queries: [
      {
        queryKey: ["teamQuery"],
        queryFn: async () => {
          const response = await AdminTeamAPI.getOptionTeam();
          return response?.data;
        }
      },
      {
        queryKey: ["statusQuery"],
        queryFn: async () => {
          const response = await OtherAPI.getOptionStatus();
          return response?.data;
        }
      },
      {
        queryKey: ["listUserQuery"],
        queryFn: async () => {
          const response = await AdminUserAPI.getListViewUser();
          return response?.data;
        }
      },
      {
        queryKey: ["roleQuery"],
        queryFn: async () => {
          const response = await RoleAPI.getOption();
          return response?.data;
        }
      },
      {
        queryKey: ["userDetailQuery", dataRow?.id],
        queryFn: async () => {
          const response = await AdminUserAPI.getUserDetail(dataRow?.id);
          return response?.data;
        },
        enabled: dataRow?.id !== undefined
      }
    ]
  });
  const { data: listTeam } = teamQuery;
  const { data: listStatus } = statusQuery;
  const { data: listViewUser, isLoading } = listUserQuery;
  const { data: listRole } = roleQuery;
  const { data: userDetail, isSuccess } = userDetailQuery;

  useEffect(() => {
    if (!isLoading) {
      setDataRow(userDetail);
    }
  }, [isLoading]);

  const teams = useMemo(() => {
    if (listTeam && !listTeam.some((x) => x.name === "All"))
      listTeam.unshift({
        name: "All",
        id: 0
      });
    return listTeam;
  }, [listTeam]);

  const status = useMemo(() => {
    if (listStatus && !listStatus.includes("All")) listStatus.unshift("All");
    return listStatus;
  }, [listStatus]);

  const listPositions = useMemo(() => {
    const newPositions = [...positions];
    if (newPositions && !newPositions.includes("All")) newPositions.unshift("All");
    return newPositions;
  }, []);

  const mutation = useMutation({
    mutationFn: (id) => {
      return AdminUserAPI.changeStatus(id);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        const errorData = error.response.data.errors;
        ToastShowError(errorData);
      } else ToastShowError(TOAST_ERROR_STRING);
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      handleClose();
      refreshQueryObject();
    }
  });

  const handleCreateNewUser = () => {
    setDisableEdit(false);
    setIsModalOpen(true);
  };

  const handleEditUser = async (params) => {
    setDisableEdit(false);
    setIsModalOpen(true);
    setDataRow(params);
  };

  const handleViewUserInfo = async (params) => {
    setDisableEdit(true);
    setIsModalOpen(true);
    setDataRow(params);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setDataRow(null);
  };

  const handleRequestChangeStatusUser = (params) => {
    setShowDeletePopup({ show: true });
    setDataRow(params);
    const titleStatusUser =
      params?.status === "Active"
        ? "Bạn có muốn dừng hoạt động của user này?"
        : "Bạn có muốn cho phép user này hoạt động trở lại?";
    setMessageChangeStatusUser(titleStatusUser);
  };

  const handleChangeStatusUser = async () => {
    mutation.mutate(dataRow?.id);
  };

  const columns = [
    {
      field: "staffId",
      headerName: "Staff ID",
      headerAlign: "center",
      width: 100,
      align: "center",
      headerClassName: "header-click"
    },
    {
      field: "fullName",
      headerName: "Full Name",
      headerAlign: "center",
      maxWidth: 410,
      flex: 1,
      align: "left",
      headerClassName: "header-click"
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      width: 230,
      align: "left",
      headerClassName: "header-click"
    },
    {
      field: "teamName",
      headerName: "Team",
      headerAlign: "center",
      width: 100,
      align: "center",
      headerClassName: "header-click"
    },
    {
      field: "position",
      headerName: "Position",
      headerAlign: "center",
      width: 150,
      align: "left",
      headerClassName: "header-click"
    },
    {
      field: "dateStartWork",
      headerName: "Start Work Date",
      headerAlign: "center",
      width: 150,
      align: "center",
      headerClassName: "header-click",

      renderCell: (params) => {
        const value = params.value;
        return formatDateTime(value);
      }
    },
    {
      field: "modifierName",
      headerName: "Modified By",
      headerAlign: "center",
      width: 100,
      align: "center",
      headerClassName: "header-click"
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      headerAlign: "center",
      width: 150,
      align: "center",
      headerClassName: "header-click",

      renderCell: (params) => {
        const value = params.value;
        return formatDateTime(value);
      }
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      width: 100,
      align: "center",
      headerClassName: "header-click",

      renderCell: (params) => {
        return (
          <div>
            {params.row.status === "Active" ? (
              <Chip
                style={{
                  width: "75px",
                  background: "#C6DCC6",
                  color: "#004D00",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="Active"
              />
            ) : (
              <Chip
                style={{
                  width: "75px",
                  background: "#E3CDCD",
                  color: "#660000",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="Inactive"
              />
            )}
          </div>
        );
      }
    },
    {
      field: "action",
      headerName: "",
      width: 100,
      align: "left",
      sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <div className="flex items-start justify-start">
            {params.row.status === "Inactive" ? (
              <>
                {tabUserPermission?.isInactive && (
                  <IconButton onClick={() => handleRequestChangeStatusUser(params.row)} className="btn-active">
                    <RotateLeftOutlinedIcon />
                  </IconButton>
                )}
              </>
            ) : (
              <>
                {tabUserPermission?.isUpdate ? (
                  <IconButton onClick={() => handleEditUser(params.row)}>
                    <EditIcon />
                  </IconButton>
                ) : tabUserPermission?.isView ? (
                  <IconButton onClick={() => handleViewUserInfo(params.row)}>
                    <DescriptionIcon />
                  </IconButton>
                ) : null}

                {tabUserPermission?.isInactive && (
                  <IconButton onClick={() => handleRequestChangeStatusUser(params.row)}>
                    <CloseIcon />
                  </IconButton>
                )}
              </>
            )}
          </div>
        );
      }
    }
  ];

  let filteredUsers = listViewUser?.filter((user) => {
    const teamFilter = (selectedTeam === 0 && listViewUser) || user.teamId === selectedTeam;
    const positionFilter = (selectedPosition === "All" && listViewUser) || user.position === selectedPosition;
    const statusFilter =
      (selectedStatus === "All" && listViewUser) ||
      (selectedStatus === "Active" && user.status === selectedStatus) ||
      (selectedStatus === "Inactive" && user.status === selectedStatus);

    return teamFilter && positionFilter && statusFilter;
  });

  const listSortedUser = filteredUsers?.sort((a, b) => {
    if (a.status === "Active" && b.status === "Inactive") {
      return -1; // a should come before b
    } else if (a.status === "Inactive" && b.status === "Active") {
      return 1; // a should come after b
    } else {
      return 0; // no change in order
    }
  });

  return (
    <div className="flex flex-col bg-white rounded-lg p-5 gap-1.5 h-full">
      <div className="flex justify-between items-center mx-2 my-1">
        <div className="flex items-center gap-3">
          <span>Team</span>
          <DropdownListSelectionFilter
            listSelection={teams?.map((team) => ({
              label: team.name,
              value: team.id
            }))}
            selected={teams ? selectedTeam : ""}
            setSelected={setSelectedTeam}
          />
          <span>Position</span>
          <DropdownListSelectionFilter
            listSelection={listPositions?.map((position) => ({
              label: position,
              value: position
            }))}
            selected={listPositions ? selectedPosition : ""}
            setSelected={setSelectedPosition}
          />
          <span>Status</span>
          <DropdownListSelectionFilter
            listSelection={status?.map((status) => ({
              label: status,
              value: status
            }))}
            selected={status ? selectedStatus : ""}
            setSelected={setSelectedStatus}
          />
        </div>
        {tabUserPermission?.isCreate && (
          <Tooltip title="Add User">
            <IconButton variant="contained" onClick={handleCreateNewUser}>
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>

      <Box
        className="flex-grow"
        sx={{
          height: "100%",
          width: "100%",
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "15px",
            fontWeight: 600
          }
        }}
      >
        <DataGrid
          rowHeight={40}
          sx={{
            border: "none",
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
              fontSize: "16px"
            },
            minHeight: "100px",
            ".MuiDataGrid-footerContainer": { borderTop: "none" },
            "--DataGrid-overlayHeight": "calc(100vh - 200px)"
          }}
          rows={listSortedUser ?? []}
          columns={columns}
          getRowId={(row) => row?.id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 14
              }
            }
          }}
          pageSizeOptions={[14]}
          slots={{
            loadingOverlay: LoadingOverLay,
            noRowsOverlay: CustomNoRowsOverlay
          }}
          isLoading={isLoading}
          hideFooterSelectedRowCount
          disableColumnMenu={true}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableColumnSort={true}
          disableRowSelectionOnClick={true}
        />
      </Box>

      {!dataRow && isModalOpen && (
        <NewUser
          listTeam={listTeam}
          open={isModalOpen}
          onClose={handleClose}
          isNew={!dataRow}
          listRole={listRole}
          selectedTeamId={selectedTeam}
          userDetail={userDetail}
          disable={disableEdit}
          refreshQueryObject={refreshQueryObject}
        />
      )}
      {dataRow && userDetail && isModalOpen && (
        <EditUser
          listTeam={listTeam}
          open={isModalOpen && isSuccess}
          userInfo={dataRow}
          onClose={handleClose}
          isNew={!!dataRow}
          listRole={listRole}
          selectedTeamId={selectedTeam}
          userDetail={userDetail}
          disable={disableEdit}
          refreshQueryObject={refreshQueryObject}
          refreshQueryUserDetail={refreshQueryUserDetail}
        />
      )}
      <DeletePopup
        open={showDeletePopup.show}
        onClose={() => setShowDeletePopup(false)}
        handleDelete={handleChangeStatusUser}
        title={messageChangeStatusUser}
      />
    </div>
  );
}
