import AddCircleIcon from "@mui/icons-material/AddCircle";
import CircleIcon from "@mui/icons-material/Circle";
import DevicesIcon from "@mui/icons-material/Devices";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import { IconButton, MenuItem, Select, Tooltip } from "@mui/material";
import CalendarAPI from "api/CalendarAPI";
import CalendarDatePicker from "components/DateTimePicker/CalendarDatePicker";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCalendarStore } from "store/bearStore";
import { getListCalendars, getListObjects } from "store/calendarSlice";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_CREATE_SUCCESS_STRING, TOAST_ERROR_STRING } from "utils/ToastString";
import CalendarTable from "./components/CalendarTable";
import NoteForm from "./components/NoteForm";

Calendar.propTypes = {};

function Calendar() {
  const dispatch = useDispatch();
  const userIdSelected = useSelector((state) => state.timesheet.userIdSelected);
  const daySelected = useSelector((state) => state.timesheet.daySelected);
  const listObjects = useSelector((state) => state.calendar.objects);
  const typeCalendar = useCalendarStore((state) => state.type);
  const changeType = useCalendarStore((state) => state.changeType);

  const [month, setMonth] = useState(daySelected || dayjs(new Date()));

  const handleChangeMonth = (monthSelected) => {
    setMonth(monthSelected);
    let timeParams = {
      userId: userIdSelected,
      type: typeCalendar,
      month: dayjs(monthSelected).month() + 1,
      year: dayjs(monthSelected).year()
    };
    fetchDataCalendar(timeParams);
  };

  const fetchDataCalendar = async (timeParams) => {
    const action = getListCalendars(timeParams);
    await dispatch(action);
  };

  const fetchObjects = async (timeParams) => {
    const action = getListObjects(timeParams);
    await dispatch(action);
  };

  let timeParams = {
    userId: userIdSelected,
    type: typeCalendar,
    month: dayjs(month).month() + 1,
    year: dayjs(month).year()
  };
  useEffect(() => {
    fetchDataCalendar(timeParams);
    fetchObjects();
  }, []);

  //open modal form calendar
  const [openNoteForm, setOpenNoteForm] = useState(false);

  const toggleNoteFormHandle = () => {
    setOpenNoteForm(!openNoteForm);
  };

  const handleCreateNote = async (noteData) => {
    try {
      const response = await CalendarAPI.createCalendar(noteData);
      if (response?.status === 200) {
        ToastShowSuccess(TOAST_CREATE_SUCCESS_STRING);
        fetchDataCalendar(timeParams);
        toggleNoteFormHandle();
      }
    } catch (error) {
      ToastShowError(TOAST_ERROR_STRING);
    }
  };

  const noteNew = {
    title: "",
    date: new Date(),
    start: "08:00",
    end: "17:00",
    object: {},
    objectId: 0,
    repeatType: "",
    content: "",
    applyFor: []
  };

  const handleChangeType = (e) => {
    const value = e.target.value;
    changeType(value);
    let timeParams = {
      userId: userIdSelected,
      type: value,
      month: dayjs(month).month() + 1,
      year: dayjs(month).year()
    };

    fetchDataCalendar(timeParams);
  };

  let noteFormPopup = null;
  if (openNoteForm) {
    noteFormPopup = (
      <NoteForm
        open={openNoteForm}
        noteInfo={noteNew}
        isUpdate={false}
        onCreateNote={handleCreateNote}
        onClose={toggleNoteFormHandle}
      />
    );
  }
  return (
    <div className="flex flex-col gap-1">
      <div className="flex h-[10%] w-full items-center justify-between p-3">
        <div className="flex w-[20%] items-center justify-start">
          <Select
            value={listObjects.length === 0 ? "" : typeCalendar || 1}
            defaultValue={""}
            onChange={(value) => {
              handleChangeType(value);
            }}
            sx={{
              width: "50%",
              backgroundColor: "#dcdcdc",
              borderRadius: "8px",
              ".MuiOutlinedInput-notchedOutline": {
                border: "none",
                outline: "none"
              }
            }}
          >
            {listObjects?.map((object, index) => {
              return (
                <MenuItem value={object?.id} key={index}>
                  {(() => {
                    switch (object?.type) {
                      case "Notice":
                        return (
                          <PersonIcon
                            sx={{
                              width: 20,
                              height: 20,
                              marginRight: "6px"
                            }}
                          />
                        );
                      case "MeetingRoom":
                        return (
                          <GroupsIcon
                            sx={{
                              width: 20,
                              height: 20,
                              marginRight: "6px"
                            }}
                          />
                        );
                      case "HiEndPc":
                        return (
                          <DevicesIcon
                            sx={{
                              width: 20,
                              height: 20,
                              marginRight: "6px"
                            }}
                          />
                        );
                      default:
                        return (
                          <CircleIcon
                            sx={{
                              width: 20,
                              height: 20,
                              marginRight: "6px"
                            }}
                          />
                        );
                    }
                  })()}
                  {object?.name}
                </MenuItem>
              );
            })}
          </Select>
        </div>

        <div className="flex w-[60%] items-center justify-center">
          <CalendarDatePicker month={month} onChangeMonth={handleChangeMonth} optionsView={["year", "month"]} />
        </div>

        <div className="flex w-[20%] items-center justify-end">
          <Tooltip title="Add new notice" placement="bottom">
            <IconButton aria-label="previous" onClick={toggleNoteFormHandle}>
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <div className="flex w-full h-full">
        <CalendarTable daySelected={month} />
      </div>

      {noteFormPopup}
    </div>
  );
}
export default Calendar;
