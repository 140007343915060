import axiosClient from "./api-config/axiosClient";

const ResetPasswordAPI = {
  async SendEmailRequest(email) {
    const url = `/RequestResetPassword`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: email
    });
  }
};

export default ResetPasswordAPI;
