import { urls } from "config/url";
import AdminLeaveForm from "pages/admin-leaveform";
import AdminOverTime from "pages/admin-overtime";
import AdminTimeSheet from "pages/admin-timesheet";
import AdminUser from "pages/admin-user";
import AdminWorkFromHome from "pages/admin-work-from-home";

const adminRoutes = [
  {
    path: urls.web.admin.user,
    element: <AdminUser />
  },
  {
    path: urls.web.admin.timeSheet,
    element: <AdminTimeSheet />
  },
  {
    path: urls.web.admin.overtime,
    element: <AdminOverTime />
  },
  {
    path: urls.web.admin.leaveForm,
    element: <AdminLeaveForm />
  },
  {
    path: urls.web.admin.wfh,
    element: <AdminWorkFromHome />
  }
];

export default adminRoutes;
