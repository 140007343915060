import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Button, Dialog, DialogContent } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import AdminObjectAPI from "api/AdminObjectAPI";
import CustomInputField from "components/CustomInputField";
import { useForm } from "react-hook-form";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import { showToastErrorCustom } from "utils/constants/ShowToastErrorCustom";

const ObjectModal = (props) => {
  const { isNew, open, onClose, ObjectModalDetails, refreshQueryListViewObject } = props;

  const schema = yup.object().shape({
    name: yup.string().required("Yêu cầu nhập")
  });

  const form = useForm({
    defaultValues: {
      id: ObjectModalDetails.id,
      name: ObjectModalDetails.name
    },
    resolver: yupResolver(schema)
  });

  const mutationUpdateObject = useMutation({
    mutationFn: (value) => {
      return AdminObjectAPI.update(value);
    },
    onError: (error) => {
      const errorData = error.response.data.errors;
      if (error.response.status === 400) {
        if (errorData) {
          showToastErrorCustom(errorData);
        } else ToastShowError(error.response.data);
      } else ToastShowError(TOAST_ERROR_STRING);
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      onClose();
      refreshQueryListViewObject();
    }
  });

  const mutationNewObject = useMutation({
    mutationFn: (value) => {
      return AdminObjectAPI.create(value);
    },
    onError: (error) => {
      const errorData = error.response.data.errors;
      if (error.response.status === 400) {
        if (errorData) {
          showToastErrorCustom(errorData);
        } else ToastShowError(error.response.data);
      } else ToastShowError(TOAST_ERROR_STRING);
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      onClose();
      refreshQueryListViewObject();
    }
  });

  const handleSubmitFunc = async () => {
    let valueForm = form.getValues();
    const valueSubmit = {
      ...valueForm,
      id: ObjectModalDetails?.id || null
    };
    if (isNew) {
      mutationNewObject.mutate(valueSubmit);
    } else mutationUpdateObject.mutate(valueSubmit);
  };

  return (
    <Dialog onClose={onClose} open={open} PaperProps={{ sx: { borderRadius: "10px" } }}>
      {isNew ? (
        <DialogContent sx={{ padding: "20px", minWidth: "240px", minHeight: "194px" }}>
          <div>
            <h1 className="text-center text-xl pb-2 font-bold">New</h1>
            <div>
              <CustomInputField
                name="name"
                label={
                  <div>
                    <span>New</span>
                    <span className="text-red-500 text-base ml-1">*</span>
                  </div>
                }
                form={form}
              />
            </div>
            <div className="flex justify-end gap-5 pt-4">
              <Button
                variant="contained"
                size="small"
                onClick={form.handleSubmit(handleSubmitFunc)}
                sx={{
                  textTransform: "uppercase",
                  boxShadow: "none",
                  "&:hover": { boxShadow: "none", background: "#bccdea" },
                  background: "#bccdea",
                  color: "#001b48",
                  fontWeight: "600",
                  borderRadius: "10px",
                  width: "90px",
                  padding: "5px 0"
                }}
              >
                ok
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  onClose();
                }}
                size="small"
                sx={{
                  textTransform: "capitalize",
                  boxShadow: "none",
                  "&:hover": { boxShadow: "none", background: "#f0f0f0" },
                  background: "#f0f0f0",
                  color: "#555555",
                  fontWeight: "600",
                  borderRadius: "10px",
                  width: "90px",
                  padding: "5px 0"
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </DialogContent>
      ) : (
        <DialogContent sx={{ padding: "20px", minWidth: "240px", minHeight: "194px" }}>
          <div>
            <h1 className="text-center text-xl pb-2 font-bold">Update</h1>
            <div>
              <CustomInputField
                name="name"
                label={
                  <div>
                    <span className="ml-1">Name</span>
                    <span className="text-red-500 text-base ml-1">*</span>
                  </div>
                }
                form={form}
              />
            </div>
            <div className="flex justify-end gap-5 pt-4">
              <Button
                variant="contained"
                size="small"
                onClick={form.handleSubmit(handleSubmitFunc)}
                sx={{
                  textTransform: "capitalize",
                  boxShadow: "none",
                  "&:hover": { boxShadow: "none", background: "#bccdea" },
                  background: "#bccdea",
                  color: "#001b48",
                  fontWeight: "600",
                  borderRadius: "10px",
                  width: "90px",
                  padding: "5px 0"
                }}
              >
                update
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  onClose();
                }}
                size="small"
                sx={{
                  textTransform: "capitalize",
                  boxShadow: "none",
                  "&:hover": { boxShadow: "none", background: "#f0f0f0" },
                  background: "#f0f0f0",
                  color: "#555555",
                  fontWeight: "600",
                  borderRadius: "10px",
                  width: "90px",
                  padding: "5px 0"
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ObjectModal;
