import { format } from "date-fns";
import dayjs from "dayjs";

function DateInfo(props) {
  const { timeIn, timeOut, showTimeInOut, date } = props;

  var iscurrentDate = date && dayjs(date).isSame(new Date(), "day");

  return (
    <div className="flex w-full h-[15%] justify-between font-semibold text-sm">
      <div className="flex w-[75%] items-center justify-start ">
        {showTimeInOut && (
          <>
            <div className={`w-[20%] ${timeIn !== "--:--" ? "text-[#31692b]" : "text-[#969696]"}`}>
              {timeIn ?? "--:--"}
            </div>
            <div className={`flex items-center justify-center ml-1 mr-1`}>
              <p>&rarr;</p>
            </div>

            <div className={`w-[40%] ${timeOut !== "--:--" ? "text-[#874341]" : "text-[#969696]"}`}>
              {timeOut ?? "--:--"}
            </div>
          </>
        )}
      </div>

      <div
        className={`flex items-center justify-center ${showTimeInOut ? "text-[#02457a]" : "text-[#70757a]"} font-semibold`}
      >
        <div
          className={`w-[20px] h-[20px] flex font-14 justify-center items-center ${
            iscurrentDate && "bg-[#1a73e8] text-white rounded-full"
          }`}
        >
          {format(date, "dd")}
        </div>
      </div>
    </div>
  );
}

export default DateInfo;
