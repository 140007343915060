import AddCircleIcon from "@mui/icons-material/AddCircle";
import { IconButton } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useQuery } from "@tanstack/react-query";
import TimeSheetAPI from "api/TimeSheetAPI";
import * as React from "react";
import { useSelector } from "react-redux";
import { useTaskStore } from "store/bearStore";

export default function AddNewProject({ disableKeyTimeSheet }) {
  const userIdSelected = useSelector((state) => state.timesheet.userIdSelected);
  const currentUser = useSelector((state) => state.user.current);
  const isCurrentUser = currentUser?.id === userIdSelected;

  const listProjectTasks = useTaskStore((state) => state.listProjectTasks);
  const mergeNewArray = useTaskStore((state) => state.mergeNewArray);

  const listProjectQuery = useQuery({
    queryKey: ["listProjectQuery"],
    queryFn: async () => {
      const response = await TimeSheetAPI.getListProjectsByUser();
      return response?.data;
    },
    enabled: true, // Enable the query by default
    refetchOnWindowFocus: true // Refetch the query when window regains focus
  });

  const { data: listProject } = listProjectQuery;

  const projectIdsInTasks = (listProjectTasks && listProjectTasks.map((task) => task.projectId)) || [];

  // Filter out projects with matching ids
  const filteredListProject =
    projectIdsInTasks && listProject?.filter((project) => !projectIdsInTasks.includes(project.id));

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleProjectChanged = async (event, arrProjectName) => {
    const findProjectByName = (name) => listProject?.find((project) => project.fullName === name);

    const newArray = arrProjectName.map((projectName) => {
      const projectSelected = findProjectByName(projectName);

      return projectSelected
        ? {
            projectId: projectSelected.id,
            projectName: projectSelected.fullName,
            tasks: []
          }
        : null;
    });

    const isProjectInList = (projectId) =>
      listProjectTasks && listProjectTasks?.some((project) => project.projectId === projectId);

    const filteredNewArray = newArray.filter((project) => project !== null && !isProjectInList(project.projectId));

    await mergeNewArray(filteredNewArray);
  };

  return (
    <>
      <p>Project</p>

      <IconButton
        aria-label="delete"
        disabled={!isCurrentUser || disableKeyTimeSheet}
        onClick={handleClickOpen}
        sx={{ color: "#4b4b4b" }}
      >
        <AddCircleIcon />
      </IconButton>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle sx={{ display: "flex", justifyContent: "center", color: "black" }}>Select project</DialogTitle>
        <DialogContent sx={{ padding: 2 }}>
          <FormControl sx={{ minWidth: 120, width: 400 }}>
            <Autocomplete
              multiple
              id="project"
              disableClearable
              options={filteredListProject?.map((option) => option.fullName)}
              sx={{
                marginTop: "10px",
                ".MuiOutlinedInput-root": {
                  padding: "0 !important"
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    type: "search"
                  }}
                />
              )}
              onChange={(event, newValue) => handleProjectChanged(event, newValue)}
            />
          </FormControl>
        </DialogContent>

        <DialogActions sx={{ padding: 2 }}>
          <Button
            variant="contained"
            size="small"
            onClick={handleClose}
            sx={{
              textTransform: "uppercase",
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
                backgroundColor: "#BCCDEA"
              },
              background: "#BCCDEA",
              color: "#001B48",
              fontWeight: 600,
              borderRadius: "10px",
              width: "90px",
              padding: "5px 0"
            }}
          >
            OK
          </Button>

          <Button
            variant="contained"
            onClick={handleClose}
            size="small"
            sx={{
              textTransform: "capitalize",
              boxShadow: "none",
              "&:hover": { boxShadow: "none", background: "#F0F0F0" },
              background: "#F0F0F0",
              color: "#555555",
              fontWeight: 600,
              borderRadius: "10px",
              width: "90px",
              padding: "5px 0",
              marginLeft: "20px"
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
