import { yupResolver } from "@hookform/resolvers/yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button } from "@mui/material";
import AdminUserAPI from "api/AdminUserAPI";
import AvatarImg from "assets/images/shared/avatar-default.svg";
import DatePickerTTD from "components/DateTimePicker/DatePickerTTD";
import DropdownListSelection from "components/DropdownListSelection";
import TextInputTTD from "components/PhaseIII/TextInputTTD";
import TextAreaInputField from "components/TextAreaInputField";
import dayjs from "dayjs";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getUserCredentialByEmail } from "store/userSlice";
import UrlConstant from "utils/constants/UrlConstant";
import { formatDateYYYYMMDD } from "utils/DateTimeUtils";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import * as yup from "yup";

UserProfileAccount.propTypes = {};

function UserProfileAccount() {
  const user = useSelector((state) => state.user.current);

  const {
    avatar,
    aboutMe,
    address,
    birthday,
    dateStartWorkText,
    email,
    fingerId,
    fullName,
    gender,
    idNo,
    issuedBy,
    issuedTo,
    phoneNumber,
    position,
    role,
    socialInsuranceBookNo,
    staffId,
    teamCode,
    userName
  } = user || {};

  const listGender = [
    { id: 1, type: "Male" },
    { id: 2, type: "Female" }
  ];

  const schema = yup.object().shape({
    birthday: yup.string().required("Yêu cầu nhập")
  });

  const form = useForm({
    defaultValues: {
      birthday: dayjs(birthday) || new Date(),
      issuedTo: dayjs(issuedTo) || new Date(),
      gender: gender ?? "Male",
      phoneNumber: phoneNumber,
      socialInsuranceBookNo: socialInsuranceBookNo,
      issuedBy: issuedBy,
      idNo: idNo,
      address: address,
      aboutMe: aboutMe
    },
    resolver: yupResolver(schema)
  });
  const dispatch = useDispatch();

  const fetchUsers = async () => {
    const actions = await getUserCredentialByEmail();
    await dispatch(actions);
  };

  const handleSubmit = async () => {
    var valueForm = form.getValues();

    let dataSubmit = {
      gender: valueForm?.gender,
      birthday: formatDateYYYYMMDD(valueForm?.birthday),
      socialInsuranceBookNo: valueForm?.socialInsuranceBookNo,
      issuedBy: valueForm?.issuedBy,
      issuedTo: formatDateYYYYMMDD(valueForm?.issuedTo),
      idNo: valueForm?.idNo,
      address: valueForm?.address,
      aboutMe: valueForm?.aboutMe
    };

    try {
      const [selfUpdate] = await Promise.all([AdminUserAPI.selfUpdate(dataSubmit), handleUploadAvatar()]);
      if (selfUpdate?.status === 200) {
        ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      }
    } catch (error) {
      ToastShowError(TOAST_ERROR_STRING);
    }
  };

  const avatarPath = `${UrlConstant.REACT_APP_DATABASE_URL}${avatar}`;

  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);

  const handleImageChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
      setFile(selectedFile);
    }
  };

  const handleUploadAvatar = async () => {
    if (file !== null) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        const response = await AdminUserAPI.uploadAvatar(formData);

        if (response?.status === 200) {
          fetchUsers();
        }
      } catch (error) {
        ToastShowError(TOAST_ERROR_STRING);
      }
    }
  };

  return (
    <div className="w-[70%] flex flex-col justify-center gap-3 overflow-x-hidden bg-white p-5 rounded-lg">
      <div className="flex flex-row gap-4">
        {/* Col 1 */}
        <div className="w-[160px] flex flex-col items-center justify-start flex-shrink-0">
          <div className="relative group cursor-pointer border bg-[#DCDCDC] rounded-lg mt-[5px] ">
            <img
              src={preview ?? avatarPath}
              alt="Image Preview"
              className="min-w-[160px] min-h-[200px] object-contain "
              onError={(event) => {
                event.target.src = AvatarImg;
                event.onerror = null;
              }}
            />
            <label
              htmlFor="fileInput"
              className="absolute rounded-lg w-full flex cursor-pointer justify-center bottom-0 left-1/2 transform -translate-x-1/2 bg-white bg-opacity-60 group-hover:visible invisible"
            >
              <CloudUploadIcon color="primary" />
            </label>
            <input
              id="fileInput"
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
        </div>

        {/* Col 2 - General */}
        <div className="flex flex-row">
          <div className="flex flex-col items-start gap-4">
            <div className="flex flex-row items-start  text-xl font-bold">General</div>
            <div className="w-full flex flex-row items-start gap-4">
              <TextInputTTD
                form={form}
                defaultValue={fullName}
                name="fullName"
                label="Name"
                disable
                requiredInput={false}
              />
              <TextInputTTD
                form={form}
                defaultValue={userName}
                disable
                name="userName"
                label="&nbsp;"
                requiredInput={false}
              />
            </div>

            <div className="w-full flex flex-row items-start gap-4">
              <div className="w-[50%]">
                <DropdownListSelection
                  name="gender"
                  label="Gender"
                  disable
                  form={form}
                  listSelection={listGender.map((item) => {
                    return {
                      value: item.type,
                      label: item.type
                    };
                  })}
                />
              </div>
              <div className="w-[50%]">
                <DatePickerTTD
                  name="birthday"
                  label="Date Of Birth"
                  disable
                  form={form}
                  requiredInput={false}
                  disablePreviousDate={true}
                />
              </div>
            </div>

            <div className="w-full flex flex-row items-start gap-4">
              <TextInputTTD
                form={form}
                defaultValue={address}
                name="address"
                disable
                label="Address"
                requiredInput={false}
              />
            </div>

            <div className="flex flex-row items-start  text-xl font-bold">Advance</div>
            <div className="w-full flex flex-row items-start gap-4">
              <TextInputTTD form={form} defaultValue={idNo} name="idNo" disable label="ID No" requiredInput={false} />

              <DatePickerTTD name="issuedTo" label="Issued On" disable form={form} requiredInput={false} />
            </div>

            <div className="w-full flex flex-row items-start gap-4">
              <TextInputTTD
                form={form}
                defaultValue={issuedBy}
                name="issuedBy"
                disable
                label="Issued By"
                requiredInput={false}
              />
            </div>

            <div className="w-full flex flex-row items-start gap-4">
              <TextInputTTD
                form={form}
                defaultValue={socialInsuranceBookNo ?? ""}
                name="socialInsuranceBookNo"
                disable
                label="Social Insurance No"
                requiredInput={false}
              />
            </div>
          </div>
        </div>

        {/* Col 3 - Company */}

        <div className="flex flex-row">
          <div className="flex flex-col items-start gap-4">
            <div className="flex flex-row items-start  text-xl font-bold">Company</div>
            <div className="w-full flex flex-row items-start gap-4">
              <TextInputTTD
                form={form}
                disable
                defaultValue={dateStartWorkText}
                name="dateStartWorkText"
                label="Start Date"
                requiredInput={false}
              />
              <TextInputTTD
                form={form}
                defaultValue={teamCode}
                disable
                name="teamCode"
                label="Team"
                requiredInput={false}
              />
            </div>

            <div className="w-full flex flex-row items-start gap-4">
              <TextInputTTD
                form={form}
                defaultValue={staffId}
                disable
                name="staffId"
                label="Staff ID"
                requiredInput={false}
              />
              <TextInputTTD
                form={form}
                defaultValue={position}
                disable
                name="position"
                label="Position"
                requiredInput={false}
              />
            </div>

            <div className="w-full flex flex-row items-start gap-4">
              <TextInputTTD
                form={form}
                defaultValue={fingerId}
                disable
                name="fingerId"
                label="Finger ID"
                requiredInput={false}
              />
              <TextInputTTD
                form={form}
                defaultValue={role}
                disable
                name="role"
                label="Role & Permission"
                requiredInput={false}
              />
            </div>

            <div className="flex flex-row items-start  text-xl font-bold">Contact</div>
            <div className="w-full flex flex-row items-start gap-4">
              <TextInputTTD form={form} defaultValue={email} name="email" disable label="Email" requiredInput={false} />
              <TextInputTTD
                form={form}
                defaultValue={phoneNumber}
                name="phoneNumber"
                label="Phone Number"
                requiredInput={false}
              />
            </div>

            <div className="flex flex-row items-start  text-xl font-bold">Others</div>
            <div className="w-full flex flex-row items-start gap-4">
              <TextAreaInputField
                name="aboutMe"
                label={
                  <div>
                    <span>About Me</span>
                  </div>
                }
                form={form}
                minRows={2.9}
                maxRows={5}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-row justify-end items-end">
        <Button
          variant="contained"
          size="small"
          onClick={form.handleSubmit(handleSubmit)}
          sx={{
            textTransform: "capitalize",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "#BCCDEA"
            },
            background: "#BCCDEA",
            color: "#001B48",
            fontWeight: 600,
            borderRadius: "10px",
            width: "90px",
            padding: "5px 0"
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default UserProfileAccount;
