import axiosClient from "./api-config/axiosClient";

const AdminLeaveFormAPI = {
  async getListView(year, month) {
    const url = `/Leave/GetListView/${year}/${month}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async approveOrRejectLeave(id, isApprove) {
    const url = `/Leave/Approve/${id}/${isApprove}`;
    return axiosClient.request({
      method: "POST",
      url: url
    });
  },

  async getReportLeave(year) {
    const url = `/Leave/GetReport/${year}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  }
};
export default AdminLeaveFormAPI;
