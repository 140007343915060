import { create } from "zustand";

export const useTaskStore = create((set) => ({
  listProjectTasks: [],
  initTask: (projects) => set({ listProjectTasks: projects }),
  addProject: (newProject) =>
    set((state) => ({
      listProjectTasks: state.listProjectTasks.concat(newProject)
    })),
  mergeNewArray: (newArray) =>
    set((state) => ({
      listProjectTasks: state.listProjectTasks.concat(newArray)
    }))
}));

export const useCalendarStore = create((set) => ({
  type: 1,
  changeType: (value) => set({ type: value })
}));

export const useTabStore = create((set) => ({
  tabIndex: 0,
  changeTabIndex: (value) => set({ tabIndex: value })
}));
