import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useQuery } from "@tanstack/react-query";
import UserAPI from "api/UserAPI";
import * as React from "react";
import { useSelector } from "react-redux";
import userState from "utils/constants/stateConstant";
import StatusItem from "./StatusItem";

export function getColor(state) {
  return userState.find((x) => x.state === state)?.color ?? "#80E07E";
}

function SelectionField({ width, className, onChangeStatusHub }) {
  const currentUser = useSelector((state) => state.user.current);
  const initState = currentUser?.state || "Available";
  const [selected, setSelected] = React.useState(initState);

  const userStateQuery = useQuery({
    queryKey: ["userState"],
    queryFn: async () => {
      const response = await UserAPI.getOptionStateUser();
      return response?.data;
    },
    enabled: true, // Enable the query by default
    refetchOnWindowFocus: true // Refetch the query when window regains focus
  });

  const { data: userStates } = userStateQuery;

  const handleChange = (event) => {
    let value = event.target.value;
    setSelected(value);
    if (onChangeStatusHub) onChangeStatusHub(value);
  };

  return (
    <Box className={className} sx={{ width: width }}>
      <FormControl fullWidth>
        <Select
          size="small"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selected}
          onChange={handleChange}
        >
          {userStates &&
            userStates.map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  <StatusItem name={item} color={getColor(item)} />
                </MenuItem>
              );
            })}
          {/* <MenuItem value="available">
            <StatusItem name="available" color="var(--bs-weekday)" />
          </MenuItem>
          <MenuItem value="wfh">
            <StatusItem name="WFH" color="var(--bs-bondi-blue)" />
          </MenuItem>
          <MenuItem value="business">
            <StatusItem name="business" color="var(--bs-pending)" />
          </MenuItem>
          <MenuItem value="busy">
            <StatusItem name="busy" color="red" />
          </MenuItem>
          <MenuItem value="unavailable">
            <StatusItem name="unavailable" color="gray" />
          </MenuItem> */}
        </Select>
      </FormControl>
    </Box>
  );
}

export default SelectionField;
