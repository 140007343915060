import { Badge } from "@mui/material";
import { startCase, toLower } from "lodash";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import customClassNames from "utils/utilClassName";

MenuItem.propTypes = {};

function MenuItem({ navigation, numberNotice }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };
  return (
    <NavLink
      to={navigation.link}
      className={({ isActive }) => customClassNames("relative", isActive ? "bg-[#646B7E] bg-opacity-75" : "")}
      onMouseEnter={toggleTooltip}
      onMouseLeave={toggleTooltip}
      onMouseOver={toggleTooltip}
    >
      <div className="h-10 pl-5 my-1 flex items-center cursor-pointer transition-all">
        <div className="w-6 flex justify-center items-center">
          {navigation?.icon && <i className={`text-base text-white ${navigation?.icon}`}></i>}
          {navigation?.path && (
            <img
              src={navigation.path}
              alt={navigation?.name}
              className="h-5 w-5 border-none rounded-none ml[-25px] inline-block"
            ></img>
          )}
        </div>
        <p className="lg:block hidden ml-2 text-sm font-medium">{navigation.name}</p>
        <Badge style={{ marginLeft: "15px" }} badgeContent={numberNotice || 0} color="error" />
      </div>
      {showTooltip && (
        <div
          className="lg:hidden absolute top-1/2 -translate-y-1/2 right-0 translate-x-[105%] bg-slate-500 px-2 py-1 rounded-md z-50 overflow-hidden text-center"
          style={{ width: navigation.name.length * 10.5 }}
        >
          {startCase(toLower(navigation.name))}
        </div>
      )}
    </NavLink>
  );
}

export default MenuItem;
