import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Chip, IconButton, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useMutation, useQueries } from "@tanstack/react-query";
import AdminUserAPI from "api/AdminUserAPI";
import HolidayAPI from "api/HolidayAPI";
import DeletePopup from "components/DeletePopUp";
import DropdownListSelectionFilter from "components/DropdownListSelectionFilter";
import LoadingOverLay from "components/LoadingOverlay";
import { listYears } from "components/SharedData";
import CustomNoRowsOverlay from "pages/admin-timesheet/finger-printer/CustomNoRowsOverlay";
import React from "react";
import { formatDateTime } from "utils/DateTimeUtils";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import { showAllValueErrors } from "utils/formErrorUtil";
import useRefreshQuery from "utils/useRefreshQuery";
import HolidayModal from "./component/HolidayModal";

const Holidays = () => {
  const [isNewHoliday, setIsNewHoliday] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedYear, setSelectedYear] = React.useState(String(new Date().getFullYear()));
  const [showDeletePopup, setShowDeletePopup] = React.useState({
    show: false
  });
  const [holidayId, setHolidayId] = React.useState();

  const { refreshQuery: refreshQueryObject } = useRefreshQuery(["listViewHolidayQuery"]);

  const [listViewHolidayQuery, listUserHolidayApplyQuery] = useQueries({
    queries: [
      {
        queryKey: ["listViewHolidayQuery", selectedYear],
        queryFn: async () => {
          const response = await HolidayAPI.GetListView(selectedYear);
          const formatData = response?.data?.map((item, index) => ({
            ...item,
            stt: index + 1
          }));
          return formatData;
        }
      },
      {
        queryKey: ["listUserHolidayApplyQuery"],
        queryFn: async () => {
          const response = await AdminUserAPI.getDynamicOption();
          return response.data;
        }
      }
    ]
  });
  const { data: listViewHoliday, isLoading } = listViewHolidayQuery;
  const { data: listUserHolidayApply } = listUserHolidayApplyQuery;

  const mutationDelete = useMutation({
    mutationFn: (value) => {
      return HolidayAPI.DeleteHoliday(value);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        const errorData = error.response.data.errors;
        ToastShowError(showAllValueErrors(errorData));
      } else ToastShowError(TOAST_ERROR_STRING);
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      onClose();
      refreshQueryObject();
    }
  });

  const handleCreateNewHoliday = () => {
    setIsNewHoliday(true);
    setIsModalOpen(true);
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  const handleRequestDelete = (params) => {
    setShowDeletePopup({ show: true });
    setHolidayId(params?.id);
  };

  const handleDeleteHoliday = () => {
    mutationDelete.mutate(holidayId);
  };

  const columns = [
    {
      field: "stt",
      headerName: "STT",
      width: 60,
      align: "center",
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "name",
      headerName: "Event Name",
      flex: 1,
      align: "left",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "type",
      headerName: "Type",
      width: 100,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "applyFor",
      headerName: "Apply For",
      width: 200,
      align: "center",
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value ? value : "-";
      }
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 100,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return formatDateTime(value);
      }
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 100,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return formatDateTime(value);
      }
    },
    {
      field: "duration",
      headerName: "Duration (days)",
      width: 150,
      align: "center",
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div>
            {params.row.status === "Apply" ? (
              <Chip
                style={{
                  width: "110px",
                  background: "#C6DCC6",
                  color: "#004D00",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="Applied"
              />
            ) : params.row.status === "Pending" ? (
              <Chip
                style={{
                  width: "110px",
                  background: "#EDE5C5",
                  color: "#1E1D19",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="Processing"
              />
            ) : (
              <Chip
                style={{
                  width: "110px",
                  background: "#E3CDCD",
                  color: "#660000",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="Deleting"
              />
            )}
          </div>
        );
      }
    },
    {
      field: "action",
      headerName: "",
      width: 100,
      align: "center",
      sortable: false,
      headerClassName: "header",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div>
            {params.row.status === "Deleted" ? (
              <div style={{ visibility: "hidden" }}></div>
            ) : (
              <div>
                <IconButton onClick={() => handleRequestDelete(params)}>
                  <DeleteIcon />
                </IconButton>
                <IconButton style={{ visibility: "hidden" }}>
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>
        );
      }
    }
  ];

  let filteredHoliday = listViewHoliday?.filter((item) => {
    const yearFilter = item.startDate.substring(0, 4) === selectedYear;
    return yearFilter;
  });

  return (
    <div className="flex flex-col bg-white rounded-lg p-5 gap-1.5 h-full">
      <div className="flex justify-between">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            width: "100px"
          }}
        >
          <div style={{ width: "max-content" }}>
            <span>Year</span>
          </div>
          <DropdownListSelectionFilter
            listSelection={listYears?.map((item) => ({
              label: item.year,
              value: item.year
            }))}
            selected={selectedYear}
            setSelected={setSelectedYear}
          />
        </div>
        <div>
          <Tooltip title="Add Holiday">
            <IconButton variant="contained" onClick={handleCreateNewHoliday}>
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <Box
        sx={{
          height: "100%",
          width: "100%",
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "15px",
            fontWeight: 600
          }
        }}
        className="flex-grow"
      >
        <DataGrid
          rowHeight={40}
          sx={{
            border: "none",
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
              fontSize: "16px"
            },
            minHeight: "100px",
            ".MuiDataGrid-footerContainer": { borderTop: "none" },
            "--DataGrid-overlayHeight": "calc(100vh - 200px)"
          }}
          rows={filteredHoliday ?? []}
          columns={columns}
          getRowId={(row) => row?.id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15
              }
            }
          }}
          pageSizeOptions={[15]}
          slots={{
            loadingOverlay: LoadingOverLay,
            noRowsOverlay: CustomNoRowsOverlay
          }}
          isLoading={isLoading}
          hideFooterSelectedRowCount
          disableColumnMenu={true}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableColumnSort={true}
          disableRowSelectionOnClick={true}
        />
      </Box>

      {isNewHoliday && isModalOpen && listUserHolidayApply && (
        <HolidayModal
          isNewHoliday={isNewHoliday}
          open={isModalOpen}
          onClose={onClose}
          dataHolidayApply={listUserHolidayApply}
          refreshQueryObject={refreshQueryObject}
        />
      )}

      <DeletePopup
        open={showDeletePopup.show}
        onClose={() => setShowDeletePopup(false)}
        handleDelete={handleDeleteHoliday}
        title="Bạn có chắc chắn xóa ?"
      />
    </div>
  );
};

export default Holidays;
