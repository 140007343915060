import CircleIcon from "@mui/icons-material/Circle";
import DevicesIcon from "@mui/icons-material/Devices";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";

function SelectObject(props) {
  const { form, label, listObjects, requiredInput } = props;
  const { watch } = form;
  const objectId = watch("objectId");

  const [valueSelected, setValueSelected] = useState(objectId || "");

  useEffect(() => {
    setValueSelected(objectId);
  }, []);

  const handleSelectChange = (e) => {
    setValueSelected(e.target.value);
    if (form) {
      form.setValue("objectId", e.target.value);
    }
  };

  return (
    <div className="flex flex-col items-start">
      <div className="flex flex-row items-start ml-2">
        {label} {requiredInput && <p style={{ color: "red", marginLeft: "5px" }}>*</p>}
      </div>

      <div className="flex flex-col items-start w-full">
        <Select
          value={valueSelected}
          defaultValue={valueSelected}
          onChange={handleSelectChange}
          form={form}
          sx={{
            width: "100%",
            borderRadius: "8px",
            backgroundColor: "#dcdcdc",
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
              outline: "flexnone"
            }
          }}
        >
          {listObjects?.map((object, index) => {
            return (
              <MenuItem value={object?.id} key={index}>
                {(() => {
                  switch (object?.type) {
                    case "Notice":
                      return (
                        <PersonIcon
                          sx={{
                            width: 20,
                            height: 20,
                            marginRight: "6px"
                          }}
                        />
                      );
                    case "MeetingRoom":
                      return (
                        <GroupsIcon
                          sx={{
                            width: 20,
                            height: 20,
                            marginRight: "6px"
                          }}
                        />
                      );
                    case "HiEndPc":
                      return (
                        <DevicesIcon
                          sx={{
                            width: 20,
                            height: 20,
                            marginRight: "6px"
                          }}
                        />
                      );
                    default:
                      return (
                        <CircleIcon
                          sx={{
                            width: 20,
                            height: 20,
                            marginRight: "6px"
                          }}
                        />
                      );
                  }
                })()}
                {object?.name}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    </div>
  );
}
export default SelectObject;
