import TokenServices from "api/TokenServices";
import axios, { isAxiosError } from "axios";
import { urls } from "config/url";
import queryString from "query-string";
import StorageKeys from "utils/constants/StorageKeys";
import UrlConstant from "utils/constants/UrlConstant";

export class AxiosServiceDownloadFile {
  instance;
  constructor() {
    this.accessToken = TokenServices.getLocalAccessToken();
    this.refreshToken = TokenServices.getLocalRefreshToken();
    this.refreshTokenRequest = null;
    this.instance = axios.create({
      baseURL: UrlConstant.REACT_APP_API_URL,
      headers: {
        "Content-Type": "application/octet-stream",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, PATCH, DELETE, OPTIONS",
        "Access-Control-Allow-Headers":
          "Origin,DNT,X-Mx-ReqToken,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type"
      },
      paramsSerializer: (params) => queryString.stringify(params)
    });

    // interceptor
    this.instance.interceptors.request.use(async (config) => {
      const accessToken = await localStorage.getItem(StorageKeys.access);
      try {
        if (accessToken) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
      } catch (error) {
        alert("Error In Axios config");
      }
      return config;
    });
    this.instance.interceptors.response.use(
      (res) => {
        return res;
      },
      (error) => {
        const config = error.response?.config || {};
        const { url } = config;

        if (url !== "login" && error.response) {
          if (isAxiosError(error) && error.response?.status === 401) {
            if (url !== "/token/refresh") {
              // console.log(url);
              // Hạn chế gọi 2 lần handleRefreshToken
              this.refreshTokenRequest = this.refreshTokenRequest
                ? this.refreshTokenRequest
                : this.handleRefreshToken().finally(() => {
                    // Giữ refreshTokenRequest trong 10s cho những request tiếp theo nếu có 401 thì dùng
                    setTimeout(() => {
                      this.refreshTokenRequest = null;
                    }, 10000);
                  });
              return this.refreshTokenRequest.then((access_token) => {
                return this.instance({ ...config, headers: { ...config.headers, authorization: access_token } });
              });
            }
          }
        }
        return Promise.reject(error);
      }
    );
  }

  handleRefreshToken() {
    const urlToken = "/token/refresh";
    return this.instance
      .request({
        method: "POST",
        url: urlToken,
        data: {
          token: localStorage.getItem(StorageKeys.refresh),
          userEmail: TokenServices.getUser()?.email
        }
      })
      .then((res) => {
        const { accessToken, refreshToken } = res.data;
        TokenServices.addToken(res.data);
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
        return accessToken;
      })
      .catch((error) => {
        history.replaceState({}, "", urls.web.authentication.login);
        window.location.reload();
        TokenServices.removeUser();
        this.accessToken = "";
        this.refreshToken = "";
        throw error;
      });
  }
}
const axiosClientDownloadFile = new AxiosServiceDownloadFile().instance;

export default axiosClientDownloadFile;
