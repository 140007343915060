import Logout from "@mui/icons-material/Logout";
import Settings from "@mui/icons-material/Settings";
import { Avatar, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import UserAPI from "api/UserAPI";
import { urls } from "config/url";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getUserCredentialByEmail, logout } from "store/userSlice";
import UrlConstant from "utils/constants/UrlConstant";
import userState from "utils/constants/stateConstant";

MenuProfile.propTypes = {};

const stringToColor = (string) => {
  if (string == null) return;
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

const stringAvatar = (name) => {
  return {
    sx: {
      bgcolor: stringToColor(name)
    },
    children: `${name ?? ""}`
  };
};

function MenuProfile() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const userCur = useSelector((state) => state.user.current);
  const lastName = userCur?.fullName?.trim().split(" ").pop().charAt(0);
  const stateColor = userState.find((x) => x.state === userCur?.state)?.color ?? "#80E07E";

  const onChangeStateHub = async (value) => {
    try {
      await UserAPI.changeState(value);
      const actions = getUserCredentialByEmail();
      await dispatch(actions);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSetting = () => {
    navigate(urls.web.user.userSetting);
  };

  const handleClickLogout = async () => {
    const action = logout();
    await dispatch(action);
    navigate(urls.web.authentication.login);
  };

  const userMenu = (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          padding: "8px",
          borderRadius: "6px",
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 18,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0
          }
        }
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {userState &&
        userState.map((h) => (
          <MenuItem onClick={() => onChangeStateHub(h.state)} key={h.state}>
            <div className="flex flex-row gap-2 items-center justify-start">
              <div className={`h-4 w-4 rounded-full`} style={{ backgroundColor: h.color }}></div>
              <span className="text-sm font-normal">{h.state}</span>
            </div>
          </MenuItem>
        ))}

      <Divider sx={{ color: "black" }} />

      <MenuItem onClick={handleClickSetting}>
        <div className="flex flex-row gap-2 items-center justify-start">
          <Settings fontSize="small" />
          <span className="text-sm font-normal">Settings</span>
        </div>
      </MenuItem>
      <MenuItem onClick={handleClickLogout}>
        <div className="flex flex-row gap-2 items-center justify-start">
          <Logout fontSize="small" sx={{ height: "20px", marginLeft: "2px" }} />
          <span className="text-sm font-normal">Logout</span>
        </div>
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      <IconButton
        size="large"
        edge="end"
        aria-label="account of current user"
        aria-haspopup="true"
        color="inherit"
        onClick={handleClick}
      >
        <Avatar
          alt={userCur?.name}
          src={userCur?.avatar ? `${UrlConstant.REACT_APP_DATABASE_URL}${userCur?.avatar}` : ""}
          {...stringAvatar(lastName)}
          sx={{ width: 35, height: 35 }}
        />
        <div
          className={`h-4 w-4 rounded-full absolute bottom-2 left-2 border-[2px] border-white`}
          style={{ backgroundColor: stateColor }}
        ></div>
      </IconButton>
      {userMenu}
    </div>
  );
}

export default MenuProfile;
