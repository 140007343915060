function findTabIndex(obj) {
  let minIndex = Infinity;

  for (const key in obj) {
    if (obj[key].isView && obj[key].index !== undefined) {
      minIndex = Math.min(minIndex, obj[key].index);
    }
  }

  return minIndex === Infinity ? null : minIndex;
}

export default findTabIndex;
