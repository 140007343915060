import axiosClient from "./api-config/axiosClient";

const AdminGroupAPI = {
  // NEW API

  async getListView() {
    const url = `/Group/GetListView`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getDetail(id) {
    const url = `/Group/GetDetail/${id}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async updateGroup(params) {
    const url = `/Group/Update`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: params
    });
  },

  async createGroup(params) {
    const url = `/Group/Create`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: params
    });
  },

  async delete(id) {
    const url = `/Group/Delete/${id}`;
    return axiosClient.request({
      method: "DELETE",
      url: url
    });
  },

  async getMemberOfGroup(id) {
    const url = `/Users/GetMemberOfGroup/${id}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getOption() {
    const url = `/Group/GetOption`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  }
};

export default AdminGroupAPI;
