import { urls } from "config/url";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SideBarAdmin from "../SideBarAdmin";
import SideBarUser from "../SideBarUser";
import TimeSheetImage from "assets/images/side-bar/time-sheet.png";
import LeaveImage from "assets/images/side-bar/leave.png";
import WFHImage from "assets/images/side-bar/wfh.png";
import AssetImage from "assets/images/side-bar/asset.png";

function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUserState = useSelector((state) => state.user.current);
  const menuAccess = useSelector((state) => state.common.menuAccess);

  const staffMenu = menuAccess?.staff || [];
  const adminMenu = menuAccess?.admin || [];

  let viewAdmin = false;
  Object.keys(adminMenu).forEach((key) => {
    if (adminMenu[key] && adminMenu[key].hasOwnProperty("isView") && adminMenu[key]["isView"]) {
      viewAdmin = true;
    }
  });

  const NAVIGATION = [
    {
      id: 1,
      name: "DASHBOARD",
      configName: "dashboard",
      role: "member",
      active: false,
      disable: false,
      icon: "fas fa-tachometer-alt",
      link: urls.web.user.dashboard
    },
    {
      id: 3,
      name: "TIME SHEET",
      role: "member",
      configName: "timesheet",
      active: false,
      disable: false,
      path: TimeSheetImage,
      link: urls.web.user.timeSheet
    },
    {
      id: 4,
      name: "OVER TIME",
      configName: "overtime",
      role: "member",
      active: false,
      disable: false,
      icon: "fas fa-clock",
      link: urls.web.user.overtime
    },
    {
      id: 5,
      name: "LEAVE FORM",
      configName: "leave",
      role: "member",
      active: false,
      disable: false,
      path: LeaveImage,
      link: urls.web.user.leaveForm
    },
    {
      id: 2,
      name: "CALENDAR",
      configName: "calendar",
      role: "member",
      active: false,
      disable: false,
      icon: "fas fa-calendar-alt",
      link: urls.web.user.calendar.replace(":userId", currentUserState?.id)
    },
    {
      id: 6,
      name: "WORK FROM HOME",
      role: "member",
      configName: "wfh",
      active: false,
      disable: false,
      path: WFHImage,
      link: urls.web.user.wfh
    },
    {
      id: 7,
      name: "ASSET",
      role: "member",
      configName: "asset",
      active: false,
      disable: false,
      path: AssetImage,
      link: urls.web.user.asset
    },
    {
      id: 10,
      name: "USERS",
      role: "admin",
      configName: "user",
      active: false,
      disable: false,
      icon: "fas fa-users-cog",
      link: urls.web.admin.user
    },
    {
      id: 11,
      name: "TIME SHEET",
      role: "admin",
      configName: "timesheet",
      active: false,
      disable: false,
      path: TimeSheetImage,
      link: urls.web.admin.timeSheet
    },
    {
      id: 12,
      name: "OVER TIME",
      configName: "overtime",
      role: "admin",
      active: false,
      disable: false,
      icon: "fas fa-clock",
      link: urls.web.admin.overtime
    },
    {
      id: 13,
      name: "LEAVE FORM",
      configName: "leave",
      role: "admin",
      active: false,
      disable: false,
      path: LeaveImage,
      link: urls.web.admin.leaveForm
    },
    {
      id: 14,
      name: "WORK FROM HOME",
      configName: "wfh",
      role: "admin",
      active: false,
      disable: false,
      path: WFHImage,
      link: urls.web.admin.wfh
    }
  ];

  const getActiveNavItem = (link) => {
    if (location.pathname === link) return true;

    if (
      (location.pathname === "/leaveform/newrequest" && link === "/leaveform") ||
      (location.pathname.includes("/timesheet") &&
        link === "/timesheet" &&
        !location.pathname.includes("admin/timesheet")) ||
      (location.pathname.includes("/calendar") && link === `/calendar/${currentUserState?.id}`)
    ) {
      return true;
    }

    return false;
  };

  const handleOnClick = async (value) => {
    if (value.disable) return;

    const newNavigation = NAVIGATION.map((item) => ({
      ...item,
      active: item.link === value.link ? true : getActiveNavItem(item.link)
    }));

    setNavigation(newNavigation);
    navigate(value.link);
  };

  const [navigation, setNavigation] = useState(() => {
    return NAVIGATION.map((item, index) => ({
      ...item,
      active:
        item.link === location.pathname ||
        (index === 0 && location.pathname === "/login") ||
        getActiveNavItem(item.link)
    }));
  });

  useEffect(() => {
    const newNavigation = NAVIGATION.map((item) => ({
      ...item,
      active: getActiveNavItem(item.link)
    }));

    setNavigation(newNavigation);
  }, [location, currentUserState]);

  const navStaff = navigation.filter((item) => item.role === "member");
  const navAdmin = navigation.filter((item) => item.role === "admin");

  return (
    <div className="w-full flex flex-col items-center gap-3">
      <SideBarUser
        item={staffMenu}
        navigation={navStaff.filter((item) => staffMenu?.[item.configName]?.isView === true)}
        navigationClick={handleOnClick}
      />

      {viewAdmin && (
        <SideBarAdmin
          item={adminMenu}
          navigation={navAdmin.filter((item) => adminMenu?.[item.configName]?.isView === true)}
          navigationClick={handleOnClick}
        />
      )}
    </div>
  );
}

export default SideBar;
