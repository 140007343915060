import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { IconButton } from "@mui/material";
import AdminGroupAPI from "api/AdminGroupAPI";
import SearchInputField from "components/SearchInputFeild";
import * as React from "react";

export default function UpdateGroup({ projectDetail, setProjectDetail, open }) {
  const [dataListGroup, setDataListGroup] = React.useState([]);
  const [searchGroupValue, setSearchGroupValue] = React.useState("");

  React.useEffect(() => {
    const fetchGetListViewGroup = async () => {
      try {
        const response = await AdminGroupAPI.getOption();
        const data = response.data;
        setDataListGroup(data);
      } catch (error) {
        console.log("Error get User List ", error);
      }
    };
    fetchGetListViewGroup();
  }, [open]);

  const handleRemoveGroupFromProject = (groupId) => {
    const updatedGroups = projectDetail?.groups.filter((group) => group.id !== groupId);

    setProjectDetail({
      ...projectDetail,
      groups: updatedGroups
    });
  };

  const handleAddGroupToProject = (group) => {
    let updatedGroups = [];
    if (projectDetail?.groups) {
      updatedGroups = projectDetail?.groups ? [...projectDetail.groups] : [];
    }

    updatedGroups.push({
      id: group.id,
      name: group.name
    });
    setProjectDetail({
      ...projectDetail,
      groups: updatedGroups
    });
  };

  const handleSearchGroup = (e) => {
    const newValue = e.target.value;
    setSearchGroupValue(newValue);
  };

  let filteredGroups = React.useMemo(() => {
    if (!dataListGroup) {
      return;
    }

    function removeVNAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    let filterGroups = [...dataListGroup];

    if (searchGroupValue !== "") {
      filterGroups = dataListGroup.filter((group) =>
        removeVNAccents(group.name.toLowerCase()).includes(removeVNAccents(searchGroupValue.toLowerCase()))
      );
    }
    if (projectDetail?.groups) {
      const groupMemberProject = filterGroups.filter((group) =>
        projectDetail.groups.find((item) => item.id === group.id)
      );
      const notGroupMemberProject = filterGroups.filter(
        (group) => !projectDetail.groups.find((item) => item.id === group.id)
      );
      filterGroups = [];
      filterGroups = [...groupMemberProject, ...notGroupMemberProject];
    }
    return filterGroups;
  }, [dataListGroup, searchGroupValue, projectDetail?.groups]);

  return (
    <div className="rounded-xl bg-slate-100 ">
      <div>
        <div className="w-[283px] pt-1 pl-3">
          <SearchInputField
            onChange={handleSearchGroup}
            name="FindGroups"
            placeholder="Find Group..."
            value={searchGroupValue}
          />
        </div>
      </div>
      <div>
        <div className="flex font-semibold mb-1 w-full">
          <span className="w-3/4 text-center">Name</span>
          <span className="w-1/4">Member</span>
        </div>
        <hr
          style={{
            color: "#000",
            backgroundColor: "black",
            height: 2,
            width: "95%",
            marginLeft: "14px"
          }}
        />
        <div className="h-[160px]">
          <ul className="max-h-40 overflow-auto px-2 py-1">
            {filteredGroups?.map((group) => {
              return (
                <li
                  key={group.id}
                  className="rounded-lg bg-slate-100 p-2 w-full flex cursor-pointer items-center h-10 hover:rounded-lg hover:bg-gray-300"
                >
                  <div className="w-3/4 text-base font-medium px-2 ">
                    <span>{group.name}</span>
                  </div>
                  <div className="w-1/4 flex justify-between items-center">
                    <div className="ml-8">
                      <span>{group.amount}</span>
                    </div>
                    <div>
                      <span>
                        {projectDetail?.groups?.some((item) => item.id === group.id) ? (
                          <IconButton onClick={() => handleRemoveGroupFromProject(group.id)}>
                            <RemoveCircleIcon />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => handleAddGroupToProject(group)}>
                            <AddCircleIcon />
                          </IconButton>
                        )}
                      </span>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
