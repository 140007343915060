import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

function DatePickerComponent(props) {
  const { value, onChange, onMonthChange, style, format, isLimitDay, ...prop } = props;
  const limitYear = isLimitDay === undefined || isLimitDay === true ? 5 : 100;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DemoContainer components={["DatePicker"]} sx={{ padding: 0 }}>
        <DatePicker
          value={value}
          minDate={new Date(`${new Date().getFullYear() - limitYear}-01-01`)}
          maxDate={new Date(`${new Date().getFullYear() + 6}-12-01`)}
          onChange={onChange}
          onMonthChange={onMonthChange}
          format={format ?? "yyyy/MM/dd"}
          slotProps={{
            popper: {
              sx: {
                zIndex: "99999",
                "& .MuiPickersYear-root": {
                  flexBasis: "33.3% !important"
                }
              }
            }
          }}
          sx={{
            ...style,
            minWidth: "160px !important",
            "& .MuiInputBase-input": {
              paddingRight: "0px !important"
            }
          }}
          {...prop}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

export default DatePickerComponent;
