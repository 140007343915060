import { format } from "date-fns";
import dayjs from "dayjs";

function DateCalendarInfo(props) {
  const { date, isCurrentMonth } = props;

  var iscurrentDate = date && dayjs(date).isSame(new Date(), "day");

  return (
    <div className={`flex w-full justify-end font-semibold text-sm`}>
      <div
        className={`w-[20px] h-[20px] flex font-14 justify-center items-center ${isCurrentMonth ? "text-[#02457a]" : "text-[#70757a]"} ${
          iscurrentDate && "bg-[#1a73e8] text-white rounded-full"
        }`}
      >
        {format(date, "dd")}
      </div>
    </div>
  );
}

export default DateCalendarInfo;
