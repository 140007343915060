import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useMutation } from "@tanstack/react-query";
import AdminUserAPI from "api/AdminUserAPI";
import DatePickerFormInput from "components/DateTimePicker/DatePickerFormInput";
import DropdownListSelection from "components/DropdownListSelection";
import TextInputTTD from "components/PhaseIII/TextInputTTD";
import TextAreaInputField from "components/TextAreaInputField";
import { useForm } from "react-hook-form";
import { formatDateYYYYMMDD } from "utils/DateTimeUtils";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import { showToastErrorCustom } from "utils/constants/ShowToastErrorCustom";
import UrlConstant from "utils/constants/UrlConstant";
import { positions } from "utils/constants/positions";
import { setReactHookFormErrors, showAllValueErrors } from "utils/formErrorUtil";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    fullName: yup.string().required("Trường này yêu cầu phải nhập").min(5, "Nhập trên 5 ký tự"),
    dateStartWork: yup.string().required("Trường này yêu cầu phải nhập"),
    staffId: yup.string().required("Trường này yêu cầu phải nhập"),
    fingerId: yup.number().typeError("Trường này yêu cầu nhập số").required("Trường này yêu cầu phải nhập"),
    email: yup.string().email("Email không đúng định dạng").required("Trường này yêu cầu phải nhập"),
    team: yup.string().required("Trường này yêu cầu phải nhập"),
    position: yup.string().required("Trường này yêu cầu phải nhập"),
    role: yup.string().required("Trường này yêu cầu phải nhập")
  })
  .required();

function EditUser({
  onClose,
  open,
  userDetail,
  disable,
  listTeam,
  listRole,
  refreshQueryObject,
  refreshQueryUserDetail
}) {
  const {
    id,
    fullName,
    userName,
    email,
    staffId,
    avatar,
    fingerId,
    phoneNumber,
    idNo,
    issuedBy,
    address,
    socialInsuranceBookNo
  } = userDetail || {};

  let newListTeam = listTeam.filter((element) => element.id !== 0);

  const form = useForm({
    defaultValues: {
      fullName: userDetail?.fullName,
      userName: userDetail?.userName,
      dateStartWork: new Date(userDetail?.dateStartWork),
      position: userDetail?.position,
      role: userDetail?.role,
      staffId: userDetail?.staffId,
      email: userDetail?.email,
      fingerId: userDetail?.fingerId,
      phoneNumber: userDetail?.phoneNumber,
      gender: userDetail?.gender || "Male",
      birthday: new Date(userDetail?.birthday),
      idNo: userDetail?.idNo,
      issuedTo: new Date(userDetail?.issuedTo),
      issuedBy: userDetail?.issuedBy,
      address: userDetail?.address,
      socialInsuranceBookNo: userDetail?.socialInsuranceBookNo,
      aboutMe: userDetail?.aboutMe,
      team: userDetail?.teamId || ""
    },
    resolver: yupResolver(schema)
  });

  const mutationForm = useMutation({
    mutationFn: (user) => {
      return AdminUserAPI.UpdateUser(user);
    },
    onError: (error) => {
      const errorData = error.response.data.errors;
      if (error.response.status === 400) {
        ToastShowError(showAllValueErrors(errorData) || TOAST_ERROR_STRING);
        setReactHookFormErrors(errorData, form.setError);
      } else {
        ToastShowError(showAllValueErrors(errorData) || TOAST_ERROR_STRING);
      }
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      handleClose();
      refreshQueryObject();
      refreshQueryUserDetail();
    }
  });

  const mutationResetPassword = useMutation({
    mutationFn: (id) => {
      return AdminUserAPI.resetPassword(id);
    },
    onError: (error) => {
      const errorData = error.response.data.errors;
      if (error.response.status === 400) {
        if (errorData) {
          setReactHookFormErrors(errorData, form.setError) || showToastErrorCustom(errorData);
        } else ToastShowError(error.response.data);
      } else ToastShowError(TOAST_ERROR_STRING);
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      handleClose();
      refreshQueryObject();
      refreshQueryUserDetail();
    }
  });

  const handleClose = () => {
    onClose(false);
  };

  const listGender = [
    { id: 1, type: "Male" },
    { id: 2, type: "Female" }
  ];

  const handleSubmitFunc = async () => {
    let valueSubmit = form.getValues();

    valueSubmit = {
      id: userDetail?.id,
      fullName: valueSubmit.fullName,
      userName: valueSubmit.userName,
      email: valueSubmit.email,
      staffId: valueSubmit.staffId,
      position: valueSubmit.position || form.control.defaultValuesRef.current.position,
      role: valueSubmit.role || form.control.defaultValuesRef.current.role,
      roleId: listRole.find((role) => role.name === valueSubmit.role)?.id,
      dateStartWork: formatDateYYYYMMDD(valueSubmit.dateStartWork) || new Date(),
      fingerId: valueSubmit.fingerId,
      phoneNumber: valueSubmit.phoneNumber,
      gender: valueSubmit.gender || "Male",
      birthday: formatDateYYYYMMDD(valueSubmit.birthday) || new Date(),
      idNo: valueSubmit.idNo || "",
      issuedTo: formatDateYYYYMMDD(valueSubmit.issuedTo) || new Date(),
      issuedBy: valueSubmit.issuedBy || "",
      address: valueSubmit.address || "",
      socialInsuranceBookNo: valueSubmit.socialInsuranceBookNo || "",
      aboutMe: valueSubmit.aboutMe || "",
      teamId: valueSubmit?.team
    };

    mutationForm.mutate(valueSubmit);
  };

  const handleResetPassword = () => {
    mutationResetPassword.mutate(userDetail?.id);
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth="lg" PaperProps={{ sx: { borderRadius: "5px" } }}>
      <DialogContent sx={{ padding: 0 }}>
        <div className="w-full flex flex-col justify-center gap-3 overflow-x-hidden bg-white p-5 rounded-lg">
          <h1 className="text-center text-xl font-bold">{id ? "Update" : "New"}</h1>
          <div className="flex flex-row gap-4">
            {/* Col 1 */}
            <div className="w-[20%] flex flex-col items-center justify-start">
              <div className="w-40">
                <div className="w-full h-52 overflow-hidden group rounded-lg bg-bg_textfield">
                  <label htmlFor="file-input" className="h-full w-full inline-block">
                    <img
                      src={avatar && `${UrlConstant.REACT_APP_DATABASE_URL}${avatar}`}
                      alt="avatar"
                      className="w-full h-52 object-contain"
                    />
                  </label>
                </div>
              </div>
            </div>

            {/* Col 2 - General */}
            <div className="flex flex-row">
              <div className="flex flex-col items-start gap-4">
                <div className="flex flex-row items-start  text-xl font-bold">General</div>
                <div className="w-full flex flex-row items-start gap-4">
                  <TextInputTTD
                    form={form}
                    defaultValue={fullName}
                    name="fullName"
                    label="Name"
                    disable={disable}
                    requiredInput
                  />
                  <TextInputTTD
                    form={form}
                    defaultValue={userName}
                    disable
                    name="userName"
                    label="&nbsp;"
                    requiredInput={false}
                  />
                </div>

                <div className="w-full flex flex-row items-start gap-4">
                  <div className="w-[50%]">
                    <DropdownListSelection
                      name="gender"
                      label="Gender"
                      form={form}
                      disable={disable}
                      listSelection={listGender?.map((item) => {
                        return {
                          value: item.type,
                          label: item.type
                        };
                      })}
                    />
                  </div>
                  <div className="w-[50%]">
                    <DatePickerFormInput
                      name="birthday"
                      label={
                        <div className="ml-1">
                          <span>Date Of Birth</span>
                        </div>
                      }
                      isLimitDay={false}
                      form={form}
                      disable={disable}
                    />
                  </div>
                </div>

                <div className="w-full flex flex-row items-start gap-4">
                  <TextInputTTD
                    form={form}
                    defaultValue={address}
                    name="address"
                    label="Address"
                    requiredInput={false}
                    disable={disable}
                  />
                </div>

                <div className="flex flex-row items-start  text-xl font-bold">Advance</div>
                <div className="w-full flex flex-row items-start gap-4">
                  <TextInputTTD
                    form={form}
                    defaultValue={idNo}
                    name="idNo"
                    label="ID No"
                    disable={disable}
                    requiredInput={false}
                  />

                  <DatePickerFormInput
                    disable={disable}
                    name="issuedTo"
                    label={
                      <div className="ml-1">
                        <span>Issued On</span>
                      </div>
                    }
                    form={form}
                  />
                </div>

                <div className="w-full flex flex-row items-start gap-4">
                  <TextInputTTD
                    form={form}
                    disable={disable}
                    defaultValue={issuedBy}
                    name="issuedBy"
                    label="Issued By"
                    requiredInput={false}
                  />
                </div>

                <div className="w-full flex flex-row items-start gap-4">
                  <TextInputTTD
                    form={form}
                    disable={disable}
                    defaultValue={socialInsuranceBookNo}
                    name="socialInsuranceBookNo"
                    label="Social Insurance No"
                    requiredInput={false}
                  />
                </div>
              </div>
            </div>

            {/* Col 3 - Company */}

            <div className="flex flex-row">
              <div className="flex flex-col items-start gap-4">
                <div className="flex flex-row items-start  text-xl font-bold">Company</div>
                <div className="w-full flex flex-row items-start gap-4">
                  <div className="w-[50%]">
                    <DatePickerFormInput
                      name="dateStartWork"
                      label={
                        <div className="ml-1">
                          <span>Start Date</span>
                          <span className="text-[red] text-base ml-1">*</span>
                        </div>
                      }
                      form={form}
                      disable={disable}
                    />
                  </div>
                  <div className="w-[50%]">
                    <DropdownListSelection
                      name="team"
                      label={
                        <div className="ml-1">
                          <span>Team</span>
                          <span className="text-[red] text-base ml-1">*</span>
                        </div>
                      }
                      disable={disable}
                      form={form}
                      listSelection={newListTeam.map((team) => ({
                        label: team.code,
                        value: team.id
                      }))}
                    />
                  </div>
                </div>

                <div className="w-full flex flex-row items-start gap-4">
                  <div className="w-[50%]">
                    <TextInputTTD
                      form={form}
                      defaultValue={staffId}
                      name="staffId"
                      disable={disable}
                      label="Staff ID"
                      requiredInput
                    />
                  </div>
                  <div className="w-[50%]">
                    <DropdownListSelection
                      name="position"
                      label={
                        <div className="ml-1">
                          <span>Position</span>
                          <span className="text-[red] text-base ml-1">*</span>
                        </div>
                      }
                      form={form}
                      disable={disable}
                      listSelection={positions.map((item) => ({
                        label: item,
                        value: item
                      }))}
                    />
                  </div>
                </div>

                <div className="w-full flex flex-row items-start gap-4">
                  <div className="w-[50%]">
                    <TextInputTTD
                      form={form}
                      defaultValue={fingerId}
                      disable={disable}
                      name="fingerId"
                      label="Finger ID"
                      requiredInput
                    />
                  </div>
                  <div className="w-[50%]">
                    <DropdownListSelection
                      name="role"
                      label={
                        <div className="ml-1">
                          <span>Role & Permissions</span>
                          <span className="text-[red] text-base ml-1">*</span>
                        </div>
                      }
                      form={form}
                      disable={disable}
                      listSelection={listRole.map((role) => ({
                        label: role.name,
                        value: role.name
                      }))}
                    />
                  </div>
                </div>

                <div className="flex flex-row items-start  text-xl font-bold">Contact</div>
                <div className="w-full flex flex-row items-start gap-4">
                  <TextInputTTD
                    form={form}
                    defaultValue={email}
                    name="email"
                    label="Email"
                    disable={disable}
                    requiredInput
                  />
                  <TextInputTTD
                    form={form}
                    defaultValue={phoneNumber}
                    name="phoneNumber"
                    label="Phone Number"
                    requiredInput={false}
                    disable={disable}
                  />
                </div>

                <div className="flex flex-row items-start  text-xl font-bold">Others</div>
                <div className="w-full flex flex-row items-start gap-4">
                  <TextAreaInputField
                    disable={disable}
                    name="aboutMe"
                    label={
                      <div>
                        <span>About Me</span>
                      </div>
                    }
                    form={form}
                    minRows={2.9}
                    maxRows={5}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-row justify-end items-end">
            {!disable && (
              <Button
                variant="contained"
                size="small"
                onClick={() => handleResetPassword()}
                sx={{
                  textTransform: "capitalize",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "#BCCDEA"
                  },
                  background: "#BCCDEA",
                  color: "#001B48",
                  fontWeight: 600,
                  borderRadius: "10px",
                  width: "120px",
                  padding: "5px 0"
                }}
              >
                Reset Password
              </Button>
            )}

            {!disable && (
              <Button
                variant="contained"
                size="small"
                onClick={form.handleSubmit(handleSubmitFunc)}
                sx={{
                  textTransform: "capitalize",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "#BCCDEA"
                  },
                  background: "#BCCDEA",
                  color: "#001B48",
                  fontWeight: 600,
                  borderRadius: "10px",
                  width: "90px",
                  marginLeft: "20px",
                  padding: "5px 0"
                }}
              >
                Update
              </Button>
            )}

            <Button
              variant="contained"
              size="small"
              sx={{
                boxShadow: "none",
                "&:hover": { boxShadow: "none", background: "#F0F0F0" },
                background: "#F0F0F0",
                color: "#555555",
                fontWeight: 600,
                borderRadius: "10px",
                width: "90px",
                padding: "5px 0",
                marginLeft: "20px",
                textTransform: "none"
              }}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default EditUser;
