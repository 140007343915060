import axiosClient from "./api-config/axiosClient";

const UserAPI = {
  async uploadAvatar(params) {
    const { formData, userId } = params;

    const url = `/UserSetting/UploadAvatar/${userId}`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: formData
    });
  },

  async getDetailOtherUser(id) {
    const url = `Users/GetDetailOtherUser/${id}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getOptionStateUser() {
    const url = `/Users/GetOptionStateUser`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async changeState(bodyParams) {
    const url = `/Users/ChangeState`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: bodyParams
    });
  },

  async changePassword(bodyParams) {
    const url = `/Users/ChangePassword`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: bodyParams
    });
  },

  async updateUserSetting(settingId, bodyParams) {
    const url = `/UserSetting/${settingId}`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: bodyParams
    });
  }
};
export default UserAPI;
