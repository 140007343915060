import Delete from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Chip, IconButton } from "@mui/material";
import parse from "html-react-parser";
import { formatDateTime } from "utils/DateTimeUtils";

export default function RequestFormColumns({ setLeaveDetail, setShowDeletePopup, setIsEditRequest }) {
  const handleEditLeaveRequest = async (params) => {
    setLeaveDetail(params.row);
    setIsEditRequest(true);
  };

  const handleRequestDeleteLeaveRequest = (params) => {
    setShowDeletePopup(true);
    setLeaveDetail(params.row);
  };
  const columns = [
    {
      field: "status",
      headerName: "Status",
      width: 130,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div>
            {params.row.status === "Approve" ? (
              <Chip
                style={{
                  width: "110px",
                  background: "#C6DCC6",
                  color: "#004D00",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="Approve"
              />
            ) : params.row.status === "Pending" ? (
              <Chip
                style={{
                  width: "110px",
                  background: "#EDE5C5",
                  color: "#1E1D19",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="Pending"
              />
            ) : (
              <Chip
                style={{
                  width: "110px",
                  background: "#E3CDCD",
                  color: "#660000",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="Reject"
              />
            )}
          </div>
        );
      }
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 100,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return formatDateTime(value);
      }
    },
    {
      field: "startTime",
      headerName: "Time",
      width: 70,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 100,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return formatDateTime(value);
      }
    },
    {
      field: "endTime",
      headerName: "Time",
      width: 70,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "paidBy",
      headerName: "Paid By",
      width: 140,
      align: "left",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "timeLeave",
      headerName: "Hours",
      width: 100,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 560,
      align: "left",
      headerClassName: "header-click",
      headerAlign: "center",
      renderCell: (params) => {
        return <>{parse(params.value)}</>;
      }
    },
    {
      field: "reviewerName",
      headerName: "Reviewer",
      width: 100,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "type",
      headerName: "Type",
      width: 120,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "action",
      headerName: "",
      width: 100,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            {params.row.status === "Pending" ? (
              <>
                <IconButton onClick={() => handleEditLeaveRequest(params)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleRequestDeleteLeaveRequest(params)}>
                  <Delete />
                </IconButton>
              </>
            ) : params.row.status === "Reject" ? (
              <>
                <IconButton onClick={() => handleEditLeaveRequest(params)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleRequestDeleteLeaveRequest(params)}>
                  <Delete />
                </IconButton>
              </>
            ) : (
              <div>
                <IconButton style={{ visibility: "hidden" }}>
                  <EditIcon />
                </IconButton>
                <IconButton style={{ visibility: "hidden" }}>
                  <Delete />
                </IconButton>
              </div>
            )}
          </>
        );
      }
    }
  ];
  return columns;
}
