import { css } from "@emotion/css";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";

CustomInputField.propTypes = {};

function CustomInputField(props) {
  const { name, form, label, placeholder, rows, type, disable, multiline } = props;

  const {
    formState: { errors }
  } = form;

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-start ml-2">{label}</div>

      <Controller
        name={name}
        control={form.control}
        render={({ field: { onChange, value } }) => (
          <TextField
            className={css`
              padding: 0;
              border-radius: 8px;
              background: #dcdcdc;
              .MuiOutlinedInput-notchedOutline {
                border: none;
                outline: none;
              }
              width: 100%;
            `}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            disabled={disable}
          />
        )}
        type={type}
        size="small"
        variant="outlined"
        fullWidth={true}
        minRows={rows}
        multiline={multiline ? false : true}
      />
      <div
        style={{
          height: "14px",
          maxWidth: "180px",
          border: "none",
          outline: "none"
        }}
      >
        {
          <div
            style={{
              fontSize: "14px",
              color: "red",
              fontWeight: 400,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {errors[name] && errors[name].message}
          </div>
        }
      </div>
    </div>
  );
}

export default CustomInputField;
