import { css } from "@emotion/css";
import TextField from "@mui/material/TextField";

SearchInputField.propTypes = {};
const useStyle = {
  input__all: {
    margin: "5px 0",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& .MuiOutlinedInputNotchedOutline": {
      border: "none",
      outline: "none"
    }
  },
  input_label: {
    marginBottom: "3px",
    fontWeight: 600,
    fontSize: "15px"
  }
};
//
function SearchInputField(props) {
  const { onChange, value, placeholder, name } = props;

  return (
    <div style={useStyle.input__all}>
      <TextField
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        value={value}
        size="small"
        variant="outlined"
        className={css`
          background-color: #dcdcdc;
          height: 40px;
          .MuiOutlinedInput-notchedOutline {
            border: none;
            outline: none;
          }
          .MuiInputBase-input {
            padding-top: 9px !important;
            padding-bottom: 9px !important;
          }
        `}
        style={{ borderRadius: "8px" }}
      />
    </div>
  );
}

export default SearchInputField;
