import DatePickerComponent from "components/DateTimePicker/DatePickerComponent";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";

function DatePickerTTD(props) {
  const { form, name, label, disable, requiredInput, disablePreviousDate } = props;
  const {
    formState: { errors }
  } = form;

  return (
    <div className="flex flex-col items-start">
      <div className="flex flex-row items-start ml-2">
        {label}

        {requiredInput && <p style={{ color: "red", marginLeft: "5px" }}>*</p>}
      </div>

      <div className="flex flex-col items-start">
        <Controller
          control={form.control}
          name={name}
          InputProps={{
            style: {
              padding: "0",
              borderRadius: "8px",
              background: "#dcdcdc",
              border: "none",
              outline: "none"
            }
          }}
          render={({ field: { onChange, value } }) => (
            <DatePickerComponent
              disablePast={disablePreviousDate}
              className="date-picker"
              openTo="day"
              value={value.toDate()}
              disabled={disable}
              onChange={(date) => {
                onChange(dayjs(date));
              }}
              style={{
                minWidth: "100px !important",
                borderRadius: "8px",
                backgroundColor: "#dcdcdc",
                ".MuiOutlinedInput-notchedOutline": {
                  border: "none",
                  outline: "flexnone"
                }
              }}
            ></DatePickerComponent>
          )}
        />
        {Object.keys(errors).length !== 0 && (
          <span style={{ fontSize: "14px", color: "red", fontWeight: 400 }}>
            {errors[name] && errors[name].message}
          </span>
        )}
      </div>
    </div>
  );
}
export default DatePickerTTD;
