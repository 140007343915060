import axiosClient from "./api-config/axiosClient";

const AdminObjectAPI = {
  async GetListView() {
    const url = "/Object/GetListView";
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async create(params) {
    const url = "/Object/Create";
    return axiosClient.request({
      method: "POST",
      url: url,
      data: params
    });
  },
  async update(params) {
    const url = `/Object/Update`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: params
    });
  },
  async delete(params) {
    const url = `/Object/Delete/${params}`;
    return axiosClient.request({
      method: "DELETE",
      url: url
    });
  }
};

export default AdminObjectAPI;
