import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DropdownListSelectionFilter from "components/DropdownListSelectionFilter";
import LoadingOverLay from "components/LoadingOverlay";
import { listYears } from "components/SharedData";
import React from "react";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_CREATE_SUCCESS_STRING, TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import { showAllValueErrors } from "utils/formErrorUtil";
import useRefreshQuery from "utils/useRefreshQuery";
import CustomNoRowsOverlay from "../finger-printer/CustomNoRowsOverlay";
import DataGridColumn from "./DataGridColumn";
import DialogSwapDay from "./components/DialogSwapDay";
import useSwapDay from "./hooks/useSwapDay";

function SwapDay() {
  const [selectedYear, setSelectedYear] = React.useState(String(new Date().getFullYear()));
  const { refreshQuery } = useRefreshQuery(["swapDay", selectedYear]);
  const { swapDays, isLoading, users, mutateCreateSwapDay, mutateUpdateSwapDay, mutateDeleteSwapDay } =
    useSwapDay(selectedYear);
  const [isCreateSwapDay, setIsCreateSwapDay] = React.useState(false);
  const [isModifySwapDay, setIsModifySwapDay] = React.useState(false);
  const [selectedSwapDay, setSelectedSwapDay] = React.useState(null);

  const columns = DataGridColumn(
    users?.data,
    () => {
      setIsModifySwapDay(true);
    },
    (id) => {
      mutateDeleteSwapDay(id, {
        onSuccess: () => {
          refreshQuery();
          ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
        },
        onError: () => {
          ToastShowError(TOAST_ERROR_STRING);
        }
      });
    }
  );

  const handleModifySwapDay = (swapDay) => {
    mutateUpdateSwapDay(swapDay, {
      onSuccess: () => {
        refreshQuery();
        ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
        setIsModifySwapDay(false);
      },
      onError: (error) => {
        ToastShowError(showAllValueErrors(error.response.data.errors) || TOAST_ERROR_STRING);
      }
    });
  };

  const handleCreateSwapDay = (swapDay) => {
    mutateCreateSwapDay(swapDay, {
      onSuccess: () => {
        refreshQuery();
        ToastShowSuccess(TOAST_CREATE_SUCCESS_STRING);
        setIsCreateSwapDay(false);
      },
      onError: (error) => {
        ToastShowError(showAllValueErrors(error.response.data.errors) || TOAST_ERROR_STRING);
      }
    });
  };

  return (
    <div className="flex flex-col rounded-[10px] bg-white h-full w-full p-5">
      <div className="items-center justify-between flex ">
        <div className="flex gap-[10px] items-center justify-center mb-[10px]">
          <span className="text-base text-center">Year</span>
          <DropdownListSelectionFilter
            listSelection={listYears?.map((item) => ({
              label: item.year,
              value: item.year
            }))}
            selected={selectedYear}
            setSelected={setSelectedYear}
          />
        </div>

        <IconButton
          variant="contained"
          onClick={() => {
            setIsCreateSwapDay(true);
          }}
        >
          <AddCircleIcon />
        </IconButton>
      </div>

      <Box
        className="flex-grow"
        sx={{
          height: "100%",
          width: "100%"
        }}
      >
        <DataGrid
          rowHeight={40}
          sx={{
            border: "none",
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
              fontSize: "16px"
            },
            ".MuiDataGrid-footerContainer": { borderTop: "none" },
            height: "100%",
            minHeight: "100px"
          }}
          rows={
            swapDays?.data.map((day, index) => {
              return { ...day, no: index + 1 };
            }) || []
          }
          onCellClick={(cell) => {
            setSelectedSwapDay(cell.row);
          }}
          columns={columns}
          disableColumnMenu={true}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableColumnSort={true}
          disableRowSelectionOnClick={true}
          autoPageSize
          slots={{
            loadingOverlay: LoadingOverLay,
            noRowsOverlay: CustomNoRowsOverlay
          }}
          loading={isLoading}
        />
      </Box>

      <DialogSwapDay
        isOpen={isModifySwapDay}
        handleClose={() => setIsModifySwapDay(false)}
        swapDay={selectedSwapDay}
        usersInfos={users?.data}
        handleOk={handleModifySwapDay}
      ></DialogSwapDay>

      <DialogSwapDay
        isOpen={isCreateSwapDay}
        handleClose={() => setIsCreateSwapDay(false)}
        usersInfos={users?.data}
        handleOk={handleCreateSwapDay}
      ></DialogSwapDay>
    </div>
  );
}

export default SwapDay;
