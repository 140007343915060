export const formatHHmm = "HH:mm";
export const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

export const disabledRangeTime = () => {
  return {
    disabledHours: () => range(0, 60).splice(9, 16)
  };
};
