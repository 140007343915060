import { TextField } from "@mui/material";

export default function InputComponent({ id, onChange, disabled, value, fullWidth = false, ...props }) {
  return (
    <>
      <TextField
        fullWidth={fullWidth}
        color="primary"
        variant="outlined"
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        sx={{
          outline: "none",
          borderRadius: "10px",
          border: "none",
          "& .MuiInputBase-input": {
            padding: "10px !important",
            fontSize: "14px",
            lineHeight: "14px",
            borderRadius: "10px"
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none"
          }
        }}
        {...props}
      />
    </>
  );
}
