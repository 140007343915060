import axiosClient from "./api-config/axiosClient";

const ProjectAPI = {
  // getCategory: (params) => {
  //   const url = "/TimesheetCategory";
  //   return axiosClient.get(url);
  // },

  // getObject: (params) => {
  //   const url = "/TimesheetObject";
  //   return axiosClient.get(url);
  // },

  // async GetClients(params) {
  //   const url = "/TimesheetProject/GetClients";
  //   return axiosClient.request({
  //     method: "GET",
  //     url: url,
  //   });
  // },

  // async GetProjectList(params) {
  //   const url = "/TimesheetProject/GetProjectList";
  //   return axiosClient.request({
  //     method: "GET",
  //     url: url,
  //   });
  // },

  // async GetInitProjectCode(params) {
  //   const url = "/TimesheetProject/GetInitProjectCode";
  //   return axiosClient.request({
  //     method: "GET",
  //     url: url,
  //   });
  // },

  // getObject: (params) => {
  //   const url = "/TimesheetObject";
  //   return axiosClient.get(url);
  // },

  // async getProjectByUser(params) {
  //   const teamId = JSON.parse(localStorage.getItem("user")).teamId;

  //   const url = `/TimesheetProject/GetAllTimesheetProjectsByTeamId/${teamId}`;
  //   return axiosClient.request({
  //     method: "GET",
  //     url: url,
  //   });
  // },

  // ============
  async GetOptionWorking() {
    const url = "/Project/GetOptionWorking";
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async GetDetail({ id, view }) {
    const url = `/Project/GetDetail/${id}/${view}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async Create(params) {
    const url = "/Project/Create";
    return axiosClient.request({
      method: "POST",
      url: url,
      data: params
    });
  },

  async update(params) {
    const url = `/Project/Update`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: params
    });
  },

  async delete(params) {
    const url = `/Project/Delete/${params}`;
    return axiosClient.request({
      method: "DELETE",
      url: url
    });
  }
};
export default ProjectAPI;
