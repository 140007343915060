import { Button } from "@mui/base/Button";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import BadgeAvatars from "components/MemberItemStatus/avatar";
import { getColor } from "components/SelectionField";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUserIdSelected,
  getListTimesheetsByMonthAndYear,
  getTimesheetCategories,
  getTimesheetDetail,
  getTimesheetObjects
} from "store/timesheetSlice";
import { checkIsAdmin } from "utils/accessUltils";
import UrlConstant from "utils/constants/UrlConstant";

const InputWrapper = styled("div")(
  ({ theme }) => `
  width: 300px;
  border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  border-radius: 10px;
  padding: 1px;
  display: flex;
  justify-content:flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 15px;

  &:hover {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
  }

  &.focused {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"};
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

const Listbox = styled("ul")(
  ({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 100;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};


    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

const StyledPopupIndicator = styled(Button)(
  ({ theme }) => `
    outline: 0;
    box-shadow: none;
    border: 0;
    border-radius: 4px;
    background-color: transparent;
    align-self: center;
    padding: 0 2px;

    &:hover {
      background-color: ${theme.palette.mode === "dark" ? "#434D5B" : "#DAECFF"};
      cursor: pointer;
    }

    & > svg {
      transform: translateY(2px);
    }

    &.popupOpen > svg {
      transform: translateY(2px) rotate(180deg);
    }
  `
);

export default function UserSelect() {
  const dispatch = useDispatch();
  const listAllUsers = useSelector((state) => state.common.listAllUsers);

  const menuAccess = useSelector((state) => state.common.menuAccess);

  const userIdSelected = useSelector((state) => state.timesheet.userIdSelected);
  const daySelected = useSelector((state) => state.timesheet.daySelected);

  const user =
    listAllUsers?.find((user) => {
      return user.id === userIdSelected;
    }) || listAllUsers[0];

  const {
    getRootProps,
    getListboxProps,
    getInputProps,
    getOptionProps,
    getPopupIndicatorProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
    popupOpen
  } = useAutocomplete({
    id: "customized-hook-demo",
    defaultValue: user,
    options: listAllUsers,
    disableClearable: true,
    isOptionEqualToValue: (option, value) => {
      return option.id === value.id;
    },
    getOptionLabel: (option) => option?.name,
    getOptionSelected: (option, value) => option.id === value.id
  });

  useEffect(() => {
    if (value !== null) {
      dispatch(changeUserIdSelected(value?.id));

      let timeParams = {
        userId: value?.id,
        month: dayjs(daySelected).month() + 1,
        year: dayjs(daySelected).year(),
        day: dayjs(daySelected).date(),
        isAdmin: checkIsAdmin(menuAccess)
      };
      dispatch(getListTimesheetsByMonthAndYear(timeParams));

      dispatch(getTimesheetDetail(timeParams));
      dispatch(getTimesheetCategories());
      dispatch(getTimesheetObjects());
    }
  }, [value, dispatch]);

  return (
    <div>
      <div {...getRootProps()}>
        <InputWrapper ref={setAnchorEl} className={focused ? "focused" : ""}>
          <BadgeAvatars
            fullName={value?.name.split(" ").pop()}
            avatarPath={`${UrlConstant.REACT_APP_DATABASE_URL}${user?.avatar}`}
            stateColor={getColor(user?.state)}
          />
          <input {...getInputProps()} />

          <StyledPopupIndicator {...getPopupIndicatorProps()} className={popupOpen ? "popupOpen" : undefined}>
            <KeyboardArrowDownIcon />
          </StyledPopupIndicator>
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li
              {...getOptionProps({ option, index })}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
              }}
              key={index}
            >
              <div className="pr-2">
                <BadgeAvatars
                  fullName={option?.name.split(" ").pop()}
                  avatarPath={`${UrlConstant.REACT_APP_DATABASE_URL}${option?.avatar}`}
                  stateColor={getColor(option?.state)}
                />
              </div>

              <span>{option?.name}</span>
            </li>
          ))}
        </Listbox>
      ) : null}
    </div>
  );
}
