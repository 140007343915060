/* eslint-disable @typescript-eslint/no-empty-function */
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Chip, IconButton } from "@mui/material";

function WFHColumn(setSelectedWFH, handleDeleteWfh) {
  const columns = [
    {
      field: "no",
      headerName: "STT",
      width: 60,
      align: "center",
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div>
            {params.row.status === "Approve" ? (
              <Chip
                style={{
                  width: "110px",
                  background: "#C6DCC6",
                  color: "#004D00",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="Approve"
              />
            ) : params.row.status === "Pending" ? (
              <Chip
                style={{
                  width: "110px",
                  background: "#EDE5C5",
                  color: "#1E1D19",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="Pending"
              />
            ) : (
              <Chip
                style={{
                  width: "110px",
                  background: "#E3CDCD",
                  color: "#660000",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="Reject"
              />
            )}
          </div>
        );
      }
    },
    {
      field: "startTime",
      headerName: "Start Date",
      width: 100,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "endTime",
      headerName: "End Date",
      width: 100,
      align: "center",
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "reason",
      headerName: "Description",
      flex: 1,
      minWidth: 820,
      align: "left",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "reviewerName",
      headerName: "Reviewer",
      width: 100,
      align: "left",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "action",
      headerName: "",
      width: 100,
      align: "center",
      sortable: false,
      headerClassName: "header",
      headerAlign: "center",
      renderCell: (params) => {
        if (params.row.status === "Pending" || params.row.status === "Reject") {
          return (
            <div className="request-reject__action">
              <IconButton onClick={() => setSelectedWFH(params.row)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDeleteWfh(params.row)}>
                <DeleteIcon />
              </IconButton>
            </div>
          );
        }
        return <></>;
      }
    }
  ];
  return columns;
}

export default WFHColumn;
