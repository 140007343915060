import { Avatar, Chip } from "@mui/material";
import Parser from "html-react-parser";
import moment from "moment";
import style from "./requestDetail.module.scss";

const RequestDetail = (props) => {
  const { dataRow } = props;
  const { details } = dataRow || {};

  return (
    <>
      {dataRow && (
        <div className="p-[20px] flex flex-col gap-2.5">
          <div className="w-full text-2xl font-bold py-2 text-center border-b border-black">Request Detail</div>

          <div className="label flex justify-between items-center pt-2.5 ">
            <div>
              {dataRow?.status === "Approve" ? (
                <Chip
                  style={{
                    width: "110px",
                    background: "#C6DCC6",
                    color: "#004D00",
                    fontWeight: 600,
                    borderRadius: "10px"
                  }}
                  label="Approve"
                />
              ) : dataRow?.status === "Calculate" ? (
                <Chip
                  style={{
                    width: "110px",
                    background: "#C6DCC6",
                    color: "#004D00",
                    fontWeight: 600,
                    borderRadius: "10px"
                  }}
                  label="Calculated"
                />
              ) : dataRow?.status === "Pending" ? (
                <Chip
                  style={{
                    width: "110px",
                    background: "#EDE5C5",
                    color: "#1E1D19",
                    fontWeight: 600,
                    borderRadius: "10px"
                  }}
                  label="Pending"
                />
              ) : dataRow?.status === "Reject" ? (
                <Chip
                  style={{
                    width: "110px",
                    background: "#E3CDCD",
                    color: "#660000",
                    fontWeight: 600,
                    borderRadius: "10px"
                  }}
                  label="Reject"
                />
              ) : (
                <Chip
                  style={{
                    width: "110px",
                    background: "#C6DCC6",
                    color: "#004D00",
                    fontWeight: 600
                  }}
                  label="Calculate"
                />
              )}
            </div>
          </div>

          <div className="pb-[20px] grid grid-cols-3 grid-rows-2 gap-x-5 gap-y-2.5 border-b border-black/opacity-20">
            <div className="flex flex-col">
              <span className="font-semibold">Date</span>
              <span>{moment(dataRow?.date).format("YYYY/MM/DD")}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-semibold">Start Time</span>
              <span>{dataRow?.start}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-semibold">End Time</span>
              <span>{dataRow?.end}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-semibold">Hours</span>
              <span>{dataRow?.hourStr}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-semibold">Actual</span>
              <span>{dataRow?.actualHourStr}</span>
            </div>
          </div>

          <div className="pb-[20px] grid grid-cols-3 grid-rows-2 gap-x-5 gap-y-2.5 border-b border-black/opacity-20">
            <div className="flex flex-col col-start-1 col-end-4">
              <span className="font-semibold">Project</span>
              <span>{dataRow?.projectName}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-semibold">Category</span>
              <span>{dataRow?.categoryName}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-semibold">Object</span>
              <span>{dataRow?.objectName}</span>
            </div>

            <div className="flex flex-col col-start-1 col-end-4">
              <span className="font-semibold">Description</span>
              <span>{Parser(dataRow?.reason)}</span>
            </div>
          </div>

          <div className="pb-[20px] grid grid-cols-3 gap-x-5 gap-y-2.5 border-b border-black/opacity-20">
            <div className="flex flex-col">
              <span className="font-semibold">Type</span>
            </div>
            <div className="flex flex-col">
              <span className="font-semibold">Hour</span>
            </div>
            <div className="flex flex-col">
              <span className="font-semibold">Paid</span>
            </div>

            {details &&
              details.length > 0 &&
              details.map((item) => (
                <>
                  <div className="flex flex-col">
                    <span>{item?.type}</span>
                  </div>
                  <div className="flex flex-col">
                    <span>{item?.actualHourStr}</span>
                  </div>
                  <div className="flex flex-col">
                    <span>{item?.paid}</span>
                  </div>
                </>
              ))}
          </div>

          <div className="grid grid-cols-2 grid-rows-2 gap-x-5 gap-y-2.5 ">
            <div className="flex flex-col col-start-1 col-end-4">
              <span className="font-semibold">Reviewed By</span>
              <div className="flex flex-row items-center gap-2">
                <Avatar className={style.avatar} />
                <span>{dataRow?.reviewerName}</span>
              </div>
            </div>
            <div className="flex flex-col col-start-1 col-end-4">
              <span className="font-semibold">Requested By</span>
              <div className="flex flex-row items-center gap-2">
                <Avatar className={style.avatar} />
                <span>{dataRow?.createdName}</span>
              </div>
            </div>

            <div className="flex flex-col">
              <span className="font-semibold">Created Date</span>
              <span> {moment(dataRow?.createdDate).format("YYYY/MM/DD")}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-semibold">Modified Date</span>
              <span> {moment(dataRow?.modifiedDate).format("YYYY/MM/DD")}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RequestDetail;
