import AddCircleIcon from "@mui/icons-material/AddCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Chip, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@mui/x-data-grid";
import { useMutation, useQueries } from "@tanstack/react-query";
import RoleAPI from "api/RolesAPI";
import DeletePopup from "components/DeletePopUp";
import LoadingOverLay from "components/LoadingOverlay";
import CustomNoRowsOverlay from "pages/admin-timesheet/finger-printer/CustomNoRowsOverlay";
import * as React from "react";
import { useSelector } from "react-redux";
import { formatDateTime } from "utils/DateTimeUtils";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import { showAllValueErrors } from "utils/formErrorUtil";
import useRefreshQuery from "utils/useRefreshQuery";
import RoleModal from "./components/modal";

export default function RoleAndPermission() {
  const menuAccess = useSelector((state) => state.common.menuAccess);
  const adminMenu = menuAccess?.admin || [];
  const userPagePermission = adminMenu?.user;
  const tabRolePermission = userPagePermission?.tabRole;

  const [dataRow, setDataRow] = React.useState();
  const [dataNewRole, setDataNewRole] = React.useState();

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isNewRole, setIsNewRole] = React.useState(false);
  const [showDeletePopup, setShowDeletePopup] = React.useState(false);

  const { refreshQuery: refreshQueryListViewRole } = useRefreshQuery(["listViewRoleQuery"]);
  const { refreshQuery: refreshQueryListOptionRole } = useRefreshQuery(["listOptionRoleQuery"]);

  const [listViewRoleQuery, listOptionRoleQuery, listCollectionRoleQuery] = useQueries({
    queries: [
      {
        queryKey: ["listViewRoleQuery"],
        queryFn: async () => {
          const response = await RoleAPI.getListView();
          const formatData = response?.data?.map((item, index) => ({
            ...item,
            stt: index + 1
          }));
          return formatData;
        }
      },
      {
        queryKey: ["listOptionRoleQuery"],
        queryFn: async () => {
          const response = await RoleAPI.getOption();
          return response.data;
        }
      },
      {
        queryKey: ["listCollectionRoleQuery"],
        queryFn: async () => {
          const response = await RoleAPI.getCollectionRole();
          return response.data;
        }
      }
    ]
  });

  const { data: listViewRole, isLoading } = listViewRoleQuery;
  const { data: listOptionRole } = listOptionRoleQuery;
  const { data: listCollectionRole } = listCollectionRoleQuery;

  const mutationDeleteRole = useMutation({
    mutationFn: (value) => {
      return RoleAPI.delete(value);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        const errorData = error.response.data.errors;
        ToastShowError(showAllValueErrors(errorData));
      } else ToastShowError(TOAST_ERROR_STRING);
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      setShowDeletePopup(false);
      refreshQueryListViewRole();
    }
  });

  const handleNewRole = () => {
    setIsNewRole(true);
    setIsModalOpen(true);
    setDataRow(null);
    setDataNewRole(listCollectionRole);
  };

  const handleEditRoleAndPermission = (params) => {
    setIsModalOpen(true);
    setDataRow(params);
    setIsNewRole(false);
  };

  const handleRequestDeleteRole = (params) => {
    setShowDeletePopup(true);
    setDataRow(params);
  };

  const handleDeleteRole = async () => {
    mutationDeleteRole.mutate(dataRow.id);
  };

  const columns = [
    {
      field: "stt",
      headerName: "STT",
      width: 60,
      align: "center",
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "name",
      headerName: "Name",
      width: 969,
      align: "left",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div>
            {params.row.type === "Default" ? (
              <Chip
                style={{
                  width: "90px",
                  background: "#E3CDCD",
                  color: "#660000",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="Default"
              />
            ) : (
              <Chip
                style={{
                  width: "90px",
                  background: "#C6DCC6",
                  color: "#004D00",
                  fontWeight: 600,
                  borderRadius: "10px"
                }}
                label="Custom"
              />
            )}
          </div>
        );
      }
    },
    {
      field: "modifierName",
      headerName: "Modified By",
      width: 150,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      width: 150,
      align: "center",
      headerClassName: "header-click",
      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return formatDateTime(value);
      }
    },
    {
      field: "action",
      headerName: "",
      flex: 1,
      // width: 100,
      align: "left",
      sortable: "false",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <div className="table__action-btn">
            {params.row.type === "Default" ? (
              <>
                <IconButton onClick={() => handleEditRoleAndPermission(params.row)}>
                  <AssignmentIcon />
                </IconButton>
              </>
            ) : (
              <>
                {tabRolePermission?.isUpdate && (
                  <IconButton onClick={() => handleEditRoleAndPermission(params.row)}>
                    <EditIcon />
                  </IconButton>
                )}

                {tabRolePermission?.isDelete && (
                  <IconButton onClick={() => handleRequestDeleteRole(params.row)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </>
            )}
          </div>
        );
      }
    }
  ];

  return (
    <div className="flex flex-col bg-white rounded-lg p-5 gap-1.5 h-full">
      <div className="flex justify-end items-center">
        {tabRolePermission?.isCreate && (
          <Tooltip title="Add New Role">
            <IconButton variant="contained" onClick={handleNewRole}>
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>

      <Box
        className="flex-grow"
        sx={{
          height: "100%",
          width: "100%",
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "15px",
            fontWeight: 600
          }
        }}
      >
        <DataGrid
          rowHeight={40}
          sx={{
            border: "none",
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
              fontSize: "16px"
            },
            minHeight: "100px",
            ".MuiDataGrid-footerContainer": { borderTop: "none" },
            "--DataGrid-overlayHeight": "calc(100vh - 200px)"
          }}
          rows={listViewRole ?? []}
          columns={columns}
          getRowId={(row) => row?.id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 14
              }
            }
          }}
          pageSizeOptions={[14]}
          slots={{
            loadingOverlay: LoadingOverLay,
            noRowsOverlay: CustomNoRowsOverlay
          }}
          isLoading={isLoading}
          hideFooterSelectedRowCount
          disableColumnMenu={true}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableColumnSort={true}
          disableRowSelectionOnClick={true}
        />
      </Box>

      {isNewRole && (
        <RoleModal
          isNewRole={isNewRole}
          open={isModalOpen}
          disabled={false}
          onClose={setIsModalOpen}
          roleInfo={dataRow}
          refreshQueryListViewRole={refreshQueryListViewRole}
          refreshQueryListOptionRole={refreshQueryListOptionRole}
          setRoleInfo={setDataRow}
          listOptionRole={listOptionRole}
          dataNewRole={dataNewRole}
          setDataNewRole={setDataNewRole}
          listCollectionRole={listCollectionRole}
        />
      )}

      {!isNewRole && dataRow && (
        <RoleModal
          isNewRole={isNewRole}
          open={isModalOpen}
          onClose={setIsModalOpen}
          roleInfo={dataRow}
          refreshQueryListViewRole={refreshQueryListViewRole}
          refreshQueryListOptionRole={refreshQueryListOptionRole}
          setRoleInfo={setDataRow}
          listOptionRole={listOptionRole}
          dataNewRole={dataRow}
          setDataNewRole={setDataNewRole}
          listCollectionRole={listCollectionRole}
        />
      )}

      <DeletePopup
        open={showDeletePopup}
        onClose={() => setShowDeletePopup(false)}
        handleDelete={handleDeleteRole}
        title="Bạn có chắc chắn xóa Role ?"
      />
    </div>
  );
}
