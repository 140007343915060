import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@mui/x-data-grid";
import { useMutation, useQueries } from "@tanstack/react-query";
import LeaveFormAPI from "api/LeaveFormAPI";
import DeletePopup from "components/DeletePopUp";
import DropdownListSelectionFilter from "components/DropdownListSelectionFilter";
import LoadingOverLay from "components/LoadingOverlay";
import { listStatus, listYears } from "components/SharedData";
import { useMemo, useState } from "react";
import { showAllValueErrors } from "utils/formErrorUtil";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import useRefreshQuery from "utils/useRefreshQuery";
import EditFormRequest from "./components/EditFormRequest";
import RequestFormColumns from "./components/FormColumns";
import NewFormRequest from "./components/NewFormRequest";

export default function Requests() {
  const [isEditRequest, setIsEditRequest] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [leaveDetail, setLeaveDetail] = useState(null);

  const [isNewRequest, setIsNewRequest] = useState(false);
  const [selectedYear, setSelectedYear] = useState(String(new Date().getFullYear()));
  const [selectedStatus, setSelectedStatus] = useState("All");
  const { refreshQuery: refreshQueryObject } = useRefreshQuery(["listViewLeaveFormQuery"]);
  const columns = RequestFormColumns({ setLeaveDetail, setShowDeletePopup, setIsEditRequest });

  const [{ data: listViewLeaveForm, isLoading }, { data: stateRemainLeaveForm }] = useQueries({
    queries: [
      {
        queryKey: ["listViewLeaveFormQuery", selectedYear],
        queryFn: async () => {
          const response = await LeaveFormAPI.getRequestListView(selectedYear);
          return response.data;
        }
      },
      {
        queryKey: ["stateRemainLeaveFormQuery"],
        queryFn: async () => {
          const response = await LeaveFormAPI.getStateRemainLeave();
          return response.data;
        }
      }
    ]
  });

  const handleCreateNewLeaveRequest = () => {
    setIsNewRequest(true);
  };

  const handleDelete = async () => {
    mutationDelete.mutate(leaveDetail.id);
  };

  const mutationDelete = useMutation({
    mutationFn: (value) => {
      return LeaveFormAPI.deleteLeave(value);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        const errorData = error.response.data.errors;
        ToastShowError(showAllValueErrors(errorData));
      } else ToastShowError(TOAST_ERROR_STRING);
      setTimeout(() => {
        refreshQueryObject();
      }, "3000");
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      setShowDeletePopup(false);
      refreshQueryObject();
    }
  });

  const filteredLeave = useMemo(() => {
    return listViewLeaveForm?.filter((leave) => {
      const yearFilter = leave.startDate.substring(0, 4) === selectedYear;
      const statusFilter =
        (selectedStatus === "All" && listViewLeaveForm) ||
        (selectedStatus === "Pending" && leave.status === selectedStatus) ||
        (selectedStatus === "Approve" && leave.status === selectedStatus) ||
        (selectedStatus === "Reject" && leave.status === selectedStatus);
      return yearFilter && statusFilter;
    });
  }, [selectedYear, listViewLeaveForm, selectedStatus]);

  return (
    <div className="flex flex-col w-full h-full gap-2.5">
      <div className="flex flex-row w-full font-semibold leading-none gap-2.5">
        <div className="flex flex-col w-[220px] h-[70px] gap-2.5  bg-white justify-between rounded-[10px] px-[20px] py-[10px]">
          <p>REMAIN ANNUAL LEAVE</p>
          <p className="text-base">{`${stateRemainLeaveForm?.annual ?? 0} (days)`}</p>
        </div>

        <div className="flex flex-col w-[230px] h-[70px] gap-2.5   bg-white justify-between rounded-[10px] px-[20px] py-[10px]">
          <p>REMAIN SUMMER LEAVE</p>
          <p className="text-base">{`${stateRemainLeaveForm?.summer ?? 0} (days)`}</p>
        </div>

        <div className="flex flex-col w-[290px] h-[70px] gap-2.5  bg-white justify-between rounded-[10px] px-[20px] py-[10px]">
          <p>REMAIN COMPENSATORY LEAVE</p>
          <p className="text-base">{`${stateRemainLeaveForm?.compensatory ?? 0} (hours)`}</p>
        </div>
      </div>

      <div className="flex flex-col h-screen w-full overflow-hidden flex-1 bg-white p-5 rounded-[10px]">
        <div className="flex gap-4 items-center justify-between">
          <div className="flex justify-between gap-2.5">
            <div className="flex items-center">
              <span className="mr-2.5">Year</span>

              <DropdownListSelectionFilter
                listSelection={listYears?.map((item) => ({
                  label: item.year,
                  value: item.year
                }))}
                selected={selectedYear}
                setSelected={setSelectedYear}
              />
            </div>

            <div className="flex items-center">
              <span className="mr-2.5">Status</span>

              <DropdownListSelectionFilter
                listSelection={listStatus?.map((item) => ({
                  label: item.status,
                  value: item.status
                }))}
                selected={selectedStatus}
                setSelected={setSelectedStatus}
              />
            </div>
          </div>

          <Tooltip title="Add request">
            <IconButton variant="contained" onClick={handleCreateNewLeaveRequest}>
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </div>

        <Box
          className="flex-grow"
          sx={{
            height: "100%",
            width: "100%",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: "15px",
              fontWeight: 600
            }
          }}
        >
          <DataGrid
            rowHeight={40}
            sx={{
              height: "100%",
              border: "none",
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: "600",
                fontSize: "16px"
              },
              ".MuiDataGrid-footerContainer": { borderTop: "none" },
              minHeight: "100px",
              "--DataGrid-overlayHeight": "300px"
            }}
            rows={filteredLeave ?? []}
            columns={columns}
            getRowId={(row) => row?.id}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 13
                }
              }
            }}
            isLoading={isLoading}
            pageSizeOptions={[13]}
            slots={{
              loadingOverlay: LoadingOverLay
            }}
            hideFooterSelectedRowCount
            disableColumnMenu={true}
            disableColumnFilter={true}
            disableColumnSelector={true}
            disableColumnSort={true}
            disableRowSelectionOnClick={true}
          />
        </Box>

        {/* <div className="flex flex-col h-screen">
          <div className="bg-blue-500">Div 1</div>
          <div className="bg-red-500 flex-grow">Div 2</div>
        </div> */}
      </div>

      {isNewRequest && (
        <NewFormRequest
          onClose={() => setIsNewRequest(false)}
          open={isNewRequest}
          refreshQueryObject={refreshQueryObject}
        />
      )}

      {isEditRequest && leaveDetail && (
        <EditFormRequest
          onClose={() => {
            setLeaveDetail(null);
            setIsEditRequest(false);
          }}
          open={!!leaveDetail}
          leaveDetail={leaveDetail}
          refreshQueryObject={refreshQueryObject}
        />
      )}

      <DeletePopup
        open={showDeletePopup}
        onClose={() => setShowDeletePopup(false)}
        handleDelete={handleDelete}
        title="Bạn có chắc chắn xóa ?"
      />
    </div>
  );
}
