import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Dialog, DialogContent } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import LeaveFormAPI from "api/LeaveFormAPI";
import CustomInputField from "components/CustomInputField";
import DatePickerFormInput from "components/DateTimePicker/DatePickerFormInput";
import DropdownListSelection from "components/DropdownListSelection";
import { listLeaveType } from "components/SharedData";
import Editor from "components/TextEditor";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { formatDateYYYYMMDD } from "utils/DateTimeUtils";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_CREATE_SUCCESS_STRING, TOAST_ERROR_STRING } from "utils/ToastString";
import { showToastErrorCustom } from "utils/constants/ShowToastErrorCustom";
import { setReactHookFormErrors } from "utils/formErrorUtil";
import * as yup from "yup";
import styles from "./styles.module.scss";

function NewFormRequest({ disable, onClose, open, refreshQueryObject }) {
  const timeRegExp = /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;

  const schema = yup.object().shape({
    startDate: yup.string().required("Yêu cầu nhập"),
    endDate: yup.string().required("Yêu cầu nhập"),
    startTime: yup.string().required("Yêu cầu nhập").matches(timeRegExp, "thời gian không hợp lệ"),
    endTime: yup.string().required("Yêu cầu nhập").matches(timeRegExp, "thời gian không hợp lệ"),
    workDecription: yup.string().required("Work Description không được bỏ trống")
  });

  const form = useForm({
    defaultValues: {
      type: "Unpaid",
      startDate: new Date(dayjs().add(1, "day").toDate()),
      endDate: new Date(dayjs().add(1, "day").toDate()),
      startTime: "08:00",
      endTime: "17:00",
      workDecription: ""
    },
    resolver: yupResolver(schema)
  });

  const mutation = useMutation({
    mutationFn: (value) => {
      return LeaveFormAPI.createLeave(value);
    },
    onError: (error) => {
      const errorData = error.response.data.errors;
      if (error.response.status === 400) {
        if (errorData) {
          setReactHookFormErrors(errorData, form.setError) || showToastErrorCustom(errorData);
        } else ToastShowError(error.response.data);
      } else ToastShowError(TOAST_ERROR_STRING);
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_CREATE_SUCCESS_STRING);
      onClose();
      refreshQueryObject();
    }
  });

  const handleSubmit = async () => {
    const valueForm = form.getValues();

    let valueSubmit = {
      ...valueForm,
      type: valueForm?.type,
      startDate: formatDateYYYYMMDD(valueForm?.startDate) || new Date(),
      endDate: formatDateYYYYMMDD(valueForm?.endDate) || new Date(),
      Reason: valueForm?.workDecription
    };

    mutation.mutate(valueSubmit);
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth="lg" PaperProps={{ sx: { borderRadius: "5px" } }}>
      <DialogContent style={{ padding: "20px" }}>
        <div className={styles.leave__container}>
          <div className={styles.leave__title}>
            <h3>New</h3>
          </div>
          <div className={styles.leave__type}>
            <DropdownListSelection
              name="type"
              label={
                <div className={styles.leave__form_label}>
                  <span className={styles.leave__label}>Type</span>
                  <span className={styles.leave__required_mark}>*</span>
                </div>
              }
              disable={disable}
              form={form}
              listSelection={listLeaveType?.map((item) => {
                return {
                  value: item.type,
                  label: item.name
                };
              })}
            />
          </div>
          <div className={styles.leave__start_date_time}>
            <DatePickerFormInput
              name="startDate"
              label={
                <div className={styles.leave__form_label}>
                  <span className={styles.leave__label}>Start Date</span>
                  <span className={styles.leave__required_mark}>*</span>
                </div>
              }
              form={form}
              disable={disable}
            />
            <div className="pt-[2px]">
              <CustomInputField
                name="startTime"
                label={
                  <div className={styles.leave__form_label}>
                    <span className={styles.leave__label}>Start Time</span>
                    <span className={styles.leave__required_mark}>*</span>
                  </div>
                }
                disable={disable}
                form={form}
              />
            </div>
          </div>
          <div className={styles.leave__end_date_time}>
            <DatePickerFormInput
              name="endDate"
              label={
                <div className={styles.leave__form_label}>
                  <span className={styles.leave__label}>End Date</span>
                  <span className={styles.leave__required_mark}>*</span>
                </div>
              }
              form={form}
              disable={disable}
            />
            <div className="pt-[2px]">
              <CustomInputField
                name="endTime"
                label={
                  <div className={styles.leave__form_label}>
                    <span className={styles.leave__label}>End Time</span>
                    <span className={styles.leave__required_mark}>*</span>
                  </div>
                }
                disable={disable}
                form={form}
              />
            </div>
          </div>
          <div className={styles.leave__work_description}>
            <div className="flex flex-row items-start ml-2">
              Work Description
              <p style={{ color: "red", marginLeft: "5px" }}>*</p>
            </div>
            <Editor name="workDecription" form={form} />
          </div>

          <div className={styles.leave__btn}>
            <div className={styles.leave__btn_ok}>
              <Button
                variant="contained"
                size="small"
                onClick={form.handleSubmit(handleSubmit)}
                sx={{
                  textTransform: "uppercase",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "#BCCDEA"
                  },
                  background: "#BCCDEA",
                  color: "#001B48",
                  fontWeight: 600,
                  borderRadius: "10px",
                  width: "90px",
                  padding: "5px 0"
                }}
              >
                OK
              </Button>
            </div>
            <div className={styles.leave__btn_cancel}>
              <Button
                variant="contained"
                onClick={() => {
                  onClose();
                }}
                size="small"
                sx={{
                  textTransform: "capitalize",
                  boxShadow: "none",
                  "&:hover": { boxShadow: "none", background: "#F0F0F0" },
                  background: "#F0F0F0",
                  color: "#555555",
                  fontWeight: 600,
                  borderRadius: "10px",
                  width: "90px",
                  padding: "5px 0",
                  marginLeft: "20px"
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default NewFormRequest;
