import { useMutation, useQuery } from "@tanstack/react-query";

import WfhAPI from "api/WfhAPI";

function useUserWFH(year) {
  const { data: listViewWfh, isLoading } = useQuery({
    queryKey: ["wfhQuery", year],
    queryFn: async () => {
      const response = await WfhAPI.getRequestListView(year);
      return response?.data;
    }
  });

  const { mutate: mutateCreateWFH } = useMutation({
    mutationFn: (swapDay) => WfhAPI.createWfh(swapDay)
  });

  const { mutate: mutateUpdateWFH } = useMutation({
    mutationFn: (swapDay) => WfhAPI.updateWfh(swapDay)
  });

  const { mutate: mutateDeleteWFH } = useMutation({
    mutationFn: (id) => WfhAPI.deleteWfh(id)
  });

  return { listViewWfh, isLoading, mutateCreateWFH, mutateUpdateWFH, mutateDeleteWFH };
}

export default useUserWFH;
