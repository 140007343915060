const userState = [
  {
    color: "#59A14F",
    state: "Available"
  },
  {
    color: "#E8C023",
    state: "WFH"
  },
  {
    color: "#F02525",
    state: "Business"
  },
  {
    color: "#8E0000",
    state: "Busy"
  },
  {
    color: "#4F4F4F",
    state: "Unavailable"
  }
];
export default userState;
