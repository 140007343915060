import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";

function TagCalendar(props) {
  const { color, time, title } = props?.taskInfo ? props.taskInfo : {};

  const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    () => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        border: "1px solid #dadde9"
      }
    })
  );

  const tooltipContent = <div>{title}</div>;

  return (
    <HtmlTooltip title={tooltipContent} placement="top-end" followCursor>
      <div
        style={{ backgroundColor: color }}
        className={`flex w-full h-[30%] items-center justify-start p-1 font-semibold text-sm rounded-xl cursor-default bg-[#bfcde9]`}
      >
        <div className="w-[25%]">{time}</div>
        <div className="overflow-hidden whitespace-nowrap text-ellipsis">{title}</div>
      </div>
    </HtmlTooltip>
  );
}

export default TagCalendar;
