import TabsCustom from "components/TabCustom/TabsCustom";
import { useState } from "react";
import SecuritySetting from "./components/SecuritySetting";
import UserProfileAccount from "./components/UserProfileAccount";

UserInformation.propTypes = {};

function UserInformation() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabs = [
    {
      title: "Profile",
      isActive: activeTabIndex === 0,
      isView: true,
      onClick: () => setActiveTabIndex(0)
    },
    {
      title: "Security",
      isActive: activeTabIndex === 1,
      isView: true,
      onClick: () => setActiveTabIndex(1)
    }
    // {
    //   title: "Advance",
    //   isActive: activeTabIndex === 2,
    //   onClick: () => setActiveTabIndex(2)
    // }
  ];

  return (
    <div className="flex flex-col h-full w-full p-5 ">
      <TabsCustom tabList={tabs} />
      <div className="h-full w-full flex-grow">
        {activeTabIndex === 0 && <UserProfileAccount />}
        {activeTabIndex === 1 && <SecuritySetting />}
        {/* {activeTabIndex === 2 && <AdvancedSetting />} */}
      </div>
    </div>
  );
}

export default UserInformation;
