import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip, Typography, styled, tooltipClasses } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ToastShowError } from "utils/Notify";
import { disabledRangeTime, formatHHmm } from "./FormatTime";

function TaskWork(props) {
  const { taskInfo, onDeleteTask, taskIndex, replaceTask, disableKeyTimeSheet } = props;
  const listCategories = useSelector((state) => state.timesheet.timesheetCategories);
  const listObjects = useSelector((state) => state.timesheet.timesheetObjects);
  const userIdSelected = useSelector((state) => state.timesheet.userIdSelected);
  const currentUser = useSelector((state) => state.user.current);
  const isCurrentUser = currentUser?.id === userIdSelected;

  const [onEdit, setOnEdit] = useState(false);

  const [taskChanged, setTaskChanged] = useState(taskInfo || {});

  const handleEditTask = () => {
    if (taskChanged?.type !== "Overtime") {
      if (isCurrentUser) {
        setOnEdit(!onEdit);
      }
    } else {
      ToastShowError("Không thể chỉnh sửa task Overtime tại đây!");
    }
  };

  const inputError = taskChanged?.description === "" || taskChanged?.hours === "";

  const handleChange = (key, event) => {
    let newValue = event.target.value;

    let newObj = {
      ...taskChanged,
      [key]: newValue
    };

    setTaskChanged(newObj);
    replaceTask(newObj, taskIndex);
  };

  const handleChangeTime = (time) => {
    let newValue = dayjs(time).format("HH:mm");

    let newObj = {
      ...taskChanged,
      hours: newValue
    };

    setTaskChanged(newObj);
    replaceTask(newObj, taskIndex);
  };

  const onDeleteClick = () => {
    onDeleteTask(taskIndex);
  };

  const handleChangeSelect = (key, event) => {
    const newValue = event.target.value;
    let updatedTaskInfo;

    if (key === "category") {
      updatedTaskInfo = {
        ...taskChanged,
        categoryId: newValue,
        category: listCategories.find((cate) => cate.id === newValue)?.name
      };
    } else if (key === "object") {
      updatedTaskInfo = {
        ...taskChanged,
        objectId: newValue,
        object: listObjects.find((obj) => obj.id === newValue)?.name
      };
    }

    setTaskChanged(updatedTaskInfo);
    replaceTask(updatedTaskInfo, taskIndex);
  };

  const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    () => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        border: "1px solid #dadde9"
      }
    })
  );

  return (
    <tr
      className={`h-[40px] bg-white border-b hover:bg-gray-200 ${
        inputError ? "border-solid border-[1px] border-red-600" : ""
      } hover:bg-gray-50`}
      onDoubleClick={() => {
        if (isCurrentUser && !disableKeyTimeSheet) {
          handleEditTask();
        }
      }}
    >
      <td className="px-2 py-2 w-[25%]"></td>

      <td className={`px-2 py-2 truncate max-w-[175px]`}>
        {onEdit ? (
          <FormControl
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "32px"
              },
              borderRadius: "10px",
              width: "135px"
            }}
            size="small"
          >
            <Select
              id="demo-select-small"
              value={taskChanged?.categoryId ?? ""}
              onChange={(e) => handleChangeSelect("category", e)}
              defaultValue={taskChanged?.categoryId ?? ""}
              sx={{ width: "100%" }}
            >
              {listCategories.map((category, index) => (
                <MenuItem key={index} value={category?.id}>
                  <Typography noWrap sx={{ paddingRight: 2 }}>
                    {category?.name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <HtmlTooltip title={taskChanged?.category} placement="top-end" followCursor>
            <p className="truncate">{taskChanged?.category}</p>
          </HtmlTooltip>
        )}
      </td>

      <td className={`px-2 py-2 truncate max-w-[175px]`}>
        {onEdit ? (
          <FormControl
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "32px"
              },
              borderRadius: "10px",
              width: "135px"
            }}
            size="small"
          >
            <Select
              id="listObjects"
              value={taskChanged?.objectId ?? ""}
              onChange={(e) => handleChangeSelect("object", e)}
              defaultValue={taskChanged?.objectId ?? ""}
              sx={{ width: "100%" }}
            >
              {listObjects.map((object, index) => (
                <MenuItem key={index} value={object?.id}>
                  <Typography noWrap sx={{ paddingRight: 1 }}>
                    {object?.name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <HtmlTooltip title={taskChanged?.object} placement="top-end" followCursor>
            <p className="truncate">{taskChanged?.object}</p>
          </HtmlTooltip>
        )}
      </td>

      <td className="px-2 py-2 w-[25%]">
        {onEdit ? (
          <TextField
            value={taskChanged?.description}
            onChange={(e) => handleChange("description", e)}
            sx={{
              "& .MuiInputBase-root": {
                height: "32px"
              },
              width: "100%"
            }}
          />
        ) : (
          <div className="max-w-[400px]">
            <HtmlTooltip title={taskChanged?.description} placement="top-end" followCursor>
              <p className="truncate">{taskChanged?.description}</p>
            </HtmlTooltip>
          </div>
        )}
      </td>

      <td className="w-[10%] flex items-center px-2 py-2 font-4xl">
        <p className={`${taskChanged?.type === "Overtime" ? " p-1 bg-[#ede5c5] rounded-lg" : null} `}>
          {taskChanged?.type === "Overtime" && "Overtime"}
        </p>
      </td>
      <td className="min-w-[75px] px-2 py-2">
        {onEdit ? (
          <TimePicker
            defaultValue={dayjs("00:00", formatHHmm)}
            value={dayjs(taskChanged?.hours, formatHHmm)}
            format={formatHHmm}
            disabledTime={disabledRangeTime}
            hideDisabledOptions
            minuteStep={15}
            showNow={false}
            allowClear={false}
            needConfirm={false}
            onChange={(e) => handleChangeTime(e)}
          />
        ) : (
          <p>{taskChanged?.hours}</p>
        )}
      </td>
      <td className="min-w-[75px] px-2 py-2 flex items-center justify-center">
        <IconButton
          aria-label="delete"
          disabled={!isCurrentUser || disableKeyTimeSheet}
          onClick={onDeleteClick}
          sx={{ padding: "0" }}
          color={isCurrentUser ? "primary" : "disable"}
        >
          <DeleteIcon />
        </IconButton>
      </td>
    </tr>
  );
}

export default TaskWork;
