import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

function DateDetailTask(props) {
  const { taskInfo, isPreviousDate, onDeleteCalendarTask, onEditTask } = props;
  const { start, end, title } = taskInfo;

  return (
    <div className="flex w-full h-full flex-col items-center justify-betweet font-semibold text-sm rounded-lg cursor-default gap-1">
      <div className={`flex w-full h-[30%] rounded-lg p-2 bg-[#bfcde9]`}>
        <div className="flex w-[15%]">
          {start}~{end}
        </div>
        <div className="flex w-[70%] justify-start pl-2">{title}</div>
        <div className="flex w-[15%] justify-end">
          <EditIcon
            style={{
              color: isPreviousDate && "#757575",
              cursor: !isPreviousDate && "pointer",
              marginRight: "10px"
            }}
            onClick={() => {
              if (!isPreviousDate) {
                onEditTask(taskInfo?.id);
              }
            }}
          />

          <DeleteIcon style={{ cursor: "pointer" }} onClick={() => onDeleteCalendarTask(taskInfo?.id)} />
        </div>
      </div>

      <div className="flex w-full flex-col bg-[#ececec] rounded-lg p-2">
        {/* <div className="flex w-full border-b border-black pb-2">
          <div className="flex w-[70%]">
            <GroupAddIcon />
            <p className="pl-2"> {invitors || "Invitors"}</p>
          </div>
          <div className="flex w-[30%] border-l border-black pl-2">{asset}</div>
        </div> */}
        <div
          className="flex w-full justify-start min-h-[75px]"
          dangerouslySetInnerHTML={{ __html: taskInfo?.contentFormat }}
        />
      </div>
    </div>
  );
}

export default DateDetailTask;
