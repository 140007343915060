const listYears = [
  { id: 1, year: "2020" },
  { id: 2, year: "2021" },
  { id: 3, year: "2022" },
  { id: 4, year: "2023" },
  { id: 5, year: "2024" },
  { id: 6, year: "2025" },
  { id: 7, year: "2026" }
];

const listStatus = [
  { id: 0, status: "All" },
  { id: 1, status: "Pending" },
  { id: 2, status: "Approve" },
  { id: 3, status: "Reject" }
];

const listOvertimeStatus = [
  { id: 0, status: "All" },
  { id: 1, status: "Pending" },
  { id: 2, status: "Approve" },
  { id: 3, status: "Reject" },
  { id: 4, status: "Calculate" }
];

const listStatusModify = [
  { id: 1, status: "Pending" },
  { id: 2, status: "Approve" },
  { id: 3, status: "Reject" }
];

const typeHolidays = [
  { id: 1, type: "Holiday" },
  { id: 2, type: "Special" }
];

const listLeaveType = [
  {
    id: 0,
    type: "Annual",
    name: "Annual Leave"
  },
  {
    id: 1,
    type: "Unpaid",
    name: "Unpaid Leave"
  },
  {
    id: 2,
    type: "SelfWedding",
    name: "Self Wedding"
  },
  {
    id: 3,
    type: "FamilyWedding",
    name: "Family Wedding"
  },
  {
    id: 4,
    type: "FamilyBereavement",
    name: "Family Bereavement"
  },
  {
    id: 5,
    type: "RelativeBereavement",
    name: "Relative Bereavement"
  },
  {
    id: 6,
    type: "SelfMaternity",
    name: "Self Maternity"
  },
  {
    id: 7,
    type: "FamilyMaternity",
    name: "Family Maternity"
  },
  {
    id: 8,
    type: "Compensatory",
    name: "Compensatory"
  },
  {
    id: 9,
    type: "SummerVacation",
    name: "Summer Vacation"
  }
];

const listOptionPositions = [
  {
    id: 1,
    value: "All",
    name: "All"
  },
  {
    id: 2,
    value: "GM",
    name: "General Manager"
  },
  {
    id: 3,
    value: "Leader",
    name: "Leader"
  },
  {
    id: 4,
    value: "SubLeader",
    name: "Sub Leader"
  },
  {
    id: 5,
    value: "PM",
    name: "Project Manager"
  },
  {
    id: 6,
    value: "Official",
    name: "Official"
  },
  {
    id: 7,
    value: "Probationary",
    name: "Probationary"
  },
  {
    id: 8,
    value: "Internship",
    name: "Internship"
  }
];

const listProjectStatus = [
  { id: 0, status: "All" },
  { id: 1, status: "Pending" },
  { id: 2, status: "Active" },
  { id: 3, status: "End" }
];
export {
  listLeaveType,
  listOptionPositions,
  listOvertimeStatus,
  listProjectStatus,
  listStatus,
  listStatusModify,
  listYears,
  typeHolidays
};
