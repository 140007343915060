import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useQueries } from "@tanstack/react-query";
import AdminOverTimeAPI from "api/AdminOverTimeAPI";
import AdminTeamAPI from "api/AdminTeamAPI";
import DropdownListSelectionFilter from "components/DropdownListSelectionFilter";
import LoadingOverLay from "components/LoadingOverlay";
import { listYears } from "components/SharedData";
import CustomNoRowsOverlay from "pages/admin-timesheet/finger-printer/CustomNoRowsOverlay";
import React, { useMemo } from "react";

const Summary = () => {
  const [selectedTeam, setSelectedTeam] = React.useState(0);
  const [selectedYear, setSelectedYear] = React.useState(String(new Date().getFullYear()));

  const [listViewAdminSummaryOverTimeQuery, listOptionTeamQuery] = useQueries({
    queries: [
      {
        queryKey: ["listViewAdminOverTimeQuery", selectedYear],
        queryFn: async () => {
          const response = await AdminOverTimeAPI.getReport(selectedYear);
          const formatData = response?.data?.map((item, index) => ({
            ...item,
            id: index + 1
          }));
          return formatData;
        }
      },
      {
        queryKey: ["listOptionTeamQuery"],
        queryFn: async () => {
          const response = await AdminTeamAPI.getOptionTeam();
          return response.data;
        }
      }
    ]
  });

  const { data: listViewAdminReportOverTime, isLoading } = listViewAdminSummaryOverTimeQuery;
  const { data: listOptionTeam } = listOptionTeamQuery;

  const columnsAnnualLeaves = [
    {
      field: "id",
      headerName: "STT",
      width: 60,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "userName",
      headerName: "User",
      width: 120,
      align: "left",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "team",
      headerName: "Team",
      width: 100,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "jan",
      headerName: "Jan",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        const textColor = value === 0 ? "black" : value >= 40 ? "red" : "black";

        return value === 0 ? (
          <span style={{ color: "black" }}>-</span>
        ) : (
          <span style={{ color: textColor }}>{value}</span>
        );
      }
    },
    {
      field: "feb",
      headerName: "Feb",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        const textColor = value === 0 ? "black" : value >= 40 ? "red" : "black";

        return value === 0 ? (
          <span style={{ color: "black" }}>-</span>
        ) : (
          <span style={{ color: textColor }}>{value}</span>
        );
      }
    },
    {
      field: "mar",
      headerName: "Mar",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        const textColor = value === 0 ? "black" : value >= 40 ? "red" : "black";

        return value === 0 ? (
          <span style={{ color: "black" }}>-</span>
        ) : (
          <span style={{ color: textColor }}>{value}</span>
        );
      }
    },
    {
      field: "apr",
      headerName: "Apr",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        const textColor = value === 0 ? "black" : value >= 40 ? "red" : "black";

        return value === 0 ? (
          <span style={{ color: "black" }}>-</span>
        ) : (
          <span style={{ color: textColor }}>{value}</span>
        );
      }
    },
    {
      field: "may",
      headerName: "May",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        const textColor = value === 0 ? "black" : value >= 40 ? "red" : "black";

        return value === 0 ? (
          <span style={{ color: "black" }}>-</span>
        ) : (
          <span style={{ color: textColor }}>{value}</span>
        );
      }
    },
    {
      field: "jun",
      headerName: "Jun",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        const textColor = value === 0 ? "black" : value >= 40 ? "red" : "black";

        return value === 0 ? (
          <span style={{ color: "black" }}>-</span>
        ) : (
          <span style={{ color: textColor }}>{value}</span>
        );
      }
    },
    {
      field: "jul",
      headerName: "Jul",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        const textColor = value === 0 ? "black" : value >= 40 ? "red" : "black";

        return value === 0 ? (
          <span style={{ color: "black" }}>-</span>
        ) : (
          <span style={{ color: textColor }}>{value}</span>
        );
      }
    },
    {
      field: "aug",
      headerName: "Aug",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        const textColor = value === 0 ? "black" : value >= 40 ? "red" : "black";

        return value === 0 ? (
          <span style={{ color: "black" }}>-</span>
        ) : (
          <span style={{ color: textColor }}>{value}</span>
        );
      }
    },
    {
      field: "sep",
      headerName: "Sep",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        const textColor = value === 0 ? "black" : value >= 40 ? "red" : "black";

        return value === 0 ? (
          <span style={{ color: "black" }}>-</span>
        ) : (
          <span style={{ color: textColor }}>{value}</span>
        );
      }
    },
    {
      field: "oct",
      headerName: "Oct",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        const textColor = value === 0 ? "black" : value >= 40 ? "red" : "black";

        return value === 0 ? (
          <span style={{ color: "black" }}>-</span>
        ) : (
          <span style={{ color: textColor }}>{value}</span>
        );
      }
    },
    {
      field: "nov",
      headerName: "Nov",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        const textColor = value === 0 ? "black" : value >= 40 ? "red" : "black";

        return value === 0 ? (
          <span style={{ color: "black" }}>-</span>
        ) : (
          <span style={{ color: textColor }}>{value}</span>
        );
      }
    },
    {
      field: "dec",
      headerName: "Dec",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        const textColor = value === 0 ? "black" : value >= 40 ? "red" : "black";

        return value === 0 ? (
          <span style={{ color: "black" }}>-</span>
        ) : (
          <span style={{ color: textColor }}>{value}</span>
        );
      }
    },
    {
      field: "total",
      headerName: "Total",
      width: 120,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        const textColor = value === 0 ? "black" : value >= 300 ? "red" : "black";

        return value === 0 ? (
          <span style={{ color: "black" }}>-</span>
        ) : (
          <span style={{ color: textColor }}>{value}</span>
        );
      }
    },
    {
      field: "totalToCompensatory",
      headerName: "To Compensatory",
      width: 150,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center"
    }
  ];

  const teams = useMemo(() => {
    if (listOptionTeam && !listOptionTeam.some((y) => y.name === "All")) {
      const newListOptionTeam = [...listOptionTeam];
      newListOptionTeam.unshift({
        name: "All",
        id: 0
      });
      return newListOptionTeam;
    }
    return listOptionTeam;
  }, [listOptionTeam]);

  const filterdTeamAnnualLeave = listViewAdminReportOverTime?.filter((item) => {
    const filterTeam = (selectedTeam === 0 && listViewAdminReportOverTime) || item.teamId === selectedTeam;
    return filterTeam;
  });

  return (
    <div className="flex flex-col h-full w-full bg-white rounded-lg gap-1.5  p-5">
      <div className="flex gap-4 items-center justify-between">
        <div className="flex justify-between gap-2.5">
          <div className="flex items-center">
            <span className="mr-2.5">Team</span>
            <DropdownListSelectionFilter
              listSelection={teams?.map((team) => ({
                label: team.name,
                value: team.id
              }))}
              selected={selectedTeam}
              setSelected={setSelectedTeam}
            />
          </div>

          <div className="flex items-center">
            <span className="mr-2.5">Year</span>

            <DropdownListSelectionFilter
              listSelection={listYears?.map((item) => ({
                label: item.year,
                value: item.year
              }))}
              selected={selectedYear}
              setSelected={setSelectedYear}
            />
          </div>
        </div>
      </div>

      <Box
        className="flex-grow"
        sx={{
          height: "100%",
          width: "100%",
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "15px",
            fontWeight: 600
          }
        }}
      >
        <DataGrid
          rowHeight={40}
          sx={{
            border: "none",
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
              fontSize: "16px"
            },
            minHeight: "100px",
            ".MuiDataGrid-footerContainer": { borderTop: "none" },
            "--DataGrid-overlayHeight": "calc(100vh - 200px)"
          }}
          rows={filterdTeamAnnualLeave ?? []}
          columns={columnsAnnualLeaves}
          getRowId={(row) => row?.id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15
              }
            }
          }}
          pageSizeOptions={[15]}
          slots={{
            loadingOverlay: LoadingOverLay,
            noRowsOverlay: CustomNoRowsOverlay
          }}
          isLoading={isLoading}
          hideFooterSelectedRowCount
          disableColumnMenu={true}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableColumnSort={true}
          disableRowSelectionOnClick={true}
        />
      </Box>
    </div>
  );
};

export default Summary;
