import TabsCustom from "components/TabCustom/TabsCustom";
import { useState } from "react";

const Asset = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabs = [
    {
      title: "Request",
      isView: true,
      isActive: activeTabIndex === 0,
      onClick: () => setActiveTabIndex(0)
    },
    {
      title: "Summary",
      isActive: activeTabIndex === 1,
      isView: true,
      onClick: () => setActiveTabIndex(1)
    }
  ];

  return (
    <div className="flex flex-col h-full w-full p-5 ">
      <TabsCustom tabList={tabs} />
      <div className="h-full w-full flex-grow">
        {/* {activeTabIndex === 0 && <AssetTable />}
        {activeTabIndex === 1 && <AssetTable />} */}
      </div>
    </div>
  );
};

export default Asset;
