import LoadingButton from "@mui/lab/LoadingButton";

export default function ButtonComponent({ children, onClick, ...prop }) {
  return (
    <LoadingButton
      variant="contained"
      sx={{
        minWidth: "96px",
        textTransform: "none",
        borderRadius: "10px",
        paddingY: "8px"
      }}
      onClick={onClick}
      {...prop}
    >
      {children}
    </LoadingButton>
  );
}
