import { useState } from "react";
import History from "./history";
import Holidays from "./holidays";
import Requests from "./requests";

import TabsCustom from "components/TabCustom/TabsCustom";
import Information from "./information";

const LeaveForm = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabs = [
    {
      title: "Request",
      isView: true,
      isActive: activeTabIndex === 0,
      onClick: () => setActiveTabIndex(0)
    },
    {
      title: "History",
      isActive: activeTabIndex === 1,
      isView: true,
      onClick: () => setActiveTabIndex(1)
    },
    {
      title: "Holidays",
      isActive: activeTabIndex === 2,
      isView: true,
      onClick: () => setActiveTabIndex(2)
    },
    {
      title: "Information",
      isActive: activeTabIndex === 3,
      isView: true,
      onClick: () => setActiveTabIndex(3)
    }
  ];

  return (
    <div className="flex flex-col h-full w-full p-5 ">
      <TabsCustom tabList={tabs} />
      <div className="h-full w-full flex-grow">
        {activeTabIndex === 0 && <Requests />}
        {activeTabIndex === 1 && <History />}
        {activeTabIndex === 2 && <Holidays />}
        {activeTabIndex === 3 && <Information />}
      </div>
    </div>
  );
};

export default LeaveForm;
