import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, IconButton, Paper } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { DateCalendar } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeDaySelected, getTimesheetDetail } from "store/timesheetSlice";
import { checkIsAdmin } from "utils/accessUltils";

function DateTimeSheetDetailPicker() {
  const dayDetail = useSelector((state) => state.timesheet.daySelected);
  const userIdSelected = useSelector((state) => state.timesheet.userIdSelected);
  const menuAccess = useSelector((state) => state.common.menuAccess);
  const [openCalendar, setOpenCalendar] = useState(false);

  const calendarBtnRef = useRef();
  const calendarRef = useRef();
  const [calendarBtnValues, setCalendarBtnValues] = useState({
    left: 0,
    top: 0
  });

  const dispatch = useDispatch();

  function handleCalendarPrev() {
    const prevDay = dayDetail.subtract(1, "day");

    dispatch(changeDaySelected(dayjs(prevDay)));
    fetchNewData(prevDay);
  }

  function handleCalendarNext() {
    const nextDay = dayDetail.add(1, "day");

    dispatch(changeDaySelected(dayjs(nextDay)));
    fetchNewData(nextDay);
  }

  function handleChangeDay(newValue) {
    dispatch(changeDaySelected(dayjs(newValue)));

    fetchNewData(dayjs(newValue));
  }

  const toggleCalendar = () => {
    const { offsetTop, offsetLeft, offsetHeight, offsetWidth } = calendarBtnRef.current;
    const calendarWidth = calendarRef.current.offsetWidth;
    setCalendarBtnValues({
      ...calendarBtnValues,
      left: offsetLeft - (calendarWidth - offsetWidth),
      top: offsetTop + offsetHeight
    });
    setOpenCalendar(!openCalendar);
  };

  //close calendar popup when clicked away
  const clickAwayCalendar = () => {
    if (openCalendar) {
      setOpenCalendar(false);
    }
  };

  function fetchNewData(date) {
    let timeParams = {
      userId: userIdSelected,
      month: dayjs(date).month() + 1,
      year: dayjs(date).year(),
      day: dayjs(date).date(),
      isAdmin: checkIsAdmin(menuAccess)
    };
    dispatch(getTimesheetDetail(timeParams));
  }

  return (
    <div
      className="h-full w-[25%] flex justify-between items-center text-[#02457a]"
      direction="row"
      spacing={1}
      ref={calendarRef}
    >
      <IconButton aria-label="previous" sx={{ color: "#02457a" }} onClick={handleCalendarPrev}>
        <NavigateBeforeIcon />
      </IconButton>
      <div className="flex flex-row uppercase items-center font-bold text-xl">
        {dayjs(dayDetail).format("YYYY/MM/DD")}
        <ClickAwayListener onClickAway={clickAwayCalendar}>
          <div>
            <IconButton aria-label="calendar" sx={{ color: "#02457a" }} ref={calendarBtnRef} onClick={toggleCalendar}>
              <CalendarTodayIcon sx={{ color: "#02457a" }} />
            </IconButton>
            {openCalendar && (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    height: "auto",
                    position: "absolute",
                    left: calendarBtnValues.left + 75,
                    top: calendarBtnValues.top,
                    margin: "auto",
                    backgroundColor: "#f8f8fb",
                    zIndex: 100
                  }
                }}
              >
                <Paper elevation={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateCalendar"]}>
                      <DateCalendar
                        defaultValue={dayDetail || dayjs(new Date())}
                        value={dayDetail}
                        views={["day", "month", "year"]}
                        openTo="day"
                        onChange={(newValue) => {
                          handleChangeDay(newValue);
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Paper>
              </Box>
            )}
          </div>
        </ClickAwayListener>
      </div>

      <IconButton aria-label="previous" onClick={handleCalendarNext}>
        <NavigateNextIcon sx={{ color: "#02457a" }} />
      </IconButton>
    </div>
  );
}

export default DateTimeSheetDetailPicker;
