import IsLoggedIn from "middlewares/IsLoggedIn";
import MustLogin from "middlewares/MustLogin";
import ForgotPassword from "pages/authentication/pages/ForgotPassword";
import LoginPage from "pages/authentication/pages/Login";
import MailExpired from "pages/common-mail/mail-expired";
import MailRequest from "pages/common-mail/mail-request";
import MailSuccess from "pages/common-mail/mail-success";
import { createBrowserRouter } from "react-router-dom";
import CanNotAccessPage from "views/error/CanNotAccess";
import ErrorPage from "views/error/ErrorPage";
import Layout from "views/layout";
import { urls } from "../config/url";
import adminRoutes from "./admin.routes";
import userRoutes from "./user.routes";

export const router = createBrowserRouter([
  {
    path: urls.web.authentication.login,
    element: (
      <IsLoggedIn>
        <LoginPage />
      </IsLoggedIn>
    )
  },
  {
    path: urls.web.authentication.forgotPassword,
    element: <ForgotPassword />
  },
  {
    path: "",
    element: (
      <MustLogin>
        <Layout />
      </MustLogin>
    ),
    children: [...userRoutes, ...adminRoutes]
  },
  {
    path: urls.web.email.mailSuccess,
    element: <MailSuccess />
  },
  {
    path: urls.web.email.mailExpired,
    element: <MailExpired />
  },
  {
    path: urls.web.email.mailRequest,
    element: <MailRequest />
  },
  {
    path: urls.web.errors.errorPage,
    element: <ErrorPage />
  },
  {
    path: "*",
    element: <CanNotAccessPage />
  }
]);
