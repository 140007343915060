import { Controller } from "react-hook-form";
import DatePickerComponent from "../DatePickerComponent";

function DatePickerFormInput(props) {
  const { form, name, label, disable, isLimitDay } = props;
  const {
    formState: { errors }
  } = form;

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-start ml-2">{label}</div>

      <Controller
        control={form.control}
        name={name}
        InputProps={{
          style: {
            padding: "0",
            borderRadius: "8px",
            background: "#dcdcdc",
            border: "none",
            outline: "none"
          }
        }}
        render={({ field: { onChange, value } }) => (
          <DatePickerComponent
            className="date-picker"
            isLimitDay={isLimitDay}
            openTo="day"
            value={value}
            disabled={disable}
            onChange={onChange}
            name={name}
          ></DatePickerComponent>
        )}
      />
      <div style={{ height: "14px", maxWidth: "180px" }}>
        <div
          style={{
            fontSize: "14px",
            color: "red",
            fontWeight: 400,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          {errors[name] && errors[name].message}
        </div>
      </div>
    </div>
  );
}
export default DatePickerFormInput;
