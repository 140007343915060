import { Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./styles.module.scss";

function Editor(props) {
  const { form, name } = props;

  const {
    formState: { errors },
    setValue,
    control
  } = form;

  const handleChange = (value) => {
    setValue(name, value);
  };

  function wordCounter(text) {
    const words = text.split(/\s+/);
    const nonEmptyWords = words.filter((word) => word.trim() !== "");
    return nonEmptyWords.length;
  }

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          required: "Please enter task description",
          validate: (value) => wordCounter(value) >= 10 || "Enter at least 10 words in the description"
        }}
        render={({ field: { value } }) => (
          <ReactQuill
            value={value}
            theme="snow"
            onChange={handleChange}
            className={styles.reactquill}
            bounds={".app"}
            formats={[
              "header",
              "font",
              "size",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "list",
              "bullet",
              "indent",
              "link",
              "image",
              "video",
              "code-block",
              "align"
            ]}
          />
        )}
      />
      <div style={{ height: "14px", width: "100%" }}>
        {
          <span style={{ fontSize: "14px", color: "red", fontWeight: 400 }}>
            {errors[name] && errors[name].message}
          </span>
        }
      </div>
    </>
  );
}

export default Editor;
