import { Chip } from "@mui/material";
export function renderChipStatus(status) {
  return (
    <div>
      {status === "Approve" ? (
        <Chip
          style={{
            width: "110px",
            background: "#C6DCC6",
            color: "#004D00",
            fontWeight: 600,
            borderRadius: "10px"
          }}
          label="Approve"
        />
      ) : status === "Pending" ? (
        <Chip
          style={{
            width: "110px",
            background: "#EDE5C5",
            color: "#1E1D19",
            fontWeight: 600,
            borderRadius: "10px"
          }}
          label="Pending"
        />
      ) : (
        <Chip
          style={{
            width: "110px",
            background: "#E3CDCD",
            color: "#660000",
            fontWeight: 600,
            borderRadius: "10px"
          }}
          label="Reject"
        />
      )}
    </div>
  );
}
