import { Button, Dialog } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import CalendarAPI from "api/CalendarAPI";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCalendarStore } from "store/bearStore";
import { getListCalendars } from "store/calendarSlice";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import DateDetailTask from "./DateDetailTask";
import NoteForm from "./NoteForm";

function DateCalendarDetail({ open, onClose, date }) {
  const typeCalendar = useCalendarStore((state) => state.type);
  const isPreviousDate = dayjs(date).isBefore(dayjs(), "day");

  const userIdSelected = useSelector((state) => state.timesheet.userIdSelected);

  const dispatch = useDispatch();

  const dateDetailQuery = useQuery({
    queryKey: ["dateDetailQuery", date],
    queryFn: async () => {
      const param = {
        type: typeCalendar,
        year: dayjs(date).year(),
        month: dayjs(date).month() + 1,
        day: dayjs(date).date()
      };
      const response = await CalendarAPI.getDetailDay(param);
      return response?.data;
    }
  });

  //open modal form calendar
  const [openNoteForm, setOpenNoteForm] = useState(false);
  const [taskSelected, setTaskSelected] = useState({});

  const { data: dateDetailData, refetch } = dateDetailQuery;

  const { tasks } = dateDetailData || [];

  const dateToday = dateDetailData?.date;

  const handleClose = () => {
    onClose(false);
  };

  const toggleNoteFormHandle = () => {
    setOpenNoteForm(!openNoteForm);
  };

  const handleDeleteHoliday = async (id) => {
    const response = await CalendarAPI.deleteCalendarById(id);

    if (response.status === 200) {
      ToastShowSuccess("Delete task success.");

      const timeParams = {
        type: typeCalendar,
        year: dayjs(date).year(),
        month: dayjs(date).month() + 1
      };

      const action = getListCalendars(timeParams);
      await dispatch(action);
      refetch();
    } else {
      ToastShowError("Error when try delete public holiday.");
    }
  };

  const handleEditTask = async (id) => {
    const response = await CalendarAPI.getDetail(id);
    setTaskSelected(response.data);
    toggleNoteFormHandle();
  };

  const handleUpdateNote = async (noteData) => {
    try {
      const response = await CalendarAPI.updateCalendar(noteData);
      if (response?.status === 200) {
        ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
        reFetchData();
        refetch();
        toggleNoteFormHandle();
      }
    } catch (error) {
      ToastShowError(TOAST_ERROR_STRING);
    }
  };

  const reFetchData = async () => {
    let timeParams = {
      userId: userIdSelected,
      type: typeCalendar,
      month: dayjs(date).month() + 1,
      year: dayjs(date).year()
    };
    const action = getListCalendars(timeParams);
    await dispatch(action);
  };

  let noteFormPopup = null;
  if (openNoteForm === true) {
    noteFormPopup = (
      <NoteForm
        open={openNoteForm}
        isUpdate={true}
        noteInfo={taskSelected}
        onCreateNote={handleUpdateNote}
        onClose={toggleNoteFormHandle}
      />
    );
  }

  return (
    <>
      <Dialog sx={{ zIndex: "1299" }} open={open} onClose={onClose}>
        <div className="flex w-full flex-col font-semibold gap-1 p-6 overflow-hidden" style={{ width: "600px" }}>
          <div className="flex w-full items-center text-xl flex-col gap-0.5 pb-3">
            {dayjs(dateToday).format("YYYY/MM/DD")}
          </div>

          <div className="flex w-full h-[75%] max-h-[75%] overflow-x-auto flex-col gap-2">
            {tasks &&
              tasks.map((task, index) => [
                <DateDetailTask
                  key={`task-${index}`}
                  taskInfo={task}
                  isPreviousDate={isPreviousDate}
                  onEditTask={handleEditTask}
                  onDeleteCalendarTask={handleDeleteHoliday}
                />
              ])}
          </div>

          <div className="flex w-full items-end justify-end flex-col gap-0.5">
            <Button
              variant="contained"
              onClick={() => {
                handleClose();
              }}
              size="small"
              sx={{
                textTransform: "capitalize",
                boxShadow: "none",
                "&:hover": { boxShadow: "none", background: "#F0F0F0" },
                background: "#F0F0F0",
                color: "#555555",
                fontWeight: 600,
                borderRadius: "10px",
                width: "90px",
                padding: "5px 0"
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
      {noteFormPopup}
    </>
  );
}

export default DateCalendarDetail;
