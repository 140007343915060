import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@mui/x-data-grid";
import { useMutation, useQuery } from "@tanstack/react-query";
import AdminGroupAPI from "api/AdminGroupAPI";
import DeletePopup from "components/DeletePopUp";
import LoadingOverLay from "components/LoadingOverlay";
import SearchInputField from "components/SearchInputFeild";
import CustomNoRowsOverlay from "pages/admin-timesheet/finger-printer/CustomNoRowsOverlay";
import * as React from "react";
import { useSelector } from "react-redux";
import { formatDateTime } from "utils/DateTimeUtils";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import useRefreshQuery from "utils/useRefreshQuery";
import GroupModal from "./components/GroupModal";

export default function Group() {
  const menuAccess = useSelector((state) => state.common.menuAccess);
  const adminMenu = menuAccess?.admin || [];
  const userPagePermission = adminMenu?.user;
  const tabGroupPermission = userPagePermission?.tabGroup;

  const [dataRow, setDataRow] = React.useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isNewGroup, setIsNewGroup] = React.useState(false);
  const [showDeletePopup, setShowDeletePopup] = React.useState(false);
  const [groupId, setGroupId] = React.useState();
  const [searchValue, setSearchValue] = React.useState("");
  const { refreshQuery: refreshQueryObjectListView } = useRefreshQuery(["listViewGroupQuery"]);

  const listViewGroupQuery = useQuery({
    queryKey: ["listViewGroupQuery"],
    queryFn: async () => {
      const response = await AdminGroupAPI.getListView();
      return response.data;
    }
  });

  const { data: listViewGroup, isLoading } = listViewGroupQuery;

  const mutation = useMutation({
    mutationFn: (value) => {
      return AdminGroupAPI.delete(value);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        const errorData = error.response.data.errors;
        ToastShowError(errorData || TOAST_ERROR_STRING);
      } else ToastShowError(TOAST_ERROR_STRING);
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      setShowDeletePopup(false);
      refreshQueryObjectListView();
    }
  });

  const handleClose = () => {
    setIsModalOpen(false);
    setDataRow(null);
  };

  const handleCreateNewGroup = () => {
    setIsModalOpen(true);
    setIsNewGroup(true);
    setGroupId(null);
  };

  const handleEditGroup = (params) => {
    setIsModalOpen(true);
    setIsNewGroup(false);
    setDataRow(params);
    setGroupId(params.id);
  };

  const handleRequestDeleteGroup = (params) => {
    setShowDeletePopup(true);
    setDataRow(params);
  };

  const handleDeleteGroup = async () => {
    mutation.mutate(dataRow?.id);
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const columns = [
    {
      field: "code",
      headerName: "Code",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-click"
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      width: 890,
      align: "left",
      headerClassName: "header-click"
    },
    {
      field: "amount",
      headerName: "Members",
      headerAlign: "center",
      width: 150,
      align: "center",
      headerClassName: "header-click"
    },
    {
      field: "creatorName",
      headerName: "Created By",
      headerAlign: "center",
      width: 150,
      align: "center",
      headerClassName: "header-click"
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      headerAlign: "center",
      width: 150,
      align: "center",
      headerClassName: "header-click",

      renderCell: (params) => {
        const value = params.value;
        return formatDateTime(value);
      }
    },
    {
      field: "action",
      headerName: "",
      width: 100,
      align: "center",
      sortable: "false",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <div className="table__action-btn">
            {tabGroupPermission?.isUpdate && (
              <IconButton onClick={() => handleEditGroup(params.row)}>
                <EditIcon />
              </IconButton>
            )}

            {tabGroupPermission?.isDelete && (
              <IconButton onClick={() => handleRequestDeleteGroup(params.row)}>
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        );
      }
    }
  ];

  let filteredGroup = listViewGroup?.filter((group) => {
    const searchFilter = (searchValue === "" && listViewGroup) || group.name.toLowerCase().includes(searchValue);
    return searchFilter;
  });

  return (
    <div className="flex flex-col bg-white rounded-lg p-5 gap-1.5 h-full">
      <div className="flex justify-between">
        <div className="w-1/4">
          <SearchInputField value={searchValue} onChange={(e) => handleChange(e)} placeholder="Find groups " />
        </div>

        {tabGroupPermission?.isCreate && (
          <div className="w-1/2 flex items-center justify-end">
            <Tooltip title="Add User">
              <IconButton variant="contained" onClick={handleCreateNewGroup}>
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>

      <Box
        className="flex-grow"
        sx={{
          height: "100%",
          width: "100%",
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "15px",
            fontWeight: 600
          }
        }}
      >
        <DataGrid
          rowHeight={40}
          sx={{
            border: "none",
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
              fontSize: "16px"
            },
            minHeight: "100px",
            ".MuiDataGrid-footerContainer": { borderTop: "none" },
            "--DataGrid-overlayHeight": "calc(100vh - 200px)"
          }}
          rows={filteredGroup ?? []}
          columns={columns}
          getRowId={(row) => row?.id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 14
              }
            }
          }}
          pageSizeOptions={[14]}
          slots={{
            loadingOverlay: LoadingOverLay,
            noRowsOverlay: CustomNoRowsOverlay
          }}
          isLoading={isLoading}
          hideFooterSelectedRowCount
          disableColumnMenu={true}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableColumnSort={true}
          disableRowSelectionOnClick={true}
        />
      </Box>

      {isNewGroup && (
        <GroupModal
          isNewGroup={isNewGroup}
          open={isModalOpen}
          onClose={handleClose}
          dataRow={dataRow}
          groupId={groupId}
          refreshQueryObjectListView={refreshQueryObjectListView}
        />
      )}

      {!isNewGroup && dataRow && groupId && (
        <GroupModal
          isNewGroup={isNewGroup}
          open={isModalOpen}
          onClose={handleClose}
          dataRow={dataRow}
          groupId={groupId}
          refreshQueryObjectListView={refreshQueryObjectListView}
        />
      )}
      <DeletePopup
        open={showDeletePopup}
        onClose={() => setShowDeletePopup(false)}
        handleDelete={handleDeleteGroup}
        title="Bạn có chắc chắn xóa group ?"
      />
    </div>
  );
}
