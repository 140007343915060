import { useDispatch } from "react-redux";
import { getDashboardUser, getTeams } from "store/commonSlice";

export const useLayoutServices = () => {
  const dispatch = useDispatch();

  const getListTeam = async function () {
    const action = getTeams();
    await dispatch(action);
  };
  const getListUsers = async function () {
    const action = getDashboardUser();
    await dispatch(action);
  };

  const getListCommonComponent = async function () {
    getListTeam();
    getListUsers();
  };

  return { getListCommonComponent };
};
