import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import React from "react";

export default function InputPasswordComponent(props) {
  const { autoComplete } = props;
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <OutlinedInput
      id="outlined-adornment-password"
      type={showPassword ? "text" : "password"}
      autoComplete={autoComplete ? "password" : "new-password"}
      sx={{
        outline: "none",
        borderRadius: "10px",
        border: "none",
        padding: 0,
        paddingRight: "14px",
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 100px #312E33",
          WebkitTextFillColor: "default"
        },
        "& .MuiInputBase-input": {
          padding: "10px !important",
          fontSize: "14px",
          lineHeight: "14px",
          borderRadius: "10px"
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none"
        }
      }}
      endAdornment={
        <InputAdornment position="end">
          <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
      {...props}
    />
  );
}
