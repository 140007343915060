import ReactDOM from "react-dom/client";
import AppRoutes from "routers";
import "./App.css";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <AppRoutes></AppRoutes>
  </>
);
