import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useQueries } from "@tanstack/react-query";
import AdminWfhAPI from "api/AdminWfhAPI";
import TeamAPI from "api/TeamAPI";
import MonthPickerInput from "components/DateTimePicker/MonthPickerInput";
import DropdownListSelectStatus from "components/DropdownListSelectStatus";
import DropdownListSelectionFilter from "components/DropdownListSelectionFilter";
import LoadingOverLay from "components/LoadingOverlay";
import { listStatus, listStatusModify } from "components/SharedData";
import parse from "html-react-parser";
import CustomNoRowsOverlay from "pages/admin-timesheet/finger-printer/CustomNoRowsOverlay";
import * as React from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { ToastShowError, ToastShowSuccess, ToastShowWarning } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import { renderChipStatus } from "utils/renderChipStatus";
import useRefreshQuery from "utils/useRefreshQuery";
import CanNotAccessPage from "views/error/CanNotAccess";

export default function AdminWorkFromHome() {
  const menuAccess = useSelector((state) => state.common.menuAccess);
  const adminMenu = menuAccess?.admin || [];
  const WFHPage = adminMenu?.wfh;

  const allowPageAccess = WFHPage.isView || false;
  const canApprove = WFHPage.tabWfhRequest?.isApprove || false;

  const [selectedMonthYears, setSelectedMonthYears] = React.useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1
  });
  const [selectedStatus, setSelectedStatus] = React.useState("All");
  const [selectedTeam, setSelectedTeam] = React.useState("All");

  const { refreshQuery: refreshQueryObject } = useRefreshQuery([
    "getListViewWfh",
    selectedMonthYears.year,
    selectedMonthYears.month
  ]);

  const [listViewWfhQuery, listViewTeamQuery] = useQueries({
    queries: [
      {
        queryKey: ["getListViewWfh", selectedMonthYears.year, selectedMonthYears.month],
        queryFn: async () => {
          const response = await AdminWfhAPI.getListView(selectedMonthYears.year, selectedMonthYears.month);
          return response.data;
        }
      },
      {
        queryKey: ["getListViewTeam"],
        queryFn: async () => {
          const response = await TeamAPI.getTeams();
          return response.data;
        }
      }
    ]
  });
  const { data: dataListViewWfh, isLoading } = listViewWfhQuery;
  const { data: dataListTeam } = listViewTeamQuery;

  const teams = useMemo(() => {
    if (dataListTeam && !dataListTeam.some((y) => y.name === "All"))
      dataListTeam.unshift({
        name: "All",
        id: 0
      });
    return dataListTeam;
  }, [dataListTeam]);

  const handleChangeStatusOverTime = async (id, value, setSelected, previousState) => {
    try {
      if (value === "Approve") {
        const response = await AdminWfhAPI.approveOrRejectWfh(id, true);
        if (response.status === 200) {
          ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
          refreshQueryObject();
        }
      } else if (value === "Reject") {
        const response = await await AdminWfhAPI.approveOrRejectWfh(id, false);
        if (response.status === 200) {
          refreshQueryObject();
          ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
        }
      } else {
        ToastShowWarning("Status không hợp lệ");
        setSelected(previousState.current);
      }
    } catch (error) {
      ToastShowError(error.response.data.errors.ERROR[0] || TOAST_ERROR_STRING);
      setSelected(previousState.current);
    }
  };

  const columns = [
    {
      field: "no",
      headerName: "STT",
      width: 60,
      align: "center",
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      align: "center",
      headerClassName: "header-click",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            {canApprove ? (
              <DropdownListSelectStatus
                listSelection={listStatusModify}
                initValue={params.row.status}
                id={params.row.id}
                handleChangeStatus={handleChangeStatusOverTime}
              />
            ) : (
              renderChipStatus(params.row.status)
            )}
          </>
        );
      }
    },
    {
      field: "createdName",
      headerName: "User",
      width: 200,
      align: "left",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "teamCode",
      headerName: "Team",
      width: 100,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "startTime",
      headerName: "Start Date",
      width: 100,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "endTime",
      headerName: "End Date",
      width: 100,
      align: "center",
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "reason",
      headerName: "Description",
      width: 800,
      align: "left",
      headerClassName: "header-click",

      headerAlign: "center",
      renderCell: (params) => {
        return <>{parse(params.value)}</>;
      }
    },
    {
      field: "reviewerName",
      headerName: "Reviewer",
      width: 100,
      align: "left",
      headerClassName: "header-click",

      headerAlign: "center"
    }
  ];

  const filteredWfhAdmin = useMemo(() => {
    let newWfhs =
      dataListViewWfh?.filter((wfhItem) => {
        const teamFilter = (selectedTeam === "All" && dataListViewWfh) || wfhItem.teamName === selectedTeam;
        const statusFilter =
          (selectedStatus === "All" && dataListViewWfh) ||
          (selectedStatus === "Pending" && wfhItem.status === selectedStatus) ||
          (selectedStatus === "Approve" && wfhItem.status === selectedStatus) ||
          (selectedStatus === "Reject" && wfhItem.status === selectedStatus);
        return statusFilter && teamFilter;
      }) ?? [];
    newWfhs =
      newWfhs?.map((v, i) => {
        return { ...v, no: i + 1 };
      }) ?? [];
    return newWfhs;
  }, [dataListViewWfh, selectedStatus, selectedTeam]);

  return (
    <>
      {allowPageAccess ? (
        <div className="w-full h-full p-5">
          <div className="flex flex-col w-full h-full overflow-hidden bg-white p-5 rounded-[10px]">
            <div className="flex items-center gap-[10px]">
              <div className="w-fit flex items-center gap-[10px]">
                <span>Team</span>
                <DropdownListSelectionFilter
                  listSelection={teams?.map((team) => ({
                    label: team.name,
                    value: team.name
                  }))}
                  selected={teams ? selectedTeam : ""}
                  setSelected={setSelectedTeam}
                />
              </div>
              <div className="flex items-center gap-[10px]">
                <span>Month</span>
                <MonthPickerInput
                  selectedMonthYears={selectedMonthYears}
                  setSelectedMonthYears={setSelectedMonthYears}
                />
              </div>
              <div className="flex items-center gap-[10px]">
                <div style={{ width: "max-content" }}>
                  <span>Status</span>
                </div>
                <DropdownListSelectionFilter
                  listSelection={listStatus?.map((item) => ({
                    label: item.status,
                    value: item.status
                  }))}
                  selected={selectedStatus}
                  setSelected={setSelectedStatus}
                />
              </div>
            </div>

            <Box
              className="flex-grow"
              sx={{
                height: "100%",
                width: "100%",
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontSize: "15px",
                  fontWeight: 600
                }
              }}
            >
              <DataGrid
                rowHeight={40}
                sx={{
                  border: "none",
                  ".MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "600",
                    fontSize: "16px"
                  },
                  minHeight: "100px",
                  ".MuiDataGrid-footerContainer": { borderTop: "none" },
                  "--DataGrid-overlayHeight": "calc(100vh - 200px)"
                }}
                rows={filteredWfhAdmin ?? []}
                columns={columns}
                getRowId={(row) => row?.id}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 15
                    }
                  }
                }}
                pageSizeOptions={[15]}
                slots={{
                  loadingOverlay: LoadingOverLay,
                  noRowsOverlay: CustomNoRowsOverlay
                }}
                isLoading={isLoading}
                hideFooterSelectedRowCount
                disableColumnMenu={true}
                disableColumnFilter={true}
                disableColumnSelector={true}
                disableColumnSort={true}
                disableRowSelectionOnClick={true}
              />
            </Box>
          </div>
        </div>
      ) : (
        <CanNotAccessPage />
      )}
    </>
  );
}
