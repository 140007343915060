import { Button, Dialog } from "@mui/material";
import Slide from "@mui/material/Slide";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

DeletePopup.propTypes = {};

function DeletePopup({ open, onClose, handleDelete, title }) {
  const handlesConfirmed = () => {
    if (handleDelete) {
      handleDelete();
    }
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        sx: { borderRadius: "10px", minWidth: "350px", minHeight: "116px" }
      }}
    >
      <div className="pt-[30px] pr-[20px] pb-[10px] pl-[20px] ">
        <span className="flex justify-center font-semibold">{title || "Bạn có chắc chắn muốn xóa ?"}</span>
        <div className="flex justify-center mt-[15px] py-5 px-0 gap-3">
          <div>
            <Button
              variant="contained"
              size="small"
              onClick={handlesConfirmed}
              sx={{
                textTransform: "uppercase",
                boxShadow: "none",
                "&:hover": { boxShadow: "none", background: "#bccdea" },
                background: "#bccdea",
                color: "#001b48",
                fontWeight: "600",
                borderRadius: "10px",
                width: "90px",
                padding: "5px 0"
              }}
            >
              ok
            </Button>
          </div>
          <div className="btn-cancel">
            <Button
              variant="contained"
              onClick={() => {
                onClose();
              }}
              size="small"
              sx={{
                textTransform: "capitalize",
                boxShadow: "none",
                "&:hover": { boxShadow: "none", background: "#f0f0f0" },
                background: "#f0f0f0",
                color: "#555555",
                fontWeight: "600",
                borderRadius: "10px",
                width: "90px",
                padding: "5px 0"
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default DeletePopup;
