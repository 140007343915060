// Function to check if the user has admin access
export const checkIsAdmin = (menuAccess) => {
  const adminMenu = menuAccess?.admin || [];

  for (const key in adminMenu) {
    if (adminMenu[key] && adminMenu[key].hasOwnProperty("isView") && adminMenu[key]["isView"]) {
      return true; // User has admin access
    }
  }

  return false; // User does not have admin access
};
