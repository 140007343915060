import CustomNoRowsOverlay from "pages/admin-timesheet/finger-printer/CustomNoRowsOverlay";
import { useEffect } from "react";
import { useTaskStore } from "store/bearStore";
import AddNewProject from "./AddNewProject";
import TasksView from "./TasksView";

function KeyTimeSheet({ tasksOfDate, disableKeyTimeSheet }) {
  const listProjectTasks = useTaskStore((state) => state.listProjectTasks);
  const setTasksInDay = useTaskStore((state) => state.initTask);

  useEffect(() => {
    setTasksInDay(tasksOfDate?.projects ?? []);
  }, [tasksOfDate]);

  return (
    <>
      <table className="w-full">
        <thead className="sticky border-b border-gray-900 top-0 z-10">
          <tr>
            <th scope="col" className="flex flex-row items-center justify-center px-6 py-3 border-">
              <AddNewProject disableKeyTimeSheet={disableKeyTimeSheet} />
            </th>

            <th scope="col" className="px-6 py-3">
              Category
            </th>
            <th scope="col" className="px-6 py-3">
              Object
            </th>
            <th scope="col" className="px-6 py-3">
              Description
            </th>

            <th scope="col" className="px-6 py-3">
              Type
            </th>

            <th scope="col" className="px-6 py-3">
              Hours
            </th>
            <th scope="col" className="px-6 py-3"></th>
          </tr>
        </thead>
        <tbody className="w-full">
          {listProjectTasks &&
            listProjectTasks?.map((projectTasks, index) => {
              return <TasksView key={index} projectTasks={projectTasks} disableKeyTimeSheet={disableKeyTimeSheet} />;
            })}
        </tbody>
      </table>
      {listProjectTasks.length === 0 && (
        <div className="w-full flex flex-row items-center justify-center px-6 py-3">
          <CustomNoRowsOverlay />
        </div>
      )}
    </>
  );
}

export default KeyTimeSheet;
