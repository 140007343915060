export default function ProjectSummaryLine(data) {
  const { dataLeft, dataRight, hours } = data;
  return (
    <div className="flex flex-row justify-between">
      <div className="w-[40%]">&#9679; {dataLeft}</div>
      <div className="w-[40%]">: {dataRight}</div>
      <div className="w-[10%]">{hours && "hours"}</div>
    </div>
  );
}
