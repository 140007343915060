import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useMutation } from "@tanstack/react-query";
import AdminTeamAPI from "api/AdminTeamAPI";
import CustomInputField from "components/CustomInputField";
import { useForm } from "react-hook-form";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import { setReactHookFormErrors } from "utils/formErrorUtil";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    code: yup.string().required("Yêu cầu nhập"),
    name: yup.string().required("Name không được bỏ trống")
  })
  .required();

function TeamModal(props) {
  let { isNewTeam, open, onClose, teamInfo, disable, refreshQueryObject } = props;

  const form = useForm({
    defaultValues: {
      ...teamInfo,
      code: teamInfo?.code ?? "",
      name: teamInfo?.name ?? ""
    },
    resolver: yupResolver(schema)
  });

  const mutationCreateTeam = useMutation({
    mutationFn: (value) => {
      return AdminTeamAPI.createTeam(value);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        const errorData = error.response.data.errors;
        setReactHookFormErrors(errorData, form.setError);
      } else ToastShowError(TOAST_UPDATE_SUCCESS_STRING);
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      onClose();
      refreshQueryObject();
    }
  });

  const mutationUpdateTeam = useMutation({
    mutationFn: (value) => {
      return AdminTeamAPI.updateTeam(value);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        const errorData = error.response.data.errors;
        setReactHookFormErrors(errorData, form.setError);
      } else ToastShowError(TOAST_UPDATE_SUCCESS_STRING);
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      onClose();
      refreshQueryObject();
    }
  });

  const handleSubmit = async () => {
    var valueSubmit = form.getValues();
    valueSubmit = {
      code: valueSubmit?.code,
      name: valueSubmit?.name,
      id: teamInfo?.id || null
    };
    if (isNewTeam) {
      mutationCreateTeam.mutate(valueSubmit);
    } else mutationUpdateTeam.mutate(valueSubmit);
  };

  return (
    <Dialog onClose={onClose} open={open} PaperProps={{ sx: { borderRadius: "8px" } }}>
      <DialogContent
        sx={{
          minWidth: "440px",
          minHeight: "280px",
          padding: "20px"
        }}
      >
        {isNewTeam ? (
          <>
            <h3 className="text-center text-xl font-bold">New</h3>
            <div className="flex flex-col gap-1">
              <div className="w-2/5 pb-2">
                <CustomInputField
                  name="code"
                  label={
                    <>
                      <span>Code</span>
                      <span className="text-[red] text-base ml-1">*</span>
                    </>
                  }
                  form={form}
                  disable={disable}
                />
              </div>
              <div className="pb-2">
                <CustomInputField
                  name="name"
                  label={
                    <>
                      <span>Name</span>
                      <span className="text-[red] text-base ml-1">*</span>
                    </>
                  }
                  form={form}
                  disable={disable}
                />
              </div>
              <div className="flex gap-3 justify-end p-1">
                <Button
                  variant="contained"
                  size="small"
                  onClick={form.handleSubmit(handleSubmit)}
                  sx={{
                    textTransform: "uppercase",
                    boxShadow: "none",
                    "&:hover": { boxShadow: "none", background: "#bccdea" },
                    background: "#bccdea",
                    color: "#001b48",
                    fontWeight: "600",
                    borderRadius: "10px",
                    width: "90px",
                    padding: "5px 0"
                  }}
                >
                  ok
                </Button>

                <Button
                  variant="contained"
                  onClick={() => onClose()}
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    boxShadow: "none",
                    "&:hover": { boxShadow: "none", background: "#f0f0f0" },
                    background: "#f0f0f0",
                    color: "#555555",
                    fontWeight: "600",
                    borderRadius: "10px",
                    width: "90px",
                    padding: "5px 0"
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <h3 className="text-center text-xl font-bold">Update</h3>
            <div className="flex flex-col gap-1">
              <div className="w-2/5 pb-2">
                <CustomInputField
                  name="code"
                  label={
                    <>
                      <span>Code</span>
                      <span className="text-[red] text-base ml-1">*</span>
                    </>
                  }
                  form={form}
                  disable={disable}
                />
              </div>
              <div className="pb-2">
                <CustomInputField
                  name="name"
                  label={
                    <>
                      <span>Name</span>
                      <span className="text-[red] text-base ml-1">*</span>
                    </>
                  }
                  form={form}
                  disable={disable}
                />
              </div>
              <div className="flex gap-3 justify-end p-1">
                <Button
                  variant="contained"
                  size="small"
                  onClick={form.handleSubmit(handleSubmit)}
                  sx={{
                    textTransform: "uppercase",
                    boxShadow: "none",
                    "&:hover": { boxShadow: "none", background: "#bccdea" },
                    background: "#bccdea",
                    color: "#001b48",
                    fontWeight: "600",
                    borderRadius: "10px",
                    width: "90px",
                    padding: "5px 0"
                  }}
                >
                  ok
                </Button>

                <Button
                  variant="contained"
                  onClick={() => onClose()}
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    boxShadow: "none",
                    "&:hover": { boxShadow: "none", background: "#f0f0f0" },
                    background: "#f0f0f0",
                    color: "#555555",
                    fontWeight: "600",
                    borderRadius: "10px",
                    width: "90px",
                    padding: "5px 0"
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default TeamModal;
