import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";

function TimeSheetItem(props) {
  const { projectName, color, hours } = props;
  const isLeaveTask = color === "leaveTask";

  const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    () => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        border: "1px solid #dadde9"
      }
    })
  );

  const tooltipContent = (
    <div>
      <strong>Project Name: </strong>
      {projectName}
    </div>
  );

  return (
    <HtmlTooltip title={tooltipContent} placement="top-end" followCursor>
      <div
        className={`flex w-full h-[30%] items-center justify-between p-1 ${
          isLeaveTask ? "bg-[#dadada]" : "bg-[#97cadb]"
        } font-semibold text-sm rounded-lg cursor-default hover:text-white hover:bg-[#018abe]`}
      >
        <div className="overflow-hidden whitespace-nowrap text-ellipsis ">{projectName}</div>
        <div>{hours}</div>
      </div>
    </HtmlTooltip>
  );
}

export default TimeSheetItem;
