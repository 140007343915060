import TabsCustom from "components/TabCustom/TabsCustom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTabStore } from "store/bearStore";
import findTabIndex from "utils/FindTabIndex";
import CanNotAccessPage from "views/error/CanNotAccess";
import Information from "./information";
import Request from "./request";
import Summary from "./summary";

const AdminOverTime = () => {
  const tabIndex = useTabStore((state) => state.tabIndex);
  const changeTabIndex = useTabStore((state) => state.changeTabIndex);

  const menuAccess = useSelector((state) => state.common.menuAccess);
  const adminMenu = menuAccess?.admin || [];
  const overTimePagePermission = adminMenu?.overtime;

  const allowPageAccess = overTimePagePermission.isView || false;
  const minIndex = findTabIndex(overTimePagePermission);
  useEffect(() => {
    changeTabIndex(minIndex);
  }, []);
  const tabs = [
    {
      title: "Request",
      isView: overTimePagePermission?.tabRequest?.isView,
      isActive: tabIndex === 0,
      onClick: () => changeTabIndex(0)
    },
    {
      title: "Summary",
      isActive: tabIndex === 1,
      isView: overTimePagePermission?.tabSummary?.isView,
      onClick: () => changeTabIndex(1)
    },
    {
      title: "Information",
      isActive: tabIndex === 2,
      isView: overTimePagePermission?.tabInformation?.isView,
      onClick: () => changeTabIndex(2)
    }
  ];

  return (
    <div className="flex flex-col h-full w-full p-5 ">
      {allowPageAccess ? (
        <>
          <TabsCustom tabList={tabs} />
          <div className="h-full w-full flex-grow">
            {tabIndex === 0 && <Request />}
            {tabIndex === 1 && <Summary />}
            {tabIndex === 2 && <Information />}
          </div>
        </>
      ) : (
        <CanNotAccessPage />
      )}
    </div>
  );
};

export default AdminOverTime;
