import { DataGrid } from "@mui/x-data-grid";
import { useQueries } from "@tanstack/react-query";
import AdminLeaveFormAPI from "api/AdminLeaveFormAPI";
import AdminTeamAPI from "api/AdminTeamAPI";
import DropdownListSelectionFilter from "components/DropdownListSelectionFilter";
import LoadingOverLay from "components/LoadingOverlay";
import { listYears } from "components/SharedData";
import CustomNoRowsOverlay from "pages/admin-timesheet/finger-printer/CustomNoRowsOverlay";
import React, { useMemo } from "react";
import style from "./summary.module.scss";

const SummaryLeaveForm = () => {
  const [selectedTeam, setSelectedTeam] = React.useState(0);
  const [selectedYear, setSelectedYear] = React.useState(String(new Date().getFullYear()));

  const [leaveReportResponseQuery, listOptionTeamQuery] = useQueries({
    queries: [
      {
        queryKey: ["leaveReportResponseQuery", selectedYear],
        queryFn: async () => {
          const response = await AdminLeaveFormAPI.getReportLeave(selectedYear);
          const dataSumaryAnualLeave = response?.data?.annualLeaves?.map((item, index) => ({
            ...item,
            stt: index + 1
          }));

          const dataSummerLeave = response?.data?.summerLeaves?.map((item, index) => ({
            ...item,
            stt: index + 1
          }));

          const dataCompensatoryLeaves = response?.data?.compensatoryLeaves?.map((item, index) => ({
            ...item,
            stt: index + 1
          }));

          return dataSumaryAnualLeave && dataSummerLeave && dataCompensatoryLeaves;
        }
      },
      {
        queryKey: ["listOptionTeamQuery"],
        queryFn: async () => {
          const response = await AdminTeamAPI.getOptionTeam();
          return response.data;
        }
      }
    ]
  });
  const {
    data: dataSumaryAnualLeaves,
    data: dataSummerLeaves,
    data: dataCompensatoryLeaves,
    isLoading
  } = leaveReportResponseQuery;

  const { data: listOptionTeam } = listOptionTeamQuery;

  const columnsAnnualLeaves = [
    {
      field: "stt",
      headerName: "STT",
      width: 60,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "userName",
      headerName: "User",
      width: 120,
      align: "left",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "team",
      headerName: "Team",
      width: 100,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "jan",
      headerName: "Jan",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "feb",
      headerName: "Feb",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "mar",
      headerName: "Mar",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },

    {
      field: "apr",
      headerName: "Apr",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "may",
      headerName: "May",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "jun",
      headerName: "Jun",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "jul",
      headerName: "Jul",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "aug",
      headerName: "Aug",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "sep",
      headerName: "Sep",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "oct",
      headerName: "Oct",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "nov",
      headerName: "Nov",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "dec",
      headerName: "Dec",
      flex: 1,
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "total",
      headerName: "Total",
      width: 100,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center"
    }
  ];

  const columnsSummerLeaves = [
    {
      field: "stt",
      headerName: "STT",
      width: 60,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "userName",
      headerName: "User",
      width: 120,
      align: "left",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "team",
      headerName: "Team",
      width: 100,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "totalDays",
      headerName: "Total Days",
      width: 100,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "jul",
      headerName: "Jul",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "aug",
      headerName: "Aug",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "sep",
      headerName: "Sep",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "oct",
      headerName: "Oct",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "total",
      headerName: "Total",
      width: 120,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center"
    }
  ];

  const columnsCompensatoryLeaves = [
    {
      field: "stt",
      headerName: "STT",
      width: 60,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "userName",
      headerName: "User",
      width: 120,
      align: "left",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "team",
      headerName: "Team",
      width: 100,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "totalDays",
      headerName: "Total Days",
      width: 100,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center"
    },
    {
      field: "jan",
      headerName: "Jan",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "feb",
      headerName: "Feb",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "mar",
      headerName: "Mar",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "apr",
      headerName: "Apr",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "may",
      headerName: "May",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "jun",
      headerName: "Jun",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "jul",
      headerName: "Jul",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "aug",
      headerName: "Aug",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "sep",
      headerName: "Sep",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "oct",
      headerName: "Oct",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "nov",
      headerName: "Nov",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "dec",
      headerName: "Dec",
      flex: 1,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value !== 0 ? <span style={{ color: "red" }}> {value} </span> : <span style={{ color: "red" }}>-</span>;
      }
    },
    {
      field: "total",
      headerName: "Total",
      width: 120,
      align: "center",
      sortable: false,
      headerClassName: "header",

      headerAlign: "center"
    }
  ];

  const teams = useMemo(() => {
    if (listOptionTeam && !listOptionTeam.some((teamItem) => teamItem.name === "All"))
      listOptionTeam.unshift({
        name: "All",
        id: 0
      });
    return listOptionTeam;
  }, [listOptionTeam]);

  const filterdTeamAnnualLeave = dataSumaryAnualLeaves?.filter((item) => {
    const filterTeam = (selectedTeam === 0 && dataSumaryAnualLeaves) || item.teamId === selectedTeam;
    return filterTeam;
  });
  const filterdSummarySummerLeaves = dataSummerLeaves?.filter((item) => {
    const filterTeam = (selectedTeam === 0 && dataSummerLeaves) || item.teamId === selectedTeam;
    return filterTeam;
  });
  const filterdCompensatoryLeavese = dataCompensatoryLeaves?.filter((item) => {
    const filterTeam = (selectedTeam === 0 && dataCompensatoryLeaves) || item.teamId === selectedTeam;
    return filterTeam;
  });

  return (
    <div className="h-full overflow-auto bg-white rounded-[10px]">
      <div className={style.container}>
        <div className={style.table_filter}>
          <div
            style={{
              // width: "245px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              gap: "10px"
            }}
          >
            <span>Team</span>
            <DropdownListSelectionFilter
              listSelection={teams?.map((team) => ({
                label: team.name,
                value: team.id
              }))}
              selected={teams ? selectedTeam : ""}
              setSelected={setSelectedTeam}
            />
          </div>

          <div
            style={{
              display: "flex",
              width: "100px",
              alignItems: "center",
              gap: "10px"
            }}
          >
            <div>
              <span>Year</span>
            </div>
            <DropdownListSelectionFilter
              listSelection={listYears?.map((item) => ({
                label: item.year,
                value: item.year
              }))}
              selected={selectedYear}
              setSelected={setSelectedYear}
            />
          </div>
        </div>
        <div>
          <div style={{ width: "100%", height: "700px" }}>
            <div style={{ textAlign: "center", margin: "10px 0" }}>
              <span className={style.table_title}>Annual Leave Of Member</span>
            </div>
            <DataGrid
              rowHeight={40}
              sx={{
                border: "none",
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "600",
                  fontSize: "16px"
                },
                minHeight: "100px",
                "--DataGrid-overlayHeight": "300px"
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15
                  }
                }
              }}
              pageSizeOptions={[15]}
              rows={filterdTeamAnnualLeave ?? []}
              columns={columnsAnnualLeaves}
              getRowId={(row) => row?.stt}
              autoPageSize
              hideFooterSelectedRowCount
              disableColumnMenu={true}
              disableColumnFilter={true}
              disableColumnSelector={true}
              disableColumnSort={true}
              disableRowSelectionOnClick={true}
              slots={{
                loadingOverlay: LoadingOverLay
              }}
              loading={isLoading}
            />
          </div>
          <div style={{ width: "100%", height: "700px", marginTop: "50px" }}>
            <div style={{ textAlign: "center", margin: "10px 0" }}>
              <span className={style.table_title}>Summer Leave Of Member</span>
            </div>
            <DataGrid
              rowHeight={40}
              sx={{
                border: "none",
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "600",
                  fontSize: "16px"
                },
                minHeight: "100px",
                "--DataGrid-overlayHeight": "300px"
              }}
              getRowId={(row) => row?.stt}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15
                  }
                }
              }}
              pageSizeOptions={[15]}
              rows={filterdSummarySummerLeaves ?? []}
              columns={columnsSummerLeaves}
              autoPageSize
              hideFooterSelectedRowCount
              disableColumnMenu={true}
              disableColumnFilter={true}
              disableColumnSelector={true}
              disableColumnSort={true}
              disableRowSelectionOnClick={true}
              slots={{
                loadingOverlay: LoadingOverLay
              }}
              loading={isLoading}
            />
          </div>

          <div style={{ width: "100%", height: "700px", marginTop: "50px" }}>
            <div style={{ textAlign: "center", margin: "10px 0" }}>
              <span className={style.table_title}>Compensatory Leave Of Member</span>
            </div>
            <DataGrid
              autoHeight
              rowHeight={40}
              sx={{
                border: "none",
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "600",
                  fontSize: "16px"
                },
                minHeight: "100px",
                "--DataGrid-overlayHeight": "300px"
              }}
              getRowId={(row) => row?.stt}
              rows={filterdCompensatoryLeavese ?? []}
              columns={columnsCompensatoryLeaves}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15
                  }
                }
              }}
              pageSizeOptions={[15]}
              slots={{ noRowsOverlay: CustomNoRowsOverlay }}
              hideFooterSelectedRowCount
              disableColumnMenu={true}
              disableColumnFilter={true}
              disableColumnSelector={true}
              disableColumnSort={true}
              disableRowSelectionOnClick={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryLeaveForm;
