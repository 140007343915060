import SearchInputField from "components/SearchInputFeild";
import * as React from "react";

export default function ViewGroup({ projectDetail }) {
  const [searchMemberValue, setMemberGroupValue] = React.useState("");

  const handleSearchGroup = (e) => {
    const newValue = e.target.value;
    setMemberGroupValue(newValue);
  };

  let filteredMembers = projectDetail?.members;
  if (searchMemberValue !== "") {
    filteredMembers = projectDetail?.members.filter((group) =>
      group.name.toLowerCase().includes(searchMemberValue.toLowerCase())
    );
  }

  return (
    <div className="rounded-xl bg-slate-100 ">
      <div className="w-[283px] pt-1 pl-3">
        <SearchInputField
          onChange={handleSearchGroup}
          name="FindGroups"
          placeholder="Find Group..."
          value={searchMemberValue}
        />
      </div>
      <div>
        <div className="flex font-semibold mb-1 w-full">
          <span className="w-2/4 text-center">Name</span>
          <span className="w-1/4 text-center">Team</span>
          <span className="w-1/4 text-center">Position</span>
        </div>
        <hr
          style={{
            color: "#000",
            backgroundColor: "black",
            height: 2,
            width: "95%",
            marginLeft: "14px"
          }}
        />
        <div className="h-[160px]">
          <ul className="max-h-40 overflow-auto px-2 py-1">
            {filteredMembers?.map((members) => {
              return (
                <li
                  key={members.id}
                  className="rounded-lg bg-slate-100 p-2 w-full flex cursor-pointer items-center h-10 hover:rounded-lg hover:bg-gray-300"
                >
                  <div className="w-2/4 text-base font-medium px-2 ">
                    <span>{members.fullName}</span>
                  </div>
                  <div className="w-2/4 flex justify-between items-center ">
                    <div className="ml-16 pl-2">
                      <span>{members.teamCode}</span>
                    </div>
                    <div className=" mr-8">
                      <span>{members.position}</span>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
