import axiosClient from "./api-config/axiosClient";

const AdminProjectAPI = {
  async GetOptionWorking() {
    const url = "/Project/GetOption";
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },
  async GetListView() {
    const url = "/Project/GetListView";
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },
  async GetDetail(id, view) {
    const url = `/Project/GetDetail/${id}/${view}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async Delete(id) {
    const url = `/Project/Delete/${id}`;
    return axiosClient.request({
      method: "DELETE",
      url: url
    });
  }
};

export default AdminProjectAPI;
