import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useQueries } from "@tanstack/react-query";
import AdminOverTimeAPI from "api/AdminOverTimeAPI";
import AdminTeamAPI from "api/AdminTeamAPI";
import MonthPickerInput from "components/DateTimePicker/MonthPickerInput";
import DropdownListSelectStatus from "components/DropdownListSelectStatus";
import DropdownListSelectionFilter from "components/DropdownListSelectionFilter";
import LoadingOverLay from "components/LoadingOverlay";
import { listOvertimeStatus, listStatusModify } from "components/SharedData";
import CustomNoRowsOverlay from "pages/admin-timesheet/finger-printer/CustomNoRowsOverlay";
import RequestDetail from "pages/user-overtime/components/Shared_Component/RequestDetail";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { formatDateTime } from "utils/DateTimeUtils";
import { ToastShowError, ToastShowSuccess, ToastShowWarning } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import { renderChipStatus } from "utils/renderChipStatus";
import useRefreshQuery from "utils/useRefreshQuery";

function Request() {
  const menuAccess = useSelector((state) => state.common.menuAccess);
  const adminMenu = menuAccess?.admin || [];

  const overtimePagePermission = adminMenu?.overtime;

  const canApprove = overtimePagePermission.tabRequest?.isApprove || false;

  const [selectedMonthYears, setSelectedMonthYears] = React.useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1
  });
  const [selectedStatus, setSelectedStatus] = React.useState("All");
  const [dataRow, setDataRow] = React.useState();
  const [selectedTeam, setSelectedTeam] = React.useState(0);

  const { refreshQuery: refreshQueryObject } = useRefreshQuery(["listViewHolidayQuery"]);

  const [listViewAdminOverTimeQuery, listOptionTeamQuery] = useQueries({
    queries: [
      {
        queryKey: ["listViewAdminOverTimeQuery", selectedMonthYears],
        queryFn: async () => {
          const response = await AdminOverTimeAPI.getListView(selectedMonthYears);
          return response.data;
        }
      },
      {
        queryKey: ["listOptionTeamQuery"],
        queryFn: async () => {
          const response = await AdminTeamAPI.getOptionTeam();
          return response.data;
        }
      }
    ]
  });

  const { data: listViewAdminOverTime, isLoading } = listViewAdminOverTimeQuery;

  const { data: listOptionTeam } = listOptionTeamQuery;

  const teams = useMemo(() => {
    if (listOptionTeam && !listOptionTeam.some((y) => y.name === "All")) {
      const newListOptionTeam = [...listOptionTeam];
      newListOptionTeam.unshift({
        name: "All",
        id: 0
      });
      return newListOptionTeam;
    }
    return listOptionTeam;
  }, [listOptionTeam]);

  const handleRowClick = (params) => {
    setDataRow(params.row);
  };

  const handleChangeStatusOverTime = async (id, value, setSelected, previousState) => {
    try {
      if (value === "Approve") {
        const response = await AdminOverTimeAPI.approve(id, true);
        if (response.status === 200) {
          ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
          refreshQueryObject();
        }
      } else if (value === "Reject") {
        const response = await await AdminOverTimeAPI.approve(id, false);
        if (response.status === 200) {
          refreshQueryObject();
          ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
        }
      } else {
        ToastShowWarning("Status không hợp lệ");
        setTimeout(() => {
          setSelected(previousState.current);
        }, 1500);
      }
    } catch (error) {
      // console.log("Error change status of leave ", error.response.data.errors.ERROR[0]);
      ToastShowError(error.response.data.errors.ERROR[0] || TOAST_ERROR_STRING);
      setTimeout(() => {
        setSelected(previousState.current);
      }, 1500);
    }
  };

  const columns = [
    {
      field: "status",
      headerName: "Status",
      width: 130,
      align: "center",
      headerClassName: "header-click",
      headerAlign: "center",

      renderCell: (params) => {
        return (
          <>
            {canApprove ? (
              <DropdownListSelectStatus
                listSelection={listStatusModify}
                initValue={params.row.status === "Calculate" ? "Approve" : params.row.status}
                id={params.row.id}
                handleChangeStatus={handleChangeStatusOverTime}
              />
            ) : (
              renderChipStatus(params.row.status)
            )}
          </>
        );
      }
    },
    {
      field: "createdName",
      headerName: "User",
      width: 200,
      align: "left",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "teamCode",
      headerName: "Team",
      width: 100,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "date",
      headerName: "Date",
      width: 100,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return formatDateTime(value);
      }
    },
    {
      field: "hourStr",
      headerName: "Hours",
      width: 70,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center"
    },
    {
      field: "actualHourStr",
      headerName: "Actual Hours",
      width: 120,
      align: "center",
      headerClassName: "header-click",

      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value;
        return value ? value : "-";
      }
    },
    {
      field: "projectName",
      headerName: "Project",
      flex: 1,
      align: "left",
      headerClassName: "header-click",

      headerAlign: "center"
    }
  ];

  const filteredOverTime =
    listViewAdminOverTime?.filter((overTime) => {
      const teamFilter = (selectedTeam === 0 && listViewAdminOverTime) || overTime.teamId === selectedTeam;
      const statusFilter =
        (selectedStatus === "All" && listViewAdminOverTime) ||
        (selectedStatus === "Pending" && overTime.status === selectedStatus) ||
        (selectedStatus === "Approve" && overTime.status === selectedStatus) ||
        (selectedStatus === "Calculate" && overTime.status === selectedStatus) ||
        (selectedStatus === "Reject" && overTime.status === selectedStatus);
      return statusFilter && teamFilter;
    }) || [];

  return (
    <div className="flex flex-col h-full w-full gap-2.5">
      <div className="flex flex-row min-h-[800px] w-full gap-2.5">
        {/* Table */}
        <div className="flex flex-col flex-grow overflow-hidden bg-white p-5 rounded-[10px]">
          <div className="flex items-center gap-[10px]">
            <div className="w-fit flex items-center gap-[10px]">
              <span>Team</span>
              <DropdownListSelectionFilter
                listSelection={teams?.map((team) => ({
                  label: team.name,
                  value: team.id
                }))}
                selected={teams ? selectedTeam : ""}
                setSelected={setSelectedTeam}
              />
            </div>
            <div className="flex items-center gap-[10px]">
              <span>Month</span>
              <MonthPickerInput selectedMonthYears={selectedMonthYears} setSelectedMonthYears={setSelectedMonthYears} />
            </div>
            <div className="flex items-center gap-[10px]">
              <div style={{ width: "max-content" }}>
                <span>Status</span>
              </div>
              <DropdownListSelectionFilter
                listSelection={listOvertimeStatus?.map((item) => ({
                  label: item.status,
                  value: item.status
                }))}
                selected={selectedStatus ?? listOvertimeStatus[0].value}
                setSelected={setSelectedStatus}
              />
            </div>
          </div>

          <Box
            className="flex-grow"
            sx={{
              height: "100%",
              width: "100%",
              "& .MuiDataGrid-columnHeaderTitle": {
                fontSize: "15px",
                fontWeight: 600
              }
            }}
          >
            <DataGrid
              rowHeight={40}
              sx={{
                border: "none",
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "600",
                  fontSize: "16px"
                },
                minHeight: "100px",
                ".MuiDataGrid-footerContainer": { borderTop: "none" },
                "--DataGrid-overlayHeight": "calc(100vh - 200px)"
              }}
              rows={filteredOverTime ?? []}
              columns={columns}
              getRowId={(row) => row?.id}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 14
                  }
                }
              }}
              pageSizeOptions={[14]}
              slots={{
                loadingOverlay: LoadingOverLay,
                noRowsOverlay: CustomNoRowsOverlay
              }}
              isLoading={isLoading}
              onRowClick={handleRowClick}
              hideFooterSelectedRowCount
              disableColumnMenu={true}
              disableColumnFilter={true}
              disableColumnSelector={true}
              disableColumnSort={true}
              disableRowSelectionOnClick={true}
            />
          </Box>
        </div>

        {/* RequestDetail */}
        <div className="w-[360px] h-[800px] overflow-y-auto bg-white rounded-[10px]">
          <RequestDetail dataRow={dataRow} />
        </div>
      </div>
    </div>
  );
}

export default Request;
