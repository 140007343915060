import { FormControl, MenuItem, Select } from "@mui/material";

import { css } from "@emotion/css";

const useStyle = {
  input__all: {
    display: "flex",
    width: "100%",
    height: "32px",
    whiteSpace: "pre-line",
    wordBreak: "break-all",
    alignItems: "center"
  },
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      outline: "none"
    },
    borderRadius: "10px",
    background: "#F0F0F0",
    height: "35px"
  }
};

function DropdownListSelectionFilter({ listSelection, selected, setSelected }) {
  const handleChange = (event) => {
    const value = event.target.value;
    setSelected(value);
  };
  return (
    <div style={useStyle.input__all}>
      <FormControl sx={{ width: 150 }}>
        <Select
          value={selected}
          onChange={handleChange}
          displayEmpty
          inputProps={{
            "aria-label": "Without label"
          }}
          size="small"
          className={css`
            background-color: #dcdcdc;
            height: 40px;
            .MuiOutlinedInput-notchedOutline {
              border: none;
              outline: none;
            }
          `}
          style={{ borderRadius: "8px" }}
        >
          {listSelection?.map((item) => (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default DropdownListSelectionFilter;
