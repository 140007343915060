import ResetPasswordAPI from "api/ResetPasswordAPI";
import background from "assets/images/login/LoginBackground.png";
import logo from "assets/images/shared/TTDLogo2.jpg";
import { Formik } from "formik";
import ButtonComponent from "pages/authentication/components/ButtonComponent";
import InputComponent from "pages/authentication/components/InputComponent";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const forgotPasswordSchema = Yup.object().shape({
  userName: Yup.string().email("Email không không đúng định dạng").required("Email không được để trống")
});

export default function ForgotPasswordPage() {
  const initialValues = { userName: "" };
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);

  const handleLogin = async (values, { setErrors }) => {
    const { userName } = values;
    try {
      const response = await ResetPasswordAPI.SendEmailRequest(userName);
      if (response.status === 204) {
        setIsSuccess(true);
      } else {
        setErrors({ userName: "Email không tồn tại" });
        setIsSuccess(false);
      }
    } catch (err) {
      setErrors({ userName: "Email không tồn tại" });
      setIsSuccess(false);
    }
  };

  return (
    <div
      style={{
        background: `linear-gradient(0, rgb(0 0 0 / 81%), rgb(175 175 175 / 25%)), url(${background})`
      }}
      className="h-screen w-screen flex justify-center items-center"
    >
      <div className="p-5 rounded-lg bg-white flex flex-col h-[270px]">
        <div className="w-full flex items-center justify-center gap-2 mb-[10px] pr-3">
          <img src={logo} alt="ttd-logo" className="h-[54px] object-contain w-[54px] border-none" />
          <h1 className="text-[42px] font-medium">{"T&T Design"}</h1>
        </div>
        {!isSuccess && (
          <Formik initialValues={initialValues} validationSchema={forgotPasswordSchema} onSubmit={handleLogin}>
            {({ values, handleChange, errors, handleSubmit, setErrors }) => (
              <div className="space-y-[10px] flex flex-col">
                <div className="flex flex-col gap-[2px] items-start justify-start">
                  <label htmlFor="userName" className="text-sm font-medium  pl-2">
                    Email
                  </label>
                  <InputComponent
                    autoFocus
                    type="userName"
                    name="userName"
                    id="userName"
                    className="bg-[#DCDCDC]  rounded-lg block p-2.5 w-[310px] text-sm px-2 py-[10px] outline-none border-none"
                    placeholder="email@ttdesignco.com"
                    value={values.userName}
                    onChange={handleChange}
                  ></InputComponent>
                  <div className="h-[16px] w-full">
                    {errors.userName && (
                      <span className="text-sm text-red-400 line-clamp-1 pl-[10px] font-normal">{errors.userName}</span>
                    )}
                  </div>
                </div>

                <ButtonComponent onClick={() => handleSubmit(values, setErrors)}>
                  <span className="!font-medium text-sm ">Reset password</span>
                </ButtonComponent>

                <button onClick={() => navigate("/login")}>
                  <span className="text-[#0045B7] text-base italic text-center">Already have a account!</span>
                </button>
              </div>
            )}
          </Formik>
        )}
        {isSuccess && (
          <div className="flex-1 flex flex-col justify-center items-center gap-[10px] w-[310px]">
            <span className="text-base font-medium">Xin hãy liên lạc với admin!</span>
            <button onClick={() => navigate("/login")}>
              <span className="text-[#0045B7] text-base italic text-center">Already have a account!</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
