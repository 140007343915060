import { Box, LinearProgress, Typography } from "@mui/material";
import EmailNotificationAPI from "api/EmailNotificationAPI";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

function MailRequest() {
  const { notification, baseUrl, params } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const sendingMailRequest = async () => {
      try {
        if (notification == null || baseUrl == null || params == null) {
          navigate("/email/expired");
          return;
        }

        const requestUrl = `/${notification}/${baseUrl}/${params}`;
        var response = await EmailNotificationAPI.sendingRequest(requestUrl);

        if (response.status === 200) {
          navigate("/email/success");
        } else if (response.data.message === "expired") {
          navigate("/email/expired");
        } else {
          navigate("/error");
        }
      } catch (err) {
        navigate("/error");
      }
    };
    sendingMailRequest();
  }, [params]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%"
      }}
    >
      <Typography sx={{ marginBottom: "5px" }}>Processing...</Typography>
      <LinearProgress sx={{ width: "36%", height: "9px" }} />
    </Box>
  );
}

export default MailRequest;
