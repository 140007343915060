import TabsCustom from "components/TabCustom/TabsCustom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTabStore } from "store/bearStore";
import findTabIndex from "utils/FindTabIndex";
import CanNotAccessPage from "views/error/CanNotAccess";
import Group from "./group";
import RoleAndPermission from "./role-and-permission";
import Team from "./team";
import User from "./user";

const AdminUser = () => {
  const tabIndex = useTabStore((state) => state.tabIndex);
  const changeTabIndex = useTabStore((state) => state.changeTabIndex);

  const menuAccess = useSelector((state) => state.common.menuAccess);
  const adminMenu = menuAccess?.admin || [];
  const userPagePermission = adminMenu?.user;

  const minIndex = findTabIndex(userPagePermission);
  useEffect(() => {
    changeTabIndex(minIndex);
  }, []);

  const allowPageAccess = userPagePermission.isView || false;

  const tabs = [
    {
      title: "User",
      isView: userPagePermission?.tabUser?.isView,
      isActive: tabIndex === 0,
      onClick: () => changeTabIndex(0)
    },
    {
      title: "Team",
      isActive: tabIndex === 1,
      isView: userPagePermission?.tabTeam?.isView,
      onClick: () => changeTabIndex(1)
    },
    {
      title: "Group",
      isActive: tabIndex === 2,
      isView: userPagePermission?.tabGroup?.isView,
      onClick: () => changeTabIndex(2)
    },
    {
      title: "Role & Permission",
      isActive: tabIndex === 3,
      isView: userPagePermission?.tabRole?.isView,
      onClick: () => changeTabIndex(3)
    }
  ];

  return (
    <div className="flex flex-col h-full w-full p-5 ">
      {allowPageAccess ? (
        <>
          <TabsCustom tabList={tabs} />
          <div className="h-full w-full flex-grow">
            {tabIndex === 0 && <User />}
            {tabIndex === 1 && <Team />}
            {tabIndex === 2 && <Group />}
            {tabIndex === 3 && <RoleAndPermission />}
          </div>
        </>
      ) : (
        <CanNotAccessPage />
      )}
    </div>
  );
};

export default AdminUser;
