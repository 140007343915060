import { ErrorMessage } from "@hookform/error-message";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { Controller } from "react-hook-form";

function TextInputTTD(props) {
  const { name, form, label, placeholder, type, disable, defaultValue, requiredInput, multiline } = props;

  const [valueText, setValueText] = useState(defaultValue);

  const {
    formState: { errors },
    setValue
  } = form;

  const handleChange = (e) => {
    const value = e.target.value;
    setValueText(value);
    setValue(name, value);
  };

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-row items-start ml-2">
        {label}

        {requiredInput && <p style={{ color: "red", marginLeft: "5px" }}>*</p>}
      </div>
      <Controller
        name={name}
        control={form.control}
        render={() => (
          <TextField
            value={valueText}
            onChange={handleChange}
            type={type}
            size="small"
            variant="outlined"
            placeholder={placeholder}
            fullWidth={true}
            disabled={disable}
            multiline={multiline ? true : false}
            sx={{
              borderRadius: "8px",
              backgroundColor: "#dcdcdc",
              ".MuiOutlinedInput-notchedOutline": {
                border: "none",
                outline: "flexnone"
              }
            }}
          />
        )}
      />

      <div style={{ height: "14px", width: "100%" }}>
        <span style={{ fontSize: "14px", color: "red", fontWeight: 400 }}>
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <p style={{ fontSize: "14px", color: "red", fontWeight: 400 }}>{message}</p>}
          />
        </span>
      </div>
    </div>
  );
}

export default TextInputTTD;
