import TabsCustom from "components/TabCustom/TabsCustom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTabStore } from "store/bearStore";
import findTabIndex from "utils/FindTabIndex";
import CanNotAccessPage from "views/error/CanNotAccess";
import Analysis from "./analysis";
import Category from "./category";
import FingerPrinter from "./finger-printer";
import Object from "./object";
import Project from "./project";
import Report from "./report";
import SwapDay from "./swap-day";

const AdminTimeSheet = () => {
  const tabIndex = useTabStore((state) => state.tabIndex);
  const changeTabIndex = useTabStore((state) => state.changeTabIndex);

  const menuAccess = useSelector((state) => state.common.menuAccess);
  const adminMenu = menuAccess?.admin || [];

  const timesheetPagePermission = adminMenu?.timesheet;

  const minIndex = findTabIndex(timesheetPagePermission);
  useEffect(() => {
    changeTabIndex(minIndex);
  }, []);
  const allowPageAccess = timesheetPagePermission.isView || false;

  const tabs = [
    {
      title: "Project",
      isView: timesheetPagePermission?.tabProject?.isView,
      isActive: tabIndex === 0,
      onClick: () => changeTabIndex(0)
    },
    {
      title: "Analysis",
      isActive: tabIndex === 1,
      isView: timesheetPagePermission?.tabAnalysis?.isView,
      onClick: () => changeTabIndex(1)
    },
    {
      title: "Object",
      isActive: tabIndex === 2,
      isView: timesheetPagePermission?.tabObject?.isView,
      onClick: () => changeTabIndex(2)
    },
    {
      title: "Category",
      isActive: tabIndex === 3,
      isView: timesheetPagePermission?.tabCategory?.isView,
      onClick: () => changeTabIndex(3)
    },
    {
      title: "Finger Printer",
      isActive: tabIndex === 4,
      isView: timesheetPagePermission?.tabFingerPrinter?.isView,
      onClick: () => changeTabIndex(4)
    },
    {
      title: "Swap Day",
      isActive: tabIndex === 5,
      isView: timesheetPagePermission?.tabSwapDay?.isView,
      onClick: () => changeTabIndex(5)
    },
    {
      title: "Report",
      isActive: tabIndex === 6,
      isView: timesheetPagePermission?.tabProject?.isView,
      onClick: () => changeTabIndex(6)
    }
  ];

  return (
    <div className="flex flex-col h-full w-full p-5 ">
      {allowPageAccess ? (
        <>
          <TabsCustom tabList={tabs} />
          <div className="h-full w-full flex-grow">
            {tabIndex === 0 && <Project />}
            {tabIndex === 1 && <Analysis />}
            {tabIndex === 2 && <Object />}
            {tabIndex === 3 && <Category />}
            {tabIndex === 4 && <FingerPrinter />}
            {tabIndex === 5 && <SwapDay />}
            {tabIndex === 6 && <Report />}
          </div>
        </>
      ) : (
        <CanNotAccessPage />
      )}
    </div>
  );
};

export default AdminTimeSheet;
