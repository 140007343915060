import { useQuery } from "@tanstack/react-query";
import AdminTeamAPI from "api/AdminTeamAPI";
import { listProjectStatus } from "components/SharedData";
import { useMemo, useState } from "react";

export const useProjectFilter = (dataProject) => {
  const [searchGroupValue, setSearchGroupValue] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedTeam, setSelectedTeam] = useState("All");

  const { data: teamsRes } = useQuery({
    queryKey: ["listOptionTeamQuery"],
    queryFn: async () => {
      const response = await AdminTeamAPI.getOptionTeam();
      return response.data;
    }
  });

  const teams = useMemo(() => {
    if (teamsRes && !teamsRes.some((y) => y.name === "All")) {
      const newListOptionTeam = [...teamsRes];
      newListOptionTeam.unshift({
        name: "All",
        id: 0
      });
      return newListOptionTeam;
    }
    return teamsRes;
  }, [teamsRes]);

  const filterProjects = useMemo(() => {
    let foundProjects = dataProject?.filter((p) => p.fullName.toLowerCase().includes(searchGroupValue.toLowerCase()));
    if (selectedTeam && selectedTeam !== "All")
      foundProjects = foundProjects.filter((p) => p?.teamName === selectedTeam);
    if (selectedStatus && selectedStatus !== "All")
      foundProjects = foundProjects.filter((p) => {
        return p?.status === selectedStatus;
      });
    return foundProjects ?? [];
  }, [searchGroupValue, selectedTeam, selectedStatus, dataProject]);

  return {
    teams,
    listProjectStatus,
    filterProjects,
    searchGroupValue,
    setSearchGroupValue,
    selectedStatus,
    setSelectedStatus,
    selectedTeam,
    setSelectedTeam
  };
};
