import { yupResolver } from "@hookform/resolvers/yup";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useMutation, useQuery } from "@tanstack/react-query";
import AdminGroupAPI from "api/AdminGroupAPI";
import CustomInputField from "components/CustomInputField";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import useRefreshQuery from "utils/useRefreshQuery";
import * as yup from "yup";
import Members from "./Members";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Trường này yêu cầu nhập"),
    code: yup.string().required("Trường này yêu cầu nhập")
  })
  .required();

function GroupModal(props) {
  let { isNewGroup, open, onClose, dataRow, refreshQueryObjectListView, disable, groupId } = props;
  const [dataDetail, setDataDetail] = React.useState([]);

  const { refreshQuery: refreshQueryObject } = useRefreshQuery(["groupDetailQuery"]);

  const { isSuccess, data, isLoading } = useQuery({
    queryKey: ["groupDetailQuery", groupId],
    queryFn: async () => {
      const response = await AdminGroupAPI.getDetail(groupId);
      return response.data;
    },
    enabled: !!groupId
  });

  useEffect(() => {
    if (isSuccess) setDataDetail(data);
  }, [isSuccess]);

  const mutationCreateGroup = useMutation({
    mutationFn: (value) => {
      return AdminGroupAPI.createGroup(value);
    },
    onError: (error) => {
      const errorData = error.response.data.errors.code[0];
      if (error.response.status === 400) {
        ToastShowError(errorData || TOAST_ERROR_STRING);
      } else ToastShowError(TOAST_ERROR_STRING);
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      onClose();
      refreshQueryObjectListView();
      refreshQueryObject();
    }
  });

  const mutationUpdateGroup = useMutation({
    mutationFn: (value) => {
      return AdminGroupAPI.updateGroup(value);
    },
    onError: (error) => {
      const errorData = error.response.data.errors.code[0];
      if (error.response.status === 400) {
        ToastShowError(errorData || TOAST_ERROR_STRING);
      } else ToastShowError(TOAST_ERROR_STRING);
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      onClose();
      refreshQueryObjectListView();
      refreshQueryObject();
    }
  });

  const defaultFormValues = isNewGroup
    ? {
        name: "",
        code: ""
      }
    : {
        name: dataRow?.name,
        code: dataRow?.code
      };

  const form = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema)
  });

  const handleSubmit = async () => {
    var valueSubmit = form.getValues();

    const memberUpdate = dataDetail?.members?.map((user) => user.id) ?? [];
    valueSubmit = {
      code: valueSubmit?.code,
      name: valueSubmit?.name,
      id: groupId || null,
      members: memberUpdate
    };

    if (isNewGroup) {
      mutationCreateGroup.mutate(valueSubmit);
    } else {
      mutationUpdateGroup.mutate(valueSubmit);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent sx={{ padding: "20px" }}>
        {!isNewGroup ? (
          <>
            <h2 className="text-center text-xl font-bold uppercase">Update Group</h2>
            <div className="flex gap-3 mb-2">
              <div className="w-3/4">
                <CustomInputField
                  name="name"
                  label={
                    <>
                      <span>Name</span>
                      <span className="text-[red] text-base ml-1">*</span>
                    </>
                  }
                  form={form}
                  disable={disable}
                  style={{ borderRadius: "10px" }}
                  defaultValues={form.defaultValues}
                />
              </div>
              <div className="w-1/4">
                <CustomInputField
                  name="code"
                  label={
                    <>
                      <span>Code</span>
                      <span className="text-[red] text-base ml-1">*</span>
                    </>
                  }
                  form={form}
                  disable={disable}
                  style={{ borderRadius: "10px", backgroud: "#ccc" }}
                />
              </div>
            </div>
            <p className="ml-2 text-base">Members</p>
            <div>
              <Members
                form={form}
                disable={disable}
                dataDetail={dataDetail}
                setDataDetail={setDataDetail}
                isNewGroup={isNewGroup}
              />
            </div>
            <div className="flex justify-end gap-3 pt-5">
              <Button
                variant="contained"
                size="small"
                onClick={form.handleSubmit(handleSubmit)}
                sx={{
                  textTransform: "uppercase",
                  boxShadow: "none",
                  "&:hover": { boxShadow: "none", background: "#bccdea" },
                  background: "#bccdea",
                  color: "#001b48",
                  fontWeight: "600",
                  borderRadius: "10px",
                  width: "90px",
                  padding: "5px 0"
                }}
              >
                OK
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  onClose();
                }}
                size="small"
                sx={{
                  textTransform: "capitalize",
                  boxShadow: "none",
                  "&:hover": { boxShadow: "none", background: "#f0f0f0" },
                  background: "#f0f0f0",
                  color: "#555555",
                  fontWeight: "600",
                  borderRadius: "10px",
                  width: "90px",
                  padding: "5px 0"
                }}
              >
                Cancel
              </Button>
            </div>
          </>
        ) : (
          <>
            <h2 className="text-center text-xl font-bold uppercase">New Group</h2>

            <div className="flex gap-3 mb-2">
              <div className="w-3/4">
                <CustomInputField
                  name="name"
                  label={
                    <>
                      <span>Name</span>
                      <span className="text-[red] text-base ml-1">*</span>
                    </>
                  }
                  form={form}
                  disable={disable}
                  style={{ borderRadius: "10px" }}
                  defaultValues={form.defaultValues}
                />
              </div>
              <div className="w-1/4">
                <CustomInputField
                  name="code"
                  label={
                    <>
                      <span>Code</span>
                      <span className="text-[red] text-base ml-1">*</span>
                    </>
                  }
                  form={form}
                  disable={disable}
                  style={{ borderRadius: "10px", backgroud: "#ccc" }}
                />
              </div>
            </div>
            <p className="ml-2 text-base ">Members</p>
            <Members
              form={form}
              disable={disable}
              dataDetail={dataDetail}
              setDataDetail={setDataDetail}
              open={open && isSuccess}
              isNewGroup={isNewGroup}
            />
            <div className="flex justify-end gap-3 pt-5">
              <Button
                variant="contained"
                size="small"
                onClick={form.handleSubmit(handleSubmit)}
                sx={{
                  textTransform: "uppercase",
                  boxShadow: "none",
                  "&:hover": { boxShadow: "none", background: "#bccdea" },
                  background: "#bccdea",
                  color: "#001b48",
                  fontWeight: "600",
                  borderRadius: "10px",
                  width: "90px",
                  padding: "5px 0"
                }}
              >
                OK
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  onClose();
                }}
                size="small"
                sx={{
                  textTransform: "capitalize",
                  boxShadow: "none",
                  "&:hover": { boxShadow: "none", background: "#f0f0f0" },
                  background: "#f0f0f0",
                  color: "#555555",
                  fontWeight: "600",
                  borderRadius: "10px",
                  width: "90px",
                  padding: "5px 0"
                }}
              >
                Cancel
              </Button>
            </div>
          </>
        )}
      </DialogContent>
      <Backdrop
        sx={{ opacity: "0", backgroundColor: "rgba(0, 0, 0, 0.2)", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Dialog>
  );
}

export default GroupModal;
