import ContactMailIcon from "@mui/icons-material/ContactMail";
import { Dialog, Fab } from "@mui/material";
import { useQueries, useQuery } from "@tanstack/react-query";
import CalendarAPI from "api/CalendarAPI";
import TimeSheetAPI from "api/TimeSheetAPI";
import CalendarDatePicker from "components/DateTimePicker/CalendarDatePicker";
import MemberListStatus from "components/MemberListStatus";
import TagContent from "components/TagContent";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { useState } from "react";
import "./Dashboard.scss";
import DateNotice from "./components/DateNotice";
import PipeChartProject from "./components/PipeChartProject";

const formatDateToCustomString = (date) => {
  if (!(date instanceof Date)) {
    console.error("Invalid date object");
    return null;
  }
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}/${month}/${day}`;
};

const DashBoard = () => {
  const [month, setMonth] = useState(dayjs(new Date()));

  const [timeSheetQuery, noticesQuery] = useQueries({
    queries: [
      {
        queryKey: ["timeSheetQuery"],
        queryFn: async () => {
          const response = await TimeSheetAPI.getDashboardTimesheet();
          return response?.data;
        }
      },
      {
        queryKey: ["noticesQuery"],
        queryFn: async () => {
          const response = await CalendarAPI.getDashboardNotices();
          return response?.data;
        }
      }
    ]
  });

  const workInforQuery = useQuery({
    queryKey: ["workInforQuery", month],
    queryFn: async () => {
      const param = {
        year: dayjs(month).year(),
        month: dayjs(month).month() + 1
      };
      const response = await TimeSheetAPI.getDashboardProject(param);
      return response?.data;
    }
  });

  const handleChangeMonth = (dateChanged) => {
    setMonth(dateChanged);
  };

  const { data: timesheetData } = timeSheetQuery;
  const { data: noticesData } = noticesQuery;
  const { data: workInforData } = workInforQuery;

  const [openMemberList, setOpenMemberList] = useState(false);

  const handleClickMemberListPopup = () => {
    setOpenMemberList(true);
  };

  const handleCloseMemberList = () => {
    setOpenMemberList(false);
  };

  const margin = "15px";

  return (
    <>
      <div className="h-full w-full p-5 flex flex-row max-h-[94vh]">
        <div className="w-[85%] h-full pr-5 grid overflow-hidden grid-cols-[65%,35%] grid-rows-[5%,12%,auto]">
          <div className="flex items-center justify-start">
            <div className="text-xl font-bold leading-none text-[#001B48]">
              Hôm nay: {formatDateToCustomString(new Date())}
            </div>
          </div>

          <div className="flex items-center justify-center">
            <CalendarDatePicker month={month} onChangeMonth={handleChangeMonth} optionsView={["year", "month"]} />
          </div>

          <div className="flex flex-row w-full text-xl font-semibold leading-none">
            <div className="flex flex-col w-full h-full  bg-white justify-between rounded-[10px] mr-4 p-4">
              <p>TIME IN</p>
              <p className="text-4xl">{timesheetData?.timeIn ? timesheetData?.timeIn : "--:--"}</p>
            </div>

            <div className="flex flex-col w-full h-full  bg-white justify-between rounded-[10px] mr-4 p-4">
              <p>TIME OUT</p>
              <p className="text-4xl">{timesheetData?.timeOut ? timesheetData?.timeOut : "--:--"}</p>
            </div>
          </div>

          <div className="flex flex-row w-full p-4 bg-white rounded-[10px] text-xl font-semibold leading-none">
            <div className="flex flex-col w-full h-full justify-between">
              <p>WORKING</p>
              <p className="text-4xl">{workInforData?.strWorkingHour}</p>
            </div>
            <div className="flex flex-col w-full h-full justify-between">
              <p>OT</p>
              <p className="text-4xl">{workInforData?.strOvertimeHour}</p>
            </div>
            <div className="flex flex-col w-full h-full justify-between">
              <p>LEAVE</p>
              <p className="text-4xl">{workInforData?.strLeaveHour}</p>
            </div>
          </div>

          <div className="flex w-full h-full pt-4 pr-4">
            <div className="flex flex-col w-full pt-4 pr-4 bg-white p-[15px] rounded-[10px]">
              <div className="border-b-2 border-black mb-5 font-bold text-xl">Notice</div>
              <div className="flex flex-col w-full pt-4 bg-white max-h-[62vh] overflow-auto">
                {noticesData?.map((item, index) => (
                  <DateNotice key={index} notice={item} />
                ))}
              </div>
            </div>
          </div>

          {window.screen.width > 1200 && (
            <div className="flex w-full h-full pt-4">
              <div className="flex flex-col h-full w-full pt-4 pr-4 bg-white p-[15px] rounded-[10px]">
                <div className="mb-5 font-medium">Hours by project</div>
                <div className="flex h-full w-full items-start">
                  <PipeChartProject projectInfo={workInforData?.projects} />
                </div>
              </div>
            </div>
          )}
        </div>
        {window.screen.width > 639 && (
          <div className="flex flex-col h-full w-[20%] bg-white px-[10px] py-[20px] rounded-[10px]">
            <MemberListStatus />
          </div>
        )}
      </div>
      {window.screen.width < 1200 && (
        <TagContent
          title="PROJECT ANALYSIS"
          marginTop={margin}
          width="100%"
          lineHeight="150px"
          height="fit-content"
          id="dashboard__project-id"
        >
          <PipeChartProject />
        </TagContent>
      )}
      {window.screen.width < 640 && (
        <>
          <Fab
            color="secondary"
            size="medium"
            aria-label="edit"
            id="floating__member-list-id"
            onClick={handleClickMemberListPopup}
          >
            <ContactMailIcon />
          </Fab>
          <Dialog onClose={handleCloseMemberList} open={openMemberList} components="div">
            <div className="floating__member-list">
              <MemberListStatus />
            </div>
          </Dialog>
        </>
      )}
    </>
  );
};

export default DashBoard;
