import axiosClient from "./api-config/axiosClient";

const AdminOverTimeAPI = {
  async getListView({ year, month }) {
    const url = `/Overtime/GetListView/${year}/${month}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async approve(id, isApprove) {
    const url = `/Overtime/Approve/${id}/${isApprove}`;
    return axiosClient.request({
      method: "POST",
      url: url
    });
  },

  async updateRule(params) {
    const url = `/Overtime/UpdateRule`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: params
    });
  },

  async getReport(params) {
    const url = `Overtime/GetReport/${params}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getOverTimeInformation() {
    const url = `Overtime/GetOvertimeInformation`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  }
};
export default AdminOverTimeAPI;
