import { Paper } from "@mui/material";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getTimesheetDetail } from "store/timesheetSlice";

import TimeSheetAPI from "api/TimeSheetAPI";
import { useTaskStore } from "store/bearStore";
import { checkIsAdmin } from "utils/accessUltils";
import KeyTimeSheet from "./components/KeyTimeSheet";

import { useEffect } from "react";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_CREATE_SUCCESS_STRING, TOAST_ERROR_STRING } from "utils/ToastString";
import UserSelect from "../overview/UserSelect";
import DateTimeSheetDetailPicker from "./components/DateTimeSheetDetailPicker";
import TagWorkInfo from "./components/TagWorkInfo";

function TimeSheetDetailPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userIdSelected = useSelector((state) => state.timesheet.userIdSelected);
  const currentUser = useSelector((state) => state.user.current);
  const isCurrentUser = currentUser?.id === userIdSelected;

  const daySelected = useSelector((state) => state.timesheet.daySelected);

  const tasksOfDate = useSelector((state) => state.timesheet.taskDetails);

  const menuAccess = useSelector((state) => state.common.menuAccess);
  const viewOther = menuAccess?.staff?.timesheetOther?.isView || false;
  const listProjectTasks = useTaskStore((state) => state.listProjectTasks);

  const isWFH = tasksOfDate?.isWfh;

  const { hourTotal, hourWorking, timeIn, timeOut, lock } = tasksOfDate || {};

  const disableKeyTimeSheet =
    (hourTotal === "00:00" && hourWorking === "00:00" && timeIn === "--:--" && timeOut === "--:--") || lock;

  let timeParams = {
    userId: userIdSelected,
    month: dayjs(daySelected).month() + 1,
    year: dayjs(daySelected).year(),
    day: dayjs(daySelected).date(),
    isAdmin: checkIsAdmin(menuAccess)
  };

  const handleSaveTimesheetTasks = async () => {
    let valueSubmit = {
      id: tasksOfDate?.id || null,
      projects: listProjectTasks
    };

    try {
      const response = await TimeSheetAPI.saveTimesheet(valueSubmit);
      if (response?.status === 200) {
        ToastShowSuccess(TOAST_CREATE_SUCCESS_STRING);
        await dispatch(getTimesheetDetail(timeParams));
      }
    } catch (error) {
      ToastShowError(error?.response?.data || TOAST_ERROR_STRING);
    }
  };

  const handleBackToOverViewPage = () => {
    navigate(`/timesheet`);
  };

  const fetchDataForTimeParams = async () => {
    await dispatch(getTimesheetDetail(timeParams));
  };

  useEffect(() => {
    fetchDataForTimeParams();
  }, []);

  return (
    <div className="flex w-full h-full bg-bg_primary items-start justify-start flex-col p-5 overflow-hidden gap-[0.625rem]">
      <div className="flex w-full items-center justify-between flex-row">
        <div className="flex w-[20%] items-center justify-start flex-row">{viewOther && <UserSelect />}</div>

        <div className="flex w-[60%] items-center justify-center flex-row">
          <DateTimeSheetDetailPicker />
        </div>
        <div className="flex w-[20%] items-center justify-end flex-row">
          <Button
            variant="contained"
            size="small"
            sx={{
              textTransform: "capitalize",
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
                backgroundColor: "#BCCDEA"
              },
              background: "#BCCDEA",
              color: "#001B48",
              fontWeight: 600,
              borderRadius: "10px",
              width: "90px",
              padding: "5px 0"
            }}
            onClick={handleBackToOverViewPage}
          >
            Back
          </Button>
        </div>
      </div>

      <div className="flex w-full items-center justify-between flex-row">
        <div className="flex w-[70%] items-center justify-start flex-row">
          <TagWorkInfo title="TIME IN" marginRight="10px">
            <div className="line-height-5 text-[20px] font-normal text-[#31692b]">
              {isWFH ? "WFH" : tasksOfDate?.timeIn}
            </div>
          </TagWorkInfo>

          <TagWorkInfo title="TIME OUT" marginRight="10px">
            <div className="line-height-5 text-[20px] font-normal text-[#874341]">
              {isWFH ? "WFH" : tasksOfDate?.timeOut}
            </div>
          </TagWorkInfo>

          <TagWorkInfo title="FG TIME (hours)" width="160px">
            <div className="line-height-5 text-[20px] font-normal text-[#001b48]">
              {isWFH ? "WFH" : tasksOfDate?.hourTotal}
            </div>
          </TagWorkInfo>
        </div>

        <div className="flex w-[25%] items-center justify-end flex-row">
          <TagWorkInfo title="TOTAL TIME (hours)" width="185px">
            <div className="line-height-5 text-[20px] font-normal text-[#001b48]">
              {isWFH ? "WFH" : tasksOfDate?.hourWorking}
            </div>
          </TagWorkInfo>
        </div>
      </div>

      <Paper selevation={3} style={{ width: "100%", borderRadius: "10px", flexGrow: 1 }} variant="outlined">
        <div className="flex w-full items-center justify-center flex-col p-5">
          <div className="flex w-full items-end justify-end pb-[10px]">
            <Button
              variant="contained"
              size="small"
              onClick={handleSaveTimesheetTasks}
              disabled={!isCurrentUser || disableKeyTimeSheet}
              sx={{
                textTransform: "capitalize",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "#BCCDEA"
                },
                background: "#BCCDEA",
                color: "#001B48",
                fontWeight: 600,
                borderRadius: "10px",
                width: "90px",
                padding: "5px 0"
              }}
            >
              Save
            </Button>
          </div>

          <div className="w-full max-h-[65vh] overflow-y-auto">
            <KeyTimeSheet tasksOfDate={tasksOfDate} disableKeyTimeSheet={disableKeyTimeSheet} />
          </div>
        </div>
      </Paper>
    </div>
  );
}
export default TimeSheetDetailPage;
