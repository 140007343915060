const Tab = ({ title, isActive, isView, onClick }) => {
  const activeClasses = isActive
    ? "text-[#2344b5] border-[#2344b5] dark:text-blue-500 dark:border-blue-500"
    : "hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300";

  return (
    <li
      onClick={onClick}
      className={`me-2 ${isView ? "inline-block " : "hidden"} p-2 cursor-pointer border-b-2 rounded-t-lg ${activeClasses}`}
    >
      {title}
    </li>
  );
};

const TabsCustom = ({ tabList }) => {
  return (
    <div className="text-sm font-medium text-center pb-4 text-gray-500 dark:text-gray-400 dark:border-gray-700 ">
      <ul className="flex flex-wrap -mb-px">
        {tabList.map((tab, index) => (
          <Tab key={index} title={tab.title} isActive={tab.isActive} isView={tab.isView} onClick={tab.onClick} />
        ))}
      </ul>
    </div>
  );
};

export default TabsCustom;
