import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, IconButton, Paper } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { useRef, useState } from "react";

function CalendarDatePicker(props) {
  const { month, onChangeMonth } = props;
  const [openCalendar, setOpenCalendar] = useState(false);
  const calendarBtnRef = useRef();
  const calendarRef = useRef();
  const [calendarBtnValues, setCalendarBtnValues] = useState({
    left: 0,
    top: 0
  });

  function handleCalendarPrev() {
    const prevMonth = month.subtract(1, "month");
    onChangeMonth(prevMonth);
  }

  function handleCalendarNext() {
    const nextMonth = month.add(1, "month");
    onChangeMonth(nextMonth);
  }

  const toggleCalendar = () => {
    const { offsetTop, offsetLeft, offsetHeight, offsetWidth } = calendarBtnRef.current;
    const calendarWidth = calendarRef.current.offsetWidth;
    setCalendarBtnValues({
      ...calendarBtnValues,
      left: offsetLeft - (calendarWidth - offsetWidth),
      top: offsetTop + offsetHeight
    });
    setOpenCalendar(!openCalendar);
  };

  //close calendar popup when clicked away
  const clickAwayCalendar = () => {
    if (openCalendar) {
      setOpenCalendar(false);
    }
  };
  return (
    <div
      className="h-full flex justify-between items-center text-[#02457a]"
      direction="row"
      spacing={1}
      ref={calendarRef}
    >
      <IconButton aria-label="previous" sx={{ color: "#02457a" }} onClick={handleCalendarPrev}>
        <NavigateBeforeIcon />
      </IconButton>
      <div className="flex flex-row uppercase items-center font-bold text-xl">
        {dayjs(month).format("YYYY/MM")}
        <ClickAwayListener onClickAway={clickAwayCalendar}>
          <div>
            <IconButton aria-label="calendar" sx={{ color: "#02457a" }} ref={calendarBtnRef} onClick={toggleCalendar}>
              <CalendarTodayIcon sx={{ color: "#02457a" }} />
            </IconButton>
            {openCalendar && (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    height: "auto",
                    position: "absolute",
                    left: calendarBtnValues.left,
                    top: calendarBtnValues.top,
                    margin: "auto",
                    backgroundColor: "#f8f8fb",
                    zIndex: 100
                  }
                }}
              >
                <Paper elevation={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DemoContainer components={["DateCalendar"]}>
                      <DateCalendar
                        minDate={new Date(`${new Date().getFullYear() - 5}-01-01`)}
                        maxDate={new Date(`${new Date().getFullYear() + 6}-12-01`)}
                        defaultValue={new Date()}
                        value={month.toDate()}
                        views={["year", "month"]}
                        openTo="month"
                        onChange={(newMonth) => {
                          onChangeMonth(dayjs(newMonth));
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Paper>
              </Box>
            )}
          </div>
        </ClickAwayListener>
      </div>

      <IconButton aria-label="previous" onClick={handleCalendarNext}>
        <NavigateNextIcon sx={{ color: "#02457a" }} />
      </IconButton>
    </div>
  );
}

export default CalendarDatePicker;
