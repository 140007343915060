import * as React from "react";

import { css } from "@emotion/css";
import DatePickerComponent from "../DatePickerComponent";

export default function MonthPickerInput(props) {
  const { setSelectedMonthYears } = props;
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedMonthYears({
      month: date.getMonth() + 1,
      year: date.getFullYear()
    });
  };

  return (
    <DatePickerComponent
      value={selectedDate}
      onChange={handleDateChange}
      openTo="month"
      views={["year", "month"]}
      format="yyyy/MM"
      className={css`
        background-color: #dcdcdc;
        border-radius: 8px;
        max-width: 100px;
        height: 40px;
        overflow: hidden;
        .MuiOutlinedInput-notchedOutline {
          border: none;
          outline: none;
        }
        .MuiInputBase-root {
          min-width: 100px !important;
          min-height: 36px !important;
        }
      `}
      style={{ minWidth: "140px !important" }}
    ></DatePickerComponent>
  );
}
