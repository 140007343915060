import axiosClient from "./api-config/axiosClient";

const HolidayAPI = {
  async GetInfoHoliday(params) {
    const url = `/Holiday/GetInfor/${params}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },
  async GetListView(params) {
    const url = `/Holiday/GetListView/${params}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },
  async CreateHoliday(params) {
    const url = `/Holiday/create`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: params
    });
  },
  async DeleteHoliday(params) {
    const url = `/Holiday/Delete/${params}`;
    return axiosClient.request({
      method: "DELETE",
      url: url
    });
  }
};

export default HolidayAPI;
