import { Divider, Menu, MenuItem } from "@mui/material";
import { getColor } from "components/SelectionField";
import UserInfoPopUp from "components/UserInformationPopup/UserInfoPopup";
import React from "react";
import { useNavigate } from "react-router-dom";
import UrlConstant from "utils/constants/UrlConstant";
import "./../shared_component.scss";
import BadgeAvatars from "./avatar";

MemberItemStatus.propTypes = {};

function MemberItemStatus({ user }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const avatarPath = `${UrlConstant.REACT_APP_DATABASE_URL}${user?.avatar}`;
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  let userPopUp = null;
  if (openDialog === true) {
    userPopUp = <UserInfoPopUp userInfo={user} open={openDialog} onClose={handleDialogClose} />;
  }

  const clickUserItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickViewInformation = () => {
    setOpenDialog(true);
  };

  const clickViewCalendar = () => {
    navigate(`/calendar/${user?.id}`);
  };
  const userMenu = (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: -2,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          }
        }
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem onClick={clickViewInformation}>View Information</MenuItem>
      <Divider />
      <MenuItem onClick={clickViewCalendar}>View Calendar</MenuItem>
    </Menu>
  );

  return (
    <>
      <div className="memberitem__status " onClick={clickUserItem}>
        <BadgeAvatars
          fullName={user?.name?.split(" ").pop()}
          avatarPath={avatarPath}
          stateColor={getColor(user?.state)}
        />
        <div className="memberitem__status-name">{user?.name}</div>
      </div>
      {userMenu}
      {userPopUp}
    </>
  );
}

export default MemberItemStatus;
