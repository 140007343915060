import moment from "moment";
import DateNoticeTask from "./DateNoticeTask";

function DateNotice(props) {
  const { notice } = props;
  const { date, tasks } = notice;

  return (
    <div className="flex w-full flex-col items-start font-semibold text-sm rounded-lg cursor-default gap-1">
      <div className="flex w-full border-b-2 border-gray-500">{moment(date).format("YYYY/MM/DD")}</div>

      <div className="flex w-full flex-col rounded-lg p-2 gap-2">
        {tasks &&
          tasks.map((task, index) => {
            return <DateNoticeTask key={`task-${index}`} task={task} />;
          })}
      </div>
    </div>
  );
}

export default DateNotice;
