import axiosClient from "./api-config/axiosClient";

const RoleAPI = {
  async getListView() {
    const url = `/Role/GetListView`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getDetail(id) {
    const url = `/Role/GetDetail/${id}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getCollectionRole() {
    const url = `/Role/GetCollectionRole`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async create(params) {
    const url = `/Role/Create`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: params
    });
  },

  async update(params) {
    const url = `/Role/Update/`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: params
    });
  },

  async delete(id) {
    const url = `/Role/Delete/${id}`;
    return axiosClient.request({
      method: "DELETE",
      url: url
    });
  },

  async getOption() {
    const url = `/Role/GetOption`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  }
};

export default RoleAPI;
