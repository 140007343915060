import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Dialog, Typography } from "@mui/material";
import DatePickerTTD from "components/DateTimePicker/DatePickerTTD";
import TextAreaInputField from "components/TextAreaInputField";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { formatDateYYYYMMDD } from "utils/DateTimeUtils";
import * as yup from "yup";

const schema = yup.object().shape({
  startTime: yup.string().required("Yêu cầu nhập"),
  endTime: yup.string().required("Yêu cầu nhập"),
  reason: yup.string().required("Yêu cầu nhập")
});

const WFHModal = ({ open, onClose, wfhInfo, onSubmitWFHForm }) => {
  const { id, startTime, endTime, reason } = wfhInfo ?? {};
  const initValue = {
    startTime: dayjs(startTime) ?? dayjs().add(1, "day"),
    endTime: dayjs(endTime) ?? dayjs().add(1, "day"),
    reason: reason ?? ""
  };

  const form = useForm({
    defaultValues: initValue,
    resolver: yupResolver(schema)
  });

  const handleClose = () => {
    onClose(false);
  };

  const handleSubmit = async () => {
    const valueForm = form.getValues();
    let valueSubmit = {
      id: wfhInfo?.id,
      startTime: formatDateYYYYMMDD(valueForm.startTime),
      endTime: formatDateYYYYMMDD(valueForm.endTime),
      reason: valueForm.reason
    };

    onSubmitWFHForm(valueSubmit, form.setError);
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth="lg" PaperProps={{ sx: { borderRadius: "5px" } }}>
      <div className="p-5 max-w-sm">
        <div className="mb-2.5 text-center text-lg font-semibold">
          <Typography variant="h5" sx={{ fontWeight: "600", paddingTop: "10px" }}>
            {id ? "Update" : "New"}
          </Typography>
        </div>

        <div className="flex flex-col">
          <div className="flex items-center gap-3">
            <DatePickerTTD name="startTime" label="Start Date" form={form} requiredInput={true} />
            <DatePickerTTD name="endTime" label="End Date" requiredInput={true} form={form} />
          </div>
        </div>

        <div className="mt-2.5">
          <TextAreaInputField
            name="reason"
            label={
              <div className="flex flex-row items-start ml-2">
                Work Description
                <p style={{ color: "red", marginLeft: "5px" }}>*</p>
              </div>
            }
            minRows={3}
            maxRows={3}
            form={form}
          />
        </div>

        <div className="flex justify-end">
          <Button
            variant="contained"
            size="small"
            onClick={form.handleSubmit(handleSubmit)}
            sx={{
              textTransform: "uppercase",
              boxShadow: "none",
              "&:hover": { boxShadow: "none", backgroundColor: "#BCCDEA" },
              background: "#BCCDEA",
              color: "#001B48",
              fontWeight: 600,
              borderRadius: "10px",
              width: "90px",
              padding: "5px 0"
            }}
          >
            OK
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleClose();
            }}
            size="small"
            sx={{
              textTransform: "capitalize",
              boxShadow: "none",
              "&:hover": { boxShadow: "none", background: "#F0F0F0" },
              background: "#F0F0F0",
              color: "#555555",
              fontWeight: 600,
              borderRadius: "10px",
              width: "90px",
              padding: "5px 0",
              marginLeft: "20px"
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default WFHModal;
