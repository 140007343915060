import { Paper } from "@mui/material";

function TagWorkInfo({
  title = "TITLE",
  width = "120px",
  height = "70px",
  marginTop = "0px",
  marginBtn = "0px",
  marginLeft = "0px",
  marginRight = "0px",
  borderRadius = "10px",
  children,
  id
}) {
  const styleObject = {
    width: width,
    height: height,
    marginTop: marginTop,
    marginBtn: marginBtn,
    marginLeft: marginLeft,
    marginRight: marginRight,
    borderRadius: borderRadius,
    boxShadow: "none"
  };

  return (
    <Paper style={styleObject} selevation={3} square id={id}>
      <div className="flex flex-col p-3">
        <div className="text-[16px] font-semibold leading-tight">{title}</div>
        <div>{children}</div>
      </div>
    </Paper>
  );
}

export default TagWorkInfo;
