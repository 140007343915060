import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTaskStore } from "store/bearStore";
import TaskNew from "./TaskNew";
import TaskTitle from "./TaskTitile";
import TaskWork from "./TaskWork";

function removeEmptyTask(arr) {
  return arr.filter((item) => item.description !== "");
}

function replaceElementByProjectId(arr, projectId, newElement) {
  return arr.map((item) => (item.projectId === projectId ? { ...item, ...newElement } : item));
}

function TasksView(props) {
  const { projectTasks, disableKeyTimeSheet } = props;

  const listProjectTasks = useTaskStore((state) => state.listProjectTasks);

  const setTasksInDay = useTaskStore((state) => state.initTask);

  const listCategories = useSelector((state) => state.timesheet.timesheetCategories);
  const listObjects = useSelector((state) => state.timesheet.timesheetObjects);

  const createDefaultTask = () => ({
    categoryId: listCategories[0]?.id,
    category: listCategories[0]?.name,
    objectId: listObjects[0]?.id,
    object: listObjects[0]?.name,
    description: "",
    type: "",
    hours: "00:00"
  });

  const clonedTask = createDefaultTask();
  const tasks = projectTasks?.tasks ? projectTasks.tasks : [];
  const initAllTask = [...tasks, clonedTask];
  const [tasksNew, setTasksNew] = useState();

  const addClonedTask = () => {
    setTasksNew((prevTasks) => [...prevTasks, clonedTask]);
  };

  const handleDeleteProjectTask = (projectId) => {
    const deletedProjectTasks = listProjectTasks.filter((item) => item.projectId !== projectId);
    setTasksInDay(deletedProjectTasks);
  };

  const handleDeleteTask = (taskIndex) => {
    const newArray = tasksNew.filter((task, index) => index !== taskIndex);

    let projectReplacedTask = {
      ...projectTasks,
      tasks: removeEmptyTask(newArray)
    };

    setTasksInDay(replaceElementByProjectId(listProjectTasks, projectTasks?.projectId, projectReplacedTask));

    setTasksNew(newArray);
  };

  const handleReplaceTask = (newObj, taskIndex) => {
    const newArray = [...tasksNew];
    newArray[taskIndex] = newObj;

    let projectReplacedTask = {
      ...projectTasks,
      tasks: removeEmptyTask(newArray)
    };

    setTasksInDay(replaceElementByProjectId(listProjectTasks, projectTasks?.projectId, projectReplacedTask));

    setTasksNew(newArray);
  };

  useEffect(() => {
    setTasksNew(initAllTask);
  }, [listCategories, listObjects]);

  return (
    <>
      <TaskTitle
        taskInfo={projectTasks}
        onDeleteProjectTask={handleDeleteProjectTask}
        disableKeyTimeSheet={disableKeyTimeSheet}
      />
      {tasksNew?.map((task, index) => {
        return (
          <React.Fragment key={index}>
            {task?.id ? (
              <TaskWork
                taskInfo={task}
                taskIndex={index}
                replaceTask={handleReplaceTask}
                onDeleteTask={handleDeleteTask}
                disableKeyTimeSheet={disableKeyTimeSheet}
              />
            ) : (
              <TaskNew
                taskInfo={task}
                taskIndex={index}
                handleDeleteTask={handleDeleteTask}
                replaceTask={handleReplaceTask}
                isLastTask={index === tasksNew.length - 1}
                handleAddTask={addClonedTask}
                disableKeyTimeSheet={disableKeyTimeSheet}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}

export default TasksView;
