import { css } from "@emotion/css";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, FormControl, Menu, MenuItem, Select, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import AdminTimeSheetAPI from "api/AdminTimesheetAPI";
import ExportAPI from "api/ExportAPI";
import DatePickerComponent from "components/DateTimePicker/DatePickerComponent";
import LoadingOverLay from "components/LoadingOverlay";
import dayjs from "dayjs";
import fileDownload from "js-file-download";
import { useState } from "react";
import { useSelector } from "react-redux";
import { formatDateMM, formatDateYYYY } from "utils/DateTimeUtils";
import CustomNoRowsOverlay from "../finger-printer/CustomNoRowsOverlay";

function Report() {
  const listTeams = useSelector((state) => state.common.teams);
  const [teamId, setTeamId] = useState(0);
  const [dateSelected, setDateSelected] = useState(new Date());
  const [type, setType] = useState(0);
  const [startEndText, setStartEndText] = useState(
    `${dayjs(new Date()).startOf("month").format("MM/DD")} ~ ${dayjs(new Date()).endOf("month").format("MM/DD")}`
  );

  const reportQuery = useQuery({
    queryKey: ["fingerPrintQuery", type, dateSelected],
    queryFn: async () => {
      const params = {
        type: type,
        year: formatDateYYYY(dateSelected),
        month: formatDateMM(dateSelected)
      };
      const response = await AdminTimeSheetAPI.getReport(params);
      return response?.data;
    }
  });
  const { data: reportQueryData } = reportQuery;

  const handleExportSummary = async () => {
    const params = {
      type: type,
      year: formatDateYYYY(dateSelected),
      month: formatDateMM(dateSelected)
    };

    const response = await ExportAPI.getReportSummary(params);

    let fileName;
    if (type === 0) {
      fileName = startEndText;
    } else {
      fileName = "20th ~ 19th";
    }

    const outputFilename = `SummaryOfTimeSheet_${fileName}.xlsx`;

    fileDownload(response.data, outputFilename);
  };

  const handleExportByTeam = async () => {
    const params = {
      type: type,
      year: formatDateYYYY(dateSelected),
      month: formatDateMM(dateSelected)
    };

    const response = await ExportAPI.getReportByTeam(params);

    let fileName;
    if (type === 0) {
      fileName = startEndText;
    } else {
      fileName = "20th ~ 19th";
    }

    const outputFilename = `ExportReportByTeam_${fileName}.xlsx`;

    fileDownload(response.data, outputFilename);
  };

  const columns = [
    {
      field: "stt",
      headerName: "STT",
      headerClassName: "header-click",
      width: 75,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1
    },
    {
      field: "userName",
      headerName: "User",
      headerClassName: "header-click",
      headerAlign: "center",
      align: "left",
      width: 350,
      sortable: false
    },
    {
      field: "team",
      headerName: "Team",
      headerClassName: "header-click",
      headerAlign: "center",
      align: "center",
      width: 250,
      sortable: false
    },
    {
      field: "workingDay",
      headerClassName: "header-click",
      headerName: "Working Day",
      headerAlign: "center",
      sortable: false,
      align: "center",
      width: 160
    },
    {
      field: "workLogStr",
      headerClassName: "header-click",
      headerName: "Work Log",
      sortable: false,
      headerAlign: "center",
      align: "center",
      width: 160
    },
    {
      field: "holidayStr",
      headerClassName: "header-click",
      headerName: "Holiday",
      sortable: false,
      headerAlign: "center",
      align: "center",
      width: 160
    },
    {
      field: "unpaidLeaveStr",
      headerClassName: "header-click",
      headerName: "Unpaid Leave",
      sortable: false,
      headerAlign: "center",
      align: "center",
      width: 160
    },
    {
      field: "paidLeaveStr",
      headerClassName: "header-click",
      headerName: "Paid Leave",
      width: 100,
      headerAlign: "center",
      align: "center",
      sortable: false
    },
    {
      field: "totalOvertimeStr",
      headerClassName: "header-click",
      headerName: "Over Time",
      sortable: false,
      headerAlign: "center",
      align: "center",
      width: 160
    }
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenDownload = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const downloadMenu = (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          borderRadius: "8px",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))"
        }
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem onClick={handleExportSummary}>Export Summary Timesheet</MenuItem>
      <MenuItem onClick={handleExportByTeam}>Export Timesheet by Team</MenuItem>
    </Menu>
  );

  return (
    <div className="flex flex-col h-full w-full bg-white rounded-lg p-5 gap-1.5 ">
      <div className="flex flex-row items-center justify-between gap-2 ">
        <div className="flex flex-row w-[80%] items-center gap-2">
          <div className="flex flex-row w-[25%] items-center gap-2">
            <Typography>Team</Typography>
            <FormControl>
              <Select
                value={teamId}
                onChange={(e) => setTeamId(e.target.value)}
                style={{
                  borderRadius: "8px",
                  backgroundColor: "#dcdcdc",
                  height: "40px",
                  ".MuiOutlinedInputNotchedOutline": {
                    border: "none",
                    outline: "flexnone"
                  },
                  width: "200px"
                }}
                className={css`
                  .MuiOutlinedInput-notchedOutline {
                    border: none;
                    outline: none;
                  }
                `}
              >
                <MenuItem value={0}>All</MenuItem>
                {Array.isArray(listTeams) &&
                  listTeams.map((user, index) => {
                    return (
                      <MenuItem key={index} value={user?.id}>
                        {user?.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <div className="flex flex-row w-full items-center gap-2">
            <div className="flex flex-row w-[26%] items-center gap-2">
              <p>Month</p>
              <DatePickerComponent
                value={dateSelected}
                onChange={(newValue) => {
                  setDateSelected(newValue);
                  setStartEndText(
                    `${dayjs(dateSelected).startOf("month").format("MM/DD")} ~ ${dayjs(dateSelected).endOf("month").format("MM/DD")}`
                  );
                }}
                onMonthChange={() => {
                  setStartEndText(
                    `${dayjs(dateSelected).startOf("month").format("MM/DD")} ~ ${dayjs(dateSelected).endOf("month").format("MM/DD")}`
                  );
                }}
                format="yyyy/MM"
                sx={{
                  width: "40%",
                  borderRadius: "8px",
                  backgroundColor: "#dcdcdc",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    outline: "flexnone"
                  }
                }}
                views={["month", "year"]}
              ></DatePickerComponent>
            </div>

            <div className="flex flex-row items-center gap-2">
              <Typography>Type</Typography>
              <FormControl>
                <Select
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#dcdcdc",
                    height: "40px",
                    ".MuiOutlinedInputNotchedOutline": {
                      border: "none",
                      outline: "flexnone"
                    },
                    width: "100%",
                    minWidth: "200px"
                  }}
                  className={css`
                    .MuiOutlinedInput-notchedOutline {
                      border: none;
                      outline: none;
                    }
                  `}
                >
                  <MenuItem value={0}>20th ~ 19th</MenuItem>
                  <MenuItem value={1}>{startEndText}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="flex flex-row h-full items-center gap-2">
          <DownloadIcon sx={{ cursor: "pointer" }} onClick={handleOpenDownload} />
        </div>
      </div>

      <Box
        className="flex-grow"
        sx={{
          height: "100%",
          width: "100%",
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "15px",
            fontWeight: 600
          }
        }}
      >
        <DataGrid
          rowHeight={40}
          sx={{
            border: "none",
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
              fontSize: "16px"
            },
            minHeight: "100px",
            ".MuiDataGrid-footerContainer": { borderTop: "none" },
            "--DataGrid-overlayHeight": "calc(100vh - 200px)"
          }}
          rows={reportQueryData ?? []}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 14
              }
            }
          }}
          getRowId={(row) => row.userId}
          pageSizeOptions={[14]}
          slots={{
            loadingOverlay: LoadingOverLay,
            noRowsOverlay: CustomNoRowsOverlay
          }}
          hideFooterSelectedRowCount
          disableColumnMenu={true}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableColumnSort={true}
          disableRowSelectionOnClick={true}
        />
      </Box>
      {downloadMenu}
    </div>
  );
}

export default Report;
