import * as echarts from "echarts";
import { useEffect } from "react";

PipeChartProject.propTypes = {};

let optionChart = {
  legendWidth: 350,
  height: "100%",
  emFontSize: "16",
  labelFontSize: "14",
  showLegend: true
};
function CheckResponsiveForChart() {
  let width = window.screen.width;
  if (width >= 360 && width < 639) {
    optionChart.legendWidth = 150;
    optionChart.emFontSize = "12";
    optionChart.labelFontSize = "10";
    optionChart.centerLeft = "48%";
    optionChart.showLegend = false;
  }
  if (width >= 640 && width < 767) {
    optionChart.legendWidth = 150;
    optionChart.emFontSize = "12";
    optionChart.labelFontSize = "12";
  }
  if (width >= 768 && width < 1199) {
    optionChart.legendWidth = 200;
    optionChart.emFontSize = "14";
    optionChart.labelFontSize = "14";
  }
  if (width >= 1200 && width < 1399) {
    optionChart.legendWidth = 230;
    optionChart.emFontSize = "14";
    optionChart.labelFontSize = "14";
  }
}

function PipeChartProject({ projectInfo }) {
  useEffect(() => {
    CheckResponsiveForChart();
    const option = {
      color: [
        "#1f78b4",
        "#a6cee3",
        "#b2df8a",
        "#33a02c",
        "#fb9a99",
        "#e31a1c",
        "#fdc06f",
        "#ff7f00",
        "#cab2d6",
        "#6a3d9a",
        "#ffff99",
        "#b15928",
        "#a6cee3",
        "#1f78b4"
      ],
      tooltip: {
        trigger: "item"
      },
      legend: {
        show: true,
        icon: "circle",
        textStyle: {
          fontSize: "14",
          width: optionChart.legendWidth
        },
        orient: "vertical",
        height: "38%",
        top: "60%",
        left: "2%",
        type: "scroll"
      },
      series: [
        {
          name: "PROJECT ANALYSIS",
          type: "pie",
          // center: [optionChart.centerLeft, optionChart.centerTop],
          center: ["50%", "30%"],

          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
            width: "100%"
          },
          label: {
            show: true,
            position: "inner",
            formatter: "{c} (h)\n\n {d}%",
            fontSize: optionChart.labelFontSize.toString(),
            fontWeight: "600"
          },
          emphasis: {
            label: {
              show: true,
              fontSize: optionChart.labelFontSize.toString(),
              fontWeight: "bold"
            }
          },
          labelLine: {
            show: false
          },
          data:
            projectInfo &&
            projectInfo.map((item) => ({
              name: item?.name,
              value: item?.workingHours
            }))
        }
      ]
    };

    var chartDom = document.getElementById("dashboard__ProjectPipeID");
    var myChart = echarts.init(chartDom);
    option && myChart.setOption(option);

    myChart.on("legendselectchanged", function (params) {
      suppressSelection(myChart, params);
      // console.log(e);
      // Add custom functionality here
    });

    function suppressSelection(chart, params) {
      chart.setOption({ animation: false });

      // Re-select what the user unselected
      chart.dispatchAction({
        type: "legendSelect",
        name: params.name
      });
      chart.setOption({ animation: true });
    }
  });

  return <div id="dashboard__ProjectPipeID" style={{ width: "100%", height: "100%" }}></div>;
}

export default PipeChartProject;
