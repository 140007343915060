import SaveIcon from "@mui/icons-material/Save";
import { Button, Dialog, Grid, TextField, Typography } from "@mui/material";
import AdminTimeSheetAPI from "api/AdminTimesheetAPI";
import { ToastShowError } from "utils/Notify";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

function EditTimeSheetPopup(props) {
  const numberRegex = new RegExp("^[0-9]+$");
  const currentUser = useSelector((state) => state.user.current);

  const closePopupHandle = () => {
    props?.setOpenEdit(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      timeInHours: props.timeIn?.substring(0, 2).toString().padStart(2, "0") || "",
      timeInMinutes: props.timeIn?.substring(3, 5).toString().padStart(2, "0") || "",
      timeOutHours: props.timeOut?.substring(0, 2).toString().padStart(2, "0") || "",
      timeOutMinutes: props.timeOut?.substring(3, 5).toString().padStart(2, "0") || ""
    }
  });

  const onSubmit = async (data) => {
    let timeInEdit = data.timeInHours
      .toString()
      .padStart(2, "0")
      .concat(":", data.timeInMinutes.toString().padStart(2, "0"))
      .concat(":00");

    let timeOutEdit = data.timeOutHours
      .toString()
      .padStart(2, "0")
      .concat(":", data.timeOutMinutes.toString().padStart(2, "0"))
      .concat(":00");

    let dateTimeInEdit = props?.date.toLocaleDateString("en-CA").concat(" ", timeInEdit);

    let dateTimeOutEdit = props?.date.toLocaleDateString("en-CA").concat(" ", timeOutEdit);

    let dataSubmit = {
      timesheetId: props?.timesheetId,
      date: props?.date,
      timeIn: dateTimeInEdit,
      timeOut: dateTimeOutEdit,
      modifiedBy: currentUser?.id
    };
    if (timeInEdit > timeOutEdit) {
      ToastShowError("Time in must be less than time out");
    } else if (timeInEdit > "24:00:00" || timeOutEdit > "24:00:00") {
      ToastShowError("Time need to be less than 24hours");
    } else if (timeInEdit === timeOutEdit) {
      ToastShowError("Time in is the same as time out");
    } else {
      await AdminTimeSheetAPI.editTimesheet(dataSubmit);
      if (props?.showEditSuccess) {
        props?.showEditSuccess("Timesheet has been updated.");
      }
      props?.setOpenEdit(false);
    }
  };

  const allowKey = [8, 9, 37, 38, 39, 40, 46];

  return (
    <div>
      <Dialog open={props?.isOpen} onClose={closePopupHandle}>
        <div className="shared__userinfomation">
          <div className="userinformation__title">
            <Typography variant="h5" sx={{ fontWeight: "600", color: "var(--bs-stratos)" }}>
              EDIT TIMESHEET
            </Typography>
          </div>
          <div className="userinformation__form">
            <Grid container>
              <Grid item xs={12} style={{ paddingTop: "5%", paddingBottom: "2%" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "var(--bs-stratos)"
                  }}
                >
                  Time In
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Controller
                  name="timeInHours"
                  control={control}
                  render={() => <TextField />}
                  rules={{ required: true }}
                  inputProps={{
                    maxLength: 2,
                    style: {
                      height: "9vh",
                      padding: "0 14px",
                      fontSize: "90px",
                      textAlign: "center"
                    }
                  }}
                  defaultValue={props.timeIn?.substring(0, 2).toString().padStart(2, "0")}
                  size="small"
                  variant="outlined"
                  fullWidth={true}
                  onKeyDown={(event) => {
                    if (allowKey.includes(event.keyCode) || numberRegex.test(event.key)) {
                      return;
                    } else {
                      event.preventDefault();
                    }
                  }}
                  error={!!errors.timeInHours}
                />
                {errors.timeInHours && <Typography variant="inherit">Please enter time in hours</Typography>}
              </Grid>
              <Grid item xs={1}>
                <Typography
                  style={{
                    height: "9vh",
                    fontSize: "90px",
                    textAlign: "center",
                    lineHeight: "10vh"
                  }}
                >
                  :
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Controller
                  name="timeInMinutes"
                  control={control}
                  render={() => <TextField />}
                  rules={{ required: true }}
                  inputProps={{
                    maxLength: 2,
                    style: {
                      height: "9vh",
                      padding: "0 14px",
                      fontSize: "90px",
                      textAlign: "center"
                    }
                  }}
                  defaultValue={props.timeIn?.substring(3, 5).toString().padStart(2, "0")}
                  size="small"
                  variant="outlined"
                  fullWidth={true}
                  onKeyDown={(event) => {
                    if (allowKey.includes(event.keyCode) || numberRegex.test(event.key)) {
                      return;
                    } else {
                      event.preventDefault();
                    }
                  }}
                  error={!!errors.timeInMinutes}
                />
                {errors.timeInMinutes && <Typography variant="inherit">Please enter time in minutes</Typography>}
              </Grid>

              <Grid item xs={12} style={{ paddingTop: "5%", paddingBottom: "2%" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "var(--bs-stratos)"
                  }}
                >
                  Time Out
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Controller
                  name="timeOutHours"
                  control={control}
                  render={() => <TextField />}
                  rules={{ required: true }}
                  inputProps={{
                    maxLength: 2,
                    style: {
                      height: "9vh",
                      padding: "0 14px",
                      fontSize: "90px",
                      textAlign: "center"
                    }
                  }}
                  defaultValue={props.timeOut?.substring(0, 2).padStart(2, "0")}
                  size="small"
                  variant="outlined"
                  fullWidth={true}
                  onKeyDown={(event) => {
                    if (allowKey.includes(event.keyCode) || numberRegex.test(event.key)) {
                      return;
                    } else {
                      event.preventDefault();
                    }
                  }}
                  error={!!errors.timeOutHours}
                />

                {errors.timeOutHours && <Typography variant="inherit">Please enter time out hours</Typography>}
              </Grid>
              <Grid item xs={1}>
                <Typography
                  style={{
                    height: "9vh",
                    fontSize: "90px",
                    textAlign: "center",
                    lineHeight: "10vh"
                  }}
                >
                  :
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Controller
                  name="timeOutMinutes"
                  control={control}
                  render={() => <TextField />}
                  rules={{ required: true }}
                  inputProps={{
                    maxLength: 2,
                    style: {
                      height: "9vh",
                      padding: "0 14px",
                      fontSize: "90px",
                      textAlign: "center"
                    }
                  }}
                  defaultValue={props.timeOut?.substring(3, 5).padStart(2, "0")}
                  size="small"
                  variant="outlined"
                  fullWidth={true}
                  onKeyDown={(event) => {
                    if (allowKey.includes(event.keyCode) || numberRegex.test(event.key)) {
                      return;
                    } else {
                      event.preventDefault();
                    }
                  }}
                  error={!!errors.timeOutMinutes}
                />

                {errors.timeOutMinutes && <Typography variant="inherit">Please enter time out minutes</Typography>}
              </Grid>
              <Controller
                name={"timeOutHours"}
                control={control}
                render={() => (
                  <TextField
                    style={{ marginRight: "5px" }}
                    defaultValue={props?.timeOut.substring(0, 2)}
                    inputProps={{ maxLength: 2 }}
                  />
                )}
              />

              <Controller
                name={"timeOutMinutes"}
                control={control}
                render={() => (
                  <TextField
                    style={{ marginRight: "5px" }}
                    defaultValue={props?.timeOut.substring(3, 5)}
                    inputProps={{ maxLength: 2 }}
                  />
                )}
              />
            </Grid>
            <div className="userinformation__form-section-06" style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                className="button-save"
                variant="contained"
                style={{ width: "100px" }}
                onClick={handleSubmit(onSubmit)}
                startIcon={<SaveIcon />}
              >
                SAVE
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default EditTimeSheetPopup;
