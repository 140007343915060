import { css } from "@emotion/css";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";

function ComboBox(props) {
  const { name, form, label, disable, listSelection, setReferenceToRoleValue } = props;

  return (
    <div className="flex flex-col items-start">
      <div className="flex flex-row items-start ml-2">{label}</div>
      <Controller
        control={form.control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <FormControl fullWidth>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              disabled={disable}
              onChange={(e) => {
                onChange(e.target.value);
                setReferenceToRoleValue(e.target.value);
              }}
              style={{ borderRadius: "8px", background: "#dcdcdc" }}
              className={css`
                background-color: #dcdcdc;
                border-radius: 8px;
                .MuiOutlinedInput-notchedOutline {
                  border: none;
                  outline: none;
                }
                width: 100%;
              `}
            >
              {listSelection?.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  <p>{item.label}</p>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      <div style={{ height: "14px", width: "100%" }}>
        {form.errors && form.errors[name] && (
          <span style={{ fontSize: "14px", color: "red", fontWeight: 400 }}>{form.errors[name]?.message}</span>
        )}
      </div>
    </div>
  );
}

export default ComboBox;
