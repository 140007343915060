import axiosClient from "./api-config/axiosClient";

const NotificationAPI = {
  async getListView() {
    const url = `/Notification/GetListView`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async markRead(id) {
    const url = `/Notification/MarkRead/${id}`;
    return axiosClient.request({
      method: "PUT",
      url: url
    });
  },

  async markReadAll() {
    const url = `/Notification/MarkReadAll`;
    return axiosClient.request({
      method: "PUT",
      url: url
    });
  },
  async deleteAll() {
    const url = `/Notification/DeleteAll`;
    return axiosClient.request({
      method: "PUT",
      url: url
    });
  },

  async deleteNotification(id) {
    const url = `/Notification/Delete/${id}`;
    return axiosClient.request({
      method: "PUT",
      url: url
    });
  }
};
export default NotificationAPI;
