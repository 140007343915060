import { yupResolver } from "@hookform/resolvers/yup";
import RoleAPI from "api/RolesAPI";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { Backdrop, Button, CircularProgress, Dialog, DialogContent } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import ComboBox from "components/ComboBox";
import CustomInputField from "components/CustomInputField";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import { showAllValueErrors } from "utils/formErrorUtil";
import useRefreshQuery from "utils/useRefreshQuery";
import PermissionsComponent from "./PermissionsComponent";

const schema = yup
  .object()
  .shape({
    roleName: yup.string().required("Trường này yêu cầu nhập")
  })
  .required();

function RoleModal(props) {
  let {
    isNewRole,
    open,
    onClose,
    roleInfo,
    disabled,
    dataNewRole,
    setDataNewRole,
    listOptionRole,
    refreshQueryListViewRole,
    refreshQueryListOptionRole,
    listCollectionRole
  } = props;

  const [newRoles, setNewRoles] = React.useState();
  const [roleDetail, setRoleDetail] = React.useState();
  const [roleReference, setRoleReference] = React.useState();
  const [referenceToRoleValue, setReferenceToRoleValue] = React.useState(roleInfo?.id ?? undefined);

  const { refreshQuery: refreshQueryDetail } = useRefreshQuery(["roleDetailQuery"]);

  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ["roleDetailQuery", referenceToRoleValue],
    queryFn: async () => {
      const response = await RoleAPI.getDetail(referenceToRoleValue);
      return response.data;
    }
  });

  React.useEffect(() => {
    if (isNewRole && referenceToRoleValue) {
      setRoleReference(data);
    }
    if (isNewRole && !referenceToRoleValue) {
      setNewRoles(listCollectionRole);
    } else {
      setRoleDetail(data);
    }
  }, [isSuccess, data]);

  const mutationNewRole = useMutation({
    mutationFn: (value) => {
      return RoleAPI.create(value);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        const errorData = error.response.data.errors;
        ToastShowError(showAllValueErrors(errorData));
      } else ToastShowError(TOAST_ERROR_STRING);
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      handleClose();
      refreshQueryListViewRole();
      refreshQueryListOptionRole();
      refreshQueryDetail();
    }
  });

  const mutationUpdateRole = useMutation({
    mutationFn: (value) => {
      return RoleAPI.update(value);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        const errorData = error.response.data.errors;
        ToastShowError(showAllValueErrors(errorData));
      } else ToastShowError(TOAST_ERROR_STRING);
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      handleClose();
      refreshQueryListViewRole();
      refreshQueryListOptionRole();
      refreshQueryDetail();
    }
  });

  const defaultValuesFormData = React.useMemo(() => {
    return isNewRole
      ? {
          roleName: "",
          referenceToRole: ""
        }
      : {
          id: roleInfo?.id,
          roleName: roleInfo?.name,
          referenceToRole: roleInfo?.id
        };
  }, [isNewRole]);

  const form = useForm({
    defaultValues: {
      ...defaultValuesFormData
    },
    resolver: yupResolver(schema)
  });

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async () => {
    const formValue = form.getValues();

    const valueSubmit = {
      id: roleInfo?.id || null,
      name: formValue?.roleName,
      roles: roleDetail?.roles ?? dataNewRole ?? roleReference?.roles
    };

    if (isNewRole) {
      mutationNewRole.mutate(valueSubmit);
    } else mutationUpdateRole.mutate(valueSubmit);
  };

  return (
    <Dialog
      open={!!open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { borderRadius: "5px" } }}
    >
      <DialogContent sx={{ padding: "20px" }}>
        {!isNewRole && roleInfo.type === "Custom" ? (
          <>
            <h2 className="text-center mb-3 text-xl font-bold capitalize ">Update</h2>
            <div className="max-h-[700px]">
              <div className="pb-4">
                <div className="w-full">
                  <div className="min-w-[500px]">
                    <CustomInputField
                      name="roleName"
                      label={
                        <>
                          <span>Role Name</span>
                          <span className="text-[red] text-base ml-1">*</span>
                        </>
                      }
                      form={form}
                      disable={disabled}
                    />
                  </div>
                  <div>
                    <ComboBox
                      name="referenceToRole"
                      label={
                        <>
                          <span>Reference To Role</span>
                          <span className="text-[red] text-base ml-1 invisible">*</span>
                        </>
                      }
                      disable={disabled}
                      form={form}
                      listSelection={listOptionRole?.map((item) => {
                        return { value: item.id, label: item.name };
                      })}
                      setReferenceToRoleValue={setReferenceToRoleValue}
                    />
                  </div>
                </div>
                <span>Permission</span>
                <div className="overflow-y-scroll h-[430px] bg-[#f0f0f0] rounded-xl">
                  <PermissionsComponent
                    form={form}
                    disabled={disabled}
                    roleDetail={roleDetail}
                    isNewRole={isNewRole}
                    setNewRoles={setNewRoles}
                    newRoles={newRoles}
                    setRoleDetail={setRoleDetail}
                    dataNewRole={dataNewRole}
                    setDataNewRole={setDataNewRole}
                    referenceToRoleValue={referenceToRoleValue}
                    roleReference={roleReference}
                    setRoleReference={setRoleReference}
                  />
                </div>
              </div>
              <div className="flex gap-3 justify-end">
                <Button
                  variant="contained"
                  size="small"
                  onClick={form.handleSubmit(handleSubmit)}
                  sx={{
                    textTransform: "capitalize",
                    boxShadow: "none",
                    "&:hover": { boxShadow: "none", background: "#bccdea" },
                    background: "#bccdea",
                    color: "#001b48",
                    fontWeight: "600",
                    borderRadius: "10px",
                    width: "90px",
                    padding: "5px 0"
                  }}
                >
                  update
                </Button>

                <Button
                  variant="contained"
                  onClick={() => onClose()}
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    boxShadow: "none",
                    "&:hover": { boxShadow: "none", background: "#f0f0f0" },
                    background: "#f0f0f0",
                    color: "#555555",
                    fontWeight: "600",
                    borderRadius: "10px",
                    width: "90px",
                    padding: "5px 0"
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </>
        ) : !isNewRole && roleInfo.type === "Default" ? (
          <>
            <h2 className="text-center mb-3 text-xl font-bold capitalize ">View</h2>
            <div className="max-h-[700px]">
              <div className="pb-4">
                <div className="w-full">
                  <div className="min-w-[500px]">
                    <CustomInputField
                      name="roleName"
                      label={
                        <>
                          <span>Role Name</span>
                          <span className="text-[red] text-base ml-1">*</span>
                        </>
                      }
                      form={form}
                      disable={disabled}
                    />
                  </div>
                  <div>
                    <ComboBox
                      name="referenceToRole"
                      label={
                        <>
                          <span>Reference To Role</span>
                          <span className="text-[red] text-base ml-1 invisible">*</span>
                        </>
                      }
                      disable={disabled}
                      form={form}
                      listSelection={listOptionRole?.map((item) => {
                        return { value: item.id, label: item.name };
                      })}
                      setReferenceToRoleValue={setReferenceToRoleValue}
                    />
                  </div>
                </div>
                <span>Permissions</span>
                <div className="overflow-y-scroll h-[430px] bg-[#f0f0f0] rounded-xl">
                  <PermissionsComponent
                    form={form}
                    disabled={disabled}
                    roleDetail={roleDetail}
                    isNewRole={isNewRole}
                    setNewRoles={setNewRoles}
                    newRoles={newRoles}
                    setRoleDetail={setRoleDetail}
                    dataNewRole={dataNewRole}
                    setDataNewRole={setDataNewRole}
                    referenceToRoleValue={referenceToRoleValue}
                    roleReference={roleReference}
                    setRoleReference={setRoleReference}
                  />
                </div>
              </div>
              <div className="flex gap-3 justify-end">
                <Button
                  variant="contained"
                  size="small"
                  onClick={form.handleSubmit(handleSubmit)}
                  sx={{
                    textTransform: "capitalize",
                    boxShadow: "none",
                    "&:hover": { boxShadow: "none", background: "#bccdea" },
                    background: "#bccdea",
                    color: "#001b48",
                    fontWeight: "600",
                    borderRadius: "10px",
                    width: "90px",
                    padding: "5px 0",
                    visibility: "hidden"
                  }}
                >
                  Update
                </Button>

                <Button
                  variant="contained"
                  onClick={() => onClose()}
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    boxShadow: "none",
                    "&:hover": { boxShadow: "none", background: "#f0f0f0" },
                    background: "#f0f0f0",
                    color: "#555555",
                    fontWeight: "600",
                    borderRadius: "10px",
                    width: "90px",
                    padding: "5px 0"
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <h2 className="text-center mb-3 text-xl font-bold capitalize ">New</h2>
            <div className="max-h-[700px]">
              <div className="pb-4">
                <div className="w-full">
                  <div className="min-w-[500px]">
                    <CustomInputField
                      name="roleName"
                      label={
                        <>
                          <span className="font-['Roboto Flex'] font-sans">Role Name</span>
                          <span className="text-[red] text-base ml-1">*</span>
                        </>
                      }
                      form={form}
                      disable={disabled}
                    />
                  </div>
                  <div>
                    <ComboBox
                      name="referenceToRole"
                      label={
                        <>
                          <span className="font-['Roboto Flex'] font-sans">Reference To Role</span>
                          <span className="text-[red] text-base ml-1">*</span>
                        </>
                      }
                      disable={disabled}
                      form={form}
                      listSelection={listOptionRole?.map((item) => {
                        return { value: item.id, label: item.name };
                      })}
                      initValue={listOptionRole[0].id}
                      setReferenceToRoleValue={setReferenceToRoleValue}
                    />
                  </div>
                </div>
                <span>Permission</span>
                <div className="overflow-y-scroll h-[430px] bg-[#f0f0f0] rounded-xl">
                  <PermissionsComponent
                    form={form}
                    disabled={disabled}
                    roleDetail={roleDetail}
                    isNewRole={isNewRole}
                    setNewRoles={setNewRoles}
                    newRoles={newRoles}
                    setRoleDetail={setRoleDetail}
                    dataNewRole={dataNewRole}
                    setDataNewRole={setDataNewRole}
                    referenceToRoleValue={referenceToRoleValue}
                    roleReference={roleReference}
                    setRoleReference={setRoleReference}
                  />
                </div>
              </div>
              <div className="flex gap-3 justify-end">
                <Button
                  variant="contained"
                  size="small"
                  onClick={form.handleSubmit(handleSubmit)}
                  sx={{
                    textTransform: "uppercase",
                    boxShadow: "none",
                    "&:hover": { boxShadow: "none", background: "#bccdea" },
                    background: "#bccdea",
                    color: "#001b48",
                    fontWeight: "600",
                    borderRadius: "10px",
                    width: "90px",
                    padding: "5px 0"
                  }}
                >
                  Ok
                </Button>

                <Button
                  variant="contained"
                  onClick={() => onClose()}
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    boxShadow: "none",
                    "&:hover": { boxShadow: "none", background: "#f0f0f0" },
                    background: "#f0f0f0",
                    color: "#555555",
                    fontWeight: "600",
                    borderRadius: "10px",
                    width: "90px",
                    padding: "5px 0"
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </>
        )}
        <Backdrop
          sx={{ opacity: "0", backgroundColor: "rgba(0, 0, 0, 0.2)", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  );
}

export default RoleModal;
