import axiosClient from "./api-config/axiosClient";

const OtherAPI = {
  async getOptionTypeRequestLeave() {
    const url = `/GetOptionTypeRequestLeave`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getOptionTypeProject() {
    const url = `/GetOptionTypeProject`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getOptionClient() {
    const url = `/GetOptionClient`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getOptionStatusProject() {
    const url = `/GetOptionStatusProject`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getNewProjectNumber() {
    const url = `/GetNewProjectNumber`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getFiscalNumber() {
    const url = `/GetFiscalNumber`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },
  async getOptionStatus() {
    const url = `/GetOptionStatus`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },
  async getOptionPosition() {
    const url = `/GetOptionPosition`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  }
};

export default OtherAPI;
