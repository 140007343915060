import { Button, Tooltip } from "@mui/material";
import CalendarDatePicker from "components/DateTimePicker/CalendarDatePicker";
import LockTimesheetPopup from "components/LockTimesheetPopup/LockTimesheetPopup";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeDaySelected,
  getListTimesheetsByMonthAndYear,
  getTimesheetCategories,
  getTimesheetObjects
} from "store/timesheetSlice";
import { ToastShowSuccess } from "utils/Notify";
import { checkIsAdmin } from "utils/accessUltils";
import TimeSheetTable from "./overview/TimesheetTable";
import UserSelect from "./overview/UserSelect";

TimeSheet.propTypes = {};

function TimeSheet() {
  const dispatch = useDispatch();
  const userIdSelected = useSelector((state) => state.timesheet.userIdSelected);
  const daySelected = useSelector((state) => state.timesheet.daySelected);
  const menuAccess = useSelector((state) => state.common.menuAccess);
  const viewOtherPermission = menuAccess?.staff?.timesheetOther?.isView || false;
  const lockPermission = menuAccess?.staff?.timesheetOther?.isLock || false;

  const [openLockPopup, setOpenLockPopup] = useState(false);
  const [month, setMonth] = useState(daySelected || dayjs(new Date()));

  const showEditSuccess = (description) => {
    ToastShowSuccess(description);
  };

  const getListTSTasks = async function (timeParams) {
    const action = getListTimesheetsByMonthAndYear(timeParams);
    await dispatch(action);
  };

  const handleLockOpen = () => {
    setOpenLockPopup(true);
  };

  const handleChangeMonth = (monthSelected) => {
    setMonth(monthSelected);
    let timeParams = {
      userId: userIdSelected,
      month: dayjs(monthSelected).month() + 1,
      year: dayjs(monthSelected).year(),
      isAdmin: checkIsAdmin(menuAccess)
    };
    dispatch(changeDaySelected(dayjs(monthSelected)));
    getListTSTasks(timeParams);
  };

  const fetchDataForTimeParams = async () => {
    let timeParams = {
      userId: userIdSelected,
      month: dayjs(month).month() + 1,
      year: dayjs(month).year(),
      isAdmin: checkIsAdmin(menuAccess)
    };
    await getListTSTasks(timeParams);
    await dispatch(getTimesheetCategories());
    await dispatch(getTimesheetObjects());
  };

  let lockPopup = null;
  if (openLockPopup === true) {
    lockPopup = (
      <LockTimesheetPopup
        isOpen={openLockPopup}
        setOpenLockPopup={setOpenLockPopup}
        handleLock={showEditSuccess}
        refetchData={fetchDataForTimeParams}
      ></LockTimesheetPopup>
    );
  }

  useEffect(() => {
    fetchDataForTimeParams();
  }, []);

  return (
    <div className="flex flex-col h-full gap-1 pt-2">
      <div className="flex h-[5%] w-full items-center justify-between pl-2 pr-2">
        <div className="flex w-[20%] items-center justify-start">{viewOtherPermission && <UserSelect />}</div>

        <div className="flex w-[60%] items-center justify-center">
          {/* <MonthPickerTTD month={month} onChangeMonth={handleChangeMonth} /> */}
          <CalendarDatePicker month={month} onChangeMonth={handleChangeMonth} optionsView={["year", "month"]} />
        </div>

        <div className="flex w-[20%] items-center justify-end">
          {lockPermission && (
            <Tooltip title="Lock timesheet mode" placement="bottom">
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "#BCCDEA"
                  },
                  background: "#BCCDEA",
                  color: "#001B48",
                  fontWeight: 600,
                  borderRadius: "10px",
                  width: "90px",
                  padding: "5px 0"
                }}
                onClick={handleLockOpen}
              >
                Lock
              </Button>
            </Tooltip>
          )}
        </div>
      </div>

      <div className="flex w-full h-full">
        <TimeSheetTable daySelected={month} />
      </div>

      {lockPopup}
    </div>
  );
}
export default TimeSheet;
