import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Dialog, DialogContent } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import HolidayAPI from "api/HolidayAPI";
import Autocomplete from "components/Autocomplete";
import DatePickerTTD from "components/DateTimePicker/DatePickerTTD";
import DropdownListSelection from "components/DropdownListSelection";
import TextInputTTD from "components/PhaseIII/TextInputTTD";
import { typeHolidays } from "components/SharedData";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { formatDateYYYYMMDD } from "utils/DateTimeUtils";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";
import { showAllValueErrors } from "utils/formErrorUtil";
import * as yup from "yup";
import styles from "./holidayModal.module.scss";

const HolidayModal = ({ open, onClose, dataHolidayApply, refreshQueryObject }) => {
  const schema = yup.object().shape({
    type: yup.string().required("Yêu cầu nhập"),
    name: yup.string().required("Name không được để trống"),
    startDate: yup.string().required("Yêu cầu nhập"),
    endDate: yup.string().required("Yêu cầu nhập")
  });

  const form = useForm({
    defaultValues: {
      type: "",
      name: "",
      startDate: dayjs(new Date()),
      endDate: dayjs(new Date()),
      applyFor: ""
    },
    resolver: yupResolver(schema)
  });

  const mutationCreate = useMutation({
    mutationFn: (value) => {
      return HolidayAPI.CreateHoliday(value);
    },
    onError: (error) => {
      if (error.response.status === 400) {
        const errorData = error.response.data.errors;
        ToastShowError(showAllValueErrors(errorData));
      } else ToastShowError(TOAST_ERROR_STRING);
    },
    onSuccess: () => {
      ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
      onClose();
      refreshQueryObject();
    }
  });

  const handleSubmit = async () => {
    var valueForm = form.getValues();

    let valueSubmit = {
      type: valueForm?.type,
      name: valueForm?.name,
      startDate: formatDateYYYYMMDD(valueForm?.startDate) || new Date(),
      endDate: formatDateYYYYMMDD(valueForm?.endDate) || new Date(),
      applyFor: valueForm?.applyFor
    };

    mutationCreate.mutate(valueSubmit);
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth="lg" PaperProps={{ sx: { borderRadius: "5px" } }}>
      <DialogContent style={{ padding: "20px" }}>
        <div className={styles.holiday__container}>
          <h3 className={styles.holiday__title}>New</h3>
          <div className={styles.holiday__type}>
            <DropdownListSelection
              name="type"
              label={
                <div className={styles.holiday__form_label}>
                  <span>Type</span>
                  <span className={styles.holiday__required_mark}>*</span>
                </div>
              }
              form={form}
              listSelection={typeHolidays.map((item) => ({
                label: item.type,
                value: item.type
              }))}
            />
          </div>

          <div className={styles.holiday__name}>
            <TextInputTTD form={form} defaultValue="" name="name" label="Name" requiredInput={true} />
          </div>

          <div className={styles.holiday__start_date_end_date}>
            <DatePickerTTD
              name="startDate"
              label="Start Date"
              requiredInput={true}
              form={form}
              disablePreviousDate={false}
            />
            <DatePickerTTD
              name="endDate"
              label="End Date"
              requiredInput={true}
              form={form}
              disablePreviousDate={false}
            />
          </div>

          <div className={styles.holiday__apply_for}>
            <Autocomplete name="applyFor" form={form} listSelection={dataHolidayApply} />
          </div>

          <div className={styles.holiday__btn}>
            <div className={styles.leave__btn_ok}>
              <Button
                variant="contained"
                size="small"
                onClick={form.handleSubmit(handleSubmit)}
                sx={{
                  textTransform: "uppercase",
                  boxShadow: "none",
                  "&:hover": { boxShadow: "none", backgroundColor: "#BCCDEA" },
                  background: "#BCCDEA",
                  color: "#001B48",
                  fontWeight: 600,
                  borderRadius: "10px",
                  width: "90px",
                  padding: "5px 0"
                }}
              >
                OK
              </Button>
            </div>
            <div className={styles.leave__btn_cancel}>
              <Button
                variant="contained"
                onClick={() => {
                  onClose();
                }}
                size="small"
                sx={{
                  textTransform: "capitalize",
                  boxShadow: "none",
                  "&:hover": { boxShadow: "none", background: "#F0F0F0" },
                  background: "#F0F0F0",
                  color: "#555555",
                  fontWeight: 600,
                  borderRadius: "10px",
                  width: "90px",
                  padding: "5px 0",
                  marginLeft: "20px"
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default HolidayModal;
