import Avatar from "@mui/material/Avatar";

export default function BadgeAvatars({ fullName, avatarPath, stateColor }) {
  return (
    <div className="relative m-1">
      <Avatar alt={fullName} src={avatarPath} sx={{ width: 32, height: 32 }} />
      <div
        className={`h-3 w-3 rounded-full absolute bottom-0 left-0 border-[1px] border-white`}
        style={{ backgroundColor: stateColor }}
      ></div>
    </div>
  );
}
