import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AdminUserAPI from "api/AdminUserAPI";
import TeamAPI from "api/TeamAPI";
import TimeSheetAPI from "api/TimeSheetAPI";

// createAsyncThunk cái này sử dụng cho login và register
export const getTeams = createAsyncThunk("Team/GetTeams", async () => {
  try {
    const response = await TeamAPI.getTeams();
    return response.data;
  } catch (error) {
    console.log(error);
    return error.message;
  }
});

export const getMenuAccess = createAsyncThunk("Users/getMenuAccess", async () => {
  try {
    const response = await AdminUserAPI.getMenuAccess();
    return response.data;
  } catch (error) {
    console.log(error);
    return error.message;
  }
});

export const getDashboardUser = createAsyncThunk("Users/getDashboardUser", async () => {
  try {
    const response = await AdminUserAPI.getDashboardUser();
    return response.data;
  } catch (error) {
    console.log(error);
    return error.message;
  }
});

export const getAllTimeSheetProjectsByUserId = createAsyncThunk(
  "TimesheetProject/GetAllTimesheetProjectsByUserId",
  async () => {
    try {
      const response = await TimeSheetAPI.getListProjectsByUser();
      return response.data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

const commonSlice = createSlice({
  name: "common",
  initialState: {
    teams: [],
    menuAccess: [],
    listAllUsers: [],
    timesheetProjects: [],
    status: "idle",
    error: null
  },
  reducers: {
    setListAllUsers: (state, action) => {
      state.listAllUsers = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeams.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTeams.fulfilled, (state, action) => {
        state.teams = action.payload;
        state.status = "succeeded";
      })
      .addCase(getTeams.rejected, (state, action) => {
        state.status = action.error.message;
      })
      .addCase(getDashboardUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDashboardUser.fulfilled, (state, action) => {
        state.listAllUsers = action.payload;
        state.status = "succeeded";
      })
      .addCase(getDashboardUser.rejected, (state, action) => {
        state.status = action.error.message;
      })
      .addCase(getAllTimeSheetProjectsByUserId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllTimeSheetProjectsByUserId.fulfilled, (state, action) => {
        state.timesheetProjects = action.payload;
        state.status = "succeeded";
      })
      .addCase(getAllTimeSheetProjectsByUserId.rejected, (state, action) => {
        state.status = action.error.message;
      })

      .addCase(getMenuAccess.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMenuAccess.fulfilled, (state, action) => {
        state.menuAccess = action.payload;
        state.status = "succeeded";
      })
      .addCase(getMenuAccess.rejected, (state, action) => {
        state.status = action.error.message;
      });
  }
});

const { actions, reducer } = commonSlice;
export const { setListAllUsers } = actions;
export default reducer;
