import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Dialog } from "@mui/material";
import { useForm } from "react-hook-form";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import * as yup from "yup";
import styles from "./styles.module.scss";

import UserOverTimeAPI from "api/OverTimeAPI";
import CustomInputField from "components/CustomInputField";
import DatePickerFormInput from "components/DateTimePicker/DatePickerFormInput";
import DropdownListSelection from "components/DropdownListSelection";
import Editor from "components/TextEditor";
import { formatDateYYYYMMDD } from "utils/DateTimeUtils";
import { TOAST_ERROR_STRING, TOAST_UPDATE_SUCCESS_STRING } from "utils/ToastString";

function EditOverTimeModal(props) {
  const { disable, onClose, open, onSuccess, dataOverTimeDetail, dataListObject, dataListCategory, dataListProject } =
    props;

  const timeRegExp = /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;

  const schema = yup.object().shape({
    date: yup.string().required("Yêu cầu nhập"),
    start: yup.string().required("Yêu cầu nhập").matches(timeRegExp, "thời gian không hợp lệ"),
    end: yup.string().required("Yêu cầu nhập").matches(timeRegExp, "thời gian không hợp lệ"),
    projectId: yup.string().required("Yêu cầu nhập"),
    categoryId: yup.string().required("Yêu cầu nhập"),
    objectId: yup.string().required("Yêu cầu nhập"),
    workDecription: yup.string().required("Yêu cầu nhập")
  });
  const initValue = {
    id: dataOverTimeDetail?.id,
    date: new Date(dataOverTimeDetail.date),
    start: dataOverTimeDetail?.start,
    end: dataOverTimeDetail?.end,
    projectId: dataOverTimeDetail?.projectId,
    categoryId: dataOverTimeDetail?.categoryId,
    objectId: dataOverTimeDetail?.objectId,
    workDecription: dataOverTimeDetail?.reason
  };
  const form = useForm({
    defaultValues: initValue,
    resolver: yupResolver(schema)
  });

  const handleClose = () => {
    onClose(false);
  };

  const handleErrorServer = (errorServerData) => {
    for (const field in errorServerData.errors) {
      const fieldName = field[0].toLowerCase() + field.slice(1);
      form.setError(fieldName, {
        type: "manual",
        message: errorServerData.errors[field][0]
      });
    }
  };

  const handleSubmit = async () => {
    var valueForm = form.getValues();

    let valueSubmit = {
      id: dataOverTimeDetail?.id,
      date: formatDateYYYYMMDD(valueForm?.date),
      start: valueForm?.start,
      end: valueForm?.end,
      projectId: valueForm?.projectId,
      categoryId: valueForm?.categoryId,
      objectId: valueForm?.objectId,
      reason: valueForm?.workDecription
    };

    try {
      const response = await UserOverTimeAPI.update(valueSubmit);
      if (response?.status === 200) {
        ToastShowSuccess(TOAST_UPDATE_SUCCESS_STRING);
        onClose();
        onSuccess();
      }
    } catch (error) {
      const errorServerData = error.response.data;
      if (errorServerData.status === 400) {
        if (errorServerData) {
          handleErrorServer(errorServerData);
        } else {
          ToastShowError(error.message || TOAST_ERROR_STRING);
        }
      } else {
        ToastShowError(TOAST_ERROR_STRING);
      }
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ sx: { borderRadius: "5px" } }}>
      <div style={{ padding: "20px" }}>
        <div className={styles.overtime_container}>
          <div className={styles.overtime_title}>
            <span>Update</span>
          </div>
          <div className={styles.overtime_date}>
            <DatePickerFormInput
              name="date"
              label={
                <div className={styles.overtime_form__label}>
                  <span className={styles.overtime_lable}> Date</span>
                  <span className={styles.overtime_required__mark}>*</span>
                </div>
              }
              form={form}
            />
          </div>
          <div className={styles.overtime_time}>
            <CustomInputField
              name="start"
              label={
                <div className={styles.overtime_form__label}>
                  <span className={styles.overtime_lable}>Start Time</span>
                  <span className={styles.overtime_required__mark}>*</span>
                </div>
              }
              disable={disable}
              form={form}
            />
            <div className={styles.overtime_end__time}>
              <CustomInputField
                name="end"
                label={
                  <div className={styles.overtime_form__label}>
                    <span className={styles.overtime_lable}>End Time</span>
                    <span className={styles.overtime_required__mark}>*</span>
                  </div>
                }
                disable={disable}
                form={form}
              />
            </div>
          </div>
          <div className={styles.overtime_project__name}>
            <DropdownListSelection
              name="projectId"
              label={
                <div className={styles.overtime_form__label}>
                  <span className={styles.overtime_lable}>Project Name</span>
                  <span className={styles.overtime_required__mark}>*</span>
                </div>
              }
              disable={disable}
              form={form}
              listSelection={dataListProject.map((project) => {
                return {
                  value: project.id,
                  label: project.fullName
                };
              })}
            />
          </div>
          <div className={styles.section_category__object}>
            <div className="w-full">
              <DropdownListSelection
                name="categoryId"
                label={
                  <div className={styles.overtime_form__label}>
                    <span className={styles.overtime_lable}>Category</span>
                    <span className={styles.overtime_required__mark}>*</span>
                  </div>
                }
                disable={disable}
                form={form}
                listSelection={dataListCategory.map((category) => {
                  return {
                    value: category.id,
                    label: category.name
                  };
                })}
              />
            </div>
            <div className="w-full">
              <DropdownListSelection
                name="objectId"
                label={
                  <div className={styles.overtime_form__label}>
                    <span className={styles.overtime_lable}>Object</span>
                    <span className={styles.overtime_required__mark}>*</span>
                  </div>
                }
                disable={disable}
                form={form}
                listSelection={dataListObject.map((object) => {
                  return {
                    value: object.id,
                    label: object.name
                  };
                })}
              />
            </div>
          </div>
          <div className={styles.work_description}>
            <div className="flex flex-row">
              <span className="flex flex-row items-start ml-2">Work Description</span>
              <p style={{ color: "red", marginLeft: "5px" }}>*</p>
            </div>
            <Editor name="workDecription" form={form} />
          </div>

          <div className={styles.overtime_btn}>
            <div className={styles.overtime_btn__ok}>
              <Button
                variant="contained"
                size="small"
                onClick={form.handleSubmit(handleSubmit)}
                sx={{
                  textTransform: "uppercase",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "#BCCDEA"
                  },
                  background: "#BCCDEA",
                  color: "#001B48",
                  fontWeight: 600,
                  borderRadius: "10px",
                  width: "90px",
                  padding: "5px 0"
                }}
              >
                OK
              </Button>
            </div>
            <div className={styles.overtime_btn__cancel}>
              <Button
                variant="contained"
                onClick={() => {
                  handleClose();
                }}
                size="small"
                sx={{
                  textTransform: "capitalize",
                  boxShadow: "none",
                  "&:hover": { boxShadow: "none", background: "#F0F0F0" },
                  background: "#F0F0F0",
                  color: "#555555",
                  fontWeight: 600,
                  borderRadius: "10px",
                  width: "90px",
                  padding: "5px 0",
                  marginLeft: "20px"
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default EditOverTimeModal;
