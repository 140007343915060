import { yupResolver } from "@hookform/resolvers/yup";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Button, Dialog, Typography } from "@mui/material";
import AdminTimeSheetAPI from "api/AdminTimesheetAPI";
import DatePickerTTD from "components/DateTimePicker/DatePickerTTD";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { formatDateYYYYMMDD } from "utils/DateTimeUtils";
import { ToastShowError, ToastShowSuccess } from "utils/Notify";
import { showAllValueErrors } from "utils/formErrorUtil";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    fromDate: yup.string().required("Please enter from date."),
    toDate: yup.string().required("Please enter to date.")
  })
  .required();

function LockTimesheetPopup(props) {
  const { setOpenLockPopup, refetchData } = props;

  const handelClosePopup = () => {
    setOpenLockPopup(false);
  };

  const date = new Date();
  const firstDayCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);

  const form = useForm({
    defaultValues: {
      fromDate: dayjs(firstDayCurrentMonth) || dayjs(new Date()),
      toDate: dayjs(new Date()).subtract(1, "day")
    },
    resolver: yupResolver(schema)
  });

  const onSubmit = async (id) => {
    let values = form.getValues();
    const fromDate = formatDateYYYYMMDD(values.fromDate);
    const toDate = formatDateYYYYMMDD(values.toDate);

    let dataSubmit = {
      start: fromDate,
      end: toDate,
      isLock: id === "unlockButton" ? false : true
    };

    try {
      const response = await AdminTimeSheetAPI.lockTimesheet(dataSubmit);

      if (response?.status === 200) {
        if (id === "unlockButton") {
          ToastShowSuccess(`Timesheet has been unlocked from ${fromDate} to ${toDate}`);
        } else {
          ToastShowSuccess(`Timesheet has been locked from ${fromDate} to ${toDate}`);
        }
        handelClosePopup();
        refetchData();
      }
    } catch (error) {
      const errorData = error.response.data.errors;
      ToastShowError(showAllValueErrors(errorData));
    }
  };

  return (
    <div>
      <Dialog open={props?.isOpen} onClose={handelClosePopup}>
        <div className="shared__userinfomation ">
          <div className="userinformation__title">
            <Typography variant="h5" sx={{ fontWeight: "600", paddingTop: "10px" }}>
              Timesheet Lock
            </Typography>
          </div>

          <div className="userinformation__form">
            <div className="flex items-center gap-3">
              <DatePickerTTD name="fromDate" label="Start Date" form={form} requiredInput={true} />
              <DatePickerTTD name="toDate" label="End Date" requiredInput={true} form={form} />
            </div>
          </div>

          <div
            className="userinformation__form-section-06"
            style={{ display: "flex", justifyContent: "space-between", paddingTop: "15px" }}
          >
            <Button
              variant="contained"
              style={{ width: "100px" }}
              sx={{
                textTransform: "capitalize",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "#BCCDEA"
                },
                background: "#BCCDEA",
                color: "#001B48",
                fontWeight: 600,
                borderRadius: "10px",
                width: "90px",
                padding: "5px 0"
              }}
              id="lockButton"
              onClick={() => form.handleSubmit(onSubmit("lockButton"))}
              startIcon={<LockIcon />}
            >
              Lock
            </Button>
            <Button
              variant="contained"
              id="unlockButton"
              style={{ width: "100px" }}
              sx={{
                textTransform: "capitalize",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "#BCCDEA"
                },
                background: "#BCCDEA",
                color: "#001B48",
                fontWeight: 600,
                borderRadius: "10px",
                width: "90px",
                padding: "5px 0"
              }}
              onClick={() => form.handleSubmit(onSubmit("unlockButton"))}
              startIcon={<LockOpenIcon />}
            >
              Unlock
            </Button>

            <Button
              variant="contained"
              onClick={() => {
                setOpenLockPopup();
              }}
              size="small"
              sx={{
                textTransform: "capitalize",
                boxShadow: "none",
                "&:hover": { boxShadow: "none", background: "#F0F0F0" },
                background: "#F0F0F0",
                color: "#555555",
                fontWeight: 600,
                borderRadius: "10px",
                width: "90px",
                padding: "5px 0"
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default LockTimesheetPopup;
