import { useQueries } from "@tanstack/react-query";
import AdminLeaveFormAPI from "api/AdminLeaveFormAPI";
import AdminOverTimeAPI from "api/AdminOverTimeAPI";
import AdminWfhAPI from "api/AdminWfhAPI";
import dayjs from "dayjs";
import MenuItem from "../MenuItem/MenuItem";

function SideBarAdmin(props) {
  const { navigation, navigationClick } = props;

  const handleOnClick = (value) => {
    if (navigationClick) navigationClick(value);
  };

  const [listViewOverTimeQuery, listViewAdminLeaveQuery, listViewAdminWFHQuery] = useQueries({
    queries: [
      {
        queryKey: ["listViewOverTimeQuery"],
        queryFn: async () => {
          const response = await AdminOverTimeAPI.getListView({ year: dayjs().year(), month: dayjs().month() + 1 });
          return response.data;
        }
      },
      {
        queryKey: ["listViewLeaveQuery"],
        queryFn: async () => {
          const response = await AdminLeaveFormAPI.getListView(dayjs().year(), dayjs().month() + 1);
          return response.data;
        }
      },
      {
        queryKey: ["listWFHQuery"],
        queryFn: async () => {
          const response = await AdminWfhAPI.getListView(dayjs().year(), dayjs().month() + 1);
          return response.data;
        }
      }
    ]
  });

  const { data: listViewAdminOverTime } = listViewOverTimeQuery;

  const { data: listViewAdminLeave } = listViewAdminLeaveQuery;
  const { data: listViewAdminWFH } = listViewAdminWFHQuery;

  const OTPendingCount = listViewAdminOverTime?.filter((x) => x.status === "Pending").length;
  const leavePendingCount = listViewAdminLeave?.filter((x) => x.status === "Pending").length;
  const WFHPendingCount = listViewAdminWFH?.filter((x) => x.status === "Pending").length;

  const renderNavigation = navigation.map((x) => {
    if (x.name === "OVER TIME") {
      return {
        ...x,
        numberNotice: OTPendingCount
      };
    } else if (x.name === "LEAVE FORM") {
      return {
        ...x,
        numberNotice: leavePendingCount
      };
    } else if (x.name === "WORK FROM HOME") {
      return {
        ...x,
        numberNotice: WFHPendingCount
      };
    } else {
      return {
        ...x,
        numberNotice: 0
      };
    }
  });

  return (
    <>
      <div className="w-full border-t-[1px] border-t-[rgb(194, 194, 194)] flex flex-col">
        <h3 className="lg:block hidden h-10 ml-5 text-lg font-semibold leading-[40px]">ADMIN</h3>
        {renderNavigation.map((item, index) => (
          <MenuItem key={index} navigation={item} onClick={handleOnClick} numberNotice={item.numberNotice} />
        ))}
      </div>
    </>
  );
}

export default SideBarAdmin;
