import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

function timeToMinutes(timeString) {
  if (!timeString) {
    return NaN;
  }

  const [hours, minutes] = timeString.split(":").map(Number);
  return hours * 60 + minutes;
}

function DateWorkInfo(props) {
  const { otHours, hourWorking, hourTotal } = props;

  const minutes = timeToMinutes(otHours);
  const haveOTTime = !isNaN(minutes) && minutes > 0;

  return (
    <div className="flex w-full h-[15%] items-center justify-between font-semibold text-sm">
      <div className={`${!otHours && "invisible"}`}>
        {haveOTTime && (
          <div className="flex items-center">
            <AccessTimeFilledIcon sx={{ fontSize: "18px" }} /> {otHours}
          </div>
        )}
      </div>
      <div className={`${!hourWorking && "invisible"}`}>
        <span
        // style={{
        //   color: failedTime ? "violet" : enough8h ? null : "#ff8c00",
        // }}
        >{`${hourWorking}`}</span>
        <span style={{ color: "gray" }}>{` / ${hourTotal}`}</span>
      </div>
    </div>
  );
}

export default DateWorkInfo;
