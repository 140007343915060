import axiosClient from "./api-config/axiosClient";

const CategoryAPI = {
  async GetOption() {
    const url = `/Category/GetOption`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  }
};
export default CategoryAPI;
