/* eslint-disable @typescript-eslint/no-empty-function */
import { Autocomplete, Button, Dialog, DialogActions, DialogTitle, TextField } from "@mui/material";
import DatePickerComponent from "components/DateTimePicker/DatePickerComponent";
import FormError from "components/FormError";
import { Formik } from "formik";
import { formatDateYYYYMMDD } from "utils/DateTimeUtils";
import * as Yup from "yup";

const swapDaySchema = Yup.object().shape({
  description: Yup.string().required("Description không được để trống"),
  fromDate: Yup.date().required("From Date không được để trống"),
  toDate: Yup.date().required("To Date không được để trống")
});

function DialogSwapDay(props) {
  const { isOpen, handleClose, handleOk, swapDay, usersInfos } = props;

  const initialValues = {
    description: swapDay?.description ?? "",
    fromDate: swapDay?.fromDateText ?? new Date(),
    toDate: swapDay?.toDateText ?? new Date(),
    users: usersInfos?.filter((u) =>
      swapDay?.users
        .split(",")
        .map((u) => u.replaceAll(" ", ""))
        ?.includes(u.name)
    )
  };

  const handleSubmit = async (values, { setErrors }) => {
    const userIds = values.users.map((u) => u.id);
    const data = {
      ...values,
      userIds: userIds,
      id: swapDay?.id,
      fromDate: formatDateYYYYMMDD(values.fromDate),
      toDate: formatDateYYYYMMDD(values.toDate)
    };
    handleOk(data, setErrors);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">
        <div className="text-xl text-center font-bold">{swapDay?.fromDateText ? "Update" : "New"}</div>
      </DialogTitle>
      <div className="px-5 pt-3 w-[370px] mb-5">
        <Formik initialValues={initialValues} validationSchema={swapDaySchema} onSubmit={handleSubmit}>
          {({ values, handleChange, errors, handleSubmit, setErrors, setFieldValue }) => (
            <div className=" flex flex-col">
              <div className=" flex flex-row gap-2">
                <div className="flex flex-col gap-[2px] items-start justify-start">
                  <label htmlFor="fromDate" className=" pl-2">
                    Leave Date<span className="text-red-500 ml-1 font-bold">*</span>
                  </label>
                  <DatePickerComponent
                    name="fromDate"
                    id="fromDate"
                    value={new Date(values.fromDate)}
                    onChange={(e) => {
                      setFieldValue("fromDate", e.toISOString().slice(0, 10));
                    }}
                    style={{
                      backgroundColor: "#dcdcdc",
                      borderRadius: "10px",
                      "& .MuiOutlinedInput-notchedOutline": { borderWidth: "0px" }
                    }}
                  ></DatePickerComponent>
                  <FormError error={errors.fromDate}></FormError>
                </div>

                <div className="flex flex-col gap-[2px] items-start justify-start">
                  <label htmlFor="toDate" className="  pl-2 ">
                    Compensatory Date<span className="text-red-500 ml-1 font-bold">*</span>
                  </label>
                  <DatePickerComponent
                    name="toDate"
                    id="toDate"
                    value={new Date(values.toDate)}
                    onChange={(e) => {
                      setFieldValue("toDate", e.toISOString().slice(0, 10));
                    }}
                    style={{
                      backgroundColor: "#dcdcdc",
                      borderRadius: "10px",
                      "& .MuiOutlinedInput-notchedOutline": { borderWidth: "0px" }
                    }}
                  ></DatePickerComponent>
                  <FormError error={errors.toDate}></FormError>
                </div>
              </div>

              <div className="flex flex-col gap-[2px] items-start justify-start">
                <label htmlFor="description" className="  pl-2 ">
                  Description<span className="text-red-500 ml-1 font-bold">*</span>
                </label>
                <textarea
                  type="description"
                  name="description"
                  id="description"
                  value={values.description}
                  onChange={handleChange}
                  autoFocus
                  rows="3"
                  className="w-full outline-none resize-none bg-bg_textfield rounded-[10px] p-[10px] "
                ></textarea>
                <FormError error={errors.description}></FormError>
              </div>

              <div className="flex flex-col gap-[2px] items-start justify-start w-full">
                <label htmlFor="users" className="  pl-2 ">
                  User
                </label>
                <Autocomplete
                  fullWidth
                  multiple
                  size="small"
                  sx={{
                    "& .MuiInputBase-input": {
                      padding: "5px !important"
                    },
                    backgroundColor: "#dcdcdc",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-notchedOutline": { borderWidth: "0px" }
                  }}
                  type="users"
                  name="users"
                  id="users"
                  options={usersInfos}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} />}
                  defaultValue={values.users}
                  onChange={(_, value) => {
                    setFieldValue("users", value);
                  }}
                />
              </div>

              <DialogActions sx={{ padding: "0px", marginTop: "20px" }}>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#BCCDEA"
                    },
                    background: "#BCCDEA",
                    color: "#001B48",
                    fontWeight: 600,
                    borderRadius: "10px",
                    width: "90px",
                    padding: "5px 0",
                    marginRight: "10px",
                    textTransform: "none"
                  }}
                  onClick={() => handleSubmit(values, setErrors)}
                >
                  OK
                </Button>

                <Button
                  variant="contained"
                  onClick={() => {
                    handleClose();
                  }}
                  size="small"
                  sx={{
                    boxShadow: "none",
                    "&:hover": { boxShadow: "none", background: "#F0F0F0" },
                    background: "#F0F0F0",
                    color: "#555555",
                    fontWeight: 600,
                    borderRadius: "10px",
                    width: "90px",
                    padding: "5px 0",
                    marginLeft: "20px",
                    textTransform: "none"
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </div>
          )}
        </Formik>
      </div>
    </Dialog>
  );
}

export default DialogSwapDay;
