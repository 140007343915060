import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ReportsAPI from "api/ReportsAPI";

// createAsyncThunk cái này sử dụng cho login và register
export const getReportsOfLeaveByUserId = createAsyncThunk("Timesheet/getReportsOfLeaveByUserId", async (payload) => {
  try {
    const response = await ReportsAPI.getReportsOfLeaveByUserId(payload);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.message;
  }
});

export const getSummaryOfTimesheetByDateRange = createAsyncThunk(
  "Timesheet/GetSummaryOfTimesheetByDateRange",
  async (payload) => {
    try {
      const response = await ReportsAPI.getSummaryOfTimesheetByDateRange(payload.month, payload.year);
      return response.data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

export const getProjectHoursByUserIdAndDateRange = createAsyncThunk(
  "Reports/GetProjectHoursByUserIdAndDateRange",
  async (payload) => {
    try {
      const response = await ReportsAPI.getProjectHoursByUserIdAndDateRange(payload);
      return response.data;
    } catch (error) {
      console.log(error);
      return error.message;
    }
  }
);

const reportSlice = createSlice({
  name: "reports",
  initialState: {
    reportOfLeaves: {},
    summaryOfTimesheets: [],
    projectHours: [],
    status: "idle",
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getReportsOfLeaveByUserId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getReportsOfLeaveByUserId.fulfilled, (state, action) => {
        state.reportOfLeaves = action.payload;
        state.status = "succeeded";
      })
      .addCase(getReportsOfLeaveByUserId.rejected, (state, action) => {
        state.status = action.error.message;
      })
      .addCase(getSummaryOfTimesheetByDateRange.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSummaryOfTimesheetByDateRange.fulfilled, (state, action) => {
        state.summaryOfTimesheets = action.payload;
        state.status = "succeeded";
      })
      .addCase(getSummaryOfTimesheetByDateRange.rejected, (state, action) => {
        state.status = action.error.message;
      })
      .addCase(getProjectHoursByUserIdAndDateRange.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProjectHoursByUserIdAndDateRange.fulfilled, (state, action) => {
        state.projectHours = action.payload;
        state.status = "succeeded";
      })
      .addCase(getProjectHoursByUserIdAndDateRange.rejected, (state, action) => {
        state.status = action.error.message;
      });
  }
});

const { actions, reducer } = reportSlice;
export const { a = {} } = actions;
export default reducer;
