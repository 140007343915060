import axiosClient from "./api-config/axiosClient";

const CalendarAPI = {
  async getObjects() {
    const url = `/Calendar/GetOption`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getListView(params) {
    const { type, year, month } = params;

    const url = `/Calendar/GetListView/${type}/${year}/${month}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getDetailDay(params) {
    const { type, year, month, day } = params;

    const url = `/Calendar/getDetailDay/${type}/${year}/${month}/${day}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getDetail(id) {
    const url = `/Calendar/getDetail/${id}`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async getOption() {
    const url = `/Calendar/getOption`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  },

  async createCalendar(params) {
    const url = `/Calendar/Create`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: params
    });
  },
  async updateCalendar(params) {
    const url = `/Calendar/Update`;
    return axiosClient.request({
      method: "PUT",
      url: url,
      data: params
    });
  },

  async deleteCalendarById(id) {
    const url = `/Calendar/Delete/${id}`;
    return axiosClient.request({
      method: "DELETE",
      url: url
    });
  },

  async getDashboardNotices() {
    const url = `/Calendar/getDashboardNotices`;
    return axiosClient.request({
      method: "GET",
      url: url
    });
  }
};

export default CalendarAPI;
